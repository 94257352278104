import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import ProjectHeader from "../../ProjectHeader";
import ProjectTitle from "../../ProjectTitle";
import { useFormik } from "formik";
import NotificationPopUp from "../../../../utils/NotificationPopUp";
import {
  getProjectJpacInfoToStore,
  updateJpacInfo,
} from "../../../../../actions/projectActions";
import * as store from "../../../../../state";
import JPACForm from "./JPACForm";

const JPAC = (props) => {
  const { projectId } = props;
  const history = useHistory();
  const dispatchRedux = useDispatch();

  const jpac = useSelector(store.selectors.project.selectJPACInfo);
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const { roleId } = loggedInUser;

  const classes = useStyles();

  const initialValuesForJPAC = {
    projectId: parseInt(projectId),
    jpacProjectId: null,
    projectName: "",
    projectNumber: "",
    complete: "",
    compositeRate: "",
    wbsHours: "",
    productivityDifferential: "",
    updateDate: null,
  };

  const [initialValues, setInitialValues] = useState(initialValuesForJPAC);
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const onSuccess = () => {
    setNotificationTitle("Project updated");
    setIsNotificationPopUpOpen(true);
    setNotificationText(<p>Project successfully updated.</p>);
    setNotificationType("success");
    setNotificationActions([
      {
        name: "OK",
        action: () => {
          handleCloseModal();
        },
      },
    ]);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateJpacInfo(values, dispatchRedux, onSuccess);
    },
  });

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleCancel = () => {
    history.push("/projects");
  };

  useEffect(() => {
    if (jpac && jpac.jpacProjectId) {
      setInitialValues(jpac);
    } else {
      setInitialValues(initialValuesForJPAC);
    }
  }, [jpac]);

  useEffect(() => {
    async function fetchData() {
      await getProjectJpacInfoToStore(dispatchRedux, projectId);
    }
    fetchData();
  }, []);

  return (
    <>
      <ProjectHeader projectId={projectId} />
      <div className={classes.container}>
        <ProjectTitle
          pageTitle={"DCI Interconnection -> JPAC®"}
          actions={[
            {
              name: "Cancel",
              action: () => handleCancel(),
              type: "default",
            },
            {
              name: "Save",
              action: () => formik.handleSubmit(),
              type: "success",
            },
          ]}
        />
        <JPACForm formik={formik} roleId={roleId} projectId={projectId} />
      </div>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
  lastUpdateLabel: {
    marginBottom: ".5rem",
  },
}));

export default JPAC;
