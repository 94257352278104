import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { toUpper } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";
import {
  Grid,
  Box,
  Button,
  Hidden,
  Divider,
  TextField,
} from "@material-ui/core";

import FancyCard from "../FancyCard";
import CreateProjectButton from "../../elements/CreateProjectButton";
import ProjectDashboardChart from "./ProjectDashboardChart";
import * as constants from "../../../constants";

import {
  getProjects,
  getDashboardReports,
} from "../../../actions/projectActions";
import ProjectDashboardCards from "./ProjectDashboardCards";
import ProjectsTable from "../ProjectsTable";
import CustomInput from "../../elements/CustomInput";
import Footer from "../../elements/Footer";
import { checkPermission } from "../../../utils/helperFunctions";
import * as store from "../../../state";

const ProjectDashboard = () => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const projects = useSelector(store.selectors.project.selectProjects);
  const dashboardReports = useSelector(
    store.selectors.project.selectDashboardReports
  );
  const companyId = loggedInUser.userCompany?.id;
  const { roleId } = loggedInUser;
  const [projectView, setProjectView] = useState(true); // default set to card view
  const [projectType, setProjectType] = useState("Current");
  const [rows, setRows] = useState(projects);
  const [query, setQuery] = useState("");

  let time;
  const onChangeQuery = (event) => {
    clearTimeout(time);
    time = setTimeout(() => {
      setQuery(event.target.value.toLowerCase());
    }, 50);
  };

  useEffect(() => {
    setRows(projects);
  }, [projects]);

  useEffect(() => {
    const newRows = query
      ? projects.filter((x) => {
          if (x.name && x.proposalNumber && x.customer.name) {
            return (
              x.name.toLowerCase().includes(query) ||
              x.proposalNumber.toLowerCase().includes(query) ||
              x.customer.name.toLowerCase().includes(query)
            );
          }
          return false;
        })
      : projects;
    setRows(newRows);
  }, [query]);

  useEffect(() => {
    if (companyId != null) {
      getProjects(companyId, projectType, query).then((projects) => {
        dispatchRedux(store.actions.project.setProjects(projects));

        if (roleId === 1 || roleId === 2) {
          getDashboardReports(companyId).then((data) => {
            dispatchRedux(store.actions.project.setDashboardReports(data));
          });
        }
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId != null) {
      getProjects(companyId, projectType, query).then((projects) => {
        dispatchRedux(store.actions.project.setProjects(projects));
        setRows(projects);
      });
    }
  }, [companyId, projectType]);

  const changeView = () => {
    setProjectView(!projectView);
  };
  const {
    total,
    totalPipeline,
    totalBacklog,
    totalProjects,
    pipelineProjects,
    backlogProjects,
  } = dashboardReports;
  return (
    <>
      {checkPermission([1, 2], loggedInUser.roleId) && (
        <>
          <Box className={classes.pageTitle}>DASHBOARD</Box>
          <Divider className={classes.divider} />
        </>
      )}
      <Grid container spacing={3}>
        {checkPermission([1, 2], loggedInUser.roleId) && (
          <>
            <Grid item xs={12} md={6} lg={6} className={classes.fancyCards}>
              <Grid item xs={6} md={6} lg={6}>
                <ProjectDashboardChart
                  pipelineProjects={totalPipeline}
                  backlogProjects={totalBacklog}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <div className={classes.statusGroup}>
                  <FancyCard money amount={total} label="total" text="Total" />
                  <FancyCard
                    money={false}
                    amount={totalProjects}
                    label="total"
                    text="Total Projects"
                  />
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} lg={6} className={classes.fancyCards}>
              <Grid item xs={6} md={6} lg={6} className={classes.statusGroup}>
                <FancyCard
                  money
                  amount={totalPipeline}
                  label="pipeline"
                  text="Total Pipeline"
                />
                <FancyCard
                  money={false}
                  amount={pipelineProjects}
                  label="pipeline"
                  text="Pipeline Projects"
                />
              </Grid>
              <Grid item xs={6} md={6} lg={6} className={classes.statusGroup}>
                <FancyCard
                  money
                  amount={totalBacklog}
                  label="backlog"
                  text="Total Backlog"
                />
                <FancyCard
                  money={false}
                  amount={backlogProjects}
                  label="backlog"
                  text="Backlog Projects"
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6} lg={6}>
          <Hidden mdUp implementation="css">
            <CreateProjectButton />
          </Hidden>
        </Grid>
        <Grid item xs={12} s={12}>
          <Grid container>
            <Grid item lg={6} md={6}>
              <div className={classes.titleButtons}>
                <Box className={classes.pageTitle}>{toUpper("projects")}</Box>
                <Button
                  variant="text"
                  size="large"
                  className={clsx(
                    projectType === "Current"
                      ? classes.projectTypeButtonSelected
                      : classes.projectTypeButtonNotSelected
                  )}
                  onClick={() => setProjectType("Current")}
                >
                  Current
                </Button>
                <Button
                  variant="text"
                  size="large"
                  className={clsx(
                    projectType === "Archive"
                      ? classes.projectTypeButtonSelected
                      : classes.projectTypeButtonNotSelected
                  )}
                  onClick={() => setProjectType("Archive")}
                >
                  Archive
                </Button>
              </div>
            </Grid>
            <Grid item lg={6} md={6}>
              <div className={classes.controlUnit}>
                <Button
                  variant="outlined"
                  color="default"
                  className={clsx(
                    classes.button,
                    projectView
                      ? classes.buttonSelected
                      : classes.buttonNotSelected
                  )}
                  startIcon={<Menu />}
                  onClick={changeView}
                >
                  card view
                </Button>
                <Button
                  disableRipple
                  variant="outlined"
                  color="default"
                  className={clsx(
                    classes.button,
                    projectView
                      ? classes.buttonNotSelected
                      : classes.buttonSelected
                  )}
                  startIcon={<Menu />}
                  onClick={changeView}
                >
                  list view
                </Button>
                <TextField
                  margin="dense"
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                    inputComponent: CustomInput,
                    onChange: onChangeQuery,
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12} s={12}>
          {projectView ? (
            <ProjectDashboardCards
              rows={rows}
              projectType={projectType}
              roleId={roleId}
            />
          ) : (
            <ProjectsTable rows={rows} projectType={projectType} />
          )}
        </Grid>
      </Grid>
      <Box pt={4}>
        <Footer />
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: "bold",
  },
  fancyCards: {
    display: "flex",
    flexDirection: "row",
  },
  statusGroup: {
    display: "flex",
    flexDirection: "column",
  },
  fixedHeight: {
    height: constants.DRAWER_WIDTH,
  },
  drawer: {
    width: constants.DRAWER_WIDTH,
    flexShrink: 0,
  },
  button: {
    borderRadius: "6px",
    margin: "0.5rem",
    color: "black",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  buttonSelected: {
    border: `1px solid ${theme.palette.secondary.main}`,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  buttonNotSelected: {
    backgroundColor: theme.palette.secondary.light,
    border: "none ",
  },
  controlUnit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  titleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& button": {
      minWidth: "8rem",
      marginLeft: "2rem",
      marginTop: "0.2rem",
      borderRadius: "0rem",
      fontSize: theme.typography.h6.fontSize,
    },
  },
  projectTypeButtonSelected: {
    borderBottom: `0.4rem solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  projectTypeButtonNotSelected: {
    borderBottom: "0.4rem solid #BEE3F8",
    color: "#BEE3F8",
  },
}));

export default ProjectDashboard;
