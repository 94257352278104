import { server } from "../api/server";

export const login = async (credentials) => {
  const response = await server({
    url: "/user/login",
    method: "POST",
    data: credentials,
  });

  return response.data;
};
