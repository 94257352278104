export const LOGIN_SET_TOKENS = "LOGIN_SET_TOKENS";
export const LOGIN_SET_LOGGED_IN_USER = "LOGIN_SET_LOGGED_IN_USER";
export const LOGIN_INIT_STATE = "LOGIN_INIT_STATE";

export const initState = () => ({
  type: LOGIN_INIT_STATE,
});

export const setLoginTokens = (payload) => ({
  type: LOGIN_SET_TOKENS,
  payload,
});

export const setLoggedInUser = (payload) => ({
  type: LOGIN_SET_LOGGED_IN_USER,
  payload,
});
