import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import CustomTextField from "../../../elements/CustomTextField";
import { checkPermission } from "../../../../utils/helperFunctions";
import * as store from "../../../../state";

const Financials = (props) => {
  const { formik } = props;
  const classes = useStyles();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const roleId = loggedInUser.roleId;

  return (
    <div className={classes.container}>
      <h4 className={classes.pageTitle}>Financials</h4>
      <Grid container>
        <Grid xs item className={classes.item}>
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="originalContractAmount"
            label={
              formik.values.projectStatusId >= 8
                ? "Original Contract Amount*"
                : "Original Contract Amount"
            }
            formik={formik}
            className={classes.customField}
          />
        </Grid>
        <Grid xs item className={classes.item}>
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="originalEstimatedTotalCost"
            label={
              formik.values.projectStatusId >= 8
                ? "Original Estimated Cost*"
                : "Original Estimated Cost"
            }
            formik={formik}
            className={classes.customField}
          />
        </Grid>
        <Grid xs item className={classes.item}>
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="originalEstimatedLaborCost"
            label={
              formik.values.projectStatusId >= 8
                ? "Original Estimated Labor Cost*"
                : "Original Estimated Labor Cost"
            }
            formik={formik}
            className={classes.customField}
          />
        </Grid>
        <Grid xs item className={classes.item}>
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="originalEstimatedLaborHours"
            label={
              formik.values.projectStatusId >= 8
                ? "Original Estimated Labor Hours*"
                : "Original Estimated Labor Hours"
            }
            formik={formik}
            className={classes.customField}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          <p className={classes.label}>
            {" "}
            Original Estimated Composite Rate:
            <b className={classes.boldLabel}>
              {formik.values.originalEstimatedLaborCost === null ||
              formik.values.originalEstimatedLaborCost === "" ||
              !formik.values.originalEstimatedLaborHours ||
              formik.values.originalEstimatedLaborHours === "0"
                ? ""
                : "$" +
                  (
                    Math.round(
                      (formik.values.originalEstimatedLaborCost /
                        formik.values.originalEstimatedLaborHours) *
                        100
                    ) / 100
                  ).toFixed(2)}
            </b>
          </p>
          <p className={classes.label}>
            {" "}
            Original Estimated Profit:
            <b className={classes.boldLabel}>
              {formik.values.originalContractAmount === null ||
              formik.values.originalContractAmount === "" ||
              formik.values.originalEstimatedTotalCost === null ||
              formik.values.originalEstimatedTotalCost === ""
                ? ""
                : `$${(
                    formik.values.originalContractAmount -
                    formik.values.originalEstimatedTotalCost
                  ).toFixed(2)}`}
            </b>
          </p>
          <p className={classes.label}>
            {" "}
            Original Estimated Profit Margin:
            <b className={classes.boldLabel}>
              {!formik.values.originalContractAmount ||
              formik.values.originalEstimatedTotalCost === null ||
              formik.values.originalEstimatedTotalCost === "" ||
              formik.values.originalContractAmount === "0"
                ? ""
                : `${parseInt(
                    Math.round(
                      ((formik.values.originalContractAmount -
                        formik.values.originalEstimatedTotalCost) /
                        formik.values.originalContractAmount) *
                        100
                    )
                  )}%`}
            </b>
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "4rem",
  },
  item: {
    minWidth: "12rem",
    marginRight: "2rem",
  },
  lastItem: {
    minWidth: "10rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    borderRadius: 5,
  },
  label: {
    marginLeft: ".5rem",
    width: "20rem",
  },
  boldLabel: {
    marginLeft: ".5rem",
  },
}));

export default Financials;
