import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@material-ui/core";
import CustomTextField from "../../../elements/CustomTextField";
import { checkPermission } from "../../../../utils/helperFunctions";
import {
  getMarketSegmentsDataToStore,
  getMarketSubcategoriesDataToStore,
  getMarketCategoriesDataToStore,
} from "../../../../actions/resourcesAction";
import * as store from "../../../../state";

const ProjectClassification = (props) => {
  const { formik } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const marketSegments = useSelector(
    store.selectors.resource.selectMarketSegments
  );
  const marketCategories = useSelector(
    store.selectors.resource.selectMarketCategories
  );
  const marketSubcategories = useSelector(
    store.selectors.resource.selectMarketSubcategories
  );
  const { roleId } = loggedInUser;

  useEffect(() => {
    getMarketSubcategoriesDataToStore(formik.values.marketCategoryId, {
      dispatchRedux,
    });
  }, [formik.values.marketCategoryId]);

  useEffect(async () => {
    await getMarketCategoriesDataToStore(formik.values.marketSegmentId, {
      dispatchRedux,
    });
  }, [formik.values.marketSegmentId]);

  useEffect(() => {
    if (
      marketSubcategories.length > 0 &&
      marketSubcategories.filter(
        (marketSubcategory) =>
          marketSubcategory.id === formik.values.marketSubcategoryId
      ).length === 0
    ) {
      formik.setFieldValue("marketSubcategoryId", "");
    }
  }, [marketSubcategories]);

  useEffect(() => {
    if (
      marketCategories.length > 0 &&
      marketCategories.filter(
        (marketCategory) => marketCategory.id === formik.values.marketCategoryId
      ).length === 0
    ) {
      formik.setFieldValue("marketCategoryId", "");
      formik.setFieldValue("marketSubcategoryId", "");
    }
  }, [marketCategories]);

  useEffect(async () => {
    await Promise.all([getMarketSegmentsDataToStore({ dispatchRedux })]);
  }, []);

  return (
    <>
      <h4 className={classes.pageTitle}>Project Classification</h4>
      <Grid container className={classes.content}>
        <Grid item xs className={classes.item}>
          <p className={classes.label}>New or improvement?</p>
          <RadioGroup
            row
            id="constructionTypeId"
            value={formik.values.constructionTypeId}
            aria-label="constructionTypeId"
            name="constructionTypeId"
            onChange={(event, value) => {
              formik.setFieldValue("constructionTypeId", value);
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                {formik.errors && formik.touched.constructionTypeId && (
                  <Box className={classes.errorMessage}>
                    {formik.errors.constructionTypeId}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={checkPermission([5], roleId)}
                  value="1"
                  control={<Radio color="primary" />}
                  label="New Construction"
                  classes={{
                    label: classes.radioLabel,
                  }}
                />
                <FormControlLabel
                  disabled={checkPermission([5], roleId)}
                  value="2"
                  control={<Radio color="primary" />}
                  label="Remodeling/Improvements"
                  classes={{
                    label: classes.radioLabel,
                  }}
                />
              </Grid>
            </Grid>
          </RadioGroup>
          <CustomTextField
            name="marketSegmentId"
            label={
              formik.values.projectStatusTypeId > 1
                ? "Market Segment*"
                : "Market Segment"
            }
            formik={formik}
            select
            disabled={checkPermission([5], roleId)}
            options={marketSegments.map((marketSegment) => (
              <MenuItem key={marketSegment.id} value={marketSegment.id}>
                {marketSegment.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs className={classes.item}>
          <p className={classes.label}>Private or public?</p>
          <RadioGroup
            row
            id="marketSectorId"
            value={formik.values.marketSectorId}
            aria-label="marketSector"
            name="projectType"
            formik={formik}
            onChange={(event, value) => {
              formik.setFieldValue("marketSectorId", value);
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                {formik.errors && formik.touched.marketSectorId && (
                  <Box className={classes.errorMessage}>
                    {formik.errors.marketSectorId}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={checkPermission([5], roleId)}
                  value="1"
                  control={<Radio color="primary" />}
                  label="Private Sector"
                  classes={{
                    label: classes.radioLabel,
                  }}
                />
                <FormControlLabel
                  disabled={checkPermission([5], roleId)}
                  value="2"
                  control={<Radio color="primary" />}
                  label="Public Sector"
                  classes={{
                    label: classes.radioLabel,
                  }}
                />
              </Grid>
            </Grid>
          </RadioGroup>
          <CustomTextField
            name="marketCategoryId"
            label={
              formik.values.projectStatusTypeId > 1
                ? "Market Category*"
                : "Market Category"
            }
            formik={formik}
            select
            disabled={checkPermission([5], roleId)}
            options={marketCategories.map((marketCategory) => (
              <MenuItem key={marketCategory.id} value={marketCategory.id}>
                {marketCategory.name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs className={classes.lastItem}>
          <p className={classes.label}>
            Minority Participation Goal(M/WBE/DBE)
          </p>
          <RadioGroup
            row
            id="isMinorityParticipationGoal"
            aria-label="isMinorityParticipationGoal"
            name="isMinorityParticipationGoal"
            value={formik.values.isMinorityParticipationGoal}
            onChange={(event, value) => {
              formik.setFieldValue("isMinorityParticipationGoal", value);
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                {formik.errors &&
                  formik.touched.isMinorityParticipationGoal && (
                    <Box className={classes.errorMessage}>
                      {formik.errors.isMinorityParticipationGoal}
                    </Box>
                  )}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  disabled={checkPermission([5], roleId)}
                  value="1"
                  control={<Radio color="primary" />}
                  label="Yes"
                  classes={{
                    label: classes.radioLabel,
                  }}
                />
                <FormControlLabel
                  disabled={checkPermission([5], roleId)}
                  value="0"
                  control={<Radio color="primary" />}
                  label="No"
                  classes={{
                    label: classes.radioLabel,
                  }}
                />
              </Grid>
            </Grid>
          </RadioGroup>
          <CustomTextField
            name="marketSubcategoryId"
            label={
              formik.values.projectStatusTypeId > 1
                ? "Market Subcategory*"
                : "Market Subcategory"
            }
            formik={formik}
            select
            disabled={checkPermission([5], roleId)}
            options={marketSubcategories.map((marketSubcategory) => (
              <MenuItem key={marketSubcategory.id} value={marketSubcategory.id}>
                {marketSubcategory.name}
              </MenuItem>
            ))}
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  item: {
    minWidth: "10rem",
    marginRight: "2rem",
  },
  lastItem: {
    minWidth: "10rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    borderRadius: 5,
  },
  label: {
    margin: 0,
  },
  content: {
    paddingLeft: 15,
  },
  customField: {
    minHeight: "3rem",
    margin: "0",
    marginTop: "0.35rem",
  },
  radioLabel: {
    fontSize: "0.825rem",
  },
  hr: {
    border: "solid 1px dimgrey !important",
  },
  errorMessage: {
    fontSize: "12px",
    color: "#FF0000",
  },
}));

export default ProjectClassification;
