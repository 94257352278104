import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import ProjectHeader from "../ProjectHeader";
import ProposalCard from "./ProposalCard";
import { Grid, Checkbox, FormControlLabel, Button } from "@material-ui/core";
import { getProposals } from "../../../../actions/proposalActions";
import * as store from "../../../../state";

const ProposalsDashboard = (props) => {
  const { projectId } = props;
  const dispatchRedux = useDispatch();

  const proposals = useSelector(store.selectors.proposal.selectProposals);

  const classes = useStyles();

  useEffect(async () => {
    await Promise.all([getProposals({ dispatchRedux }, projectId)]);
  }, []);

  return (
    <>
      <ProjectHeader projectId={projectId} />
      <div className={classes.container}>
        <Grid container>
          <Grid xs={10} item>
            <h2 className={classes.pageTitle}> Proposals </h2>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  color="primary"
                  checked={true}
                  disabled={true}
                  // READ ONLY FOR THE MOMENT, WE ONLY HAVE ONE PROPOSAL
                  //onChange={/}
                />
              }
              label={
                <p>
                  <b>Primary proposal</b> (Disaplys in Pipeline)
                </p>
              }
            />
          </Grid>
          <Grid xs={2} item>
            <Button
              variant="contained"
              id={"createNewButton"}
              className={classes.button}
              //TO-DO : point to good page
              //onClick={() => action.action()}
            >
              {"Create new proposal"}
            </Button>
          </Grid>
        </Grid>
        {proposals.map((proposal) => (
          <ProposalCard
            key={proposal.proposalNumber}
            proposalId={proposal.id}
            proposalNumber={proposal.proposalNumber}
            customer={proposal.customer}
            version={proposal.version}
            description={proposal.description}
            projectStatusTypeId={proposal.statusTypeId}
            projectId={projectId}
          />
        ))}
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
  pageTitle: {
    textTransform: "uppercase",
    color: "#3c4655",
    margin: 0,
  },
  checkbox: {
    "& span": {
      margin: "0",
      color: "black",
      fontSize: ".875rem",
    },
  },
  button: {
    backgroundColor: "#3182CE",
  },
}));

export default ProposalsDashboard;
