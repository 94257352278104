import React from "react";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { DRAWER_BACKGROUND_COLOR } from "../../constants";
import LoginForm from "./LoginForm";
import RequestAccount from "./RequestAccount";
import Footer from "../elements/Footer";

const Login = () => {
  const classes = useStyles();
  return (
    <div className={classes.loginContainer}>
      <div className={classes.whiteContainer}>
        <LoginForm />
        <Divider orientation="vertical" className={classes.divider} />
        <RequestAccount />
      </div>
      <div className={classes.footer}>
        <Divider />
        <Footer />
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  loginContainer: {
    width: "100vw",
    height: "100vh",
    zIndex: "1202",
    backgroundColor: DRAWER_BACKGROUND_COLOR,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  whiteContainer: {
    display: "flex",
    backgroundColor: "#fff",
    padding: "4rem",
    marginTop: "10%",
    maxWidth: "60rem",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1rem",
  },
  footer: {
    width: "65%",
    position: "absolute",
    bottom: "1rem",
  },
  divider: {
    margin: "0 2rem",
  },
}));

export default Login;
