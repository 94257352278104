import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import ProjectHeader from "../ProjectHeader";
import ProjectTitle from "../ProjectTitle";
import Details from "./Details";
import { useFormik } from "formik";
import Location from "./Location";
import ProjectClassification from "./ProjectClassification";
import NotificationPopUp from "../../../utils/NotificationPopUp";
import {
  getProject,
  deleteProjects,
  updateProject,
} from "../../../../actions/projectActions";
import { projectDetailsValidationSchema } from "../../validationSchemas/validationSchema";
import * as store from "../../../../state";

const GeneralInformation = (props) => {
  const { projectId } = props;
  const history = useHistory();
  const dispatchRedux = useDispatch();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId = loggedInUser.userCompany?.id;
  const roleId = loggedInUser.roleId;
  const project = useSelector(store.selectors.project.selectProject);

  const classes = useStyles();

  const initialValuesForGeneralInformation = {
    projectStatusTypeId: 0,
    // details
    id: projectId,
    name: "",
    divisionId: "",
    subsidiaryId: "",
    companyId,
    description: "",
    customer: null,
    childProjects: [],
    expectedStartDate: "",
    expectedEndDate: "",
    //location
    location: null,
    countryId: "",
    stateId: "",
    countyId: "",
    city: "",
    address1: "",
    address2: "",
    postalCode: "",
    //classification
    marketSegmentId: "",
    marketCategoryId: "",
    marketSubcategoryId: "",
    constructionTypeId: "",
    marketSectorId: "",
    isMinorityParticipationGoal: "",
    parentId: null,
  };

  const [initialValues, setInitialValues] = useState(
    initialValuesForGeneralInformation
  );
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [initialStatus, setInitialStatus] = useState("");

  const onSucces = (projectName) => {
    setNotificationTitle("Project updated");
    setIsNotificationPopUpOpen(true);
    setNotificationText(
      <p>
        Project <b>{projectName}</b> successfully updated.
      </p>
    );
    setNotificationType("success");
    setNotificationActions([
      {
        name: "OK",
        action: () => {
          handleCloseModal();
        },
      },
    ]);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: projectDetailsValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const projectDetails = {
        ...values,
        location: {
          id: values.location?.id,
          projectRef: values.location?.projectRef,
          countryId: values.countryId,
          stateId: values.stateId,
          countyId: values.countyId,
          city: values.city,
          address1: values.address1,
          address2: values.address2,
          postalCode: values.postalCode,
        },
        isMinorityParticipationGoal: values.isMinorityParticipationGoal === "1",
        constructionTypeId: parseInt(values.constructionTypeId, 10),
        marketSectorId: parseInt(values.marketSectorId, 10),
        countyId: values.countyId ? values.countyId : null,
        stateId: values.stateId ? values.stateId : null,
        countryId: values.countryId ? values.countryId : null,
        expectedStartDate: values.expectedStartDate
          ? values.expectedStartDate
          : null,
        expectedEndDate: values.expectedEndDate ? values.expectedEndDate : null,
        marketCategoryId: values.marketCategoryId
          ? values.marketCategoryId
          : null,
        marketSegmentId: values.marketSegmentId ? values.marketSegmentId : null,
        marketSubcategoryId: values.marketSubcategoryId
          ? values.marketSubcategoryId
          : null,
      };
      updateProject(projectDetails, dispatchRedux, roleId, onSucces);
    },
  });

  const deleteProject = (id) => {
    deleteProjects([parseInt(id)], companyId, dispatchRedux);
  };

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleDeleteProject = () => {
    setNotificationTitle("Delete Project");
    setNotificationText(
      "Deleting the project will erase all information. Are you sure you want to delete the project?"
    );
    setNotificationType("warning");
    setNotificationActions([
      {
        name: "DELETE PROJECT",
        action: () => {
          deleteProject(projectId);
          handleCancel();
        },
      },
      { name: "CANCEL", action: () => handleCloseModal() },
    ]);
    setIsNotificationPopUpOpen(true);
  };

  const handleCancel = () => {
    history.push("/projects");
  };

  useEffect(() => {
    if (project && project.id) {
      setInitialStatus(project.projectStatusTypeId);
      setInitialValues({
        ...project,
        countryId: project.location?.countryId,
        stateId: project.location?.stateId,
        countyId: project.location?.countyId,
        city: project.location ? project.location.city : "",
        address1: project.location ? project.location.address1 : "",
        address2: project.location ? project.location.address2 : "",
        postalCode: project.location ? project.location.postalCode : "",
        isMinorityParticipationGoal:
          project.constructionTypeId == 0
            ? ""
            : project.isMinorityParticipationGoal
            ? "1"
            : "0",
        constructionTypeId:
          project.constructionTypeId == 0
            ? ""
            : `${project.constructionTypeId}`,
        marketSectorId:
          project.marketSectorId == 0 ? "" : `${project.marketSectorId}`,
        expectedStartDate: project.expectedStartDate || "",
        expectedEndDate: project.expectedEndDate || "",
        marketSegmentId:
          project.marketSegmentId === 0 ? "" : project.marketSegmentId,
        marketCategoryId:
          project.marketCategoryId === 0 ? "" : project.marketCategoryId,
        marketSubcategoryId:
          project.marketSubcategoryId === 0 ? "" : project.marketSubcategoryId,
        childProjects: project.childProjects.map((project) => ({
          ...project,
          name: project.proposalNumber,
        })),
      });
    }
  }, [project]);

  useEffect(() => {
    if (
      formik.values.projectStatusTypeId === 8 &&
      initialStatus !== formik.values.projectStatusTypeId
    ) {
      setNotificationTitle("Update project to Awarded?");
      setNotificationText(
        "Before you can update a project to Awarded, you need to add a contract."
      );
      setNotificationType("error");
      setNotificationActions([
        {
          name: "ADD CONTRACT",
          action: () => {
            history.push("/projectDetails/contractInformation/" + projectId);
          },
        },
        {
          name: "CANCEL",
          action: () => {
            handleCloseModal();
            formik.setFieldValue("projectStatusTypeId", initialStatus);
          },
        },
      ]);
      setIsNotificationPopUpOpen(true);
    }
  }, [formik.values.projectStatusTypeId]);

  useEffect(() => {
    async function fetchData() {
      await getProject(dispatchRedux, projectId);
    }
    fetchData();
  }, []);

  return (
    <>
      <ProjectHeader projectId={projectId} />
      <div className={classes.container}>
        <ProjectTitle
          pageTitle={"General Information"}
          currentStatus={initialValues.projectStatusTypeId}
          nameOfStatusField={"projectStatusTypeId"}
          formik={formik}
          actions={[
            {
              name: "Delete Project",
              action: () => handleDeleteProject(),
              type: "error",
            },
            {
              name: "Cancel",
              action: () => handleCancel(),
              type: "default",
            },
            {
              name: "Save",
              action: () => formik.handleSubmit(),
              type: "success",
            },
          ]}
        />
        <Details companyId={companyId} formik={formik} projectId={projectId} />
        <Location companyId={companyId} formik={formik} projectId={projectId} />
        <ProjectClassification formik={formik} />
      </div>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
}));

export default GeneralInformation;
