import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton } from "@material-ui/core";
import clsx from "clsx";
import ClearIcon from "@material-ui/icons/Clear";
import CachedIcon from "@material-ui/icons/Cached";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const Logo = (props) => {
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState(undefined);
  const classes = useStyles();
  const { file, hover, type } = props;
  const { changeFile, deleteFile } = props;

  useEffect(() => {
    if (!file) {
      setThumb(undefined);
      return;
    }
    setLoading(true);
  }, [file]);

  useEffect(() => {
    if (!file) {
      return;
    }
    if (typeof file === "string") {
      setThumb(file);
      setLoading(false);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setLoading(false);
      setThumb(reader.result);
    };
    reader.readAsDataURL(file);
  }, [loading]);

  if (loading) {
    return <p>loading...</p>;
  }
  if (!file && !props.children) {
    return null;
  }
  return (
    <div key="logo" className={classes.image}>
      {hover && (
        <div className={classes.hoverComponent}>
          {thumb ? (
            <>
              <div
                className={
                  type && type === "rounded"
                    ? classes.avatarButtonGroup
                    : classes.buttonGroup
                }
              >
                <input
                  id="logo"
                  onChange={changeFile}
                  type="file"
                  style={{ display: "none" }}
                />
                <label htmlFor="logo">
                  <IconButton
                    aria-label="replace logo"
                    className={classes.button}
                    component="span"
                  >
                    <CachedIcon />
                  </IconButton>
                </label>
                <Box>Change</Box>
              </div>

              <div
                className={
                  type && type === "rounded"
                    ? classes.avatarButtonGroup
                    : classes.buttonGroup
                }
              >
                <IconButton
                  aria-label="replace picture"
                  className={classes.button}
                  component="span"
                  onClick={deleteFile}
                >
                  <ClearIcon />
                </IconButton>
                <Box>Delete</Box>
              </div>
            </>
          ) : (
            <div
              className={
                type && type === "rounded"
                  ? classes.avatarButtonGroup
                  : classes.buttonGroup
              }
            >
              <input
                id="picture"
                onChange={changeFile}
                type="file"
                style={{ display: "none" }}
              />
              <label htmlFor="picture">
                <IconButton
                  aria-label="upload picture"
                  className={classes.button}
                  component="span"
                >
                  <AddCircleIcon />
                </IconButton>
              </label>
              <Box>Add</Box>
            </div>
          )}
        </div>
      )}
      {thumb ? (
        <img
          src={`${thumb}`}
          alt="Company Logo"
          className={clsx(classes.imageSrc, {
            [classes.opacity]: hover,
            [classes.rounded]: type && type === "rounded",
          })}
        />
      ) : (
        <Box
          className={clsx(classes.imageSrc, {
            [classes.opacity]: hover,
            [classes.rounded]: type && type === "rounded",
          })}
        >
          {props.children}
        </Box>
      )}
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  errorMessage: {
    width: "100%",
    fontSize: theme.typography.h6.fontSize,
    backgroundColor: "inherit",
    color: "red",
  },
  image: {
    width: "100%",
    position: "relative",
    borderRadius: "0.5rem",
    height: "9rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
  },
  button: {
    zIndex: 1,
    backgroundColor: "#FFFFFF",
    padding: "6px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  buttonGroup: {
    margin: "0 1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarButtonGroup: {
    margin: "0 0.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "0.8rem",
  },
  imageSrc: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    maxWidth: "11rem",
    height: "8.5rem",
    borderRadius: "inherit",
    backgroundPosition: "center 40%",
  },
  opacity: {
    opacity: 0.5,
  },
  hoverComponent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "38%",
  },
  rounded: {
    borderRadius: "100%",
    height: "6.25rem",
    width: "6.25rem",
  },
}));
export default Logo;
