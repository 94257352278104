import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import DeleteModal from "../elements/DeleteModal";
//import { SubsidiaryContext } from "../../contexts/SubsidiaryContext";
import {
  getSubsidiariesDataToStore,
  removeSubsidiariesData,
} from "../../actions/subsidiaryActions";
import * as store from "../../state";

const DeleteSubsidiaries = (props) => {
  const [open, setOpen] = useState(true);
  const dispatchRedux = useDispatch();
  const { selected } = props;
  const history = useHistory();
  //const { state, dispatch } = useContext(SubsidiaryContext);
  const subsidiaries = useSelector(
    store.selectors.subsidiary.selectSubsidiaries
  );
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId = loggedInUser.userCompany.id;

  const handleClose = () => {
    history.push("/company");
    setOpen(false);
  };
  const handleDelete = () => {
    //removeSubsidiaries(selected, dispatch);
    removeSubsidiariesData(selected);
    history.push("/company");
  };

  useEffect(async () => {
    //getSubsidiaries(dispatch, companyId);
    await getSubsidiariesDataToStore(companyId, { dispatchRedux });
  }, []);

  const getSeletedElements = (ids, elements) => {
    const selected = _.takeWhile(elements, (o) => _.includes(ids, o.id));
    return selected;
  };

  const content = (
    <>
      {getSeletedElements(selected, subsidiaries).map((sub) => (
        <div key={sub.id}>
          <span>
            Name:
            {sub.name}
          </span>
        </div>
      ))}
    </>
  );

  return (
    <div>
      <DeleteModal
        open={open}
        title="Delete Subsidiaries"
        subtitle="Are you sure you want to delete these subsidiaries"
        content={content}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default DeleteSubsidiaries;
