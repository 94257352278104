import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";

// import { ResourcesContext } from "../../contexts/ResourcesContext";
import * as constants from "../../constants";
import { getName, formatDateUS } from "../../utils/helperFunctions";
import ProjectsWithMissingInfo from "./ProjectsWithMissingInfo";
import * as store from "../../state";
const NoteContainer = (props) => {
  const classes = useStyles();
  const {
    data,
    requested,
    isTeamReports,
    isCustomerReports,
    isLocationReports,
    isMarketReports,
    filters,
  } = props;
  // const { state } = useContext(ResourcesContext);
  const jobTypes = useSelector(store.selectors.resource.selectJobTypes);
  const newProj = getProjectsByStatus(data, 2);
  const estimatingProj = getProjectsByStatus(data, 4);

  const [modalInfo, setModalInfo] = useState({
    open: false,
    title: "",
    rows: [],
  });

  const handleClose = () => {
    setModalInfo({
      open: false,
      title: "",
      rows: [],
    });
  };

  const openNew = () => {
    setModalInfo({
      open: true,
      title: "New Projects with missing information",
      rows: newProj,
    });
  };

  const openEstimating = () => {
    setModalInfo({
      open: true,
      title: "Estimating Projects with missing information",
      rows: estimatingProj,
    });
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.flexRow}>
          <Box>
            <span className={classes.title}>Note: </span>
          </Box>
          <div className={classes.flexCol}>
            {Boolean(requested) && (
              <Box className={classes.requested}>
                {`${requested} projects in “Requested” status are not included`}
              </Box>
            )}

            {Boolean(estimatingProj.length) && Boolean(newProj.length) && (
              <Box>
                {Boolean(newProj.length) && (
                  <Button
                    variant="text"
                    disableRipple
                    className={classes.newEstimating}
                    onClick={openNew}
                  >
                    {`${newProj.length} "New" projects`}
                  </Button>
                )}
                {newProj.length ? "&" : ""}
                {Boolean(estimatingProj.length) && (
                  <Button
                    variant="text"
                    disableRipple
                    className={classes.newEstimating}
                    onClick={openEstimating}
                  >
                    {`${estimatingProj.length} "Estimation/Bidding" projects`}
                  </Button>
                )}
                {Boolean(estimatingProj.length) || Boolean(newProj.length)
                  ? " are not included due to missing information."
                  : "-"}
              </Box>
            )}
          </div>
        </div>
        <p style={{ margin: 0 }}>
          <span className={classes.title}>{"Filter Criteria:  "}</span>
        </p>
        <div className={classes.flexRow}>
          <Box>
            {"Subsidiary: "}
            <span className={classes.values}>
              {filters.subsidiariesIds.length
                ? getSubsidiariesName(filters.subsidiariesIds)
                : "All"}{" "}
            </span>
            {"; Division: "}{" "}
            <span className={classes.values}>
              {filters.divisionsIds.length
                ? getDivisionsName(filters.divisionsIds)
                : "All"}{" "}
            </span>
            {"; Time: "}
            <span className={classes.values}>
              (
              {formatDateUS(filters.startDate ? filters.startDate : Date.now())}{" "}
              -
            </span>
            <span className={classes.values}>
              {filters.endDate ? formatDateUS(filters.endDate) : " All"}
              ),
            </span>
            {"; Output: "}
            <span className={classes.values}>
              {getOutputValue(filters.outputValue)}
            </span>
            {Boolean(isTeamReports) && (
              <>
                ; Project Team:
                <span className={classes.values}>
                  {getName(jobTypes, parseInt(filters.roleIds))}
                </span>
                ; Assignees:{" "}
                <span className={classes.values}>
                  {filters.assigneesIds.length
                    ? filters.assigneesIds.map(
                        (elem) => `${elem.firstName} ${elem.lastName}`
                      )
                    : "All "}
                </span>
              </>
            )}
            {Boolean(isCustomerReports) && (
              <>
                ; Customer:
                <span className={classes.values}>
                  {filters.customerIds.length
                    ? filters.customerIds.map((elem) => `${elem.name}, `)
                    : "All "}
                </span>
              </>
            )}
            {Boolean(isMarketReports) && (
              <>
                ; Market Segment:
                <span className={classes.values}>
                  {filters.marketSegmentIds.length
                    ? filters.marketSegmentIds.map((elem) => elem.name)
                    : "All "}
                </span>
                ; Market Categories:{" "}
                <span className={classes.values}>
                  {filters.marketCategoryIds.length
                    ? filters.marketCategoryIds.map((elem) => elem.name)
                    : "All "}
                </span>
              </>
            )}
            {Boolean(isLocationReports) && (
              <>
                ; States:
                <span className={classes.values}>
                  {filters.stateIds.length
                    ? filters.stateIds.map((elem) => elem.name)
                    : "All "}
                </span>
                ; Counties:{" "}
                <span className={classes.values}>
                  {filters.countyIds.length
                    ? filters.countyIds.map((elem) => elem.name)
                    : "All "}
                </span>
              </>
            )}
          </Box>
        </div>
      </div>
      <ProjectsWithMissingInfo
        open={modalInfo.open}
        title={modalInfo.title}
        rows={modalInfo.rows}
        handleClose={handleClose}
      />
    </>
  );
};

const getDivisionsName = (divisions) => {
  let names = "";
  divisions.forEach((division) => {
    names += `${division.name}, `;
  });
  return names;
};
const getSubsidiariesName = (subsidiaries) => {
  let names = "";
  subsidiaries.forEach((subsidiary) => {
    names += `${subsidiary.name}, `;
  });
  return names;
};

const getOutputValue = (type) => {
  switch (type) {
    case "contractBidValue":
      return "Contract/Bid Value";
    case "totalCost":
      return "Total Cost";
    case "laborHours":
      return "Labor Hours";
    default:
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "0.5rem 2rem",
    backgroundColor: constants.DRAWER_BACKGROUND_COLOR,
    marginTop: "2rem",
  },
  flexCol: {
    marginLeft: "1rem",
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: "0.5rem",
  },
  requested: {
    marginLeft: "0.5rem",
    color: constants.ASTERISK_COLOR,
  },
  newEstimating: {
    color: theme.palette.primary.dark,
  },
  values: {
    fontStyle: "italic",
  },
  title: {
    fontWeight: "bold",
  },
}));

export const getProjectsByStatus = (projects, projectStatusId) =>
  _.filter(projects, (o) => o.projectStatusId === projectStatusId);

export default NoteContainer;
