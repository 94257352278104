import { combineReducers } from "redux";
import appReducer from "./app";
import loginReducer from "./login";
import companyReducer from "./company";
import contactsReducer from "./contacts";
import resourceReducer from "./resource";
import reportReducer from "./report";
import subsidiaryReducer from "./subsidiary";
import userReducer from "./user";
import divisionReducer from "./division";
import projectReducer from "./project";
import proposalReducer from "./proposal";
import interconnectionReducer from "./interconnection";

export const rootReducer = combineReducers({
  app: appReducer,
  login: loginReducer,
  company: companyReducer,
  contacts: contactsReducer,
  resource: resourceReducer,
  report: reportReducer,
  subsidiary: subsidiaryReducer,
  user: userReducer,
  division: divisionReducer,
  project: projectReducer,
  proposal: proposalReducer,
  interconnection: interconnectionReducer,
});

// Reference to all reducer
const reducers = {
  appReducer,
  loginReducer,
  companyReducer,
  contactsReducer,
  resourceReducer,
  reportReducer,
  subsidiaryReducer,
  userReducer,
  divisionReducer,
  projectReducer,
  proposalReducer,
  interconnectionReducer,
};
export default reducers;
