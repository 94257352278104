import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { getLabelTitle, formatDateUS } from "../../../utils/helperFunctions";
import { getProjectHeaderInfoToStore } from "../../../actions/projectActions";
import clsx from "clsx";
import * as store from "../../../state";

const ProjectHeader = (props) => {
  const { projectId } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();

  const generalInformation = useSelector(
    store.selectors.project.selectProjectHeaderInfo
  );

  useEffect(async () => {
    await Promise.all([getProjectHeaderInfoToStore(dispatchRedux, projectId)]);
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={4} className={classes.item} spacing={0}>
        <h1 className={classes.projectName}>{generalInformation.name}</h1>
        <p className={classes.customerName}>
          {generalInformation.customer?.name
            ? generalInformation.customer?.name
            : "(No customer selected)"}
        </p>
      </Grid>
      <Grid item xs={12} sm={4} className={classes.item}>
        <p className={classes.proposalNr}>
          {generalInformation.projectStatusTypeId > 7 ? (
            <span>Project number {generalInformation.projectNumber} </span>
          ) : (
            <>
              {generalInformation.proposalNumber ? (
                <span>Proposal {generalInformation.proposalNumber} </span>
              ) : (
                <span style={{ color: "#777" }}>(No proposal number)</span>
              )}
            </>
          )}
        </p>
        {generalInformation.startDate && generalInformation.endDate && (
          <p className={classes.dates}>
            <span
              style={{
                color: "#777",
                display: "inline-block",
                marginRight: 5,
              }}
            >
              {generalInformation.projectStatusTypeId > 7
                ? "Project Dates:"
                : " Expected Dates:"}
            </span>
            <span style={{ fontWeight: "bold" }}>
              {formatDateUS(generalInformation.startDate)} -{" "}
              {formatDateUS(generalInformation.endDate)}{" "}
            </span>
          </p>
        )}
      </Grid>
      <Grid item xs={12} sm={4} container justify="flex-end">
        <div>
          <h3
            style={{ display: "flex", alignItems: "center", marginBottom: 0 }}
          >
            <span
              style={{
                marginTop: 0,
                marginBottom: 0,
                marginRight: 5,
                color: "#777",
              }}
            >
              Status:{" "}
            </span>
            <span
              className={clsx(
                classes[
                  getLabelTitle(
                    generalInformation.projectStatusTypeId
                  ).replaceAll(" ", "_")
                ],
                classes.status
              )}
            >
              {getLabelTitle(generalInformation.projectStatusTypeId)}
            </span>
          </h3>
        </div>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "25px 20px",
    backgroundColor: "#F7FAFC",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    marginTop: -35,
  },
  item: {
    minWidth: "10rem",
  },
  projectName: {
    fontWeight: "bold",
    margin: 0,
  },
  customerName: {
    fontSize: "1rem",
    margin: 0,
  },
  proposalNr: {
    fontSize: "1rem",
    margin: 0,
  },
  dates: {
    fontSize: "1rem",
    margin: 0,
  },
  status: {
    fontSize: "1rem",
    textAlign: "center",
    padding: ".5rem",
    margin: 0,
    borderRadius: 50,
    display: "inline-block",
  },
  Requested: {
    color: "#333",
  },
  New: {
    color: theme.palette.secondary.dark,
  },
  Estimating: {
    color: "rgba(118, 169, 250, 1)",
  },
  NoBid: {
    color: "#c15700",
  },
  Active: {
    color: theme.palette.success.dark,
  },
  Awarded: {
    color: theme.palette.success.main,
  },
  Bid_Won: {
    color: theme.palette.success.main,
  },
  Bid_Lost: {
    color: "rgba(255, 0, 0, 1)",
  },
  Bid_Pending: {
    color: theme.palette.info.dark,
  },
  Bid_Submitted: {
    color: theme.palette.success.dark,
  },
  Closed: {
    color: "rgba(255, 0, 0, 1)",
  },
}));

export default ProjectHeader;
