import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toUpper } from "lodash";
import { useHistory } from "react-router-dom";
import { ListItem, Box } from "@material-ui/core";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import * as store from "../../../state";

const SuperAdminListItems = (props) => {
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);

  const handleClick = () => {
    dispatchRedux(
      store.actions.login.setLoggedInUser({
        ...loggedInUser,
        userCompany: null,
      })
    );

    history.push("/systemadministration");
  };

  const handleAdminUsersClick = () => {
    history.push("/sysadmin/users");
  };

  const { classes } = props;
  return (
    <div>
      <div>
        <Box
          textAlign="left"
          fontWeight="fontWeightBold"
          letterSpacing={1}
          className={classes.title}
        >
          <WorkOutlineIcon />
          {toUpper("system admin")}
        </Box>
      </div>
      <ListItem id="accountsButton" button onClick={handleClick}>
        <Box className={classes.item}>Accounts</Box>
      </ListItem>
      <ListItem button onClick={handleAdminUsersClick}>
        <Box className={classes.item}>Admin Users</Box>
      </ListItem>
    </div>
  );
};

export default SuperAdminListItems;
