// import server from "../api/server";
import * as API from "./_apiCalls";
import _ from "lodash";
_.mixin(require("lodash-inflection"));
import * as store from "../state";
// import {
//   GET_STATES,
//   GET_INDUSTRIES,
//   GET_COUNTIES,
//   GET_COUNTRIES,
//   GET_MARKET_SEGMENTS,
//   GET_MARKET_SUBCATEGORIES,
//   GET_MARKET_CATEGORIES,
//   GET_PROJECT_TYPES,
//   GET_WORK_TYPES,
//   GET_CONTRACT_TYPES,
//   GET_CONTACT_TYPES,
//   GET_CONTACTS,
//   GET_JOB_TYPES,
//   GET_JPAC_PROJECTS,
//   GET_DIVISION_STATUSES,
// } from "./types";

export const getResourceData = async (path, resourceName, callback) => {
  return API.getData(
    `${path}/get${_.capitalize(_.pluralize(resourceName))}`,
    callback
  );
};

export const getStatesData = async (id, callback) => {
  return await API.getDataById("/state/getStates", id || 1, callback);
};

export const getStatesDataToStore = async (id, { callback, dispatchRedux }) => {
  return getStatesData(id, callback).then(({ data }) => {
    dispatchRedux(store.actions.resource.setStates(data));
  });
};

// export const getStates = async (dispatch, id) => {
//   const response = await server({
//     url: `/state/getStates/${id || 1}`,
//     method: "GET",
//   });
//   const content = response.data;
//   dispatch({
//     type: GET_STATES,
//     states: content,
//   });
// };

export const getCountiesData = async (id, callback) => {
  return await API.getDataById("/county/getCounties", id, callback);
};

export const getCountiesDataToStore = async (
  id,
  { callback, dispatchRedux }
) => {
  return getCountiesData(id, callback).then(({ data }) => {
    dispatchRedux(store.actions.resource.setCounties(data));
  });
};

// export const getCounties = async (dispatch, id) => {
//   const response = await server({
//     url: `/county/getCounties/${id}`,
//     method: "GET",
//   });
//   const content = response.data;
//   dispatch({
//     type: GET_COUNTIES,
//     counties: content,
//   });
// };

export const getCountriesData = async (callback) => {
  return getResourceData("/country", "country", callback);
};

export const getCountriesDataToStore = async ({ callback, dispatchRedux }) => {
  return getCountriesData(callback).then(({ data }) => {
    dispatchRedux(store.actions.resource.setContries(data));
  });
};

// export const getCountries = async (dispatch) => {
//   const response = await server({
//     url: "/country/getCountries",
//     method: "GET",
//   });
//   const content = response.data;
//   dispatch({
//     type: GET_COUNTRIES,
//     countries: content,
//   });
// };

export const getIndustriesData = async (callback) => {
  return getResourceData("/industryClassification", "industry", callback);
};

export const getIndustriesDataToStore = async ({ callback, dispatchRedux }) => {
  return getIndustriesData(callback).then(({ data }) => {
    dispatchRedux(store.actions.resource.setIndustries(data));
  });
};
// export const getIndustries = async (dispatch) => {
//   const response = await server({
//     url: "/industryClassification/getIndustries",
//     method: "GET",
//   });
//   const content = response.data;
//   dispatch({
//     type: GET_INDUSTRIES,
//     industries: content,
//   });
// };

export const getStatusesData = async (callback) => {
  return getResourceData("/division", "status", callback);
};

export const getStatusesDataToStore = async ({ callback, dispatchRedux }) => {
  return getStatusesData(callback).then(({ data }) => {
    dispatchRedux(store.actions.resource.setDivisionStatuses(data));
  });
};
// export const getStatuses = async (dispatch) => {
//   const response = await server({
//     url: "/division/getstatuses",
//     method: "GET",
//   });
//   const content = response.data;
//   dispatch({
//     type: GET_DIVISION_STATUSES,
//     divisionStatuses: content,
//   });
// };

export const getMarketSegmentsData = async (callback) => {
  const baseUrl = "/marketSegment/getMarketSegments";
  return await API.getData(baseUrl, callback);
};

export const getMarketSegmentsDataToStore = async ({
  callback,
  dispatchRedux,
}) => {
  return getMarketSegmentsData(callback).then(({ data }) => {
    dispatchRedux(store.actions.resource.setMarketSegments(data));
  });
};

export const getMarketCategoriesData = async (marketSegmentId, callback) => {
  const baseUrl = `/marketCategory/getMarketCategories/${marketSegmentId}`;
  return await API.getData(baseUrl, callback);
};

export const getMarketCategoriesDataToStore = async (
  marketSegmentId,
  { callback, dispatchRedux }
) => {
  return getMarketCategoriesData(marketSegmentId, callback).then(({ data }) => {
    dispatchRedux(store.actions.resource.setMarketCategories(data));
  });
};

export const getMarketSubcategoriesData = async (
  marketCategoryId,
  callback
) => {
  const baseUrl = `/marketSubcategory/getMarketSubcategories/${marketCategoryId}`;
  return await API.getData(baseUrl, callback);
};

export const getMarketSubcategoriesDataToStore = async (
  marketCategoryId,
  { dispatchRedux, callback }
) => {
  return getMarketSubcategoriesData(marketCategoryId, callback).then(
    ({ data }) => {
      dispatchRedux(store.actions.resource.setMarketSubcategories(data));
    }
  );
};

// Not called - maybe we can delete
export const getProjectStatusTypesData = async (callback) => {
  const baseUrl = "/projectStatusType/getprojectStatusTypes";
  return await API.getData(baseUrl, callback);
};

export const getWorkTypesData = async (callback) => {
  const baseUrl = "/workType/getWorkTypes";
  return await API.getData(baseUrl, callback);
};

export const getContractTypesData = async (callback) => {
  const baseUrl = "/contractType/getContractTypes";
  return await API.getData(baseUrl, callback);
};

export const getContactTypesDataToStore = async ({
  callback,
  dispatchRedux,
}) => {
  return await getContactTypesData(callback).then(({ data }) => {
    dispatchRedux(store.actions.resource.setContactTypes(data));
  });
};

export const getContactTypesData = async (callback) => {
  const baseUrl = "/contactType/getContactTypes";
  return await API.getData(baseUrl, callback);
};

export const getJobTypesData = async (callback) => {
  const baseUrl = "/jobType/getJobTypes";
  return await API.getData(baseUrl, callback);
};

// export const getJobTypes = async (dispatch) => {
//   const response = await server({
//     url: "/jobType/getJobTypes",
//     method: "GET",
//   });
//   const content = response.data;

//   dispatch({
//     type: GET_JOB_TYPES,
//     jobTypes: content,
//   });
// };

// export const getJpacProjects = async (dispatch, jpacCompanyId) => {
//   const baseUrl = `/project/getJpacProjects?jpacCompanyId=${jpacCompanyId}`;
//   const response = await server({
//     url: baseUrl,
//     method: "GET",
//   });
//   const content = response.data;
//   dispatch({
//     type: GET_JPAC_PROJECTS,
//     jpacProjects: content,
//   });
// };

export const getProjectInfoFromJpacData = async (jpacProjectId, callback) => {
  const baseUrl = `/project/getProjectInfoFromJpac?jpacProjectId=${jpacProjectId}`;
  return await API.getData(baseUrl, callback);
  // const response = await server({
  //   url: baseUrl,
  //   method: "GET",
  // });
  // const content = response.data;
  // return content;
};
// export const getProjectInfoFromJpac = async (jpacProjectId) => {
//   const baseUrl = `/project/getProjectInfoFromJpac?jpacProjectId=${jpacProjectId}`;
//   const response = await server({
//     url: baseUrl,
//     method: "GET",
//   });
//   const content = response.data;
//   return content;
// };

export const updateJpacProjectsData = async (companyId, callback) => {
  const baseUrl = `/project/updateJpacProjects?companyId=${companyId}`;
  return await API.getData(baseUrl, callback);
  // await server({
  //   url: baseUrl,
  //   method: "GET",
  // });
};
