import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";

import {
  getProjectsByStatus,
  getLabel,
  getLabelTitle,
  checkPermission,
} from "../../../utils/helperFunctions";
import Project from "./Project";
import ProjectAccordion from "./projectsAccordion/ProjectAccordion";

const ProjectDashboardCards = (props) => {
  const { rows, projectType, roleId } = props;
  const classes = useStyles();
  const [expended, setExpended] = useState("collapse");

  const expendAll = () => {
    setExpended("expand");
  };

  const collapseAll = () => {
    setExpended("collapse");
  };

  const setParentExpanded = (value) => {
    setExpended(value);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            size="small"
            className={classes.buttons}
            onClick={expendAll}
          >
            Expand All
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classes.buttons}
            onClick={collapseAll}
          >
            Collapse All
          </Button>
        </div>
        {projectType === "Current" && (
          <Grid container spacing={1} direction="column">
            <ProjectAccordion
              status="Requested"
              parentExpended={expended}
              setParentExpanded={setParentExpanded}
            >
              {getProjectsByStatus(rows, 1).map((row) => (
                <Project
                  row={row}
                  key={row.id}
                  label={getLabel(row.projectStatusTypeId)}
                  labelTitle={getLabelTitle(row.projectStatusTypeId)}
                  projectType={projectType}
                />
              ))}
            </ProjectAccordion>
            {/* New */}
            <ProjectAccordion
              status="New"
              parentExpended={expended}
              setParentExpanded={setParentExpanded}
            >
              {getProjectsByStatus(rows, 2).map((row) => (
                <Project
                  row={row}
                  key={row.id}
                  label={getLabel(row.projectStatusTypeId)}
                  labelTitle={getLabelTitle(row.projectStatusTypeId)}
                />
              ))}
            </ProjectAccordion>
            {/* Estimating */}
            <ProjectAccordion
              status="Estimating"
              parentExpended={expended}
              setParentExpanded={setParentExpanded}
            >
              {getProjectsByStatus(rows, 4).map((row) => (
                <Project
                  row={row}
                  key={row.id}
                  label={getLabel(row.projectStatusTypeId)}
                  labelTitle={getLabelTitle(row.projectStatusTypeId)}
                />
              ))}
            </ProjectAccordion>
            {/* Bid Submitted */}
            <ProjectAccordion
              status="Bid Submitted"
              parentExpended={expended}
              setParentExpanded={setParentExpanded}
            >
              {getProjectsByStatus(rows, 6).map((row) => (
                <Project
                  row={row}
                  key={row.id}
                  label={getLabel(row.projectStatusTypeId)}
                  labelTitle={getLabelTitle(row.projectStatusTypeId)}
                />
              ))}
            </ProjectAccordion>
            {/* Awarded */}
            <ProjectAccordion
              status="Awarded"
              parentExpended={expended}
              setParentExpanded={setParentExpanded}
            >
              {getProjectsByStatus(rows, 8).map((row) => (
                <Project
                  row={row}
                  key={row.id}
                  label={getLabel(row.projectStatusTypeId)}
                  labelTitle={getLabelTitle(row.projectStatusTypeId)}
                />
              ))}
            </ProjectAccordion>
            {/* Active */}
            <ProjectAccordion
              status="Active"
              parentExpended={expended}
              setParentExpanded={setParentExpanded}
            >
              {getProjectsByStatus(rows, 9).map((row) => (
                <Project
                  row={row}
                  key={row.id}
                  label={getLabel(row.projectStatusTypeId)}
                  labelTitle={getLabelTitle(row.projectStatusTypeId)}
                />
              ))}
            </ProjectAccordion>
          </Grid>
        )}
        {projectType === "Archive" && (
          <Grid container spacing={1} direction="column">
            {/* No bid */}
            <ProjectAccordion
              status="No Bid"
              parentExpended={expended}
              setParentExpanded={setParentExpanded}
            >
              {getProjectsByStatus(rows, 3).map((row) => (
                <Project
                  row={row}
                  key={row.id}
                  label={getLabel(row.projectStatusTypeId)}
                  labelTitle={getLabelTitle(row.projectStatusTypeId)}
                />
              ))}
            </ProjectAccordion>
            {/* Bid Lost */}
            <ProjectAccordion
              status="Bid Lost"
              parentExpended={expended}
              setParentExpanded={setParentExpanded}
            >
              {getProjectsByStatus(rows, 7).map((row) => (
                <Project
                  row={row}
                  key={row.id}
                  label={getLabel(row.projectStatusTypeId)}
                  labelTitle={getLabelTitle(row.projectStatusTypeId)}
                />
              ))}
            </ProjectAccordion>
            {/* Closed */}
            <ProjectAccordion
              status="Closed"
              parentExpended={expended}
              setParentExpanded={setParentExpanded}
            >
              {getProjectsByStatus(rows, 10).map((row) => (
                <Project
                  row={row}
                  key={row.id}
                  label={getLabel(row.projectStatusTypeId)}
                  labelTitle={getLabelTitle(row.projectStatusTypeId)}
                />
              ))}
            </ProjectAccordion>
            {/* Deleted */}
            {checkPermission([1], roleId) && (
              <ProjectAccordion
                status="Deleted"
                parentExpended={expended}
                setParentExpanded={setParentExpanded}
              >
                {getProjectsByStatus(rows, 11).map((row) => (
                  <Project
                    row={row}
                    key={row.id}
                    label={
                      row.previousProjectStatusTypeId
                        ? getLabel(row.previousProjectStatusTypeId)
                        : getLabel(1)
                    }
                    labelTitle={
                      row.previousProjectStatusTypeId
                        ? getLabel(row.previousProjectStatusTypeId)
                        : getLabel(1)
                    }
                  />
                ))}
              </ProjectAccordion>
            )}
          </Grid>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  buttons: {
    color: "black",
    fontWeight: "bold",
    backgroundColor: theme.palette.secondary.light,
  },
}));

export default ProjectDashboardCards;
