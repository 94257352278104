import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Box } from "@material-ui/core";
import classNames from "classnames";

const ChartWithPeriodFilter = (props) => {
  const classes = useStyles();
  const {
    onFilterChange,
    activeFilter,
    onActionButtonEvent,
    actionButtonsVisible,
    filterButtonsVisible,
    actionLinkList,
    onActionLinkEvent,
  } = props;

  const getActionListElements = (entryList) => {
    const elements = entryList.map((obj, index) => (
      <span
        key={index}
        className={classes.actionLink}
        onClick={() => onActionLinkEvent(index)}
      >{`${index > 0 ? " > " : " "} ${obj}`}</span>
    ));

    return <div key="actionList">{elements}</div>;
  };

  return (
    <>
      <div className={classes.chartButtonContainer}>
        <div className={classes.chartActionButtonContainer}>
          {actionButtonsVisible ? (
            <>
              <Button
                variant="contained"
                size="medium"
                className={classes.chartActionButton}
                onClick={() => onActionButtonEvent("first")}
              >
                <Box>&#60;&#60;</Box>
              </Button>
              <Button
                variant="contained"
                size="medium"
                className={classes.chartActionButton}
                onClick={() => onActionButtonEvent("back")}
              >
                <Box>&#60;</Box>
              </Button>
            </>
          ) : (
            <></>
          )}
          {!actionButtonsVisible &&
            actionLinkList &&
            getActionListElements(actionLinkList)}
        </div>
        {filterButtonsVisible && (
          <div className={classes.chartSettingsButtonContainer}>
            <Button
              variant="contained"
              size="medium"
              className={classNames(classes.chartSettingsButton, {
                selected: activeFilter === "monthly",
              })}
              onClick={() => onFilterChange("monthly")}
            >
              <Box>Monthly</Box>
            </Button>
            <Button
              variant="contained"
              size="medium"
              className={classNames(classes.chartSettingsButton, {
                selected: activeFilter === "quarterly",
              })}
              onClick={() => onFilterChange("quarterly")}
            >
              <Box>Quarterly</Box>
            </Button>
            <Button
              variant="contained"
              size="medium"
              className={classNames(classes.chartSettingsButton, {
                selected: activeFilter === "yearly",
              })}
              onClick={() => onFilterChange("yearly")}
            >
              <Box>Yearly</Box>
            </Button>
          </div>
        )}
      </div>
      {props.children}
    </>
  );
};

const useStyles = makeStyles({
  chartButtonContainer: {
    display: "flex",
  },
  chartActionButtonContainer: {
    textAlign: "left",
    width: "40%",
  },
  chartActionButton: {
    backgroundColor: "#F8F0F0",
    margin: "10px",
    color: "black",
  },
  chartSettingsButtonContainer: {
    textAlign: "right",
    width: "60%",
  },
  chartSettingsButton: {
    backgroundColor: "#F8F0F0",
    margin: "10px",
    color: "black",

    "&.selected": {
      backgroundColor: "#4299E1 !important",
      color: "white",
    },
  },
  actionLink: {
    cursor: "pointer",

    "&:hover": {
      fontWeight: "600",
    },
  },
});

export default ChartWithPeriodFilter;
