export const APP_SHOW_APP_LOADER = "APP_SHOW_APP_LOADER";
export const APP_HIDE_APP_LOADER = "APP_HIDE_APP_LOADER";

export const showAppLoader = () => ({
  type: APP_SHOW_APP_LOADER,
});

export const hideAppLoader = () => ({
  type: APP_HIDE_APP_LOADER,
});
