import * as actions from "../action/interconnection";

const initialState = {
  jpacProjects: [],
  jpacProjectInfo: {},
};

const interconnectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_JPAC_PROJECTS:
      return {
        ...state,
        jpacProjects: action.payload,
      };
    case actions.SET_JPAC_PROJECT_INFO:
      return {
        ...state,
        jpacProjectInfo: action.payload,
      };
    default:
      return state;
  }
};

export default interconnectionReducer;
