import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const SelectList = (props) => {
  const {
    text,
    name,
    options,
    value,
    handleChange,
    handleBlur,
    groupBy,
    disabled,
    getOptionSelected,
    isMultiSelect,
    formik,
    noOptionsText,
    onInputChange,
    getOptionLabel,
  } = props;
  const classes = useStyles();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Autocomplete
      multiple={isMultiSelect}
      disableCloseOnSelect
      className={classes.autoCompletePopup}
      disableListWrap
      limitTags={1}
      id={name}
      name={name}
      value={value}
      options={options}
      getOptionLabel={
        getOptionLabel ? getOptionLabel : (option) => `${option.name}` || ""
      }
      groupBy={groupBy}
      disabled={disabled}
      getOptionSelected={getOptionSelected}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </>
      )}
      onChange={(event, value) => handleChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="standard"
          margin="dense"
          label={text}
          className={classes.autoCompleteInput}
          error={
            formik ? formik.touched[name] && Boolean(formik.errors[name]) : null
          }
          helperText={
            formik ? formik.touched[name] && formik.errors[name] : null
          }
        />
      )}
      onBlur={() => (handleBlur ? handleBlur() : null)}
      noOptionsText={noOptionsText ? noOptionsText : <></>}
      onInputChange={onInputChange ? onInputChange : null}
    />
  );
};

const useStyles = makeStyles(() => ({
  autoCompletePopup: {
    overflowY: "visible",
  },
  autoCompleteInput: {
    overflow: "hidden",
    whiteSpace: "nowrap",

    "& div": {
      whiteSpace: "nowrap",
    },
  },
}));

export default SelectList;
