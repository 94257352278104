import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, Box, Paper } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { addSubsidiaryData } from "../../actions/subsidiaryActions";
//import { SubsidiaryContext } from "../../contexts/SubsidiaryContext";
import SubsidiaryForm from "./SubsidiaryForm";
import validationSchemaAdd from "./validationSchemas/validationSchemaAdd";
import * as store from "../../state";

const AddSubsidiary = (props) => {
  //const { dispatch } = useContext(SubsidiaryContext);
  const dispatchRedux = useDispatch();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId =
    props.view === "sysAdmin"
      ? parseInt(props.companyId)
      : loggedInUser.userCompany.id;
  const subsidiaryDashboardUrl =
    props.view === "sysAdmin"
      ? `/systemAdministration/subsidiaries/${props.companyId}`
      : "/subsidiaries";
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    history.push(subsidiaryDashboardUrl);
  };

  const initialValues = {
    companyId,
    name: "",
    subsidiaryId: "",
    industryClassificationId: "",
    divisionIndustryClassificationId: "",
    divisionName: "",
    divisionId: "",
  };

  const handleSuccess = () => {
    handleClose();
  };

  const onSubmit = async (values) => {
    //await addSubsidiary(values, dispatch, handleSuccess);
    await addSubsidiaryData(values, handleSuccess).then(({ data }) => {
      dispatchRedux(store.actions.subsidiary.setSubsidiary(data));
    });
  };

  const onCancel = () => {
    handleClose();
  };

  return (
    <>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        SYSTEM ADMINISTRATION
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.pageSubTitle}>Create New Subsidiary</Box>
      <SubsidiaryForm
        page="add"
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        validationSchema={validationSchemaAdd}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  additionalComponent: {
    marginLeft: "2rem",
    "& p": {
      margin: "0",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "1rem",
    },
  },
}));

export default AddSubsidiary;
