import * as actions from "../action/division";
import _ from "lodash";

const initialState = {
  divisions: [],
};

const addDivision = (state, division) => ({
  ...state,
  divisions: [...state.divisions, division],
});

const removeDivisions = (state, divisions) => {
  const newdivisions = state.divisions.filter(
    (division) => _.findIndex(divisions, (o) => o.id === division.id) < 0
  );
  return { ...state, divisions: newdivisions };
};

const editDivisionStatus = (state, statusId, divisionIds) => {
  const updateDivisions = state.divisions.map((division) =>
    divisionIds.indexOf(division.id) > -1 ? { ...division, statusId } : division
  );
  return { ...state, divisions: updateDivisions };
};

const divisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.DIVISION_SET_DIVISIONS:
      return {
        ...state,
        divisions: action.payload,
      };
    case actions.DIVISION_EDIT_DIVISION:
      return editDivisionStatus(
        state,
        action.payload.statusId,
        action.payload.divisionIds
      );
    case actions.DIVISION_REMOVE_DIVISION:
      return removeDivisions(state, action.payload);
    case actions.DIVISION_ADD_DIVISION:
      return addDivision(state, action.payload);
    default:
      return state;
  }
};

export default divisionReducer;
