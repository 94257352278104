import { setAction } from "./_setter";
export const GET_COMPANIES = "GET_COMPANIES";
export const ADD_COMPANY = "ADD_COMPANY";
export const GET_COMPANY = "GET_COMPANY";
export const REMOVE_COMPANIES = "REMOVE_COMPANIES";
export const EDIT_COMPANY = "EDIT_COMPANY";
export const GET_MATERIALS = "GET_MATERIALS";
export const GET_REQUIREMENTS = "GET_REQUIREMENTS";
export const ADD_MATERIALS = "ADD_MATERIALS";
export const ADD_REQUIREMENTS = "ADD_REQUIREMENTS";
export const ADD_CUSTOMERS = "ADD_CUSTOMERS";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_PERSONS = "GET_PERSONS";
export const ADD_PERSONS = "ADD_PERSONS";
export const GET_AVAILABLE_DIVISIONS = "GET_AVAILABLE_DIVISIONS";
export const GET_AVAILABLE_USER_SEATS = "GET_AVAILABLE_USER_SEATS";
export const GET_AVAILABLE_SUBSIDIARIES = "GET_AVAILABLE_SUBSIDIARIES";
export const EDIT_COMPANY_STATUS = "EDIT_COMPANY_STATUS";

export const setCompanies = setAction(GET_COMPANIES);
export const addCompany = setAction(ADD_COMPANY);
export const editCompanyStatus = setAction(EDIT_COMPANY_STATUS);
export const setCompany = setAction(GET_COMPANY);
export const setAvailableSubsidiaries = setAction(GET_AVAILABLE_SUBSIDIARIES);
export const setAvailableDivisions = setAction(GET_AVAILABLE_DIVISIONS);

export const setMaterials = setAction(GET_MATERIALS);
export const setRequirement = setAction(GET_REQUIREMENTS);
export const setCustomers = setAction(GET_CUSTOMERS);
export const setPersons = setAction(GET_PERSONS);
export const addCustomer = setAction(ADD_CUSTOMERS);
export const addPersons = setAction(ADD_PERSONS);
export const addMaterial = setAction(ADD_MATERIALS);
export const addRequirement = setAction(ADD_REQUIREMENTS);
