import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Divider, Box, Paper } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import classNames from "classnames";
import {
  getTeamReports,
  getTeamReportsExpandedTable,
} from "../../../actions/reportsActions";
import Filters from "../Filters";
import NoteContainer from "../NoteContainer";
import TeamReportsTable from "./teamReport/TeamReportsTable";
import ChartWithPeriodFilter from "../ChartWithPeriodFilter";
import { getChartObject } from "../ReportUtil";
import * as store from "../../../state";

const TeamReport = () => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const teamReport = useSelector(store.selectors.report.selectTeamReport);
  const companyId = loggedInUser.userCompany?.id;
  const requested = teamReport.requestedProjects
    ? teamReport.requestedProjects
    : 0;
  const teamReports = teamReport.assignees;
  const { missingInformationProjects } = teamReport;
  const [reportData, setReportData] = useState(teamReport);
  const [chartSettings, setChartSettings] = useState("monthly");
  const [chartSelectionFilter, setChartSelectionFilter] = useState({
    assigneeId: null,
  });
  const [chartLevelSettings, setChartLevelSettings] = useState(0);
  const [chartFilterVisible, setChartFilterVisible] = useState(false);
  const [reportFilterOutputLabel, setReportFilterOutputLabel] = useState(
    "Contract/Bid Value ($)"
  );
  const [chartLinkList, setChartLinkList] = useState(["All Team Members"]);

  const [filters, setFilters] = useState({
    companyId,
    subsidiariesIds: [],
    divisionsIds: [],
    startDate: null,
    endDate: null,
    outputValue: "contractBidValue",
    assigneesIds: [],
    roleIds: "2",
    period: "monthly",
  });
  const [query, setQuery] = useState("");

  const runReport = async (options, period) => {
    const newFilters = { ...options, period };
    setChartSettings(period);
    setFilters(newFilters);

    getTeamReports(newFilters).then((newReportData) => {
      dispatchRedux(store.actions.report.setTeamReport(newReportData));
      setChartLevelSettings(0);
      setChartSelectionFilter({ assigneeId: null });
      setReportData(newReportData);
      setFilterOutputLabel(options);
    });
  };

  const expandTable = async () => {
    const newFilters = { ...filters };
    newFilters.assigneesIds = chartSelectionFilter.assigneeId
      ? [{ id: chartSelectionFilter.assigneeId }]
      : filters.assigneesIds;
    newFilters.period = chartSettings;

    getTeamReportsExpandedTable(newFilters).then((newReportData) => {
      dispatchRedux(
        store.actions.report.setTeamExpendableTableReport(newReportData)
      );
      setReportData(newReportData);
    });
  };

  const setFilterOutputLabel = (options) => {
    if (!options || !options.outputValue) {
      return;
    }

    switch (options.outputValue) {
      case "contractBidValue":
        setReportFilterOutputLabel("Contract/Bid Value ($)");
        break;
      case "totalCost":
        setReportFilterOutputLabel("Total Cost ($)");
        break;
      case "laborHours":
        setReportFilterOutputLabel("Labor Hours");
        break;
      default:
        setReportFilterOutputLabel("N/A");
        break;
    }
  };

  useEffect(() => {
    getTeamReports(filters).then((newReportData) => {
      dispatchRedux(store.actions.report.setTeamReport(newReportData));
    });
    setReportFilterOutputLabel("Contract/Bid Value ($)");
  }, []);

  useEffect(() => {
    if (!Array.isArray(teamReports)) {
      return;
    }
    expandTable();
  }, [chartSelectionFilter, chartSettings]);

  useEffect(() => {
    let chartData = [];
    let chartCategory = "period";
    let chartTooltipText = "";
    setChartFilterVisible(true);

    switch (chartLevelSettings) {
      case 0: {
        chartData = reportData.assignees;
        chartCategory = "name";
        chartTooltipText = "Lead PM";
        setChartFilterVisible(false);
        setChartSettings("monthly");
        setChartLinkList(chartLinkList.slice(0, 1));
        break;
      }
      case 1: {
        chartData = Array.isArray(reportData.assignees[0].periods)
          ? reportData.assignees[0].periods
          : [];
        chartCategory = "period";
        chartTooltipText = "Period";
        setChartFilterVisible(true);
        if (chartSelectionFilter && chartSelectionFilter.assigneeName) {
          if (chartLinkList.length < 2) {
            setChartLinkList([
              ...chartLinkList,
              chartSelectionFilter.assigneeName,
            ]);
          } else {
            setChartLinkList(
              chartLinkList.map((element, index) =>
                index !== 1 ? element : chartSelectionFilter.assigneeName
              )
            );
          }
        }
        break;
      }
      default:
        return;
    }
    const lowerCaseQuery = query.toLowerCase();
    chartData = query
      ? chartData.filter((x) => {
          if (chartLevelSettings > 0)
            return x.period.toLowerCase().includes(lowerCaseQuery);
          return x.name.toLowerCase().includes(lowerCaseQuery);
        })
      : chartData;

    const chart = getChartObject({
      chartTooltipText,
      chartData,
      chartLevelSettings,
      chartCategory,
      reportFilterOutputLabel,
      handleChartClickFilterUpdate,
    });

    am4core.createFromConfig(chart, "chart-container", am4charts.XYChart);
  }, [
    chartLevelSettings,
    chartSelectionFilter,
    teamReports,
    reportData,
    reportFilterOutputLabel,
    query,
  ]);

  const handleChartClickFilterUpdate = (filterValue, chartLevel) => {
    if (chartLevel === 0) {
      setChartSelectionFilter({ assigneeId: filterValue.id });
      setChartLevelSettings(chartLevel + 1);
      setChartLinkList([...chartLinkList, filterValue.name]);
    }
  };

  const handleActionLink = (eventType) => {
    if (eventType !== null && eventType !== chartLevelSettings) {
      setChartLevelSettings(eventType);
      setChartSelectionFilter({ assigneeId: null });
      setChartLinkList(chartLinkList.slice(0, eventType + 1));
    }
  };

  return (
    <>
      <div>
        <Box textAlign="left" className={classes.backwardLink}>
          <ArrowBackIosIcon />
          Go back to all reports
        </Box>
        <Link to="/reports/allReports" className={classes.link}>
          <div />
        </Link>
      </div>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <PeopleIcon />
        </Paper>
        DCI
        <sup className={classes.sup}>TM&nbsp;</sup>
        Pipeline & Backlog Report: Project Team
      </Box>
      <Divider className={classes.divider} />

      <Filters runReport={runReport} isTeamReports />
      <Divider className={classes.divider} />

      <Paper elevation={3} className={classes.paper}>
        <ChartWithPeriodFilter
          actionButtonsVisible={false}
          filterButtonsVisible={chartFilterVisible}
          actionLinkList={chartLinkList}
          onActionLinkEvent={handleActionLink}
          onFilterChange={setChartSettings}
          activeFilter={chartSettings}
        >
          <div
            id="chart-container"
            className={classNames(classes.chartContainer, {
              chartTopPadding: !chartFilterVisible && chartLevelSettings === 0,
            })}
          />
        </ChartWithPeriodFilter>
      </Paper>
      <NoteContainer
        data={missingInformationProjects}
        requested={requested}
        isTeamReports
        filters={filters}
      />
      <TeamReportsTable
        data={teamReports}
        filters={filters}
        expandTable={expandTable}
        setChartSelectionFilter={setChartSelectionFilter}
        setChartLevelSettings={setChartLevelSettings}
        chartSelectionFilter={chartSelectionFilter}
        chartLevelSettings={chartLevelSettings}
        query={query}
        setQuery={setQuery}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: "bold",
    display: "flex",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: theme.typography.h3.fontSize,
      },
    },
  },
  backwardLink: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontSize: theme.typography.h5.fontSize,
  },
  link: {
    position: "relative",
    bottom: "2rem",
    "& div": {
      height: "2rem",
      width: "13rem",
    },
  },
  divider: {
    margin: "1rem 0",
  },
  paper: {
    height: "32rem",
    margin: "5rem 0",
    padding: "1rem",
  },
  chartContainer: {
    height: "100%",

    "&.chartTopPadding": {
      marginTop: "4%",
    },
  },
  sup: {
    verticalAlign: "super",
    fontSize: "1.25rem",
  },
}));

export default TeamReport;
