import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Box, Paper } from "@material-ui/core";
import {
  Settings,
  Create,
  Add,
  Delete,
  Beenhere,
  Block,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
// import { CompanyContext } from "../../contexts/CompanyContext";
import {
  getAvailableSubsidiariesData,
  getCompanyDataToStore,
} from "../../actions/companyActions";
// import { ResourcesContext } from "../../contexts/ResourcesContext";
import {
  getIndustriesDataToStore,
  getStatusesDataToStore,
} from "../../actions/resourcesAction";
import SubsidiaryTable from "./SubsidiaryTable";
// import { SubsidiaryContext } from "../../contexts/SubsidiaryContext";
import {
  getSubsidiariesData,
  updateSubsidiaryStatusDataToStore,
} from "../../actions/subsidiaryActions";
import NotificationPopUp from "../utils/NotificationPopUp";
import AdministrationTabBar from "../elements/AdministrationTabBar";
import * as store from "../../state";

const SubsidiaryDashboard = (props) => {
  const history = useHistory();
  const dispatchRedux = useDispatch();
  // const { dispatch, state } = useContext(SubsidiaryContext);
  const subsidiaries = useSelector(
    store.selectors.subsidiary.selectSubsidiaries
  );
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  // const companyContext = useContext(CompanyContext);
  // const resourcesContext = useContext(ResourcesContext);
  const availableSubsidiaries = useSelector(
    store.selectors.company.selectAvailableSubsidiaries
  );
  const maxNumberOfSubsidiaries = useSelector(
    store.selectors.company.selectMaxNumberOfSubsidiaries
  );

  const company = useSelector(store.selectors.company.selectCompany);

  const divisionStatuses = useSelector(
    store.selectors.resource.selectDivisionStatuses
  );
  const industries = useSelector(store.selectors.resource.selectIndustries);
  const companyId =
    props.view === "sysAdmin"
      ? parseInt(props.companyId)
      : loggedInUser.companyId;
  const addSubsidiaryUrl =
    props.view === "sysAdmin"
      ? `/systemAdministration/addSubsidiaries/${props.companyId}`
      : "/addSubsidiary";
  const editSubsidiaryRootUrl =
    props.view === "sysAdmin"
      ? `/systemAdministration/editSubsidiary/${props.companyId}?subsidiaryId=`
      : "/editSubsidiary/";
  const { roleId } = loggedInUser;
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState([]);
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const isEditButtonDisable = selectedRows.length === 1;
  const isDeleteButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.statusId === 3).length !==
      selectedRows.length;
  const isActivateButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.statusId === 2).length !== 0;
  const isDeactivateButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.statusId === 1).length !== 0;

  const deleteSubsidiary = () => {
    updateSubsidiaryStatusDataToStore(
      {
        subsidiaryIds: selectedRows
          .filter((row) => row.statusId !== 3)
          .map((row) => row.id),
        status: "DELETED",
        statusId: 3,
      },
      { dispatchRedux }
    );
    // updateSubsidiaryStatus(
    //   selectedRows.filter((row) => row.statusId !== 3).map((row) => row.id),
    //   "DELETED",
    //   3,
    //   dispatch
    // );
  };

  const activateSubsidiary = () => {
    updateSubsidiaryStatusDataToStore(
      {
        subsidiaryIds: selectedRows
          .filter((row) => row.statusId === 2)
          .map((row) => row.id),
        status: "ACTIVE",
        statusId: 1,
      },
      { dispatchRedux }
    );
    // updateSubsidiaryStatus(
    //   selectedRows.filter((row) => row.statusId === 2).map((row) => row.id),
    //   "ACTIVE",
    //   1,
    //   dispatch
    // );

    setNotificationTitle("Subsidiary has been reactivated");
    setNotificationText(
      "The selected subsidiary has been successfully reactivated."
    );
    setNotificationType("success");
    setNotificationActions([{ name: "OK", action: () => handleClose() }]);
    setIsNotificationPopUpOpen(true);
  };

  const deactivateSubsidiary = () => {
    setNotificationTitle("Deactivate Subsidiary");
    setNotificationText(
      "Deactivating a subsidiary will make it unavailable for projects. Do you want to continue?"
    );
    setNotificationType("warning");
    setNotificationActions([
      {
        name: "DEACTIVATE",
        action: () => {
          updateSubsidiaryStatusDataToStore(
            {
              subsidiaryIds: selectedRows
                .filter((row) => row.statusId === 1)
                .map((row) => row.id),
              status: "INACTIVE",
              statusId: 2,
            },
            { dispatchRedux }
          );
          // updateSubsidiaryStatus(
          //   selectedRows
          //     .filter((row) => row.statusId === 1)
          //     .map((row) => row.id),
          //   "INACTIVE",
          //   2,
          //   dispatch
          // );
          handleClose();
        },
      },
      { name: "CANCEL", action: () => handleClose() },
    ]);
    setIsNotificationPopUpOpen(true);
  };

  const handleClose = () => {
    setIsNotificationPopUpOpen(false);
  };

  const areMultipleStatusesSelected = (requiredStatus) => {
    let multipleStatusesSelected = false;
    selectedRows.forEach((row) => {
      if (row.statusId !== requiredStatus) {
        multipleStatusesSelected = true;
      }
    });
    return multipleStatusesSelected;
  };

  const handleAdd = () => {
    if (availableSubsidiaries <= 0) {
      setNotificationTitle("Subsidiary Limit");
      setNotificationText(
        "You have reached the maximum number of subsidiaries. Please contact our support for help."
      );
      setNotificationType("warning");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    } else {
      history.push(addSubsidiaryUrl);
    }
  };

  const handleEdit = () => {
    history.push(`${editSubsidiaryRootUrl}${selectedRows[0].id}`);
  };

  const handleDelete = () => {
    if (
      selectedRows.filter((row) => row.statusId === 1).length !==
      subsidiaries.filter((row) => row.statusId === 1).length
    ) {
      setNotificationTitle("Delete Subsidiary");
      setNotificationText(
        "The subsidiary will be deleted. Are you sure you want to continue?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "DELETE",
          action: () => {
            deleteSubsidiary();
            handleClose();
          },
        },
        { name: "CANCEL", action: () => handleClose() },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      setNotificationTitle("Delete Subsidiary");
      setNotificationText(
        "Deletion cannot be performed. At least one subsidiary must remain active."
      );
      setNotificationType("error");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    }
  };

  const handleActivateSubsidiary = () => {
    if (
      availableSubsidiaries -
        selectedRows.filter((row) => row.statusId === 2).length <
      0
    ) {
      setNotificationTitle("Subsidiary Limit");
      setNotificationText(
        "You have reached the maximum number of subsidiaries. Please contact our support for help."
      );
      setNotificationType("warning");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    } else if (areMultipleStatusesSelected(2)) {
      setNotificationTitle("Multiple statuses selected");
      setNotificationText(
        "Only INACTIVE subsidiaries will be activated. Would you like to proceed?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "YES",
          action: () => {
            activateSubsidiary();
          },
        },
        {
          name: "NO",
          action: () => {
            handleClose();
          },
        },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      activateSubsidiary();
    }
  };

  const handleDeactivateSubsidiary = () => {
    if (areMultipleStatusesSelected(1)) {
      setNotificationTitle("Multiple statuses selected");
      setNotificationText(
        "Only ACTIVE subsidiaries will be inactivated. Would you like to proceed?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "YES",
          action: () => {
            deactivateSubsidiary();
          },
        },
        {
          name: "NO",
          action: () => {
            handleClose();
          },
        },
      ]);
      setIsNotificationPopUpOpen(true);
    } else if (
      selectedRows.filter((row) => row.statusId === 1).length !==
      subsidiaries.filter((row) => row.statusId === 1).length
    ) {
      deactivateSubsidiary();
    } else {
      setNotificationTitle("Deactivate Subsidiary");
      setNotificationText(
        "Deactivation cannot be performed. At least one subsidiary must remain active."
      );
      setNotificationType("error");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    }
  };

  useEffect(() => {
    getAvailableSubsidiariesData(companyId, ({ data }) => {
      dispatchRedux(store.actions.company.setAvailableSubsidiaries(data));
    });
    setSelectedRows([]);
  }, [subsidiaries]);

  useEffect(async () => {
    Promise.all([
      //getSubsidiaries(dispatch, companyId);
      getSubsidiariesData(companyId, (response) => {
        dispatchRedux(store.actions.subsidiary.setSubsidiaries(response.data));
      }),
      //getAvailableSubsidiaries(companyContext.dispatch, companyId);
      getAvailableSubsidiariesData(companyId, ({ data }) => {
        dispatchRedux(store.actions.company.setAvailableSubsidiaries(data));
      }),
      //getCompany(companyId, companyContext.dispatch);
      getCompanyDataToStore(companyId, { dispatchRedux }),
      //getIndustries(resourcesContext.dispatch);
      getIndustriesDataToStore({ dispatchRedux }),
      //getStatuses(resourcesContext.dispatch);
      getStatusesDataToStore({ dispatchRedux }),
    ]);
  }, []);

  return (
    <>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleClose}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        ADMINISTRATION
      </Box>
      <Divider className={classes.divider} />
      <AdministrationTabBar
        page="subsidiaries"
        view={props.view}
        id={companyId}
      />
      <Box id="subPageTitle" className={classes.pageSubTitle}>
        {company.name}
        {" : Subsidiary Management"}
      </Box>
      <div className={classes.actionArea}>
        <div className={classes.actionButtons}>
          <div
            id="addButton"
            className={classes.actionButton}
            onClick={() => handleAdd()}
          >
            <Add className={classes.icon} />
            <b>Add</b>
          </div>
          <div
            id="editButton"
            className={
              isEditButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() => (isEditButtonDisable ? handleEdit() : null)}
          >
            <Create className={classes.icon} />
            <b>Edit</b>
          </div>
          <div
            id="deleteButton"
            className={
              isDeleteButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() => (isDeleteButtonDisable ? handleDelete() : null)}
          >
            <Delete className={classes.icon} />
            <b>Delete</b>
          </div>
          <div
            id="activateButton"
            className={
              isActivateButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isActivateButtonDisable ? handleActivateSubsidiary() : null
            }
          >
            <Beenhere className={classes.icon} />
            <b>Activate Subsidiary</b>
          </div>
          <div
            id="decativateButton"
            className={
              isDeactivateButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isDeactivateButtonDisable ? handleDeactivateSubsidiary() : null
            }
          >
            <Block className={classes.icon} />
            <b>Deactivate Subsidiary</b>
          </div>
        </div>
        <div id="totalActiveSubsidiariesLabel" className={classes.labelArea}>
          <b>Total Active Subsidiaries: </b>
          <label>
            {" "}
            {maxNumberOfSubsidiaries - availableSubsidiaries} of{" "}
            {maxNumberOfSubsidiaries} subsidiaries
          </label>
        </div>
      </div>
      <SubsidiaryTable
        setSelectedRows={setSelectedRows}
        rows={subsidiaries}
        selectedRows={selectedRows}
        roleId={roleId}
        editSubsidiaryRootUrl={editSubsidiaryRootUrl}
        statuses={divisionStatuses}
        industries={industries}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  actionArea: {
    backgroundColor: "#CBD5E0",
    padding: ".75rem",
    display: "flow-root",
  },
  labelArea: {
    float: "right",
  },
  actionButtons: {
    display: "flex",
    textAlign: "left",
    float: "left",
  },
  actionButton: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    marginRight: "1.5rem",
  },
  disableActionButton: {
    display: "flex",
    cursor: "default",
    alignItems: "center",
    marginRight: "1.5rem",
    color: "grey",
  },
  icon: {
    fontSize: "1rem",
    marginRight: ".5rem",
    color: "#4A5568",
  },
  titleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& button": {
      minWidth: "8rem",
      marginRight: ".5rem",
      marginBottom: "2rem",
      borderRadius: "0rem",
      fontSize: theme.typography.h6.fontSize,
    },
  },
  projectTypeButtonSelected: {
    borderBottom: `0.2rem solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  projectTypeButtonNotSelected: {
    borderBottom: "0.2rem solid #BEE3F8",
    color: "#BEE3F8",
  },
}));

export default SubsidiaryDashboard;
