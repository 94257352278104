export const USER_SET_USERS = "USER_SET_USERS";
export const EDIT_USER_STATUS = "EDIT_USER_STATUS";

export const setUsers = (payload) => ({
  type: USER_SET_USERS,
  payload,
});

export const editUsers = (payload) => ({
  type: EDIT_USER_STATUS,
  payload,
});
