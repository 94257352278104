import server from "../api/server";
import * as store from "../state";
import { getProjectHeaderInfoToStore } from "./projectActions";

export const getContactsData = async (dispatchRedux, projectId) => {
  const response = await server({
    url: `/contactCard/getContactCards?projectId=${projectId}`,
    method: "GET",
  });
  const content = response.data;

  dispatchRedux(store.actions.contacts.setContacts(content));

  return content;
};

export const updateContactCards = async (
  dispatchRedux,
  contacts,
  onSuccess
) => {
  const response = await server({
    url: `/contactCard/updateContactCard`,
    method: "POST",
    data: contacts,
  });

  if (response.status === 200) {
    onSuccess();
    await getProjectHeaderInfoToStore(dispatchRedux, contacts[0].projectId);
    dispatchRedux(store.actions.contacts.setContacts(contacts));
  }
};
