import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Divider, Box, Paper } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { addCompanyData } from "../../actions/companyActions";
//import { CompanyContext } from "../../contexts/CompanyContext";
import CompanyForm from "./CompanyForm";
import NotificationPopUp from "../utils/NotificationPopUp";
import * as store from "../../state";
import { useDispatch } from "react-redux";

const AddCompany = () => {
  //const { dispatch } = useContext(CompanyContext);
  const dispatchRedux = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [notificationActions, setNotificationActions] = useState([]);

  const handleClose = (newCompanyId) => {
    setOpen(false);
    history.push(`/systemadministration/company/${newCompanyId}`);
  };

  const handleCancel = () => {
    history.push("/systemadministration");
  };

  const initialValues = {
    name: "",
    address: "",
    address2: "",
    city: "",
    stateId: "",
    countryId: "",
    zipCode: "",
    phone: "",
    fax: "",
    website: "",
    logo: null,
    industryClassificationId: "",
    maxNumberOfDivisions: "1",
    maxNumberOfSubsidiaries: "1",
    maxNumberOfUsers: "1",
  };

  const onSubmit = async (values) => {
    const { data: newCompany } = await addCompanyData(values, () => {
      dispatchRedux(store.actions.company.addCompany(values));
    });
    setNotificationText(
      `The account for ${values.name} has been created successfully.`
    );
    setNotificationActions([
      {
        name: "OK",
        action: () => handleClose(newCompany.id),
      },
    ]);
    setOpen(true);
  };

  const additionalComponent = (
    <div className={classes.additionalComponent}>
      <p>To complete the set up of the account:</p>
      <p>1. Add a subsidiary</p>
      <p>2. Add a division</p>
      <p>3. Add an account manager and other users</p>
    </div>
  );

  return (
    <>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        SYSTEM ADMINISTRATION
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.pageSubTitle}>Create New Account</Box>
      <CompanyForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        handleCancel={handleCancel}
        title="Create New Company"
        subtitle="In here you can create company entities"
        setNotificationText={setNotificationText}
        page="add"
      />
      <NotificationPopUp
        isOpen={open}
        handleClose={handleClose}
        additionalComponent={additionalComponent}
        type="success"
        title="New Account Created"
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  additionalComponent: {
    marginLeft: "2rem",
    "& p": {
      margin: "0",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "1rem",
    },
  },
}));
export default AddCompany;
