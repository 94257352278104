import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import DeleteModal from "../elements/DeleteModal";
import {
  getCompaniesData,
  removeCompaniesData,
} from "../../actions/companyActions";
import * as store from "../../state";
import { useSelector } from "react-redux";

const DeleteCompanies = (props) => {
  const [open, setOpen] = useState(true);
  const { selected } = props;
  const history = useHistory();
  // const { state, dispatch } = useContext(CompanyContext);
  const companies = useSelector(store.selectors.company.selectCompanies);
  const handleClose = () => {
    history.push("/systemadministration");
    setOpen(false);
  };
  const handleDelete = () => {
    removeCompaniesData(selected);
  };

  useEffect(() => {
    getCompaniesData();
  }, []);

  const getSeletedElements = (ids, elements) => {
    const selected = _.takeWhile(elements, (o) => _.includes(ids, o.id));
    return selected;
  };

  const content = (
    <>
      {getSeletedElements(selected, companies).map((sub) => (
        <div key={sub.id}>
          <span>
            Name:
            {sub.name}
          </span>
        </div>
      ))}
    </>
  );

  return (
    <div>
      <DeleteModal
        open={open}
        title="Delete Companies"
        subtitle="Are you sure you want to delete these companies"
        content={content}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default DeleteCompanies;
