import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, IconButton, Grid } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  formatDateUS,
  checkPermission,
} from "../../../../../utils/helperFunctions";
import NotificationPopUp from "../../../../utils/NotificationPopUp";
import {
  getJpacProjectsDataToStore,
  getProjectInfoFromJpacDataToStore,
} from "../../../../../actions/interconnectionActions";
import { getContractToStore } from "../../../../../actions/projectActions";
import * as store from "../../../../../state";

const JPACForm = (props) => {
  const { formik, roleId, projectId } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const jpacProjects = useSelector(
    store.selectors.interconnection.selectJPACProjects
  );
  const project = useSelector(store.selectors.project.selectProjectHeaderInfo);
  const contract = useSelector(store.selectors.project.selectContractInfo);

  const [selectedJpacProject, setSelectedJpacProject] = useState(
    Boolean(formik.values.jpacProjectId)
  );
  const [jpacProject, setJpacProject] = useState(null);
  const [open, setOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const connectJpacProject = async () => {
    const { data: jpacInfo } = await getProjectInfoFromJpacDataToStore(
      formik.values.jpacProjectId,
      dispatchRedux
    );
    formik.setFieldValue("complete", jpacInfo.complete);
    formik.setFieldValue("compositeRate", jpacInfo.compositeRate);
    formik.setFieldValue(
      "productivityDifferential",
      jpacInfo.productivityDifferential
    );
    formik.setFieldValue("projectName", jpacInfo.projectName);
    formik.setFieldValue("projectNumber", jpacInfo.projectNumber);
    formik.setFieldValue("wbsHours", jpacInfo.wbsHours);
    formik.setFieldValue("updateDate", jpacInfo.updateDate);
    setSelectedJpacProject(true);
  };

  const removeJpacConnection = () => {
    setSelectedJpacProject(false);
    setJpacProject(null);
    formik.setFieldValue("jpacProjectId", null);
    formik.setFieldValue("complete", null);
    formik.setFieldValue("compositeRate", null);
    formik.setFieldValue("productivityDifferential", null);
    formik.setFieldValue("projectName", null);
    formik.setFieldValue("projectNumber", null);
    formik.setFieldValue("wbsHours", null);
    formik.setFieldValue("updateDate", null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearSelectedJpac = () => {
    setNotificationTitle("Delete JPAC® Interconnection");
    setNotificationText(
      "Do you want to delete the interconnection of this project to JPAC®?"
    );
    setNotificationType("warning");
    setNotificationActions([
      {
        name: "CONTINUE",
        action: () => {
          removeJpacConnection();
          handleClose();
        },
      },
      {
        name: "DISMISS",
        action: () => {
          handleClose();
        },
      },
    ]);
    setOpen(true);
  };

  const getEstimatedCompositeRatePercentage = (compositeRate) => {
    const originalEstimatedCompositeRate = isNaN(
      contract.originalEstimatedLaborCost / contract.originalEstimatedLaborHours
    )
      ? 0
      : (
          Math.round(
            (contract.originalEstimatedLaborCost /
              contract.originalEstimatedLaborHours) *
              100
          ) / 100
        ).toFixed(2);

    return isNaN(
      (compositeRate - originalEstimatedCompositeRate) /
        originalEstimatedCompositeRate
    )
      ? 0
      : (
          ((compositeRate - originalEstimatedCompositeRate) /
            originalEstimatedCompositeRate) *
          100
        ).toFixed(2);
  };

  useEffect(async () => {
    if (formik.values.jpacProjectId && Boolean(jpacProject)) {
      setNotificationActions([
        {
          name: "CONNECT",
          action: () => {
            setNotificationTitle("JPAC® Interconnection Successful");
            setNotificationText(
              "You have successfully established the interconnection to JPAC® for this project."
            );
            setNotificationType("success");
            setNotificationActions([
              {
                name: "OK",
                action: async () => {
                  await connectJpacProject();
                  handleClose();
                },
              },
            ]);
          },
        },
        { name: "DISMISS", action: () => handleClose() },
      ]);
      setNotificationTitle("JPAC® Interconnection");
      setNotificationText(
        `You selected ${jpacProject.name} in JPAC® to connect with ${project.name}.
        Do you want to continue?`
      );
      setNotificationType("warning");
      setOpen(true);
    } else if (formik.values.jpacProjectId) {
      setSelectedJpacProject(true);
    } else {
      setSelectedJpacProject(false);
    }
  }, [formik.values.jpacProjectId]);

  useEffect(async () => {
    Promise.all([
      getJpacProjectsDataToStore(
        loggedInUser.userCompany.jpacCompanyId,
        dispatchRedux
      ),
      getContractToStore(projectId, dispatchRedux),
    ]);
  }, []);

  return (
    <>
      <h4 className={classes.pageTitle}>JPAC®</h4>
      {selectedJpacProject ? (
        <Grid container>
          <Grid item xs={12} className={classes.jpacInfoContainer}>
            <div>
              <Box className={classes.jpacInfoContainerLabel}>
                {`${formik.values.projectName} (${formik.values.projectNumber})`}
              </Box>
              <Box className={classes.jpacInfoContainerLabelBottom}>
                {`JPAC(R) information as of: ${
                  formik.values.updateDate
                    ? formatDateUS(formik.values.updateDate)
                    : "-"
                }`}
              </Box>
            </div>
            <IconButton
              color="default"
              disabled={
                formik.values.projectStatusTypeId === 10 ||
                checkPermission([5], roleId)
              }
              id="clear-selected-jpac-btn"
              component="span"
              onClick={handleClearSelectedJpac}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} className={classes.gridItems}>
            <p className={classes.label}>
              {" "}
              % Complete:
              <b className={classes.boldLabel}>
                {formik.values.complete
                  ? formik.values.complete.toFixed(2)
                  : ""}{" "}
                %
              </b>
            </p>
            <p className={classes.label}>
              {" "}
              Composite Rate:
              <b className={classes.boldLabel}>
                {`$ ${
                  formik.values.compositeRate
                    ? formik.values.compositeRate.toLocaleString("en-US")
                    : "-"
                } (`}
                <span
                  className={
                    classes[
                      getEstimatedCompositeRatePercentage(
                        formik.values.compositeRate
                          ? formik.values.compositeRate
                          : 0
                      ) > 0
                        ? "red"
                        : "green"
                    ]
                  }
                >
                  {`${
                    formik.values.compositeRate
                      ? getEstimatedCompositeRatePercentage(
                          formik.values.compositeRate
                        )
                      : 0
                  } %`}
                </span>
                {") "}
              </b>
            </p>
          </Grid>
          <Grid item xs={12} className={classes.gridItems}>
            <p className={classes.label}>
              {" "}
              WBS Hours:
              <b className={classes.boldLabel}>{formik.values.wbsHours} hrs</b>
            </p>
            <p className={classes.label}>
              {" "}
              Productivity Differential:
              <b
                className={clsx(
                  classes[
                    formik.values.productivityDifferential < 0 ? "red" : "green"
                  ],
                  classes.boldLabel
                )}
              >
                {`${
                  formik.values.productivityDifferential
                    ? formik.values.productivityDifferential.toFixed(2)
                    : "-"
                }%`}
              </b>
            </p>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.topInput}>
          <Autocomplete
            style={{ marginTop: "0.5rem" }}
            id="jpacProject"
            value={jpacProject}
            disabled={
              jpacProjects.length === 0 ||
              formik.values.projectStatusTypeId === 10 ||
              checkPermission([5], roleId)
            }
            options={jpacProjects}
            getOptionLabel={(option) => `${option.name} ${option.number}`}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value) => {
              setJpacProject(value);
              formik.setFieldValue("jpacProjectId", value ? value.id : null);
            }}
            noOptionsText="There are no projects with this ID"
            renderInput={(params) => (
              <TextField
                onBlur={formik.handleBlur}
                {...params}
                variant="standard"
                placeholder="Search a Project"
              />
            )}
          />
          {jpacProjects.length === 0 && (
            <p className={classes.noProjectMessagge}>
              We couldn’t find an interconnection for your DCI account to
              JPAC(R). Please contact support for help if you are interested in
              JPAC(R) or you are already using JPAC(R).
            </p>
          )}
        </div>
      )}
      <NotificationPopUp
        isOpen={open}
        handleClose={handleClose}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  green: {
    color: "#4D841D",
  },
  red: {
    color: "red",
  },
  margin: {
    marginLeft: ".4rem",
  },
  gridItems: {
    display: "flex",
  },
  customField: {
    minHeight: "4.25rem",
    minWidth: "10rem",
    maxWidth: "22.5rem",
    margin: "0 0.5rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    borderRadius: 5,
  },
  jpacInfoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "rgba(190, 227, 248, 0.5)",
    borderRadius: "4px",
    marginBottom: "1rem",
    padding: " 0.5rem",
  },
  jpacInfoContainerLabel: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: "bold",
  },
  jpacInfoContainerLabelBottom: {
    fontStyle: "italic",
  },
  label: {
    marginLeft: ".5rem",
    width: "20rem",
  },
  boldLabel: {
    marginLeft: ".5rem",
  },
  noProjectMessagge: {
    color: "red",
  },
}));

export default JPACForm;
