import * as actions from "../action/proposal";

const initialState = {
  proposals: [],
  proposal: {},
};

const proposalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PROPOSALS:
      return {
        ...state,
        proposals: action.payload,
      };
    case actions.SET_PROPOSAL:
      return {
        ...state,
        proposal: action.payload,
      };
    default:
      return state;
  }
};

export default proposalReducer;
