import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import {
  Box,
  TextField,
  Chip,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker } from "@material-ui/pickers";

import * as constants from "../../constants";
//import { SubsidiaryContext } from "../../contexts/SubsidiaryContext";
import { getSubsidiariesData } from "../../actions/subsidiaryActions";
import { getDivisionsData } from "../../actions/divisionActions";
// import { ResourcesContext } from "../../contexts/ResourcesContext";
import {
  getJobTypesData,
  getStatesDataToStore,
  getCountiesDataToStore,
  getMarketSegmentsDataToStore,
  getMarketCategoriesDataToStore,
} from "../../actions/resourcesAction";
import { getCustomersDataToStore } from "../../actions/companyActions";
// import { CompanyContext } from "../../contexts/CompanyContext";
import { getUsersData } from "../../actions/usersAction";
import * as store from "../../state";

const Filters = (props) => {
  const {
    runReport,
    isTeamReports,
    isLocationReport,
    isCustomerReport,
    isMarketReport,
  } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  //const subsidiaryContext = useContext(SubsidiaryContext);
  const subsidiaries = useSelector(
    store.selectors.subsidiary.selectSubsidiaries
  );
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyUsers = useSelector(store.selectors.user.selectUsers);
  const divisionsStore = useSelector(store.selectors.division.selectDivisions);
  //const resourcesContext = useContext(ResourcesContext);
  /*
 jobTypes
 states
 counties
 marketSegments
 marketCategories
*/
  const jobTypes = useSelector(store.selectors.resource.selectJobTypes);
  const states = useSelector(store.selectors.resource.selectStates);
  const counties = useSelector(store.selectors.resource.selectCounties);
  const marketSegments = useSelector(
    store.selectors.resource.selectMarketSegments
  );
  const marketCategories = useSelector(
    store.selectors.resource.selectMarketCategories
  );
  // const marketSubcategories = useSelector(
  //   store.selectors.resource.selectMarketSubcategories
  // );
  //const companyContext = useContext(CompanyContext);
  const customers = useSelector(store.selectors.company.selectCustomers);
  const companyId = loggedInUser.userCompany.id;

  //const [subsidiaries, setSubsidiaries] = useState([]);
  const [divisions, setDivisions] = useState([]);

  // useEffect(() => {
  //   setSubsidiaries(
  //     subsidiaries.filter(
  //       (subsidiary) => subsidiary.statusId !== 3
  //     )
  //   );
  // }, [subsidiaryContext.state.subsidiaries]);

  useEffect(() => {
    setDivisions(divisionsStore.filter((division) => division.statusId !== 3));
  }, [divisionsStore]);

  useEffect(async () => {
    getDivisionsData(companyId).then(({ data }) => {
      dispatchRedux(store.actions.division.setDivisions(data));
    });
    getSubsidiariesData(companyId).then(({ data }) =>
      dispatchRedux(store.actions.subsidiary.setSubsidiaries(data))
    );

    if (isTeamReports) {
      //await getJobTypes(resourcesContext.dispatch);
      getJobTypesData(({ data }) => {
        dispatchRedux(store.actions.resource.setJobTypes(data));
      });
      getUsersData(loggedInUser.userCompany.id).then(({ data }) => {
        dispatchRedux(store.actions.user.setUsers(data));
      });
    }

    if (isLocationReport) {
      //await getStates(resourcesContext.dispatch, 1);
      await getStatesDataToStore(1, { dispatchRedux });
    }

    if (isCustomerReport) {
      //await getCustomers(companyContext.dispatch, companyId);
      await getCustomersDataToStore(companyId, { dispatchRedux });
    }

    if (isMarketReport) {
      //await getMarketSegments(resourcesContext.dispatch);
      await getMarketSegmentsDataToStore({ dispatchRedux });
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      companyId,
      subsidiariesIds: [],
      divisionsIds: [],
      startDate: null,
      endDate: null,
      stateIds: [],
      countyIds: [],
      outputValue: "contractBidValue",
      roleIds: "2",
      assigneesIds: [],
      customerIds: [],
      marketSegmentIds: [],
      marketCategoryIds: [],
    },
    onSubmit: (values) => {
      runReport(Object.assign(values), "monthly");
    },
  });

  useEffect(() => {
    if (formik.values.stateIds.length === 1) {
      ///getCounties(resourcesContext.dispatch, formik.values.stateIds[0].id);
      getCountiesDataToStore(formik.values.stateIds[0].id, { dispatchRedux });
    } else {
      formik.setFieldValue("countyIds", []);
    }
  }, [formik.values.stateIds]);

  useEffect(async () => {
    if (formik.values.marketSegmentIds.length === 1) {
      // getMarketCategories(
      //   resourcesContext.dispatch,
      //   formik.values.marketSegmentIds[0].id
      // );
      await getMarketCategoriesDataToStore(
        formik.values.marketSegmentIds[0].id,
        {
          dispatchRedux,
        }
      );
    } else {
      formik.setFieldValue("marketCategoryIds", []);
    }
  }, [formik.values.marketSegmentIds]);

  const resetFilters = () => {
    formik.setFieldValue("subsidiariesIds", []);
    formik.setFieldValue("divisionsIds", []);
    formik.setFieldValue("stateIds", []);
    formik.setFieldValue("countyIds", []);
    formik.setFieldValue("outputValue", "contractBidValue");
    formik.setFieldValue("roleIds", "2");
    formik.setFieldValue("assigneesIds", []);
    formik.setFieldValue("startDate", null);
    formik.setFieldValue("endDate", null);
    formik.setFieldValue("customerIds", []);
    formik.setFieldValue("marketCategoryIds", []);
    formik.setFieldValue("marketSegmentIds", []);

    formik.handleSubmit();
  };

  return (
    <form>
      <div className={classes.root}>
        <div className={classes.pannel}>
          <Box className={classes.title}>Company</Box>
          <div className={classes.inputGroup}>
            <div className={classes.input}>
              <Autocomplete
                multiple
                id="subsidiariesIds"
                value={formik.values.subsidiariesIds}
                options={subsidiaries}
                getOptionLabel={(option) => `${option.name}`}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      classes={{ label: classes.chip }}
                      deleteIcon={<CloseIcon />}
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(event, value) =>
                  formik.setFieldValue("subsidiariesIds", value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Subsidiaries"
                    className={classes.inputField}
                  />
                )}
              />
            </div>
            <div className={classes.input}>
              <Autocomplete
                multiple
                id="divisionsIds"
                value={formik.values.divisionsIds}
                options={divisions}
                getOptionLabel={(option) => `${option.name}`}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      classes={{ label: classes.chip }}
                      deleteIcon={<CloseIcon />}
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(event, value) =>
                  formik.setFieldValue("divisionsIds", value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Divisions"
                    className={classes.inputField}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <div className={classes.pannel}>
          <Box className={classes.title}>Time Period</Box>
          <div className={classes.inputGroup}>
            <div className={classes.input}>
              <Box className={classes.inputTitle}>Start Date</Box>
              <DatePicker
                className={classes.datePickerInput}
                allowKeyboardControl
                disableToolbar
                autoOk
                clearLabel
                clearable
                value={formik.values.startDate}
                onChange={(val) => formik.setFieldValue("startDate", val)}
                format="MM/dd/yyyy"
                emptyLabel="mm.dd.yyyy"
                variant="inline"
                inputVariant="outlined"
                minDate={new Date()}
                maxDate={
                  formik.values.endDate ? formik.values.endDate : undefined
                }
                maxDateMessage="Start must be before end date"
              />
            </div>
            <div className={classes.input}>
              <Box className={classes.inputTitle}>End Date</Box>
              <DatePicker
                className={classes.datePickerInput}
                allowKeyboardControl
                disableToolbar
                autoOk
                clearLabel
                clearable
                value={formik.values.endDate}
                onChange={(val) => formik.setFieldValue("endDate", val)}
                format="MM/dd/yyyy"
                emptyLabel="mm.dd.yyyy"
                variant="inline"
                inputVariant="outlined"
                minDate={
                  formik.values.startDate ? formik.values.startDate : new Date()
                }
                minDateMessage="End must be after start date"
              />
            </div>
          </div>
        </div>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <div className={classes.pannel}>
          <Box className={classes.title}>Output Value</Box>
          <RadioGroup
            aria-label="outputValue"
            name="outputValue"
            id="outputValue"
            value={formik.values.outputValue}
            onChange={(event, value) => {
              formik.setFieldValue("outputValue", value);
            }}
          >
            <FormControlLabel
              value="contractBidValue"
              control={<Radio color="primary" />}
              label="Contract/Bid Value($)"
            />
            <FormControlLabel
              value="totalCost"
              control={<Radio color="primary" />}
              label="Total Cost($)"
            />
            <FormControlLabel
              value="laborHours"
              control={<Radio color="primary" />}
              label="Labor Hours"
            />
          </RadioGroup>
        </div>
        {isTeamReports && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <div className={classes.pannel}>
              <Box className={classes.title}>Project Team</Box>
              <div className={classes.inputGroup}>
                <div className={classes.input}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    id="roleIds"
                    name="roleIds"
                    className={classes.inputField}
                    select
                    disabled
                    onBlur={formik.handleBlur}
                    value={formik.values.roleIds}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.roleIds && Boolean(formik.errors.roleIds)
                    }
                    helperText={formik.touched.roleIds && formik.errors.roleIds}
                  >
                    {jobTypes.map((jobRole) => (
                      <MenuItem key={jobRole.id} value={jobRole.id}>
                        {jobRole.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div className={classes.input}>
                  <Autocomplete
                    multiple
                    id="assigneesIds"
                    value={formik.values.assigneesIds}
                    options={companyUsers}
                    getOptionLabel={(option) =>
                      `${`${option.firstName} ${option.lastName}`}`
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          classes={{ label: classes.chip }}
                          deleteIcon={<CloseIcon />}
                          variant="outlined"
                          label={option.firstName + option.lastName}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(event, value) =>
                      formik.setFieldValue("assigneesIds", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select People"
                        className={classes.inputField}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {isLocationReport && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <div className={classes.pannel}>
              <Box className={classes.title}>Location</Box>
              <div className={classes.inputGroup}>
                <div className={classes.input}>
                  <Autocomplete
                    multiple
                    id="stateIds"
                    value={formik.values.stateIds}
                    options={states}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          classes={{ label: classes.chip }}
                          deleteIcon={<CloseIcon />}
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(event, value) =>
                      formik.setFieldValue("stateIds", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select State"
                        className={classes.inputField}
                      />
                    )}
                  />
                </div>
                <div className={classes.input}>
                  <Autocomplete
                    multiple
                    id="countyIds"
                    disabled={formik.values.stateIds.length !== 1}
                    value={formik.values.countyIds}
                    options={counties}
                    getOptionLabel={(option) => `${option.name}`}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          classes={{ label: classes.chip }}
                          deleteIcon={<CloseIcon />}
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(event, value) =>
                      formik.setFieldValue("countyIds", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select County"
                        className={classes.inputField}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {isCustomerReport && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <div className={classes.pannel}>
              <Box className={classes.title}>Customer</Box>
              <div className={classes.inputGroup}>
                <div className={classes.input}>
                  <Autocomplete
                    multiple
                    id="customerIds"
                    value={formik.values.customerIds}
                    options={customers}
                    getOptionLabel={(option) => `${option.name}`}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          classes={{ label: classes.chip }}
                          deleteIcon={<CloseIcon />}
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(event, value) =>
                      formik.setFieldValue("customerIds", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Customer"
                        className={classes.inputField}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {isMarketReport && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <div className={classes.pannel}>
              <Box className={classes.title}>Market</Box>
              <div className={classes.inputGroup}>
                <div className={classes.input}>
                  <Autocomplete
                    multiple
                    id="marketSegmentIds"
                    value={formik.values.marketSegmentIds}
                    options={marketSegments}
                    getOptionLabel={(option) => `${option.name}`}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          classes={{ label: classes.chip }}
                          deleteIcon={<CloseIcon />}
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(event, value) =>
                      formik.setFieldValue("marketSegmentIds", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Segment"
                        className={classes.inputField}
                      />
                    )}
                  />
                </div>
                <div className={classes.input}>
                  <Autocomplete
                    multiple
                    id="marketCategoryIds"
                    disabled={formik.values.marketSegmentIds.length !== 1}
                    value={formik.values.marketCategoryIds}
                    options={marketCategories}
                    getOptionLabel={(option) => `${option.name}`}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          classes={{ label: classes.chip }}
                          deleteIcon={<CloseIcon />}
                          variant="outlined"
                          label={option.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(event, value) =>
                      formik.setFieldValue("marketCategoryIds", value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Category"
                        className={classes.inputField}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={classes.inputGroup}>
        <div className={classes.input}>
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            onClick={resetFilters}
          >
            <Box>Reset Filters</Box>
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={formik.handleSubmit}
          >
            <Box>Run Report</Box>
          </Button>
        </div>
      </div>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: "2px solid #4A5568",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "0.5rem",
    backgroundColor: constants.DRAWER_BACKGROUND_COLOR,
    overflow: "auto",
    flexWrap: "wrap",
  },
  divider: {
    margin: "0 1rem",
  },
  pannel: {
    minHeight: "10rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
  },
  lastPannel: {
    flexGrow: 1,
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "1rem",
  },
  input: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: "1rem",
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: "bold",
    marginBottom: "0.5rem",
    alignSelf: "center",
  },
  inputTitle: {
    fontSize: theme.typography.subtitle1.fontSize,
    marginRight: "0.5rem",
    minWidth: "5.5rem",
  },
  inputField: {
    width: "18rem",
    marginBottom: "0.1rem",
    "& input": {
      padding: "0.3rem",
    },
  },
  chip: {
    textOverflow: "ellipsis",
    maxWidth: "5rem",
  },
  checkBox: {
    "& span": {
      padding: "0.1rem",
      marginLeft: "0.4rem",
    },
  },
  smallerpadding: {
    width: "100%",
    "& input": {
      padding: "0.5rem",
    },
  },
  datePickerInput: {
    "& div": {
      "& input": {
        padding: "9px 9px",
      },
    },
  },
}));

export default Filters;
