import * as actions from "../action/subsidiary";

const initialState = {
  subsidiaries: [],
  subsidiary: {},
};

const editSubsidiaryStatus = (state, statusId, subsidiaryIds) => {
  const updateSubsidiaries = state.subsidiaries.map((subsidiary) =>
    subsidiaryIds.indexOf(subsidiary.id) > -1
      ? { ...subsidiary, statusId }
      : subsidiary
  );
  return { ...state, subsidiaries: updateSubsidiaries };
};
const subsidiaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_SUBSIDIARIES:
      return { ...state, subsidiaries: action.payload };
    case actions.GET_SUBSIDIARY:
      return { ...state, subsidiary: action.payload };
    case actions.EDIT_SUBSIDIARY_STATUS:
      return editSubsidiaryStatus(
        state,
        action.payload.statusId,
        action.payload.subsidiaryIds
      );
    //TODO! - not used, can be deleted
    case actions.ADD_SUBSIDIARY:
    case actions.REMOVE_SUBSIDIARIES:
      throw "Not implemented";
    default:
      return state;
  }
};

export default subsidiaryReducer;
