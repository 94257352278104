import React, { useState, useEffect } from "react";
import clsx from "clsx";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Box } from "@material-ui/core";

export default function ProjectAccordion(props) {
  const classes = useStyles();

  const { status, children, parentExpended, setParentExpanded } = props;

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (parentExpended === "expand") {
      setExpanded(true);
    } else if (parentExpended === "collapse") {
      setExpanded(false);
    }
  }, [parentExpended]);

  const handleChange = () => {
    setExpanded(!expanded);
    setParentExpanded("");
  };

  return (
    <div className={classes.acordionContainer}>
      <Accordion expanded={expanded} onChange={() => handleChange()}>
        <AccordionSummary
          aria-controls={`panel${status}-content`}
          id={`panel${status}-header`}
        >
          <Box
            textAlign="left"
            fontWeight="fontWeightBold"
            letterSpacing={1}
            className={classes.title}
          >
            <ArrowDropDownIcon
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
            />
            Status: {status}
          </Box>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontSize: theme.typography.h6.fontSize,
  },
  expand: {
    fontSize: "2rem",
    margin: "0 0.3rem",
    borderRadius: "1rem",
    border: "1px solid #718096",
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  acordionContainer: {
    width: "100%",
  },
}));

const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    border: "1px solid #718096",
    borderRadius: "10px",
    margin: 5,
    padding: "0 0.5rem",
    minHeight: "0rem",
    "&$expanded": {
      minHeight: "0rem",
    },
  },
  content: {
    margin: "0.5rem 0",
    "&$expanded": {
      margin: "0.5rem 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: "0 0.5rem",
    display: "block",
  },
}))(MuiAccordionDetails);
