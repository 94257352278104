import React from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const PersonOfContactForm = () => {
  const classes = useStyles();

  return (
    <div className={classes.top}>
      <Box>
        Don’t have a <br /> company account to DCI&#8482;?
      </Box>
      <Button
        color="primary"
        variant="contained"
        className={classes.button}
        disabled
      >
        Request account
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  top: {
    margin: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& div": {
      textAlign: "center",
      fontSize: theme.typography.h4.fontSize,
      fontWeight: "bold",
    },
  },
  formCard: {
    minHeight: "12rem",
    minWidth: "20rem",
    margin: "1rem",
    borderRadius: "1rem",
    padding: "0 1rem 1rem 1rem",
  },
  button: {
    width: "70%",
  },
}));

export default PersonOfContactForm;
