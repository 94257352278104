//import server from "../api/server";
import * as API from "./_apiCalls";
import * as store from "../state";
// import {
//   // GET_SUBSIDIARIES,
//   // GET_SUBSIDIARY,
//   // ADD_SUBSIDIARY,
//   //EDIT_SUBSIDIARY_STATUS,
//   // REMOVE_SUBSIDIARIES,
//   // EDIT_SUBSIDIARY,
// } from "./types";

export const addSubsidiaryData = async (subsidiary, callback) => {
  //  export const addSubsidiary = async (subsidiary, dispatch, handleCallback) => {
  return API.postData("/subsidiary/addSubsidiary", subsidiary, callback);
  // const response = await server({
  //   url: "/subsidiary/addSubsidiary",
  //   method: "POST",
  //   data: subsidiary,
  // });
  // dispatch({
  //   type: ADD_SUBSIDIARY,
  //   subsidiary: { ...subsidiary, id: response },
  // });
  // if (response.status === 200) {
  //   handleCallback();
  // }
};

//export const removeSubsidiaries = async (subsidiaries, dispatch) => {
export const removeSubsidiariesData = async (subsidiaries, callback) => {
  return await API.getData(
    "/subsidiary/removesubsidiaries",
    subsidiaries,
    callback
  );
  // await server({
  //   url: "/subsidiary/removesubsidiaries",
  //   method: "PUT",
  //   data: subsidiaries,
  // });
  // dispatch({
  //   type: REMOVE_SUBSIDIARIES,
  //   subsidiaries,
  // });
};

export const updateSubsidiaryStatusDataToStore = async (
  data,
  { callback, dispatchRedux }
) => {
  const { subsidiaryIds, statusId } = data;
  return await updateSubsidiaryStatusData(data, callback).then((response) => {
    if (response.status === 200) {
      dispatchRedux(
        store.actions.subsidiary.setStatus({ subsidiaryIds, statusId })
      );
    }
  });
};

export const updateSubsidiaryStatusData = async (data, callback) => {
  const { subsidiaryIds, status } = data;
  const url = `/subsidiary/updateSubsidiaryStatus?status=${status}`;

  return API.postData(url, JSON.stringify(subsidiaryIds), callback);
};

// export const updateSubsidiaryStatus = async (
//   subsidiaryIds,
//   status,
//   statusId,
//   dispatch
// ) => {
export const updateSubsidiaryStatus = async (
  { subsidiaryIds, status },
  callback
) => {
  const baseUrl = `/subsidiary/updateSubsidiaryStatus?status=${status}`;
  return await API.postData(baseUrl, JSON.stringify(subsidiaryIds), callback);

  // const rawResponse = await server({
  //   url: baseUrl,
  //   method: "PUT",
  //   data: JSON.stringify(subsidiaryIds),
  // });

  // if (rawResponse.status === 200 && dispatch) {
  //   dispatch({
  //     type: EDIT_SUBSIDIARY_STATUS,
  //     statusId,
  //     subsidiaryIds,
  //   });
  // }
};

export const editSubsidiaryData = async (subsidiary, callback) => {
  //export const editSubsidiary = async (subsidiary, dispatch, handleSuccess) => {
  return await API.postData(
    "/subsidiary/updateSubsidiary",
    subsidiary,
    callback
  );
  // const response = await server({
  //   url: "/subsidiary/updateSubsidiary",
  //   method: "PUT",
  //   data: subsidiary,
  // });
  // if (response.status === 200) {
  //   handleSuccess();
  //   dispatch({
  //     type: EDIT_SUBSIDIARY,
  //     subsidiary,
  //   });
  // }
};

export const getSubsidiariesDataToStore = async (
  companyId,
  { callback, dispatchRedux }
) => {
  return await getSubsidiariesData(companyId, callback).then(({ data }) => {
    dispatchRedux(store.actions.subsidiary.setSubsidiaries(data));
  });
};

export const getSubsidiariesData = async (companyId, callback) => {
  return API.getData(`/subsidiary/getSubsidiaries/${companyId}`, callback);
  // const response = await server({
  //   url: `/subsidiary/getSubsidiaries/${companyId}`,
  //   method: "GET",
  // });
  // const content = response.data;

  // callback && callback(content);
  // return content;
};

// export const getSubsidiaries = async (dispatch, companyId) => {
//   const response = await server({
//     url: `/subsidiary/getSubsidiaries/${companyId}`,
//     method: "GET",
//   });
//   const content = response.data;

//   dispatch({
//     type: GET_SUBSIDIARIES,
//     subsidiaries: content,
//   });

//   return content;
// };

//export const getSubsidiary = async (subsidiaryId, dispatch) => {
export const getSubsidiaryData = async (subsidiaryId, callback) => {
  return API.getData(`/subsidiary/getSubsidiary/${subsidiaryId}`, callback);
  // const response = await server({
  //   url: `/subsidiary/getSubsidiary/${subsidiaryId}`,
  //   method: "GET",
  // });
  // const content = response.data;

  // dispatch({
  //   type: GET_SUBSIDIARY,
  //   subsidiary: content,
  // });
  // return response.data;
};
