import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Avatar, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useHistory } from "react-router-dom";
import * as constants from "../../constants";
import profilePlaceholder from "../../resources/profilePlaceholder.png";
import * as store from "../../state";

const ProfileSelect = () => {
  const classes = useStyles();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const [userName, setUserName] = useState("");
  const dispatchRedux = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!loggedInUser) {
      setUserName("");
      return;
    }

    setUserName(`${loggedInUser.firstName} ${loggedInUser.lastName}`);
  }, [loggedInUser]);

  const renderValue = () => <p>{userName}</p>;
  const onClick = () => {
    dispatchRedux(store.actions.login.initState());
    history.push("/login");
  };

  const handleUserProfileClick = () => {
    if (loggedInUser.roleId && loggedInUser.roleId === 1) {
      history.push(`/sysadmin/user/${loggedInUser.id}`);
      return;
    }

    history.push(`/users/user/${loggedInUser.id}`);
  };

  return (
    <div className={classes.container}>
      <Avatar
        src={
          loggedInUser && loggedInUser.picture
            ? loggedInUser.picture
            : profilePlaceholder
        }
        className={classes.account}
      />
      <Select
        variant="standard"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        IconComponent={KeyboardArrowDownIcon}
        id="userDetailsDropdown"
        defaultValue="Unknown"
        value={userName || ""}
        renderValue={renderValue}
        className={classes.select}
        disableUnderline
      >
        <MenuItem value={userName} className={classes.hiddenItem} />
        <MenuItem
          id="userProfileButton"
          value="User Profile"
          onClick={handleUserProfileClick}
        >
          User Profile
        </MenuItem>
        <Divider />
        <MenuItem id="logOutButton" button value="log out" onClick={onClick}>
          Log Out
        </MenuItem>
      </Select>
    </div>
  );
};

const useStyles = makeStyles({
  select: {
    "&:after": {
      backgroundColor: "white",
    },
    marginLeft: "1rem",
    minWidth: constants.DRAWER_WIDTH / 2,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  hiddenItem: {
    visibility: "hidden",
    padding: "0",
  },
});

export default ProfileSelect;
