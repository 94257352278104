import React from "react";
import { Typography, Box, Tooltip } from "@material-ui/core";

const TooltipDisplay = (props) => {
  const { title, text } = props;
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary">
        {title}
      </Typography>

      {text.length > 14 ? (
        <Tooltip title={text} interactive arrow>
          <Box fontSize="subtitle1.fontSize" fontWeight="fontWeightBold">
            {text || "-"}
          </Box>
        </Tooltip>
      ) : (
        <Box fontSize="subtitle1.fontSize" fontWeight="fontWeightBold">
          {text || "-"}
        </Box>
      )}
    </>
  );
};

export default TooltipDisplay;
