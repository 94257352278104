import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  getProposal,
  updateProposal,
} from "../../../../actions/proposalActions";
import ProjectHeader from "../ProjectHeader";
import ProjectTitle from "../ProjectTitle";
import ProposalDetails from "./ProposalDetails";
import BiddingFinancials from "./BiddingFinancials";
import TeamAssignments from "./TeamAssignments";
import NotificationPopUp from "../../../utils/NotificationPopUp";
import * as store from "../../../../state";
import { proposalDetailsSchema } from "../../validationSchemas/validationSchema";

const EditProposal = (props) => {
  const { projectId, proposalId } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatchRedux = useDispatch();

  const proposal = useSelector(store.selectors.proposal.selectProposal);
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId = loggedInUser.userCompany?.id;

  const initialValuesForProposal = {
    statusTypeId: 1,
    id: proposalId,
    projectId: projectId,
    proposalNumber: "",
    isPrimaryProposal: false,
    bidDueDate: "",
    description: "",
    materials: [],
    requirements: [],
    notes: "",
    leadEstimator: null,
    estimators: [],
    leadProjectManager: null,
    projectManagers: [],
    projectCoordinators: [],
    bidValue: "",
    estimatedCost: "",
    estimatedLaborCost: "",
    estimatedLaborHours: "",
    proposalWorkTypes: [],
    version: "",
  };

  const [initialValues, setInitialValues] = useState(initialValuesForProposal);
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const onSuccess = (proposalName) => {
    setNotificationTitle("Proposal updated");
    setIsNotificationPopUpOpen(true);
    setNotificationText(
      <p>
        Proposal <b>{proposalName}</b> successfully updated.
      </p>
    );
    setNotificationType("success");
    setNotificationActions([
      {
        name: "OK",
        action: () => {
          handleCloseModal();
        },
      },
    ]);
  };

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleCancel = () => {
    history.push("/projectDetails/proposalsDashboard/" + projectId);
  };

  useEffect(() => {
    if (proposal && proposal.version) {
      setInitialValues({
        ...proposal,
        bidValue:
          proposal.bidValue === 0
            ? 0
            : proposal.bidValue
            ? `${proposal.bidValue}`
            : "",
        bidDueDate: proposal.bidDueDate
          ? proposal.bidDueDate.substring(0, 10)
          : "",
        estimatedCost:
          proposal.estimatedCost === 0
            ? 0
            : proposal.estimatedCost
            ? `${proposal.estimatedCost}`
            : "",
        estimatedLaborCost:
          proposal.estimatedLaborCost === 0
            ? 0
            : proposal.estimatedLaborCost
            ? `${proposal.estimatedLaborCost}`
            : "",
        estimatedLaborHours:
          proposal.estimatedLaborHours === 0
            ? 0
            : proposal.estimatedLaborHours
            ? `${proposal.estimatedLaborHours}`
            : "",
        notes: proposal.notes ? proposal.notes : "",
      });
    }
  }, [proposal]);

  useEffect(async () => {
    await getProposal({ dispatchRedux }, proposalId);
  }, []);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: proposalDetailsSchema,
    onSubmit: (values) => {
      const proposal = {
        ...values,
        bidDueDate: values.bidDueDate || null,
        bidValue:
          values.bidValue === "0" || values.bidValue === 0
            ? 0
            : parseFloat(values.bidValue) || null,
        estimatedCost:
          values.estimatedCost === "0" || values.estimatedCost === 0
            ? 0
            : parseFloat(values.estimatedCost) || null,
        estimatedLaborCost:
          values.estimatedLaborCost === "0" || values.estimatedLaborCost === 0
            ? 0
            : parseFloat(values.estimatedLaborCost) || null,
        estimatedLaborHours:
          values.estimatedLaborHours === "0" || values.estimatedLaborHours === 0
            ? 0
            : parseFloat(values.estimatedLaborHours) || null,
      };
      updateProposal(proposal, projectId, { dispatchRedux }, onSuccess);
    },
  });

  return (
    <>
      <ProjectHeader projectId={projectId} />
      <div className={classes.container}>
        <ProjectTitle
          pageTitle={
            "Proposals -> " +
            formik.values.proposalNumber +
            " -> " +
            formik.values.version
          }
          currentStatus={initialValues.statusTypeId}
          formik={formik}
          nameOfStatusField={"statusTypeId"}
          actions={[
            // {
            //   name: "Delete Project",
            //   action: () => console.log("delete"),
            //   type: "error",
            // },
            {
              name: "Cancel",
              action: () => handleCancel(),
              type: "default",
            },
            {
              name: "Save",
              action: () => formik.handleSubmit(),
              type: "success",
            },
          ]}
        />
        <ProposalDetails companyId={companyId} formik={formik} />
        <BiddingFinancials formik={formik} />
        <TeamAssignments formik={formik} />
      </div>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  additionalComponent: {
    marginLeft: "2rem",
    "& p": {
      margin: "0",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "1rem",
    },
  },
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.5rem 0",
    fontWeight: "bold",
  },
}));

export default EditProposal;
