import React from "react";
import { formatDateUS } from "../../../../utils/helperFunctions";
import TooltipDisplay from "./TooltipDisplay";

const BidPending = (props) => {
  const {
    expectedStartDate,
    expectedEndDate,
    bidValue,
    bidDueDate,
    leadEstimator,
    leadProjectManager,
    estimatedCost,
    estimatedLaborCost,
    estimatedLaborHours,
  } = props.row;
  return (
    <>
      <div>
        <TooltipDisplay
          title="Bid Due by"
          text={bidDueDate ? formatDateUS(bidDueDate) : "-"}
        />
        <TooltipDisplay
          title="Exp. Start Date"
          text={expectedStartDate ? formatDateUS(expectedStartDate) : "-"}
        />
      </div>
      <div>
        <TooltipDisplay
          title="Bid Value"
          text={bidValue ? `$${bidValue.toLocaleString("en-US")}` : "-"}
        />
        <TooltipDisplay
          title="Exp. End Date"
          text={expectedEndDate ? formatDateUS(expectedEndDate) : "-"}
        />
      </div>
      <div>
        <TooltipDisplay
          title="Est. Total Cost"
          text={
            estimatedCost
              ? `$${parseInt(estimatedCost).toLocaleString("en-US")}`
              : "-"
          }
        />
        <TooltipDisplay title="Lead Estimator" text={leadEstimator || "-"} />
      </div>
      <div>
        <TooltipDisplay
          title="Est. Labor Hours"
          text={
            estimatedLaborHours
              ? `${parseInt(estimatedLaborHours).toLocaleString("en-US")} hours`
              : "-"
          }
        />
        <TooltipDisplay
          title="Project Manager"
          text={leadProjectManager || "-"}
        />
      </div>
      <div>
        <TooltipDisplay
          title="Est. Comp. Rate"
          text={
            !estimatedLaborCost ||
            !estimatedLaborHours ||
            isNaN(estimatedLaborCost / estimatedLaborHours)
              ? "-"
              : `$${(
                  Math.round((estimatedLaborCost / estimatedLaborHours) * 100) /
                  100
                ).toFixed(2)}`
          }
        />
        <TooltipDisplay title={" "} text={" "} />
      </div>
    </>
  );
};

export default BidPending;
