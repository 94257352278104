import React from "react";
import { toUpper } from "lodash";
import { useHistory } from "react-router-dom";
import { ListItem, Box } from "@material-ui/core";
import { BarChart } from "@material-ui/icons";
import {
  isProjectActive,
  isProjectAwarded,
  isProjectNew,
} from "../../../utils/helperFunctions";

const ProjectListItems = (props) => {
  const history = useHistory();
  const { classes, disabled, projectId, projectStatusId } = props;

  const handleClickGeneralInformation = () => {
    history.push("/projectDetails/generalInformation/" + projectId);
  };

  const handleClickProposalDashboard = () => {
    history.push("/projectDetails/proposalsDashboard/" + projectId);
  };

  const handleClickContractInformation = () => {
    history.push("/projectDetails/contractInformation/" + projectId);
  };

  const handleClickAccountingHours = () => {
    history.push("/projectDetails/accountingHours/" + projectId);
  };

  const handleClickContacts = () => {
    history.push("/projectDetails/contacts/" + projectId);
  };

  return (
    <div>
      <div>
        <Box
          textAlign="left"
          fontWeight="fontWeightBold"
          letterSpacing={1}
          className={classes.title}
        >
          <BarChart />
          {toUpper("project details")}
        </Box>
      </div>
      <ListItem
        id="tgeneralInformationButton"
        disabled={disabled}
        button
        onClick={handleClickGeneralInformation}
      >
        <Box className={classes.item}>General Information</Box>
      </ListItem>
      {isProjectNew(projectStatusId) && (
        <ListItem
          id="proposaslsDashboardButton"
          disabled={disabled}
          button
          onClick={handleClickProposalDashboard}
        >
          <Box className={classes.item}>Proposals Dashboard</Box>
        </ListItem>
      )}
      {isProjectAwarded(projectStatusId) && (
        <ListItem
          id="contractInformationButton"
          disabled={disabled}
          button
          onClick={handleClickContractInformation}
        >
          <Box className={classes.item}>Contract Information</Box>
        </ListItem>
      )}
      {isProjectActive(projectStatusId) && (
        <ListItem
          id="accountingHoursButton"
          disabled={disabled}
          button
          onClick={handleClickAccountingHours}
        >
          <Box className={classes.item}>Accounting</Box>
        </ListItem>
      )}

      <ListItem
        id="contactsButton"
        disabled={disabled}
        button
        onClick={handleClickContacts}
      >
        <Box className={classes.item}>Contacts</Box>
      </ListItem>
    </div>
  );
};

export default ProjectListItems;
