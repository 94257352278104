import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Box,
} from "@material-ui/core";
import * as store from "../../../state";
//import { ResourcesContext } from "../../contexts/ResourcesContext";
import { getContactTypesDataToStore } from "../../../actions/resourcesAction";

const validationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required"),
  contactTypeId: yup.number().required("Type is required"),
});

const CustomerAutocompleteAdd = (props) => {
  const { open, handleClose, title, companyId, name, onSubmit } = props;
  const dispatchRedux = useDispatch();
  //const resourcesContext = useContext(ResourcesContext);
  const contactTypes = useSelector(store.selectors.resource.selectContactTypes);
  useEffect(async () => {
    await getContactTypesDataToStore({ dispatchRedux });
    //getContactTypes(resourcesContext.dispatch);
  }, []);

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      name,
      companyId,
      contactTypeId: "",
    },
    onSubmit: (values) => {
      onSubmit(values);
      handleClose();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <form style={{ minWidth: "30rem" }}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Box fontSize="subtitle1.fontSize">Name</Box>
            <TextField
              fullWidth
              margin="dense"
              size="small"
              variant="outlined"
              id="name"
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <Box fontSize="subtitle1.fontSize">Contact Type</Box>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              id="contactTypeId"
              name="contactTypeId"
              select
              onBlur={formik.handleBlur}
              value={formik.values.contactTypeId}
              onChange={formik.handleChange}
              error={
                formik.touched.contactTypeId &&
                Boolean(formik.errors.contactTypeId)
              }
              helperText={
                formik.touched.contactTypeId && formik.errors.contactTypeId
              }
            >
              {contactTypes.map((contactType) => (
                <MenuItem key={contactType.id} value={contactType.id}>
                  {contactType.name}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default CustomerAutocompleteAdd;
