import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { getLabel, getLabelTitle } from "../../../../utils/helperFunctions";

const ProposalCard = (props) => {
  const {
    proposalNumber,
    customer,
    version,
    description,
    projectStatusTypeId,
    proposalId,
    projectId,
  } = props;

  const classes = useStyles();
  const history = useHistory();

  const editProposal = () => {
    history.push(
      "/projectDetails/" + projectId + "/editProposal/" + proposalId
    );
  };

  return (
    <Grid
      onClick={() => editProposal()}
      container
      spacing={1}
      className={classes.container}
    >
      <Grid item xs className={classes.item}>
        <text className={classes.proposalNr}>Proposal #{proposalNumber}</text>
        <p className={classes.customerName}>{customer}</p>
      </Grid>
      <Grid item xs className={classes.item}>
        <text className={classes.proposalNr}>{version}</text>

        <p className={classes.dates}>{description}</p>
      </Grid>
      <Grid item xs className={classes.item}>
        <Grid container>
          <Grid item xs></Grid>
          <Grid item xs>
            <p
              className={clsx(
                classes[getLabel(projectStatusTypeId)],
                classes.status
              )}
            >
              {getLabelTitle(projectStatusTypeId)}
            </p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: ".5rem",
    borderRadius: "4px",
    border: ".0125rem solid grey",
    marginBottom: "1rem",
  },
  item: {
    minWidth: "10rem",
  },
  customerName: {
    fontSize: "1rem",
  },
  proposalNr: {
    fontSize: "1.125rem",
  },
  status: {
    minWidth: "7rem",
    fontSize: "1.125rem",
    textAlign: "center",
    padding: ".5rem",
    borderRadius: "4px",
  },
  requested: {
    backgroundColor: "rgba(203, 213, 224, 0.8)",
  },
  new: {
    backgroundColor: `${theme.palette.secondary.dark}`,
  },
  estimating: {
    backgroundColor: "rgba(118, 169, 250, 0.7)",
  },
  noBfield: {
    backgroundColor: "#c15700",
  },
  active: {
    backgroundColor: `${theme.palette.success.dark}`,
  },
  bidWon: {
    backgroundColor: `${theme.palette.success.main}`,
  },
  bidLost: {
    backgroundColor: "rgba(255,0,0,1)",
  },
  bidPending: {
    backgroundColor: `${theme.palette.info.dark}`,
  },
  closed: {
    backgroundColor: "black",
  },
}));

export default ProposalCard;
