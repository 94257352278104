import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Divider, Box, Paper } from "@material-ui/core";
import LocationOn from "@material-ui/icons/LocationOn";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import classNames from "classnames";
import Filters from "../Filters";
import NoteContainer from "../NoteContainer";
import LocationReportsTable from "./locationReport/LocationReportsTable";
import {
  getLocationReports,
  getLocationReportsExpandedTable,
} from "../../../actions/reportsActions";
import ChartWithPeriodFilter from "../ChartWithPeriodFilter";
import { getChartObject } from "../ReportUtil";
import * as store from "../../../state";

const LocationReport = () => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const locationReport = useSelector(
    store.selectors.report.selectLocationReport
  );
  const requested = locationReport.requestedProjects
    ? locationReport.requestedProjects
    : 0;
  const companyId = loggedInUser.userCompany?.id;
  const locationReports = Object.assign(locationReport.states);
  const { missingInformationProjects } = locationReport;

  const [chartSettings, setChartSettings] = useState("monthly");
  const [reportData, setReportData] = useState(Object.assign(locationReport));
  const [chartSelectionFilter, setChartSelectionFilter] = useState({
    stateId: null,
    countyId: null,
  });
  const [chartLevelSettings, setChartLevelSettings] = useState(0);
  const [chartFilterVisible, setChartFilterVisible] = useState(false);
  const [reportFilterOutputLabel, setReportFilterOutputLabel] = useState(
    "Contract/Bid Value ($)"
  );
  const [chartLinkList, setChartLinkList] = useState(["All Locations"]);
  const [filters, setFilters] = useState({
    companyId,
    subsidiariesIds: [],
    divisionsIds: [],
    startDate: null,
    endDate: null,
    outputValue: "contractBidValue",
    stateIds: [],
    countyIds: [],
    period: "monthy",
  });
  const [query, setQuery] = useState("");

  const runReport = async (options, period) => {
    const newFilters = { ...options, period };
    setChartSettings(period);
    setFilters(newFilters);
    setChartLevelSettings(0);
    getLocationReports(newFilters).then((newReportData) => {
      dispatchRedux(store.actions.report.setLocationReport(newReportData));

      setReportData(newReportData);
      setFilterOutputLabel(options);
      setChartSelectionFilter({ stateId: null, countyId: null });
    });
  };

  const expandTable = async () => {
    const newFilters = {
      ...filters,
      stateIds: chartSelectionFilter.stateId
        ? [{ id: chartSelectionFilter.stateId }]
        : filters.stateIds,
      countyIds: chartSelectionFilter.countyId
        ? [{ id: chartSelectionFilter.countyId }]
        : filters.countyIds,
      period: chartSettings,
    };
    getLocationReportsExpandedTable(newFilters).then((newReportData) => {
      dispatchRedux(
        store.actions.report.setLocationExpendableTableReport(newReportData)
      );
      setReportData(newReportData);
    });
  };

  const setFilterOutputLabel = (options) => {
    if (!options || !options.outputValue) {
      return;
    }

    switch (options.outputValue) {
      case "contractBidValue":
        setReportFilterOutputLabel("Contract/Bid Value ($)");
        break;
      case "totalCost":
        setReportFilterOutputLabel("Total Cost ($)");
        break;
      case "laborHours":
        setReportFilterOutputLabel("Labor Hours");
        break;
      default:
        setReportFilterOutputLabel("N/A");
        break;
    }
  };

  useEffect(() => {
    getLocationReports(filters).then((newReportData) => {
      dispatchRedux(store.actions.report.setLocationReport(newReportData));
    });
    setReportFilterOutputLabel("Contract/Bid Value ($)");
  }, []);

  useEffect(() => {
    if (!Array.isArray(locationReports)) {
      return;
    }

    expandTable();
  }, [chartSelectionFilter, chartSettings]);

  useEffect(() => {
    let chartData = [];
    let chartCategory = "period";
    let chartTooltipText = "";
    setChartFilterVisible(true);
    switch (chartLevelSettings) {
      case 0: {
        chartData = reportData.states;
        chartCategory = "name";
        chartTooltipText = "State";
        setChartFilterVisible(false);
        setChartSettings("monthly");
        setChartLinkList(chartLinkList.slice(0, 1));

        break;
      }
      case 1: {
        chartData =
          reportData.states[0] && Array.isArray(reportData.states[0].counties)
            ? reportData.states[0].counties
            : [];
        chartCategory = "name";
        chartTooltipText = "County";
        setChartFilterVisible(false);
        setChartSettings("monthly");

        const auxchartLinkList = chartLinkList.slice(0, 2);

        if (chartSelectionFilter && chartSelectionFilter.stateName) {
          if (chartLinkList.length < 2) {
            setChartLinkList([
              ...chartLinkList,
              chartSelectionFilter.stateName,
            ]);
          } else {
            setChartLinkList(
              auxchartLinkList.map((element, index) =>
                index !== 1 ? element : chartSelectionFilter.stateName
              )
            );
          }
        } else {
          setChartLinkList(auxchartLinkList);
        }
        break;
      }
      case 2: {
        chartData = Array.isArray(reportData.states[0].counties[0].periods)
          ? reportData.states[0].counties[0].periods
          : [];
        chartCategory = "period";
        chartTooltipText = "Period";
        setChartFilterVisible(true);

        if (chartSelectionFilter && chartSelectionFilter.countyName) {
          if (chartLinkList.length < 3) {
            setChartLinkList([
              ...chartLinkList,
              chartSelectionFilter.countyName,
            ]);
          } else {
            setChartLinkList(
              chartLinkList.map((element, index) =>
                index !== 2 ? element : chartSelectionFilter.countyName
              )
            );
          }
        }
        break;
      }
      default:
        return;
    }
    const lowerCaseQuery = query.toLowerCase();
    chartData = query
      ? chartData.filter((x) => {
          if (chartLevelSettings > 1)
            return x.period.toLowerCase().includes(lowerCaseQuery);
          return x.name.toLowerCase().includes(lowerCaseQuery);
        })
      : chartData;

    const chart = getChartObject({
      chartTooltipText,
      chartData,
      chartLevelSettings,
      chartCategory,
      reportFilterOutputLabel,
      handleChartClickFilterUpdate,
    });

    am4core.createFromConfig(chart, "chart-container", am4charts.XYChart);
  }, [
    chartLevelSettings,
    chartSelectionFilter,
    locationReports,
    reportData,
    reportFilterOutputLabel,
    query,
  ]);

  const handleChartClickFilterUpdate = (filterValue, chartLevel) => {
    if (chartLevel === 0) {
      setChartSelectionFilter({
        ...chartSelectionFilter,
        stateId: filterValue.id,
        stateName: filterValue.name,
      });
      setChartLevelSettings(chartLevel + 1);
      setChartLinkList([...chartLinkList, filterValue.name]);
    }

    if (chartLevel === 1) {
      setChartSelectionFilter({
        ...chartSelectionFilter,
        countyId: filterValue.id,
        countyName: filterValue.name,
      });
      setChartLevelSettings(chartLevel + 1);
      setChartLinkList([...chartLinkList, filterValue.name]);
    }
  };

  const handleActionLink = (eventType) => {
    if (eventType !== null && eventType !== chartLevelSettings) {
      setChartLevelSettings(eventType);

      if (eventType === 1) {
        setChartSelectionFilter({ ...chartSelectionFilter, countyId: null });
      }

      if (eventType === 0) {
        setChartSelectionFilter({
          ...chartSelectionFilter,
          countyId: null,
          stateId: null,
        });
      }

      setChartLinkList(chartLinkList.slice(0, eventType + 1));
    }
  };

  return (
    <>
      <div>
        <Box textAlign="left" className={classes.backwardLink}>
          <ArrowBackIosIcon />
          Go back to all reports
        </Box>
        <Link to="/reports/allReports" className={classes.link}>
          <div />
        </Link>
      </div>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <LocationOn />
        </Paper>
        DCI
        <sup className={classes.sup}>TM&nbsp;</sup>
        Pipeline & Backlog Report: Location
      </Box>
      <Divider className={classes.divider} />

      <Filters runReport={runReport} isLocationReport />
      <Divider className={classes.divider} />
      <Paper elevation={3} className={classes.paper}>
        <ChartWithPeriodFilter
          actionButtonsVisible={false}
          filterButtonsVisible={chartFilterVisible}
          actionLinkList={chartLinkList}
          onActionLinkEvent={handleActionLink}
          onFilterChange={setChartSettings}
          activeFilter={chartSettings}
        >
          <div
            id="chart-container"
            className={classNames(classes.chartContainer, {
              chartTopPadding: !chartFilterVisible && chartLevelSettings === 0,
            })}
          />
        </ChartWithPeriodFilter>
      </Paper>
      <Divider className={classes.divider} />
      <NoteContainer
        data={missingInformationProjects}
        requested={requested}
        isLocationReports
        filters={filters}
      />
      <LocationReportsTable
        expandTable={expandTable}
        data={locationReports}
        filters={filters}
        setChartSelectionFilter={setChartSelectionFilter}
        setChartLevelSettings={setChartLevelSettings}
        chartSelectionFilter={chartSelectionFilter}
        chartLevelSettings={chartLevelSettings}
        query={query}
        setQuery={setQuery}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  backwardLink: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontSize: theme.typography.h5.fontSize,
  },
  link: {
    position: "relative",
    bottom: "2rem",
    "& div": {
      height: "2rem",
      width: "13rem",
    },
  },
  divider: {
    margin: "1rem 0",
  },
  paper: {
    height: "32rem",
    margin: "5rem 0",
    padding: "1rem",
  },
  pageTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: "bold",
    display: "flex",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: theme.typography.h3.fontSize,
      },
    },
  },
  chartContainer: {
    height: "100%",

    "&.chartTopPadding": {
      marginTop: "4%",
    },
  },
  sup: {
    verticalAlign: "super",
    fontSize: "1.25rem",
  },
}));

export default LocationReport;
