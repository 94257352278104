import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { Grid, Button, MenuItem, ThemeProvider } from "@material-ui/core";
import CustomTextField from "../../elements/CustomTextField";
import {
  getAvailableStatuses,
  getAvailableStatusesForContract,
} from "../../../utils/helperFunctions";

const ProjectTitle = (props) => {
  const {
    pageTitle,
    currentStatus,
    actions,
    formik,
    nameOfStatusField,
    isForContract,
    isStatusDisabled,
  } = props;
  const classes = useStyles();

  const options = isForContract
    ? getAvailableStatusesForContract(currentStatus)
    : getAvailableStatuses(currentStatus);

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs className={classes.item}>
        <h2 className={classes.pageTitle}>{pageTitle}</h2>
      </Grid>
      <Grid item xs className={classes.item}>
        {currentStatus && (
          <CustomTextField
            name={nameOfStatusField}
            label="Current Status"
            formik={formik}
            disabled={isStatusDisabled}
            style={{ width: "50%" }}
            select
            options={options.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
          />
        )}
      </Grid>
      <ThemeProvider theme={theme}>
        <Grid item container xs className={classes.item} justify="flex-end">
          {actions.map((action) => (
            <div key={action.type} style={{ marginRight: 10, marginLeft: 10 }}>
              <Button
                variant="contained"
                id={action.name + "Button"}
                color={
                  action.type === "error"
                    ? "secondary"
                    : action.type === "success"
                    ? "primary"
                    : "default"
                }
                onClick={() => action.action()}
              >
                {action.name}
              </Button>
            </div>
          ))}
        </Grid>
      </ThemeProvider>
    </Grid>
  );
};

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#1e88dd",
      main: "#1e88dd",
      dark: "#222A68",
      contrastText: "#fff",
    },
    secondary: {
      light: "#D53A3A",
      main: "#D53A3A",
      dark: "#D53A3A",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "capitalize",
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  container: {},
  pageTitle: {
    textTransform: "uppercase",
    color: "#3c4655",
    margin: 0,
  },
  item: {
    minWidth: "10rem",
    display: "flex",
  },
  actions: {
    fontSize: "1.125rem",
  },
  status: {
    fontSize: "1.125rem",
  },
  defaultButton: {
    backgroundColor: "#CBD5E0",
  },
  successButton: {
    backgroundColor: "#3182CE",
  },
  errorButton: {
    backgroundColor: "#FF0000",
  },
}));

export default ProjectTitle;
