import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { checkPermission } from "../../../../utils/helperFunctions";
import ValidationInput from "../../../elements/ValidationInput";
import { useState } from "react";

const AccountingHoursForm = (props) => {
  const { formik, roleId, contract } = props;
  const classes = useStyles();

  const [compositeRate, setCompositeRate] = useState(null);

  const getEstimatedCompositeRatePercentage = (compositeRate) => {
    const originalEstimatedCompositeRate = isNaN(
      contract.originalEstimatedLaborCost / contract.originalEstimatedLaborHours
    )
      ? 0
      : (
          Math.round(
            (contract.originalEstimatedLaborCost /
              contract.originalEstimatedLaborHours) *
              100
          ) / 100
        ).toFixed(2);

    return isNaN(
      (compositeRate - originalEstimatedCompositeRate) /
        originalEstimatedCompositeRate
    )
      ? 0
      : (
          ((compositeRate - originalEstimatedCompositeRate) /
            originalEstimatedCompositeRate) *
          100
        ).toFixed(2);
  };

  useEffect(() => {
    const calculations =
      !formik.values.totalLaborCostIncurredToDate ||
      !formik.values.totalLaborHoursToDate ||
      isNaN(
        formik.values.totalLaborCostIncurredToDate /
          formik.values.totalLaborHoursToDate
      )
        ? 0
        : (
            Math.round(
              (formik.values.totalLaborCostIncurredToDate /
                formik.values.totalLaborHoursToDate) *
                100
            ) / 100
          ).toFixed(2);
    setCompositeRate(calculations);
  }, [
    formik.values.totalLaborCostIncurredToDate,
    formik.values.totalLaborHoursToDate,
  ]);

  return (
    <>
      <Grid item xs={6} spacing={0}>
        <ValidationInput
          id="totalCostToDate"
          name="totalCostToDate"
          label={"Total Cost to Date"}
          disabled={
            formik.values.projectStatusTypeId === 10 ||
            checkPermission([5], roleId)
          }
          value={formik.values.totalCostToDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.totalCostToDate &&
            Boolean(formik.errors.totalCostToDate)
          }
          helperText={
            formik.touched.totalCostToDate && formik.errors.totalCostToDate
          }
          valueLabel="$"
          valueLabelPosition="front"
          className={classes.customField}
        />
      </Grid>
      <Grid item xs={6}>
        <ValidationInput
          id="totalLaborCostIncurredToDate"
          name="totalLaborCostIncurredToDate"
          label={"Total Labor Cost Incurred to Date"}
          disabled={
            formik.values.projectStatusTypeId === 10 ||
            checkPermission([5], roleId)
          }
          value={formik.values.totalLaborCostIncurredToDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.totalLaborCostIncurredToDate &&
            Boolean(formik.errors.totalLaborCostIncurredToDate)
          }
          helperText={
            formik.touched.totalLaborCostIncurredToDate &&
            formik.errors.totalLaborCostIncurredToDate
          }
          valueLabel="$"
          valueLabelPosition="front"
          className={classes.customField}
        />
      </Grid>
      <Grid item xs={6}>
        <ValidationInput
          id="totalLaborHoursToDate"
          name="totalLaborHoursToDate"
          label={"Total Labor Hours to Date"}
          disabled={
            formik.values.projectStatusTypeId === 10 ||
            checkPermission([5], roleId)
          }
          value={formik.values.totalLaborHoursToDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.totalLaborHoursToDate &&
            Boolean(formik.errors.totalLaborHoursToDate)
          }
          helperText={
            formik.touched.totalLaborHoursToDate &&
            formik.errors.totalLaborHoursToDate
          }
          valueLabel="hrs"
          valueLabelPosition="back"
          className={classes.customField}
        />
      </Grid>
      <Grid item xs={6} className={classes.calculations}>
        <p>Current Composite Rate: </p>
        {parseFloat(compositeRate) !== 0 && (
          <p>
            <b className={classes.margin}>
              {` ${parseFloat(compositeRate).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })} (`}
              <span
                className={
                  classes[
                    getEstimatedCompositeRatePercentage(compositeRate) > 0
                      ? "red"
                      : "green"
                  ]
                }
              >
                {`${
                  getEstimatedCompositeRatePercentage(compositeRate) ===
                  "Infinity"
                    ? "-"
                    : getEstimatedCompositeRatePercentage(compositeRate)
                } %`}
              </span>
              {") "}
            </b>
          </p>
        )}
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  green: {
    color: "#4D841D",
  },
  red: {
    color: "red",
  },
  calculations: {
    marginLeft: ".5rem",
    fontSize: "1rem",
  },
  margin: {
    marginLeft: ".4rem",
  },
  customField: {
    minHeight: "4.25rem",
    minWidth: "10rem",
    maxWidth: "22.5rem",
    margin: "0 0.5rem",
  },
}));

export default AccountingHoursForm;
