import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Divider, Box, Paper } from "@material-ui/core";

import TimelineIcon from "@material-ui/icons/Timeline";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { getTimeReports } from "../../../actions/reportsActions";
import ChartWithPeriodFilter from "../ChartWithPeriodFilter";
import Filters from "../Filters";
import NoteContainer from "../NoteContainer";
import TimeReportsTable from "./timeReport/TimeReportsTable";
import { getChartObject } from "../ReportUtil";
import * as store from "../../../state";

const TimeReport = () => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const timeReport = useSelector(store.selectors.report.selectTimeReport);
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId = loggedInUser.userCompany?.id;

  const [query, setQuery] = useState("");
  const lowerCaseQuery = query.toLowerCase();
  const [reportData, setReportData] = useState(timeReport.timeReportsModel);
  const { missingInformationProjects } = timeReport;
  const [chartSettings, setChartSettings] = useState("monthly");
  const [reportFilterOutputLabel, setReportFilterOutputLabel] = useState(
    "Contract/Bid Value ($)"
  );
  const [filters, setFilters] = useState({
    companyId: companyId,
    subsidiariesIds: [],
    divisionsIds: [],
    startDate: null,
    endDate: null,
    outputValue: "contractBidValue",
    jobRoleId: "2",
    period: "monthly",
  });
  useEffect(() => {
    setTimeout(() => {
      setReportData(
        query
          ? timeReport.timeReportsModel.filter((x) =>
              x.period.toLowerCase().includes(lowerCaseQuery)
            )
          : timeReport.timeReportsModel
      );
    }, 50);
  }, [query, timeReport.timeReportsModel]);

  const runReport = (options, period) => {
    const newFilters = { ...options, period };
    setChartSettings(period);
    setFilters(newFilters);
    getTimeReports(newFilters).then((response) => {
      dispatchRedux(store.actions.report.setTimeReport(response));
    });
    setFilterOutputLabel(options);
  };

  const setFilterOutputLabel = (options) => {
    if (!options || !options.outputValue) {
      return;
    }

    switch (options.outputValue) {
      case "contractBidValue":
        setReportFilterOutputLabel("Contract/Bid Value ($)");
        break;
      case "totalCost":
        setReportFilterOutputLabel("Total Cost ($)");
        break;
      case "laborHours":
        setReportFilterOutputLabel("Labor Hours");
        break;
      default:
        setReportFilterOutputLabel("N/A");
        break;
    }
  };

  useEffect(() => {
    getTimeReports(filters).then((response) => {
      dispatchRedux(store.actions.report.setTimeReport(response));
    });
    setReportFilterOutputLabel("Contract/Bid Value ($)");
  }, []);

  useEffect(() => {
    runReport(filters, chartSettings);
  }, [chartSettings]);

  useEffect(() => {
    const chartData = reportData;
    const chartTooltipText = "Period";
    const chart = getChartObject({
      chartTooltipText,
      chartData,
      chartLevelSettings: 0,
      chartCategory: "period",
      reportFilterOutputLabel,
      handleChartClickFilterUpdate: null,
    });

    am4core.createFromConfig(chart, "chart-container", am4charts.XYChart);
  }, [reportData, chartSettings]);

  return (
    <>
      <div>
        <Box textAlign="left" className={classes.backwardLink}>
          <ArrowBackIosIcon />
          Go back to all reports
        </Box>
        <Link to="/reports/allReports" className={classes.link}>
          <div />
        </Link>
      </div>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <TimelineIcon />
        </Paper>
        DCI
        <sup className={classes.sup}>TM&nbsp;</sup>
        Pipeline & Backlog Report: Time
      </Box>
      <Divider className={classes.divider} />

      <Filters runReport={runReport} />
      <Divider className={classes.divider} />

      <Paper elevation={3} className={classes.paper}>
        <ChartWithPeriodFilter
          actionButtonsVisible={false}
          filterButtonsVisible
          onFilterChange={setChartSettings}
          activeFilter={chartSettings}
        >
          <div id="chart-container" className={classes.chartContainer} />
        </ChartWithPeriodFilter>
      </Paper>
      <NoteContainer
        data={missingInformationProjects}
        requested={timeReport.requestedProjects || 0}
        filters={filters}
      />
      <TimeReportsTable
        data={reportData}
        filters={filters}
        setQuery={setQuery}
        query={query}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: "bold",
    display: "flex",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: theme.typography.h3.fontSize,
      },
    },
  },
  backwardLink: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontSize: theme.typography.h5.fontSize,
  },
  link: {
    position: "relative",
    bottom: "2rem",
    "& div": {
      height: "2rem",
      width: "13rem",
    },
  },
  divider: {
    margin: "1rem 0",
  },
  paper: {
    height: "32rem",
    margin: "5rem 0",
    padding: "1rem",
  },
  chartContainer: {
    height: "100%",
  },
  sup: {
    verticalAlign: "super",
    fontSize: "1.25rem",
  },
}));

export default TimeReport;
