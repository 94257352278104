import * as yup from "yup";

const validationSchema = yup.object({
  subsidiaryId: yup
    .string()
    .typeError("Enter your Subsidiary Id")
    .max(10, "Maximum of 10 characters"),
  name: yup
    .string("Enter Subsidiary name")
    .required("Name is required")
    .max(40, "Maximum of 40 characters"),
  industryClassificationId: yup
    .number()
    .required("Subsidiary Industry classification ID is required"),
});

export default validationSchema;
