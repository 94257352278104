import { useIdleTimer } from "react-idle-timer";
import * as constants from "../constants";
import axios from "axios";
import store from "../state/index";
import * as actions from "../state/action/index";

const uiActivityMonitor = () => {
  const execRefreshToken = () => {
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");

    if (!refreshToken || window.location.pathname === "/login") return;

    store.dispatch({ type: actions.app.APP_SHOW_APP_LOADER });
    return axios
      .post(`${constants.API}/user/refreshToken`, {
        refreshToken,
        ExpiredAccessToken: accessToken,
        version: constants.FE_VERSION,
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
        }
        store.dispatch({ type: actions.app.APP_HIDE_APP_LOADER });
      })
      .catch(() => {
        store.dispatch({ type: actions.app.APP_HIDE_APP_LOADER });
        localStorage.clear();
        location.reload();
      });
  };

  const handleOnActive = () => {
    execRefreshToken();
  };

  return useIdleTimer({
    timeout: constants.USER_ACTIVITY_TIMEOUT,
    onActive: handleOnActive,
    crossTab: {
      emitOnAllTabs: true,
    },
  });
};

export default uiActivityMonitor;
