import { React, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  IconButton,
  InputAdornment,
  Button,
  Slide,
  Paper,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import clsx from "clsx";
import * as constants from "../../../constants";
import CustomTextField from "../../elements/CustomTextField";

const CompanyForm = (props) => {
  const { onSubmit, initialValues } = props;
  const classes = useStyles();
  const [successUpdate, setSuccessUpdate] = useState(false);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
      setSuccessUpdate(true);
      setTimeout(() => {
        setSuccessUpdate(false);
      }, 5000);
    },
  });

  return (
    <div>
      <form>
        <Grid container>
          <Grid item xs={9}>
            <Box id="accountSettingsSubtitle" className={classes.pageSubTitle}>
              Manage Accounts Limits
            </Box>
            <Grid container>
              <Grid item className={clsx(classes.flexRow)}>
                <Box className={classes.label}>Subsidiaries*</Box>
                <CustomTextField
                  id="maxNumberOfSubsidiariesTextBox"
                  name="maxNumberOfSubsidiaries"
                  label="Enter Number of Subsidiaries"
                  formik={formik}
                  InputProps={{
                    endAdornment: returnEndAdornment(
                      formik,
                      "maxNumberOfSubsidiaries"
                    ),
                  }}
                />
              </Grid>
              <Grid item className={clsx(classes.flexRow)}>
                <Box className={clsx(classes.label)}>Divisions*</Box>
                <CustomTextField
                  id="maxNumberOfDivisionsTextBox"
                  name="maxNumberOfDivisions"
                  label="Enter Number of Divisions"
                  formik={formik}
                  InputProps={{
                    endAdornment: returnEndAdornment(
                      formik,
                      "maxNumberOfDivisions"
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <div className={clsx(classes.flexRow)}>
                  <Box className={classes.label}>User Limit*</Box>
                  <CustomTextField
                    id="maxNumberOfUsersTextBox"
                    name="maxNumberOfUsers"
                    label="Enter Number of Users"
                    formik={formik}
                    InputProps={{
                      endAdornment: returnEndAdornment(
                        formik,
                        "maxNumberOfUsers"
                      ),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.buttonGoup}>
            <Button
              variant="contained"
              id="saveAccountSettingsButton"
              className={classes.saveButton}
              onClick={() => formik.handleSubmit()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
        <Slide
          id="accountSettingsNotification"
          timeout={{ enter: 500, exit: 500 }}
          direction="left"
          in={successUpdate}
          mountOnEnter
          unmountOnExit
        >
          <Paper elevation={4} className={classes.notificationPaper}>
            <CheckCircleIcon className={classes.icon} />
            <Box className={classes.successNotificationIconText}>
              Account Successfully Updated
            </Box>
          </Paper>
        </Slide>
      </form>
    </div>
  );
};

const returnEndAdornment = (formik, field) => (
  <InputAdornment position="end">
    <IconButton
      disableRipple
      disabled={parseInt(formik.values[field]) <= 1}
      id={`decreaseValue${field}`}
      onClick={() => {
        formik.setFieldValue(
          `${field}`,
          `${parseInt(formik.values[field]) - 1}`
        );
      }}
    >
      <ArrowDropDownIcon />
    </IconButton>
    <IconButton
      disableRipple
      id={`increaseValue${field}`}
      disabled={parseInt(formik.values[field]) >= 99999}
      onClick={() => {
        formik.setFieldValue(
          `${field}`,
          `${parseInt(formik.values[field]) + 1}`
        );
      }}
    >
      <ArrowDropUpIcon />
    </IconButton>
  </InputAdornment>
);

const validationSchema = yup.object({
  maxNumberOfUsers: yup
    .number()
    .positive()
    .integer("The value must be a number.")
    .typeError("The value must be a number.")
    .min(1)
    .max(99999)
    .required("Max number of user seats is required"),
  maxNumberOfSubsidiaries: yup
    .number()
    .positive()
    .integer("The value must be a number.")
    .typeError("The value must be a number.")
    .min(1)
    .max(99999)
    .required("Max number of subsidiaries is required"),
  maxNumberOfDivisions: yup
    .number()
    .positive()
    .integer("The value must be a number.")
    .typeError("The value must be a number.")
    .min(1)
    .max(99999)
    .required("Max number of divisions is required"),
});

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "1rem 0.5rem 0.5rem 0.5rem",
    fontWeight: "bold",
    minWidth: "6rem",
  },
  asterisk: {
    color: constants.ASTERISK_COLOR,
  },
  button: {
    backgroundColor: theme.palette.info.light,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  buttonGoup: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-end",
    "& button": {
      width: "10rem",
    },
  },
  saveButton: {
    backgroundColor: "#3182CE",
  },
  pageSubTitle: {
    fontSize: "0.875rem",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#4A5568",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  icon: {
    fontSize: 30,
    color: "#AFDA95",
  },
  marginLeft: {
    marginRight: "1rem",
  },
  notificationPaper: {
    display: "flex",
    flexDirection: "row",
    padding: "1rem",
    alignItems: "center",
    height: "4rem",
    width: "24rem",
    borderRadius: "4px",
    zIndex: "1000",
    position: "sticky",
    left: "60rem",
  },
  successNotificationIconText: {
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "2rem",
  },
}));

export default CompanyForm;
