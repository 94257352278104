import React from "react";
import { toUpper } from "lodash";
import { useHistory } from "react-router-dom";
import { ListItem, Box } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";

const AdministrationListItems = (props) => {
  const history = useHistory();

  const handleClickCompany = () => {
    history.push("/company");
  };
  const handleClickDivision = () => {
    history.push("/divisions");
  };
  const handleClickUsers = () => {
    history.push("/users");
  };
  const handleClickSubsidiary = () => {
    history.push("/subsidiaries");
  };
  const { classes, disabled } = props;
  return (
    <div>
      <div>
        <Box
          textAlign="left"
          fontWeight="fontWeightBold"
          letterSpacing={1}
          className={classes.title}
        >
          <SettingsIcon />
          {toUpper("administration")}
        </Box>
      </div>
      <ListItem
        id="companyButton"
        disabled={disabled}
        button
        onClick={handleClickCompany}
      >
        <Box className={classes.item}>Company</Box>
      </ListItem>
      <ListItem
        id="subsidiaryButton"
        disabled={disabled}
        button
        onClick={handleClickSubsidiary}
      >
        <Box className={classes.item}>Subsidiary</Box>
      </ListItem>
      <ListItem
        id="divisionsButton"
        disabled={disabled}
        button
        onClick={handleClickDivision}
      >
        <Box className={classes.item}>Divisions</Box>
      </ListItem>
      <ListItem
        id="usersButton"
        disabled={disabled}
        button
        onClick={handleClickUsers}
      >
        <Box className={classes.item}>Users</Box>
      </ListItem>
    </div>
  );
};

export default AdministrationListItems;
