import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";

export const CheckboxGroup = (props) => {
  const { label, options, error, errorText } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl
        required
        error={error}
        component="fieldset"
        className={classes.formControl}
      >
        {error && <FormHelperText>{errorText}</FormHelperText>}
        <FormLabel component="legend">{label}</FormLabel>
        <FormGroup>
          {options.map((option) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={option.isChecked}
                  onChange={option.handleChange}
                  name={option.name}
                />
              }
              className={classes.checkbox}
              key={option.label}
              label={option.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    " & .MuiFormControlLabel-label": {
      fontSize: "0.875rem",
    },
  },
  formControl: {
    margin: " 1rem 0",
  },
  checkbox: {
    fontSize: "0.875rem",
  },
}));
