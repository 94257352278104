import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, Box, Grid } from "@material-ui/core";
import ActiveInfoGroup from "./projectInfoGroup/Active";
import ActiveButtonGroup from "./projectButtonGroup/Active";
import BidWonInfoGroup from "./projectInfoGroup/BidWon";
import BidWonButtonGroup from "./projectButtonGroup/BidWon";
import BidPendingInfoGroup from "./projectInfoGroup/BidPending";
import BidPendingButtonGroup from "./projectButtonGroup/BidPending";
import EstimatingInfoGroup from "./projectInfoGroup/Estimating";
import EstimatingButtonGroup from "./projectButtonGroup/Estimating";
import NewButtonGroup from "./projectButtonGroup/New";
import NewInfoGroup from "./projectInfoGroup/New";
import RequestedButtonGroup from "./projectButtonGroup/Requested";
import RequestedInfoGroup from "./projectInfoGroup/Requested";
import NoBid from "./projectInfoGroup/NoBid";
import BidLost from "./projectInfoGroup/BidLost";
import Closed from "./projectInfoGroup/Closed";
import ProgressChart from "./projectInfoGroup/ProgressChart";

const Project = (props) => {
  const classes = useStyles();
  const labelColor = rederColor(classes, props.label);
  const labelColorSmall = rederColorSmall(classes, props.label);

  const [screenSize, setScreenSize] = useState(window.innerWidth > 600);

  const updateMedia = () => {
    setScreenSize(window.innerWidth > 600);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      {screenSize
        ? projectLarge(labelColor, classes, props)
        : projectSmall(labelColor, labelColorSmall, classes, props)}
    </>
  );
};

const projectLarge = (labelColor, classes, props) => (
  <Link
    to={
      props.row.projectStatusTypeId < 7
        ? "/projectDetails/generalInformation/" + props.row.id
        : "/projectDetails/contractInformation/" + props.row.id
    }
    className={classes.link}
  >
    <Card
      className={clsx(classes.cardRoot, labelColor)}
      key={props.row.id}
      elevation={2}
    >
      <div className={classes.content}>
        {/*  */}
        <CardContent className={classes.inneTitleContent}>
          <Grid container>
            <Grid item>
              <Box fontSize="h6.fontSize" fontWeight="fontWeightBold">
                {props.row.name}
              </Box>
              <Box fontSize="h6.fontSize" fontStyle="italic">
                {props.row.projectStatusTypeId >= 8
                  ? `Project No.: ${
                      props.row.projectNumber ? props.row.projectNumber : "-"
                    }`
                  : `Proposal No.: ${props.row.proposalNumber}`}
              </Box>
              <Box fontSize="h6.fontSize" fontStyle="italic">
                {props.row.customer.name}
              </Box>
            </Grid>
            <Grid item>{renderButtonGroup(props.label, props.row.id)}</Grid>
          </Grid>
        </CardContent>
        <CardContent className={classes.progressChartCointainer}>
          {renderProgressChart(props)}
        </CardContent>
        <CardContent className={classes.innerInfoContent}>
          {renderInfoGroup(props.label, props.row)}
        </CardContent>
      </div>
    </Card>
  </Link>
);

const projectSmall = (labelColor, labelColorSmall, classes, props) => (
  <Link
    to={
      props.row.projectStatusTypeId < 7
        ? "/projectDetails/generalInformation/" + props.row.id
        : "/projectDetails/contractInformation/" + props.row.id
    }
  >
    <Card className={classes.cardRoot} key={props.row.id}>
      <CardContent className={classes.content}>
        <div>
          <Box fontSize="h6.fontSize" fontWeight="fontWeightBold">
            {props.row.name}
          </Box>
          <Box fontSize="h6.fontSize" fontWeight="fontWeightBold">
            {props.row.proposalNumber}
          </Box>
          <Box fontSize="h6.fontSize" fontWeight="fontWeightBold">
            {props.row.customer.name}
          </Box>
        </div>
      </CardContent>
      <CardContent className={classes.content}>
        <Button
          variant="outlined"
          color="default"
          className={clsx(classes.labelSmall, labelColorSmall)}
        >
          {props.labelTitle}
        </Button>
      </CardContent>
      <CardContent className={classes.content}>
        {renderInfoGroup(props.label, props.row)}
      </CardContent>
      <CardContent>{renderButtonGroup(props.label, props.row.id)}</CardContent>
    </Card>
  </Link>
);

const renderProgressChart = (props) => {
  if (props.label === "active") {
    return <ProgressChart row={props.row} />;
  }
};

const rederColor = (classes, label) => classes[label];

const rederColorSmall = (classes, label) => classes[`${label}Small`];

const renderButtonGroup = (label, projectId) => {
  switch (label) {
    case "requested":
      return <RequestedButtonGroup projectId={projectId} />;
    case "new":
      return <NewButtonGroup projectId={projectId} />;
    case "estimating":
      return <EstimatingButtonGroup projectId={projectId} />;
    case "bidPending":
      return <BidPendingButtonGroup projectId={projectId} />;
    case "bidWon":
      return <BidWonButtonGroup projectId={projectId} />;
    case "active":
      return <ActiveButtonGroup projectId={projectId} />;
    default:
      return <NewButtonGroup projectId={projectId} />;
  }
};

const renderInfoGroup = (label, row) => {
  switch (label) {
    case "requested":
      return <RequestedInfoGroup row={row} />;
    case "new":
      return <NewInfoGroup row={row} />;
    case "estimating":
      return <EstimatingInfoGroup row={row} />;
    case "bidPending":
      return <BidPendingInfoGroup row={row} />;
    case "bidWon":
      return <BidWonInfoGroup row={row} />;
    case "active":
      return <ActiveInfoGroup row={row} />;
    case "closed":
      return <Closed row={row} />;
    case "bidLost":
      return <BidLost row={row} />;
    case "noBid":
      return <NoBid row={row} />;
    case "deleted":
      return <NoBid row={row} />;
    default:
      return <NewInfoGroup row={row} />;
  }
};

const useStyles = makeStyles((theme) => ({
  requested: {
    borderLeft: "1.5rem solid rgba(203, 213, 224, 0.8)",
  },
  new: {
    borderLeft: `1.5rem solid ${theme.palette.secondary.dark}`,
  },
  estimating: {
    borderLeft: "1.5rem solid rgba(118, 169, 250, 0.7)",
  },
  noBid: {
    borderLeft: " 1.5rem solid #c15700",
  },
  active: {
    borderLeft: ` 1.5rem solid ${theme.palette.success.dark}`,
  },
  bidWon: {
    borderLeft: ` 1.5rem solid ${theme.palette.success.main}`,
  },
  bidLost: {
    borderLeft: " 1.5rem solid rgba(255,0,0,1)",
  },
  bidPending: {
    borderLeft: ` 1.5rem solid ${theme.palette.info.dark}`,
  },
  closed: {
    borderLeft: " 1.5rem solid rgba(255, 0, 0, 0)",
  },
  requestedSmall: {
    backgroundColor: "rgba(203, 213, 224, 0.8)",
    color: theme.palette.secondary.dark,
  },
  newSmall: {
    backgroundColor: fade(theme.palette.secondary.dark, 0.25),
    color: theme.palette.secondary.dark,
  },
  estimatingSmall: {
    backgroundColor: fade(theme.palette.info.light, 0.25),
    color: theme.palette.info.light,
  },
  activeSmall: {
    backgroundColor: fade(theme.palette.success.dark, 0.25),
    color: theme.palette.success.dark,
  },
  bidWonSmall: {
    backgroundColor: fade(theme.palette.success.main, 0.25),
    color: theme.palette.success.main,
  },
  bidPendingSmall: {
    backgroundColor: fade(theme.palette.info.dark, 0.25),
    color: theme.palette.info.dark,
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      margin: "0.5rem",
      borderRadius: 16,
      flexDirection: "row",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    position: "relative",
  },
  label: {
    color: "white",
    width: "2%",
    // minWidth: "7rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& div": {
      textAlign: "center",
      margin: 2,
    },
  },
  labelSmall: {
    margin: 0,
    fontWeight: "bold",
    fontSize: "1rem",
    border: "none !important",
  },
  logo: {
    maxWidth: "8rem",
    display: "flex",
    float: "right",
    borderRadius: 8,
  },
  externalLogo: {
    width: "20%",
  },
  inneTitleContent: {
    width: "30%",
  },
  innerInfoContent: {
    width: "60%",
    paddingLeft: "0rem",
    display: "flex",
    flexDirection: "row",
    "& div": {
      width: "8rem",
      marginRight: "0.5rem",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  link: {
    textDecoration: "none",
  },
  progressChartCointainer: {
    width: "10%",
    alignSelf: "baseline",
  },
}));

export default Project;
