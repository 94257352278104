import React from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Close, Error, CheckCircle, Cancel } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const NotificationPopUp = (props) => {
  const {
    isOpen,
    handleClose,
    type,
    title,
    text,
    actions,
    additionalComponent,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classes.root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <Close />
      </IconButton>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <div className={classes.notificationArea}>
        <div className={classes.notificationType}>
          {type === "warning" && <Error className={classes.warningIcon} />}
          {type === "error" && <Cancel className={classes.errorIcon} />}
          {type === "success" && (
            <CheckCircle className={classes.successIcon} />
          )}
        </div>
        <div className={classes.notificationText}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {typeof text === "string" ? <p>{text}</p> : <div>{text}</div>}
            </DialogContentText>
          </DialogContent>
        </div>
      </div>
      {additionalComponent}
      <DialogActions>
        {actions.map((action, index) => (
          <Button
            key={index}
            onClick={action.action}
            color={action.name === "CANCEL" ? "black" : "primary"}
          >
            <b>{action.name}</b>
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& div": {
      borderRadius: "0px",
    },
  },
  paper: {
    borderRadius: "0px !important",
    overflowY: "hidden",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    color: "black",
  },
  notificationArea: {
    display: "flex",
  },
  notificationText: {
    float: "right",
    maxWidth: "23.5rem",
    "& div": {
      paddingTop: "0px !important",
    },
  },
  notificationType: {
    marginLeft: "2rem",
    float: "left",
    marginTop: "1rem",
  },
  warningIcon: {
    color: "#FCB500",
    fontSize: "2.5rem",
  },
  errorIcon: {
    color: "#FC0000",
    fontSize: "2.5rem",
  },
  successIcon: {
    color: "#AFDA95",
    fontSize: "2.5rem",
  },
}));

export default NotificationPopUp;
