import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Grid, MenuItem } from "@material-ui/core";
import CustomTextField from "../../../elements/CustomTextField";
import { checkPermission } from "../../../../utils/helperFunctions";
import {
  getCountriesDataToStore,
  getCountiesDataToStore,
  getStatesDataToStore,
} from "../../../../actions/resourcesAction";
import * as store from "../../../../state";

const Location = (props) => {
  const { formik } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const countries = useSelector(store.selectors.resource.selectCountries);
  const states = useSelector(store.selectors.resource.selectStates);
  const counties = useSelector(store.selectors.resource.selectCounties);

  const roleId = loggedInUser.roleId;

  useEffect(() => {
    getStatesDataToStore(formik.values.countryId, { dispatchRedux }).then();
  }, [formik.values.countryId]);

  useEffect(() => {
    getCountiesDataToStore(formik.values.stateId, {
      dispatchRedux,
    }).then();
  }, [formik.values.stateId]);

  useEffect(() => {
    if (
      states.length > 0 &&
      states.filter((state) => state.id === formik.values.stateId).length === 0
    ) {
      formik.setFieldValue("stateId", "");
      formik.setFieldValue("countyId", "");
    }
  }, [states]);

  useEffect(() => {
    if (
      counties.length > 0 &&
      counties.filter((county) => county.id === formik.values.countyId)
        .length === 0
    ) {
      formik.setFieldValue("countyId", "");
    }
  }, [counties]);

  useEffect(async () => {
    await Promise.all([
      getCountriesDataToStore({ dispatchRedux }),
      formik.values.countryId &&
        getStatesDataToStore(formik.values.countryId, { dispatchRedux }).then(),
      formik.values.stateId &&
        getCountiesDataToStore(formik.values.stateId, {
          dispatchRedux,
        }).then(),
    ]);
  }, []);

  return (
    <div className={classes.container}>
      <h4 className={classes.pageTitle}>Location/Address</h4>
      <Grid container className={classes.content}>
        <Grid item xs className={classes.item}>
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="countryId"
            label={
              formik.values.projectStatusTypeId > 1 ? "Country*" : "Country"
            }
            formik={formik}
            select
            options={countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          />
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="city"
            label={formik.values.projectStatusTypeId > 1 ? "City*" : "City"}
            formik={formik}
          />
        </Grid>
        <Grid item xs className={classes.item}>
          <Grid container>
            <Grid item xs={4}>
              <CustomTextField
                disabled={checkPermission([5], roleId)}
                name="stateId"
                label={
                  formik.values.projectStatusTypeId > 1 ? "State*" : "State"
                }
                formik={formik}
                select
                options={states.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.code}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={8}>
              <CustomTextField
                disabled={checkPermission([5], roleId)}
                name="postalCode"
                label={
                  formik.values.projectStatusTypeId > 1
                    ? "Zip Code*"
                    : "Zip Code"
                }
                formik={formik}
              />
            </Grid>
          </Grid>
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="address1"
            label="Address Line 1"
            formik={formik}
          />
        </Grid>
        <Grid item xs className={classes.lastItem}>
          <CustomTextField
            name="countyId"
            label={formik.values.projectStatusTypeId > 1 ? "County*" : "County"}
            formik={formik}
            select
            disabled={checkPermission([5], roleId)}
            options={counties.map((county) => (
              <MenuItem key={county.id} value={county.id}>
                {county.name}
              </MenuItem>
            ))}
          />
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="address2"
            label="Address Line 2"
            formik={formik}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "4rem",
  },
  item: {
    minWidth: "10rem",
    marginRight: "2rem",
  },
  lastItem: {
    minWidth: "10rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    borderRadius: 5,
  },
  content: {
    paddingLeft: 15,
  },
  customField: {
    minHeight: "3rem",
    margin: "0",
    marginTop: "0.35rem",
  },
  hr: {
    border: "solid 1px dimgrey !important",
  },
}));

export default Location;
