import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { List, Divider, Icon } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import DCI_logo from "../../resources/DCI_logo.png";
import AdministrationListItems from "./menuItems/AdministrationListItems";
import ReportsListItems from "./menuItems/ReportsListItems";
import SuperAdminListItems from "./menuItems/SuperAdminListItems";
import { checkPermission, isProjectActive } from "../../utils/helperFunctions";
import ProjectListItems from "./menuItems/ProjectListItems";
import DCIInterconnectionListItems from "./menuItems/DCIInterconnectionListItems";
import * as store from "../../state";

const Sidebar = () => {
  const classes = useStyles();

  const location = useLocation();
  const currentPath = location.pathname;

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const currentProject = useSelector(
    store.selectors.project.selectProjectHeaderInfo
  );

  return (
    <div className={classes.drawerContainer}>
      <Icon classes={{ root: classes.iconRoot }}>
        <img className={classes.imageIcon} src={DCI_logo} alt="Company Logo" />
      </Icon>
      <Divider />
      {!currentPath.includes("projectDetails") ? (
        <>
          {checkPermission([1, 2], loggedInUser.roleId) && (
            <List className={classes.list}>
              <ReportsListItems
                classes={classes}
                disabled={!loggedInUser.userCompany}
              />
            </List>
          )}
          {checkPermission([1, 2], loggedInUser.roleId) &&
            (loggedInUser.companyId ||
              (loggedInUser.userCompany && loggedInUser.userCompany.id)) && (
              <>
                <Divider />
                <List className={classes.list}>
                  <AdministrationListItems
                    classes={classes}
                    disabled={!loggedInUser.userCompany}
                  />
                </List>
              </>
            )}
          {checkPermission([1], loggedInUser.roleId) && (
            <>
              <Divider />
              <List className={classes.list}>
                <SuperAdminListItems classes={classes} />
              </List>
            </>
          )}
        </>
      ) : (
        <>
          {checkPermission([1, 2], loggedInUser.roleId) && (
            <>
              <Divider />
              <List className={classes.list}>
                <ProjectListItems
                  classes={classes}
                  projectStatusId={currentProject.projectStatusTypeId}
                  projectId={
                    currentPath.includes("edit")
                      ? currentPath.split("/")[2]
                      : currentPath.split("/").pop()
                  }
                />
              </List>
              {isProjectActive(currentProject.projectStatusTypeId) && (
                <>
                  <Divider />
                  <List className={classes.list}>
                    <DCIInterconnectionListItems
                      classes={classes}
                      projectId={
                        currentPath.includes("edit")
                          ? currentPath.split("/")[2]
                          : currentPath.split("/").pop()
                      }
                    />
                  </List>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    overflow: "auto",
  },
  title: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontSize: theme.typography.h6.fontSize,
    "& svg": {
      fontSize: "2rem",
      marginRight: "0.3rem",
    },
  },
  subTitle: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: "bold",
    margin: "0.3rem 0",
    color: theme.palette.grey[700],
  },
  item: {
    fontSize: theme.typography.h6.fontSize,
    margin: "0.15rem .5rem",
    color: theme.palette.grey[600],
  },
  list: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  imageIcon: {
    margin: "1rem 2rem",
    height: "8rem",
    width: "10rem",
  },
  iconRoot: {
    textAlign: "center",
  },
}));

export default Sidebar;
