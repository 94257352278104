import React from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const AdministrationTabBar = (props) => {
  const classes = useStyles();
  const { page, view, id } = props;
  const history = useHistory();

  const handleCompanyClick = props.handleCompanyClick
    ? props.handleCompanyClick
    : () => {
        history.push(
          view === "sysAdmin"
            ? `/systemAdministration/company/${id}`
            : "/company"
        );
      };

  const handleSubsidiaryClick = props.handleSubsidiaryClick
    ? props.handleSubsidiaryClick
    : () => {
        history.push(
          view === "sysAdmin"
            ? `/systemAdministration/subsidiaries/${id}`
            : "/subsidiaries"
        );
      };
  const handleDivisionClick = props.handleDivisionClick
    ? props.handleDivisionClick
    : () => {
        history.push(
          view === "sysAdmin"
            ? `/systemAdministration/divisions/${id}`
            : "/divisions"
        );
      };
  const handleUsersClick = props.handleUsersClick
    ? props.handleUsersClick
    : () => {
        history.push(
          view === "sysAdmin" ? `/systemAdministration/users/${id}` : "/users"
        );
      };
  const handleAccountSettingsClick = props.handleAccountSettingsClick
    ? props.handleAccountSettingsClick
    : () => {
        history.push(
          view === "sysAdmin"
            ? `/systemAdministration/company/accountSettings/${id}`
            : "/"
        );
      };
  const handleInterconnectionClick = props.handleInterconnectionClick
    ? props.handleInterconnectionClick
    : () => {
        history.push(
          view === "sysAdmin" ? `/systemAdministration/subsidiaries/${id}` : "/"
        );
      };

  return (
    <div className={classes.titleButtons}>
      <Button
        variant="text"
        size="large"
        className={clsx({
          [classes.projectTypeButtonNotSelected]: page !== "company",
          [classes.projectTypeButtonSelected]: page === "company",
        })}
        onClick={handleCompanyClick}
      >
        COMPANY
      </Button>
      <Button
        variant="text"
        size="large"
        className={clsx({
          [classes.projectTypeButtonNotSelected]: page !== "subsidiaries",
          [classes.projectTypeButtonSelected]: page === "subsidiaries",
        })}
        onClick={handleSubsidiaryClick}
      >
        SUBSIDIARY
      </Button>
      <Button
        variant="text"
        size="large"
        className={clsx({
          [classes.projectTypeButtonNotSelected]: page !== "divisions",
          [classes.projectTypeButtonSelected]: page === "divisions",
        })}
        onClick={handleDivisionClick}
      >
        DIVISIONS
      </Button>
      <Button
        variant="text"
        size="large"
        className={clsx({
          [classes.projectTypeButtonNotSelected]: page !== "users",
          [classes.projectTypeButtonSelected]: page === "users",
        })}
        onClick={handleUsersClick}
      >
        USERS
      </Button>
      {view === "sysAdmin" && (
        <>
          <Button
            variant="text"
            size="large"
            className={clsx({
              [classes.projectTypeButtonNotSelected]:
                page !== "accountSettings",
              [classes.projectTypeButtonSelected]: page === "accountSettings",
            })}
            onClick={handleAccountSettingsClick}
          >
            ACCOUNT SETTINGS
          </Button>
          <Button
            variant="text"
            size="large"
            className={clsx({
              [classes.projectTypeButtonNotSelected]:
                page !== "interconnection",
              [classes.projectTypeButtonSelected]: page === "interconnection",
            })}
            onClick={handleInterconnectionClick}
          >
            INTERCONNECTION
          </Button>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  titleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& button": {
      width: "11.5rem",
      minWidth: "fit-content",
      marginRight: ".75rem",
      marginBottom: "2rem",
      borderRadius: "0rem",
      lineHeight: "1.313rem",
      fontSize: theme.typography.h6.fontSize,
    },
  },
  projectTypeButtonSelected: {
    borderBottom: `0.2rem solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  projectTypeButtonNotSelected: {
    borderBottom: "0.2rem solid #BEE3F8",
    color: "#BEE3F8",
  },
}));

export default AdministrationTabBar;
