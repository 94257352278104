import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getCompaniesData,
  getCompanyData,
  updateCompanyStatusData,
} from "../../../actions/companyActions";
import { useSelector, useDispatch } from "react-redux";
import { getStatusesData } from "../../../actions/resourcesAction";
import AccountsTable from "./AccountsTable";
import { Box } from "@material-ui/core";
import { Create, Add, Delete, Beenhere, Block } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import SystemAdministrationTabs from "../SystemAdministrationTabs";
import NotificationPopUp from "../../utils/NotificationPopUp";
import * as store from "../../../state";

const AccountsDashboard = () => {
  let history = useHistory();
  const dispatchRedux = useDispatch();
  const companies = useSelector(store.selectors.company.selectCompanies);
  const divisionStatuses = useSelector(
    store.selectors.resource.selectDivisionStatuses
  );
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState([]);
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const isEditButtonDisable = selectedRows.length === 1;
  const isDeleteButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.statusId === 3).length !==
      selectedRows.length;
  const isActivateButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.statusId === 2).length !== 0;
  const isDeactivateButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.statusId === 1).length !== 0;

  const changeCompanyStatusData = (condition, toStatusId, toStatus) => {
    const companyIds = selectedRows
      .filter((cmp) => condition(cmp))
      .map((cmp) => cmp.id);
    updateCompanyStatusData(
      {
        companyIds,
        status: toStatus,
        statusId: toStatusId,
      },
      () => {
        dispatchRedux(
          store.actions.company.editCompanyStatus({
            companyIds,
            statusId: toStatusId,
          })
        );
      }
    );
  };

  const deleteAccount = () => {
    changeCompanyStatusData((cmp) => cmp.statusId !== 3, 3, "DELETED");
  };

  const activateAccount = () => {
    changeCompanyStatusData((cmp) => cmp.statusId === 2, 1, "ACTIVE");
  };

  const deactivateAccount = () => {
    setNotificationTitle("Deactivate Account");
    setNotificationText(
      "Deactivating this account will prevent all users in this account from logging in.  Do you want to continue?"
    );
    setNotificationType("warning");
    setNotificationActions([
      {
        name: "DEACTIVATE",
        action: () => {
          changeCompanyStatusData((cmp) => cmp.statusId === 1, 2, "INACTIVE");
          handleClose();
        },
      },
      { name: "CANCEL", action: () => handleClose() },
    ]);
    setIsNotificationPopUpOpen(true);
  };

  const areMultipleStatusesSelected = (requiredStatus) => {
    let multipleStatusesSelected = false;
    selectedRows.forEach((row) => {
      if (row.statusId !== requiredStatus) {
        multipleStatusesSelected = true;
      }
    });
    return multipleStatusesSelected;
  };

  const handleAdd = () => {
    history.push(`/addCompany`);
  };

  const handleEdit = () => {
    history.push(`/systemAdministration/company/${selectedRows[0].id}`);
  };

  const handleDelete = () => {
    setNotificationTitle("Delete Account");
    setNotificationText(
      "Deleting this account will prevent all users in this account from logging in and the entire account will be deleted. Do you want to continue?"
    );
    setNotificationType("warning");
    setNotificationActions([
      {
        name: "DELETE",
        action: () => {
          deleteAccount();
          handleClose();
        },
      },
      { name: "CANCEL", action: () => handleClose() },
    ]);
    setIsNotificationPopUpOpen(true);
  };

  const handleActivateAccount = () => {
    if (areMultipleStatusesSelected(2)) {
      setNotificationTitle("Multiple statuses selected");
      setNotificationText(
        "Only INACTIVE accounts will be activated. Would you like to proceed?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "YES",
          action: () => {
            activateAccount();
            handleClose();
          },
        },
        {
          name: "NO",
          action: () => {
            handleClose();
          },
        },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      activateAccount();
    }
  };

  const handleDeactivateAccount = () => {
    if (areMultipleStatusesSelected(1)) {
      setNotificationTitle("Multiple statuses selected");
      setNotificationText(
        "Only ACTIVE accounts will be inactivated. Would you like to proceed?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "YES",
          action: () => {
            deactivateAccount();
          },
        },
        {
          name: "NO",
          action: () => {
            handleClose();
          },
        },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      deactivateAccount();
    }
  };

  const handleClose = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleFakeLogin = async (companyId) => {
    const { data: userCompany } = await getCompanyData(companyId);

    dispatchRedux(
      store.actions.login.setLoggedInUser({
        ...loggedInUser,
        userCompany,
        companyId,
      })
    );

    history.push(`/projects`);
  };

  useEffect(() => {
    setSelectedRows([]);
  }, [companies]);

  useEffect(() => {
    getCompaniesData(({ data }) => {
      dispatchRedux(store.actions.company.setCompanies(data));
    });

    getStatusesData(({ data }) => {
      dispatchRedux(store.actions.resource.setDivisionStatuses(data));
    });
  }, []);

  return (
    <>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleClose}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
      <SystemAdministrationTabs selectedPage={0} />
      <Box className={classes.pageSubTitle}>
        {"DCI"}
        <sup className={classes.sup}>TM&nbsp;</sup>
        {" Accounts"}
      </Box>
      <div className={classes.actionArea}>
        <div className={classes.actionButtons}>
          <div
            id="addButton"
            className={classes.actionButton}
            onClick={() => handleAdd()}
          >
            <Add className={classes.icon} />
            <b>Add</b>
          </div>
          <div
            id="editButton"
            className={
              isEditButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() => (isEditButtonDisable ? handleEdit() : null)}
          >
            <Create className={classes.icon} />
            <b>Edit</b>
          </div>
          <div
            id="deleteButton"
            className={
              isDeleteButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() => (isDeleteButtonDisable ? handleDelete() : null)}
          >
            <Delete className={classes.icon} />
            <b>Delete</b>
          </div>
          <div
            id="activateButton"
            className={
              isActivateButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isActivateButtonDisable ? handleActivateAccount() : null
            }
          >
            <Beenhere className={classes.icon} />
            <b>Activate Account</b>
          </div>
          <div
            id="decativateButton"
            className={
              isDeactivateButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isDeactivateButtonDisable ? handleDeactivateAccount() : null
            }
          >
            <Block className={classes.icon} />
            <b>Deactivate Account</b>
          </div>
        </div>
      </div>
      <AccountsTable
        setSelectedRows={setSelectedRows}
        rows={companies}
        selectedRows={selectedRows}
        handleFakeLogin={handleFakeLogin}
        statuses={divisionStatuses}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  actionArea: {
    backgroundColor: "#CBD5E0",
    padding: ".75rem",
    display: "flow-root",
  },
  labelArea: {
    float: "right",
  },
  actionButtons: {
    display: "flex",
    textAlign: "left",
    float: "left",
  },
  actionButton: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    marginRight: "1.5rem",
  },
  disableActionButton: {
    display: "flex",
    cursor: "default",
    alignItems: "center",
    marginRight: "1.5rem",
    color: "grey",
  },
  icon: {
    fontSize: "1rem",
    marginRight: ".5rem",
    color: "#4A5568",
  },
  titleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& button": {
      minWidth: "8rem",
      marginRight: ".5rem",
      marginBottom: "2rem",
      borderRadius: "0rem",
      fontSize: theme.typography.h6.fontSize,
    },
  },
  projectTypeButtonSelected: {
    borderBottom: `0.2rem solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  projectTypeButtonNotSelected: {
    borderBottom: `0.2rem solid #BEE3F8`,
    color: "#BEE3F8",
  },
  sup: {
    verticalAlign: "super",
    fontSize: ".5rem",
  },
}));

export default AccountsDashboard;
