// !! import this module in the corresponding reducer !!

export const RESOURCE_SET_DIVISION_STATUSES = "RESOURCE_SET_DIVISION_STATUSES";
export const RESOURCE_SET_INDUSTRIES = "RESOURCE_SET_INDUSTRIES";
export const RESOURCE_SET_JOB_TYPES = "RESOURCE_SET_JOB_TYPES";
export const RESOURCE_SET_CONTACT_TYPES = "RESOURCE_SET__CONTACT_TYPES";
export const RESOURCE_SET_CONTRACT_TYPES = "RESOURCE_SET_CONTRACT_TYPES";
export const RESOURCE_SET_WORK_TYPES = "RESOURCE_SET_WORK_TYPES";
export const RESOURCE_SET_PROJECT_STATUS_TYPES =
  "RESOURCE_SET_PROJECT_STATUS_TYPES";
export const RESOURCE_SET_MARKET_SUBCATEGORIES =
  "RESOURCE_SET_MARKET_SUBCATEGORIES";
export const RESOURCE_SET_MARKET_CATEGORIES = "RESOURCE_SET_MARKET_CATEGORIES";
export const RESOURCE_SET_MARKET_SEGMENTS = "RESOURCE_SET_MARKET_SEGMENTS";
export const RESOURCE_SET_CONTRIES = "RESOURCE_SET_CONTRIES";
export const RESOURCE_SET_COUNTIES = "RESOURCE_SET_COUNTIES";
export const RESOURCE_SET_STATES = "RESOURCE_SET_STATES";

export const setDivisionStatuses = (payload) => ({
  type: RESOURCE_SET_DIVISION_STATUSES,
  payload,
});

export const setIndustries = (payload) => ({
  type: RESOURCE_SET_INDUSTRIES,
  payload,
});

export const setJobTypes = (payload) => ({
  type: RESOURCE_SET_JOB_TYPES,
  payload,
});

export const setContactTypes = (payload) => ({
  type: RESOURCE_SET_CONTACT_TYPES,
  payload,
});

export const setContractTypes = (payload) => ({
  type: RESOURCE_SET_CONTRACT_TYPES,
  payload,
});

export const setWorkTypes = (payload) => ({
  type: RESOURCE_SET_WORK_TYPES,
  payload,
});

export const setProjectStatusTypes = (payload) => ({
  type: RESOURCE_SET_PROJECT_STATUS_TYPES,
  payload,
});

export const setMarketSubcategories = (payload) => ({
  type: RESOURCE_SET_MARKET_SUBCATEGORIES,
  payload,
});

export const setMarketCategories = (payload) => ({
  type: RESOURCE_SET_MARKET_CATEGORIES,
  payload,
});

export const setMarketSegments = (payload) => ({
  type: RESOURCE_SET_MARKET_SEGMENTS,
  payload,
});

export const setContries = (payload) => ({
  type: RESOURCE_SET_CONTRIES,
  payload,
});

export const setCounties = (payload) => ({
  type: RESOURCE_SET_COUNTIES,
  payload,
});

export const setStates = (payload) => ({
  type: RESOURCE_SET_STATES,
  payload,
});
