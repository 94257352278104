import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Divider, Box, Paper } from "@material-ui/core";
import PieChartIcon from "@material-ui/icons/PieChart";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import classNames from "classnames";
import Filters from "../Filters";
import NoteContainer from "../NoteContainer";
import MarketReportsTable from "./marketReport/MarketReportsTable";
import { getMarketReports } from "../../../actions/reportsActions";
import ChartWithPeriodFilter from "../ChartWithPeriodFilter";
import { getChartObject } from "../ReportUtil";
import * as store from "../../../state";

const MarketReport = () => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const marketReport = useSelector(store.selectors.report.selectMarketReport);
  const requested = marketReport.requestedProjects
    ? marketReport.requestedProjects
    : 0;
  const companyId = loggedInUser.userCompany?.id;
  const marketReports = marketReport.segments;
  const { missingInformationProjects } = marketReport;

  const [chartSettings, setChartSettings] = useState("monthly");
  const [reportData, setReportData] = useState(marketReport);
  const [chartSelectionFilter, setChartSelectionFilter] = useState({
    marketSegmentId: null,
    marketCategoryId: null,
  });
  const [chartLevelSettings, setChartLevelSettings] = useState(0);
  const [chartFilterVisible, setChartFilterVisible] = useState(false);
  const [reportFilterOutputLabel, setReportFilterOutputLabel] = useState(
    "Contract/Bid Value ($)"
  );
  const [chartLinkList, setChartLinkList] = useState(["All Markets"]);
  const [filters, setFilters] = useState({
    companyId,
    subsidiariesIds: [],
    divisionsIds: [],
    startDate: null,
    endDate: null,
    outputValue: "contractBidValue",
    marketSegmentIds: [],
    marketCategoryIds: [],
    period: "monthy",
  });
  const [query, setQuery] = useState("");

  const runReport = async (options, period) => {
    const newFilters = { ...options, period };
    setChartSettings(period);
    setFilters(newFilters);
    getMarketReports(newFilters).then((newReportData) => {
      dispatchRedux(store.actions.report.setMarketReport(newReportData));
      setChartLevelSettings(0);
      setChartSelectionFilter({
        marketSegmentId: null,
        marketCategoryId: null,
      });
      setReportData(newReportData);
      setFilterOutputLabel(options);
    });
  };

  const expandTable = async () => {
    const newFilters = { ...filters };
    newFilters.marketSegmentIds = chartSelectionFilter.marketSegmentId
      ? [{ id: chartSelectionFilter.marketSegmentId }]
      : filters.marketSegmentIds;
    newFilters.marketCategoryIds = chartSelectionFilter.marketCategoryId
      ? [{ id: chartSelectionFilter.marketCategoryId }]
      : filters.marketCategoryIds;
    newFilters.period = chartSettings;
    getMarketReports(newFilters).then((newReportData) => {
      dispatchRedux(
        store.actions.report.setMarketExpendableTableReport(newReportData)
      );
      setReportData(newReportData);
    });
  };

  const setFilterOutputLabel = (options) => {
    if (!options || !options.outputValue) {
      return;
    }

    switch (options.outputValue) {
      case "contractBidValue":
        setReportFilterOutputLabel("Contract/Bid Value ($)");
        break;
      case "totalCost":
        setReportFilterOutputLabel("Total Cost ($)");
        break;
      case "laborHours":
        setReportFilterOutputLabel("Labor Hours");
        break;
      default:
        setReportFilterOutputLabel("N/A");
        break;
    }
  };

  useEffect(() => {
    getMarketReports(filters).then((newReportData) => {
      dispatchRedux(store.actions.report.setMarketReport(newReportData));
    });
    setReportFilterOutputLabel("Contract/Bid Value ($)");
  }, []);

  useEffect(() => {
    if (!Array.isArray(marketReports)) {
      return;
    }
    expandTable();
  }, [chartSelectionFilter, chartSettings]);

  useEffect(() => {
    let chartData = [];
    let chartCategory = "period";
    let chartTooltipText = "";
    setChartFilterVisible(true);
    switch (chartLevelSettings) {
      case 0: {
        chartData = reportData.segments;
        chartCategory = "name";
        chartTooltipText = "Segment";
        setChartFilterVisible(false);
        setChartSettings("monthly");
        setChartLinkList(chartLinkList.slice(0, 1));
        break;
      }
      case 1: {
        chartData = Array.isArray(reportData.segments[0].categories)
          ? reportData.segments[0].categories
          : [];
        chartCategory = "name";
        chartTooltipText = "Category";
        setChartFilterVisible(false);
        setChartSettings("monthly");
        const auxchartLinkList = chartLinkList.slice(0, 2);

        if (chartSelectionFilter && chartSelectionFilter.marketName) {
          if (chartLinkList.length < 2) {
            setChartLinkList([
              ...chartLinkList,
              chartSelectionFilter.marketName,
            ]);
          } else {
            setChartLinkList(
              auxchartLinkList.map((element, index) =>
                index !== 1 ? element : chartSelectionFilter.marketName
              )
            );
          }
        } else {
          setChartLinkList(auxchartLinkList);
        }

        break;
      }
      case 2: {
        chartData = Array.isArray(reportData.segments[0].categories[0].periods)
          ? reportData.segments[0].categories[0].periods
          : [];
        chartCategory = "period";
        chartTooltipText = "Period";
        setChartFilterVisible(true);

        if (chartSelectionFilter && chartSelectionFilter.categoryName) {
          if (chartLinkList.length < 3) {
            setChartLinkList([
              ...chartLinkList,
              chartSelectionFilter.categoryName,
            ]);
          } else {
            setChartLinkList(
              chartLinkList.map((element, index) =>
                index !== 2 ? element : chartSelectionFilter.categoryName
              )
            );
          }
        }
        break;
      }
      default:
        return;
    }
    const lowerCaseQuery = query.toLowerCase();
    chartData = query
      ? chartData.filter((x) => {
          if (chartLevelSettings > 1)
            return x.period.toLowerCase().includes(lowerCaseQuery);
          return x.name.toLowerCase().includes(lowerCaseQuery);
        })
      : chartData;

    const chart = getChartObject({
      chartTooltipText,
      chartData,
      chartLevelSettings,
      chartCategory,
      reportFilterOutputLabel,
      handleChartClickFilterUpdate,
    });

    am4core.createFromConfig(chart, "chart-container", am4charts.XYChart);
  }, [
    chartLevelSettings,
    chartSelectionFilter,
    marketReports,
    reportData,
    reportFilterOutputLabel,
    query,
  ]);

  const handleChartClickFilterUpdate = (filterValue, chartLevel) => {
    if (chartLevel === 0) {
      setChartSelectionFilter({
        ...chartSelectionFilter,
        marketSegmentId: filterValue.id,
        marketName: filterValue.name,
      });
      setChartLevelSettings(chartLevel + 1);
      setChartLinkList([...chartLinkList, filterValue.name]);
    }

    if (chartLevel === 1) {
      setChartSelectionFilter({
        ...chartSelectionFilter,
        marketCategoryId: filterValue.id,
        categoryName: filterValue.name,
      });
      setChartLevelSettings(chartLevel + 1);
      setChartLinkList([...chartLinkList, filterValue.name]);
    }
  };

  const handleActionLink = (eventType) => {
    if (eventType !== null && eventType !== chartLevelSettings) {
      setChartLevelSettings(eventType);

      if (eventType === 1) {
        setChartSelectionFilter({
          ...chartSelectionFilter,
          marketCategoryId: null,
        });
      }

      if (eventType === 0) {
        setChartSelectionFilter({
          ...chartSelectionFilter,
          marketCategoryId: null,
          marketSegmentId: null,
        });
      }

      setChartLinkList(chartLinkList.slice(0, eventType + 1));
    }
  };

  return (
    <>
      <div>
        <Box textAlign="left" className={classes.backwardLink}>
          <ArrowBackIosIcon />
          Go back to all reports
        </Box>
        <Link to="/reports/allReports" className={classes.link}>
          <div />
        </Link>
      </div>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <PieChartIcon />
        </Paper>
        DCI
        <sup className={classes.sup}>TM&nbsp;</sup>
        Pipeline & Backlog Report: Market Segment
      </Box>
      <Divider className={classes.divider} />

      <Filters runReport={runReport} isMarketReport />
      <Divider className={classes.divider} />
      <Paper elevation={3} className={classes.paper}>
        <ChartWithPeriodFilter
          actionButtonsVisible={false}
          filterButtonsVisible={chartFilterVisible}
          actionLinkList={chartLinkList}
          onActionLinkEvent={handleActionLink}
          onFilterChange={setChartSettings}
          activeFilter={chartSettings}
        >
          <div
            id="chart-container"
            className={classNames(classes.chartContainer, {
              chartTopPadding: !chartFilterVisible && chartLevelSettings === 0,
            })}
          />
        </ChartWithPeriodFilter>
      </Paper>
      <NoteContainer
        data={missingInformationProjects}
        requested={requested}
        isMarketReports
        filters={filters}
      />
      <MarketReportsTable
        data={marketReports}
        filters={filters}
        expandTable={expandTable}
        setChartSelectionFilter={setChartSelectionFilter}
        setChartLevelSettings={setChartLevelSettings}
        chartSelectionFilter={chartSelectionFilter}
        chartLevelSettings={chartLevelSettings}
        query={query}
        setQuery={setQuery}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  backwardLink: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontSize: theme.typography.h5.fontSize,
  },
  link: {
    position: "relative",
    bottom: "2rem",
    "& div": {
      height: "2rem",
      width: "13rem",
    },
  },
  divider: {
    margin: "1rem 0",
  },
  paper: {
    height: "32rem",
    margin: "5rem 0",
    padding: "1rem",
  },
  pageTitle: {
    fontSize: theme.typography.h3.fontSize,
    fontWeight: "bold",
    display: "flex",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: theme.typography.h3.fontSize,
      },
    },
  },
  chartContainer: {
    height: "100%",

    "&.chartTopPadding": {
      marginTop: "4%",
    },
  },
  sup: {
    verticalAlign: "super",
    fontSize: "1.25rem",
  },
}));

export default MarketReport;
