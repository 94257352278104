import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  TextField,
  Box,
  Paper,
  FormLabel,
  DialogActions,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import { getContactType } from "../../../utils/helperFunctions";
import {
  getPersonsDataToStore,
  addPersonData,
} from "../../../actions/companyActions";
import PersonAutocompleteAdd from "./PersonAutocompleteAdd";
import { useDispatch, useSelector } from "react-redux";
import * as store from "../../../state";

const PersonOfContactForm = (props) => {
  const { person, customer, editContact, onCancel, isCustomer } = props;
  const currentProject = useSelector(store.selectors.project.selectProject);
  const persons = useSelector(store.selectors.company.selectPersons);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  // const companyContext = useContext(CompanyContext);
  const dispatchRedux = useDispatch();
  const { projectId } = currentProject;

  useEffect(() => {
    //getPersons(companyContext.dispatch, customer.id, projectId);
    getPersonsDataToStore(
      { customerId: customer.id, projectId },
      { dispatchRedux }
    );
  }, []);

  const formik = useFormik({
    initialValues: {
      customerId: customer.id,
      customer,
      isCustomer,
      person,
      personId: person ? person.id : null,
    },
    onSubmit: (values) => {
      editContact(values);
      onCancel();
    },
  });

  const onSubmitPerson = (value) => {
    //addPerson(value, companyContext.dispatch, handlePersonAdd);
    Promise.all([
      addPersonData(value, ({ data }) => {
        const newPerson = { id: data.id, ...value };
        handlePersonAdd(newPerson);
      }),
    ]);
  };

  const handlePersonAdd = (person) => {
    formik.setFieldValue("person", person);
    formik.setFieldValue("personId", person.id);
  };

  return (
    <>
      <div>
        <Paper elevation={3} className={classes.customer}>
          <div>
            <FormLabel component="legend">
              Use the form below to add a new person of contact
            </FormLabel>
            <Box className={classes.label}>Company Name</Box>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              id="companyName"
              name="companyName"
              disabled
              value={customer.name}
            />
            <Box className={classes.label}>Type</Box>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              id="contactType"
              name="contactType"
              disabled
              value={getContactType(customer.contactTypeId)}
            />
          </div>
          <form style={{ minWidth: "30rem" }}>
            <Box className={classes.label}>Person</Box>
            <Autocomplete
              id="person"
              value={formik.values.person}
              options={persons}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              onChange={(event, value) => formik.setFieldValue("person", value)}
              noOptionsText={
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onMouseDown={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setOpen(true);
                    }}
                  >
                    Add new Person
                  </Button>
                </>
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search..."
                />
              )}
            />
            <DialogActions>
              <Button onClick={onCancel} variant="outlined">
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={formik.handleSubmit}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
          <PersonAutocompleteAdd
            customerId={customer.id}
            open={open}
            handleClose={() => setOpen(false)}
            title="Add a new Person of Contact"
            onSubmit={onSubmitPerson}
          />
        </Paper>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.7rem 0 0 0",
  },
  topLabel: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: "bold",
    margin: "0.7rem 0",
  },
  customer: {
    padding: "1rem",
    minHeight: "12rem",
    margin: "0.5rem 0",
  },
  addButtonContainer: {
    marginTop: "0.5rem",
    display: "flex",
    flexDirection: "row-reverse",
  },
  addButton: {
    backgroundColor: theme.palette.info.dark,
  },
}));

export default PersonOfContactForm;
