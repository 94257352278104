import React from "react";
import { Box, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  formatDateUS,
  getIndustryTitle,
  getStatusLabel,
} from "../../utils/helperFunctions";
import DashboardTable from "../elements/DashboardTable";

const DivisionTable = (props) => {
  const {
    rows,
    setSelectedRows,
    selectedRows,
    roleId,
    editDivisionUrl,
    statuses,
    industries,
  } = props;
  const classes = useStyles();
  const columns = [
    {
      field: "divisionId",
      headerName: "ID",
      width: 120,
    },
    {
      field: "name",
      headerName: "Division Name",
      width: 200,
      renderCell: (params) => (
        <Link to={`${editDivisionUrl}${params.row.id}`}>{params.value}</Link>
      ),
    },
    {
      field: "subsidiary",
      headerName: "Subsidiary",
      width: 300,
    },
    {
      field: "statusId",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Box
          fontSize="subtitle2.fontSize"
          className={clsx(
            classes[getStatusLabel(params.value, statuses)],
            classes.chip
          )}
        >
          {getStatusLabel(params.value, statuses)}
        </Box>
      ),
      filterOperators: divisionsOperator("status", null, statuses),
    },
    {
      field: "industryClassificationId",
      headerName: "Industry classification (NAICS) ",
      width: 450,
      valueFormatter: (params) =>
        getIndustryTitle(params.value, industries, true),
      sortComparator: (v1, v2) =>
        getIndustryTitle(v1, industries, true).localeCompare(
          getIndustryTitle(v2, industries, true)
        ),
      filterOperators: divisionsOperator(
        "industryClassification",
        null,
        industries
      ),
    },
    {
      field: "createdAt",
      headerName: "Created On",
      width: 130,
      type: "date",
      valueFormatter: (params) =>
        params.value
          ? formatDateUS(params.value) === "1/1/1"
            ? "-"
            : formatDateUS(params.value)
          : "-",
      filterOperators: dateOperator,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 200,
      valueFormatter: (params) =>
        params.value
          ? params.value.roleId !== 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : roleId === 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : "System"
          : "-",
      sortComparator: (v1, v2) =>
        `${v1.firstName}${v1.lastName}`.localeCompare(
          `${v2.firstName}${v2.lastName}`
        ),
      filterOperators: divisionsOperator("createdBy", roleId),
    },
  ];

  return (
    <>
      <DashboardTable
        rows={rows}
        columns={columns}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

const divisionsOperator = (type, roleId, neededArray) => [
  {
    label: "Contains",
    value: "contains",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "status"
          ? getStatusLabel(params.value, neededArray)
          : type === "industryClassification"
          ? getIndustryTitle(params.value, neededArray, true)
          : params.value
          ? params.value.roleId !== 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : roleId === 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : "System"
          : "-";
        return rowValue.toUpperCase().includes(filterItem.value.toUpperCase());
      };
    },
    InputComponent: DivisionInputValue,
    InputComponentProps: { type: "string" },
  },
  {
    label: "Equals",
    value: "equals",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "status"
          ? getStatusLabel(params.value, neededArray)
          : type === "industryClassification"
          ? getIndustryTitle(params.value, neededArray, true)
          : params.value
          ? params.value.roleId !== 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : roleId === 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : "System"
          : "-";
        return rowValue.toUpperCase() === filterItem.value.toUpperCase();
      };
    },
    InputComponent: DivisionInputValue,
    InputComponentProps: { type: "string" },
  },
  {
    label: "Starts with",
    value: "startsWith",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "status"
          ? getStatusLabel(params.value, neededArray)
          : type === "industryClassification"
          ? getIndustryTitle(params.value, neededArray, true)
          : params.value
          ? params.value.roleId !== 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : roleId === 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : "System"
          : "-";
        return rowValue
          .toUpperCase()
          .startsWith(filterItem.value.toUpperCase());
      };
    },
    InputComponent: DivisionInputValue,
    InputComponentProps: { type: "string" },
  },
  {
    label: "Ends with",
    value: "endsWith",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "status"
          ? getStatusLabel(params.value, neededArray)
          : type === "industryClassification"
          ? getIndustryTitle(params.value, neededArray, true)
          : params.value
          ? params.value.roleId !== 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : roleId === 1
            ? `${params.value.firstName} ${params.value.lastName}`
            : "System"
          : "-";
        return rowValue.toUpperCase().endsWith(filterItem.value.toUpperCase());
      };
    },
    InputComponent: DivisionInputValue,
    InputComponentProps: { type: "string" },
  },
];

const dateOperator = [
  {
    label: "Is",
    value: "is",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) === filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
  {
    label: "Is After",
    value: "isAfter",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) > filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
  {
    label: "Is Before",
    value: "isBefore",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) < filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
];

function DivisionInputValue(props) {
  const classes = useStyles();
  const { item, applyValue } = props;

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <div>
      <TextField
        className={classes.dateInput}
        name="custom-rating-filter-operator"
        placeholder="Filter value"
        value={item.value}
        onChange={handleFilterChange}
      />
    </div>
  );
}

function DateInputValue(props) {
  const classes = useStyles();
  const { item, applyValue } = props;

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <div>
      <TextField
        className={classes.dateInput}
        name="custom-rating-filter-operator"
        placeholder="Filter value"
        value={item.value}
        onChange={handleFilterChange}
        type="date"
      />
    </div>
  );
}

const useStyles = makeStyles(() => ({
  dateInput: {
    height: "48px",
    "& div": {
      height: "100%",
    },
  },
  chip: {
    fontWeight: "bold",
    width: "8rem",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
  INACTIVE: {
    color: "#757575",
  },
  ACTIVE: {
    color: "#7FB49B",
  },
  tablecellTrucate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default DivisionTable;
