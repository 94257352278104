import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, Typography } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import LanguageIcon from "@material-ui/icons/Language";
import MCA from "../../resources/MCA.png";

import { openInNewTab } from "../../utils/helperFunctions";

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Icon classes={{ root: classes.iconRoot }}>
        <img className={classes.imageIcon} src={MCA} alt="Company Icon" />
      </Icon>
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()}
        .MCA, Inc., 363 E. Grand Blanc Rd., Grand Blanc, Michigan 48439
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        className={classes.text}
      >
        <LanguageIcon />
        <a
          href={"http://www.mca.net/"}
          color="inherit"
          target="popup"
          to=""
          onClick={() => openInNewTab("http://www.mca.net/")}
        >
          www.mca.net
        </a>
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        className={classes.text}
      >
        <MailOutlineIcon />
        info@mca.net
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        className={classes.text}
      >
        <PhoneIcon />
        +1 (810) 232 9797
      </Typography>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "0 3rem",
  },
  text: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& svg": {
      marginRight: "1rem",
    },
  },
  imageIcon: {
    height: "inherit",
    width: "inherit",
  },
  iconRoot: {
    height: "4rem",
    width: "3rem",
    textAlign: "center",
  },
}));

export default Footer;
