import React, { useState, useEffect } from "react";
import * as store from "../../../../state";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Box, Paper, Button, FormLabel } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PersonAutocompleteAdd from "./PersonAutocompleteAdd";
import CustomerAutocompleteAdd from "./CustomerAutocompleteAdd";
import {
  getOtherPersons,
  getOtherCustomers,
  validateCardAdd,
  checkPermission,
} from "../../../../utils/helperFunctions";
import {
  getPersonsDataToStore,
  addCustomerData,
  addPersonData,
  getCustomersDataToStore,
} from "../../../../actions/companyActions";

const AddContactCard = (props) => {
  const { projectId, formik } = props;
  const dispatchRedux = useDispatch();
  const classes = useStyles();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const persons = useSelector(store.selectors.company.selectPersons);
  const customers = useSelector(store.selectors.company.selectCustomers);

  const companyId = loggedInUser.userCompany?.id;
  const { roleId } = loggedInUser;

  const initialValues = {
    otherContact: null,
    otherContactPerson: null,
  };

  const [openCustomer, setOpenCustomer] = useState(false);
  const [open, setOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");

  const contactsFormik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const newCard = {
        customer: values.otherContact,
        isCustomer: false,
        customerId: values.otherContact.id,
        person: values.otherContactPerson || null,
        personId: values.otherContactPerson
          ? values.otherContactPerson.id
          : null,
        projectId: parseInt(formik.values.id),
      };
      formik.setFieldValue("contacts", [...formik.values.contacts, newCard]);
      resetForm();
    },
  });

  const handleContactAdd = (company) => {
    contactsFormik.setFieldValue("otherContact", company);
  };

  const handlePersonAdd = (person) => {
    contactsFormik.setFieldValue("otherContactPerson", person);
  };

  const onSubmitContact = (value) => {
    Promise.all([
      addCustomerData(value, ({ data }) => {
        const newContact = { id: data.id, ...value };
        handleContactAdd(newContact);
        dispatchRedux(store.actions.company.addCustomer(newContact));
      }),
    ]);
  };

  const onSubmitPerson = (value) => {
    const personToAdd = { ...value, customerId: value.customerId.id };
    addPersonData(personToAdd, ({ data }) => {
      const newPerson = { id: data.id, ...personToAdd };
      dispatchRedux(store.actions.company.addPersons(newPerson));
      handlePersonAdd(newPerson);
    });
  };

  useEffect(async () => {
    if (contactsFormik.values.otherContact) {
      await getPersonsDataToStore(
        {
          customerId: contactsFormik.values.otherContact.id,
          projectId,
        },
        { dispatchRedux }
      );
    }
  }, [contactsFormik.values.otherContact]);

  useEffect(async () => {
    await getCustomersDataToStore(companyId, { dispatchRedux });
  }, []);

  return (
    <>
      <Paper elevation={3} className={classes.customer}>
        <div>
          <FormLabel component="legend">
            Use the form below to add a new contact
          </FormLabel>
        </div>
        <form onSubmit={contactsFormik.handleSubmit}>
          <>
            <Box className={classes.label}>Company</Box>
            <Autocomplete
              id="otherContact"
              disabled={checkPermission([5], roleId)}
              value={contactsFormik.values.otherContact}
              options={getOtherCustomers(customers, formik.values.contacts)}
              getOptionLabel={(option) => `${option.name}`}
              onChange={(event, value) => {
                if (value) {
                  contactsFormik.setFieldValue("otherContact", value);
                } else {
                  contactsFormik.setFieldValue("otherContact", null);
                  contactsFormik.setFieldValue("otherContactPerson", null);
                }
              }}
              onInputChange={(event) => {
                if (event) {
                  setCustomerName(event.target.value);
                }
              }}
              noOptionsText={
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onMouseDown={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setOpenCustomer(true);
                    }}
                  >
                    Add new Customer
                  </Button>
                </>
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search..."
                />
              )}
            />

            <Box className={classes.label}>Person</Box>
            <Autocomplete
              id="otherContactPerson"
              disabled={
                !contactsFormik.values.otherContact ||
                checkPermission([5], roleId)
              }
              value={contactsFormik.values.otherContactPerson}
              options={getOtherPersons(persons, formik.values.contacts)}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              onChange={(event, value) =>
                contactsFormik.setFieldValue("otherContactPerson", value)
              }
              noOptionsText={
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onMouseDown={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setOpen(true);
                    }}
                  >
                    Add new Person
                  </Button>
                </>
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Search..."
                />
              )}
            />
          </>
          <div className={classes.addButtonContainer}>
            <Button
              disabled={
                contactsFormik.values.otherContact
                  ? validateCardAdd(
                      formik.values.contacts,
                      contactsFormik.values.otherContact,
                      contactsFormik.values.otherContactPerson
                    ) || checkPermission([5], roleId)
                  : true
              }
              variant="contained"
              size="medium"
              className={classes.addButton}
              type="submit"
              onClick={contactsFormik.handleSubmit}
            >
              <Box>Add</Box>
            </Button>
          </div>
        </form>
        <CustomerAutocompleteAdd
          name={customerName}
          companyId={companyId}
          open={openCustomer}
          handleClose={() => setOpenCustomer(false)}
          title="Add another Contact"
          onSubmit={onSubmitContact}
        />
        <PersonAutocompleteAdd
          customerId={contactsFormik.values.otherContact}
          open={open}
          handleClose={() => setOpen(false)}
          title="Add a new Person of Contact"
          onSubmit={onSubmitPerson}
        />
      </Paper>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.7rem 0",
  },
  topLabel: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: "bold",
    margin: "0.7rem 0",
  },
  customer: {
    padding: "1rem",
    minHeight: "12rem",
    margin: "0.5rem 0",
  },
  addButtonContainer: {
    marginTop: "0.5rem",
    display: "flex",
    flexDirection: "row-reverse",
  },
  addButton: {
    backgroundColor: theme.palette.info.dark,
    margin: "0rem",
  },
}));

export default AddContactCard;
