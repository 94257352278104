import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import * as store from "../../../../state";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, Button, TextField } from "@material-ui/core";
import CustomTextField from "../../../elements/CustomTextField";
import {
  checkPermission,
  formatDatePicker,
  getObjectById,
  getOtherProjects,
} from "../../../../utils/helperFunctions";
import { getSubsidiariesDataToStore } from "../../../../actions/subsidiaryActions";
import {
  getCustomersDataToStore,
  addCustomerData,
} from "../../../../actions/companyActions";
import { getDivisionBySubsidiariesData } from "../../../../actions/divisionActions";
import { getProjects } from "../../../../actions/projectActions";
import SelectList from "../../../elements/SelectList";
import ContactCard from "../../addProject/ContactCard";
import CustomerAutocompleteAdd from "../../addProject/CustomerAutocompleteAdd";

const Details = (props) => {
  const { companyId, formik, projectId } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const subsidiaries = useSelector(
    store.selectors.subsidiary.selectSubsidiaries
  );
  const customers = useSelector(store.selectors.company.selectCustomers);
  const projects = useSelector(store.selectors.project.selectProjects);
  const { roleId } = loggedInUser;

  const [openCustomer, setOpenCustomer] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [availableDivisions, setAvailableDivisions] = useState([]);
  const [activeDivisions, setActiveDivisions] = useState([]);
  const [activeSubsidiaries, setActiveSubsidiaries] = useState([]);
  const [projectWithName, setProjectsWithName] = useState([]);

  const onSubmitCustomer = (customer) => {
    addCustomerData(customer, ({ data }) => {
      const newCustomer = { id: data.id, ...customer };
      handleCustomerAdd(newCustomer);
      dispatchRedux(store.actions.company.addCustomer(newCustomer));
    });
  };

  const onRemoveCustomer = () => {
    formik.setFieldValue("customer", null);
  };

  const handleCustomerAdd = (customer) => {
    changeCustomer(customer);
  };

  const changeCustomer = (customer) => {
    formik.setFieldValue("customer", customer);
  };

  useEffect(() => {
    setProjectsWithName(
      projects.map((project) => ({
        ...project,
        name: project.proposalNumber,
      }))
    );
  }, [projects]);

  useEffect(async () => {
    await Promise.all([
      getCustomersDataToStore(companyId, { dispatchRedux }),
      getSubsidiariesDataToStore(companyId, { dispatchRedux }),
      getProjects(companyId).then((projects) => {
        dispatchRedux(store.actions.project.setProjects(projects));
      }),
    ]);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const { data: newDivisions } = await getDivisionBySubsidiariesData([
        { id: formik.values.subsidiaryId },
      ]);
      if (Array.isArray(newDivisions) && newDivisions.length > 0) {
        setAvailableDivisions(newDivisions[0].divisions || []);
      }
    }

    if (formik.values.subsidiaryId) {
      fetchData();
    } else {
      setAvailableDivisions([]);
    }
  }, [formik.values.subsidiaryId]);

  useEffect(() => {
    setActiveSubsidiaries(
      subsidiaries.filter((subsidiary) => subsidiary.statusId === 1)
    );
  }, [subsidiaries]);

  useEffect(() => {
    if (
      availableDivisions.length > 0 &&
      formik.values.divisionId &&
      availableDivisions.filter(
        (division) => division.id === formik.values.divisionId
      ).length === 0
    ) {
      formik.setFieldValue("divisionId", "");
    }
    if (formik.values.divisionId) {
      setActiveDivisions(
        availableDivisions.filter(
          (division) =>
            division.statusId === 1 || division.id === formik.values.divisionId
        )
      );
    } else {
      availableDivisions.filter((division) => division.statusId === 1);
    }
  }, [availableDivisions]);

  return (
    <div className={classes.container}>
      <h4 className={classes.pageTitle}>Details</h4>
      <Grid container className={classes.content}>
        <Grid item xs className={classes.item}>
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="name"
            label="Project Name*"
            formik={formik}
            className={classes.customField}
          />
          {formik.values.customer ? (
            <ContactCard
              contact={{
                customer: formik.values.customer,
                isCustomer: true,
                person: formik.values.customer,
              }}
              tab="generalInfo"
              onRemove={onRemoveCustomer}
            />
          ) : (
            <>
              <Autocomplete
                disabled={checkPermission([5], roleId)}
                style={{ marginTop: "0.5rem" }}
                id="customer"
                value={formik.values.customer}
                options={customers}
                getOptionLabel={(option) => `${option.name}`}
                onChange={(event, value) => {
                  changeCustomer(value);
                }}
                onInputChange={(event) => {
                  setCustomerName(event.target.value);
                }}
                noOptionsText={
                  <>
                    <Button
                      color="primary"
                      variant="contained"
                      onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setOpenCustomer(true);
                      }}
                    >
                      Add new Customer
                    </Button>
                  </>
                }
                renderInput={(params) => (
                  <TextField
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.customer && Boolean(formik.errors.customer)
                    }
                    helperText={
                      formik.touched.customer && formik.errors.customer
                    }
                    {...params}
                    label={" Customer* (Bid Proposal Requestor)"}
                    className={classes.autoCompleteInput}
                  />
                )}
              />
              <CustomerAutocompleteAdd
                name={customerName}
                companyId={companyId}
                open={openCustomer}
                handleClose={() => setOpenCustomer(false)}
                title="Add a new Customer"
                onSubmit={onSubmitCustomer}
              />
            </>
          )}
          <SelectList
            text="Has Child Projects?"
            name="childProjects"
            disabled={checkPermission([5], roleId)}
            options={getOtherProjects(projectWithName, projectId)}
            value={formik.values.childProjects}
            handleChange={(value) =>
              formik.setFieldValue("childProjects", value)
            }
            isMultiSelect={true}
            formik={formik}
            className={classes.customField}
          />
        </Grid>
        <Grid item xs className={classes.item}>
          <Autocomplete
            style={{ marginTop: "0.5rem" }}
            id="subsidiaryId"
            name="subsidiaryId"
            formik={formik}
            value={
              formik.values.subsidiaryId
                ? getObjectById(subsidiaries, formik.values.subsidiaryId)
                : null
            }
            options={activeSubsidiaries}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(event, value) =>
              value
                ? formik.setFieldValue("subsidiaryId", value.id)
                : formik.setFieldValue("subsidiaryId", "")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                disabled={checkPermission([5], roleId)}
                label={"Subsidiary*"}
                className={classes.autoCompleteInput}
                error={
                  formik
                    ? formik.touched["subsidiaryId"] &&
                      Boolean(formik.errors["subsidiaryId"])
                    : null
                }
                helperText={
                  formik
                    ? formik.touched["subsidiaryId"] &&
                      formik.errors["subsidiaryId"]
                    : null
                }
              />
            )}
          />
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="description"
            label="Description*"
            formik={formik}
            className={classes.textArea}
            multiline={true}
          />
        </Grid>
        <Grid item xs className={classes.lastItem}>
          <Autocomplete
            style={{ marginTop: "0.5rem" }}
            id="divisionId"
            name="divisionId"
            formik={formik}
            value={
              availableDivisions && formik.values.divisionId
                ? getObjectById(availableDivisions, formik.values.divisionId)
                : null
            }
            options={activeDivisions}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(event, value) =>
              value
                ? formik.setFieldValue("divisionId", value.id)
                : formik.setFieldValue("divisionId", "")
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                disabled={checkPermission([5], roleId)}
                label={"Division*"}
                className={classes.autoCompleteInput}
                error={
                  formik
                    ? formik.touched["divisionId"] &&
                      Boolean(formik.errors["divisionId"])
                    : null
                }
                helperText={
                  formik
                    ? formik.touched["divisionId"] &&
                      formik.errors["divisionId"]
                    : null
                }
              />
            )}
          />
          <Grid container>
            <Grid item xs>
              <TextField
                id="expectedStartDate"
                disabled={checkPermission([5], roleId)}
                label={
                  formik.values.projectStatusTypeId > 1
                    ? "Expected Start Date*"
                    : "Expected Start Date"
                }
                value={formatDatePicker(formik.values.expectedStartDate)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.expectedStartDate &&
                  Boolean(formik.errors.expectedStartDate)
                }
                helperText={
                  formik.touched.expectedStartDate &&
                  formik.errors.expectedStartDate
                }
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.customField}
              />
            </Grid>

            <Grid item xs>
              <TextField
                id="expectedEndDate"
                label={
                  formik.values.projectStatusTypeId > 1
                    ? "Expected End Date*"
                    : "Expected End Date"
                }
                disabled={checkPermission([5], roleId)}
                value={formatDatePicker(formik.values.expectedEndDate)}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.expectedEndDate &&
                  Boolean(formik.errors.expectedEndDate)
                }
                helperText={
                  formik.touched.expectedEndDate &&
                  formik.errors.expectedEndDate
                }
                type="date"
                className={classes.customField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "4rem",
  },
  content: {
    paddingLeft: 15,
  },
  item: {
    minWidth: "10rem",
    marginRight: "2rem",
  },
  lastItem: {
    minWidth: "10rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    borderRadius: 5,
  },
  customField: {
    minHeight: "3rem",
    margin: "0",
    marginTop: "0.35rem",
    width: "100%",
  },
  textArea: {
    minHeight: "3rem",
    margin: "0",
    marginTop: "0.35rem",
    width: "100%",
    resize: "both",
  },
}));

export default Details;
