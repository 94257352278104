import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, Box, Paper } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  editSubsidiaryData,
  getSubsidiariesData,
  getSubsidiaryData,
  updateSubsidiaryStatusDataToStore,
} from "../../actions/subsidiaryActions";
// import { ResourcesContext } from "../../contexts/ResourcesContext";
//mport { CompanyContext } from "../../contexts/CompanyContext";
import {
  getIndustriesDataToStore,
  getStatusesDataToStore,
} from "../../actions/resourcesAction";
import SubsidiaryForm from "./SubsidiaryForm";
import validationSchemaEdit from "./validationSchemas/validationSchemaEdit";
import DivisionsTable from "./DivisionsTable";
import NotificationPopUp from "../utils/NotificationPopUp";
import { getAvailableSubsidiariesData } from "../../actions/companyActions";
import * as store from "../../state";

const EditSubsidiary = (props) => {
  const dispatchRedux = useDispatch();
  const subsidiaries = useSelector(
    store.selectors.subsidiary.selectSubsidiaries
  );
  //const resourcesContext = useContext(ResourcesContext);
  const divisionStatuses = useSelector(
    store.selectors.resource.selectDivisionStatuses
  );
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  //const companyContext = useContext(CompanyContext);

  const companyId =
    props.view === "sysAdmin"
      ? parseInt(props.companyId)
      : loggedInUser.userCompany.id;
  const id =
    props.view === "sysAdmin"
      ? parseInt(props.subsidiaryId)
      : parseInt(props.id);
  const subsidiaryDashboardUrl =
    props.view === "sysAdmin"
      ? `/systemAdministration/subsidiaries/${props.companyId}`
      : "/subsidiaries";
  const availableSubsidiaries = useSelector(
    store.selectors.company.selectAvailableSubsidiaries
  );

  const classes = useStyles();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState({
    companyId,
    name: "",
    subsidiaryId: "",
    industryClassificationId: "",
  });
  const [subsidiaryStatus, setSubsidiaryStatus] = useState(1);

  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const handleDelete = () => {
    if (
      subsidiaries.filter((row) => row.statusId === 1).length === 1 &&
      initialValues.statusId === 1
    ) {
      setNotificationTitle("Delete Subsidiary");
      setNotificationText(
        "Deletion cannot be performed. At least one subsidiary must remain active."
      );
      setNotificationType("error");
      setNotificationActions([
        { name: "OK", action: () => handleCloseModal() },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      setNotificationTitle("Delete Subsidiary");
      setNotificationText(
        "The subsidiary and all its related divisions will be deleted. Are you sure you want to continue?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "DELETE",
          action: async () => {
            await deleteSubsidiary();
            handleClose();
          },
        },
        { name: "CANCEL", action: () => handleCloseModal() },
      ]);
      setIsNotificationPopUpOpen(true);
    }
  };

  const deleteSubsidiary = async () => {
    //await updateSubsidiaryStatus([id], "DELETED", 3, dispatch);
    await updateSubsidiaryStatusDataToStore(
      {
        subsidiaryIds: [id],
        status: "DELETED",
        statusId: 3,
      },
      { dispatchRedux }
    );
  };

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleClose = () => {
    handleCloseModal();
    history.push(subsidiaryDashboardUrl);
  };

  const handleSuccess = () => {
    handleClose();
  };

  const handleSuccessActivate = () => {
    setNotificationTitle("Subsidiary has been reactivated");
    setNotificationText(
      "The selected subsidiary has been successfully reactivated."
    );
    setNotificationType("success");
    setNotificationActions([{ name: "OK", action: () => handleClose() }]);
    setIsNotificationPopUpOpen(true);
  };

  const onSubmit = async (values) => {
    if (values.statusId !== initialValues.statusId) {
      if (values.statusId === 1) {
        if (availableSubsidiaries <= 0) {
          setNotificationTitle("Activate Subsidiary Limit");
          setNotificationText(
            "You have reached the maximum number of active subsidiaries. Please contact our support for help."
          );
          setNotificationType("warning");
          setNotificationActions([
            { name: "OK", action: () => handleCloseModal() },
          ]);
          setIsNotificationPopUpOpen(true);
        } else {
          //await editSubsidiary(values, dispatch, handleSuccessActivate);
          await editSubsidiaryData(values, handleSuccessActivate);
        }
      } else if (values.statusId === 2) {
        if (subsidiaries.filter((row) => row.statusId === 1).length === 1) {
          setNotificationTitle("Deactivate Subsidiary");
          setNotificationText(
            "Deactivation cannot be performed. At least one subsidiary must remain active."
          );
          setNotificationType("error");
          setNotificationActions([
            { name: "OK", action: () => handleCloseModal() },
          ]);
          setIsNotificationPopUpOpen(true);
        } else {
          setNotificationTitle("Deactivate Subsidiary");
          setNotificationText(
            "Deactivating a subsidiary will make it unavailable for projects. Do you want to continue?"
          );
          setNotificationType("warning");
          setNotificationActions([
            {
              name: "DEACTIVATE",
              action: async () => {
                //await editSubsidiary(values, dispatch, handleSuccess);
                await editSubsidiaryData(values, handleSuccess);
                handleClose();
              },
            },
            { name: "CANCEL", action: () => handleClose() },
          ]);
          setIsNotificationPopUpOpen(true);
        }
      }
    } else {
      //await editSubsidiary(values, dispatch, handleSuccess);
      await editSubsidiaryData(values, handleSuccess);
    }
  };

  const onCancel = () => {
    handleClose();
  };

  useEffect(async () => {
    async function fetchData() {
      //const newInitialValues = await getSubsidiary(id, dispatch);
      const { data: newInitialValues } = await getSubsidiaryData(id);
      newInitialValues.subsidiaryId
        ? setInitialValues(newInitialValues)
        : setInitialValues({ ...newInitialValues, subsidiaryId: "" });
      setSubsidiaryStatus(newInitialValues.statusId);
    }
    fetchData();
  }, []);

  useEffect(async () => {
    // getIndustries(resourcesContext.dispatch);
    // getStatuses(resourcesContext.dispatch);
    // getSubsidiaries(dispatch, companyId);
    await getIndustriesDataToStore({ dispatchRedux });
    await getStatusesDataToStore({ dispatchRedux });
    await getSubsidiariesData(companyId);
  }, []);

  useEffect(() => {
    getAvailableSubsidiariesData(companyId).then(({ data }) => {
      store.actions.company.setAvailableSubsidiaries(data);
    });
  }, [companyId]);
  //}, [companyContext.dispatch, companyId]);

  return (
    <>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        {props.view === "sysAdmin" ? "SYSTEM ADMINISTRATION" : "ADMINISTRATION"}
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.pageSubTitle}>
        {`Edit Subsidiary: ${initialValues.name}`}
      </Box>
      <SubsidiaryForm
        page="edit"
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        validationSchema={validationSchemaEdit}
        handleDelete={handleDelete}
        subsidiaryStatus={subsidiaryStatus}
      />
      <Box className={classes.label}>Related Division</Box>
      <div>
        <DivisionsTable
          rows={initialValues.divisions || []}
          view={props.view}
          statuses={divisionStatuses}
          companyId={companyId}
        />
      </div>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  additionalComponent: {
    marginLeft: "2rem",
    "& p": {
      margin: "0",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "1rem",
    },
  },
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.5rem 0",
    fontWeight: "bold",
  },
}));

export default EditSubsidiary;
