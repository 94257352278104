import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Box } from "@material-ui/core";

const FancyCard = (props) => {
  const { label, amount, text, money } = props;
  const classes = useStyles();
  return (
    <>
      <Card
        id={`${text.replace(" ", "")}Card`}
        className={clsx(classes.cardRoot, classes[`${props.label}BorderColor`])}
        elevation={2}
      >
        <CardContent className={classes[`${label}Color`]}>
          <Box className={classes.title}>{text}</Box>
          <Box className={classes.amount} fontWeight="bold">
            {money ? "$" : ""}
            {amount ? parseInt(amount).toLocaleString("en-US") : "0"}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    minWidth: "16.5rem",
    display: "flex",
    margin: "1.5rem",
    borderRadius: 10,
  },

  title: {
    fontSize: theme.typography.h5.fontSize,
  },
  amount: {
    fontSize: theme.typography.h3.fontSize,
  },
  totalColor: {
    color: "#4A5568",
  },
  backlogColor: {
    color: "#057A55",
  },
  pipelineColor: {
    color: "#1E429F",
  },
  totalBorderColor: {
    borderLeft: "1rem solid #4A5568",
  },
  backlogBorderColor: {
    borderLeft: "1rem solid #AFDA95",
  },
  pipelineBorderColor: {
    borderLeft: "1rem solid #7995E0",
  },
}));

export default FancyCard;
