import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const ProgressChart = (props) => {
  const classes = useStyles();
  const { id, completion } = props.row;

  useEffect(() => {
    const chart = am4core.create(`progressChart-${id}`, am4charts.PieChart);
    chart.data = [
      {
        progress: "done",
        value:
          completion && !isNaN(completion)
            ? Number.parseInt(completion.toFixed(0))
            : 0,
      },
      {
        progress: "noDone",
        value:
          completion && !isNaN(completion)
            ? 100 - Number.parseInt(completion.toFixed(0))
            : 100,
      },
    ];
    chart.innerRadius = am4core.percent(60);
    const label = chart.seriesContainer.createChild(am4core.Label);
    label.text = `${
      completion && !isNaN(completion)
        ? Number.parseInt(completion.toFixed(0))
        : "0"
    }%`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 13;
    label.fill = "#999999";

    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "progress";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.tooltipText = "";

    const colorSet = new am4core.ColorSet();
    colorSet.list = ["#057a55", "#F2F2F2"].map(
      (color) => new am4core.color(color)
    );
    pieSeries.colors = colorSet;

    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
  }, [completion]);

  return (
    <div>
      <Typography variant="subtitle2" color="textSecondary" align="center">
        %Complete
      </Typography>
      <div id={`progressChart-${id}`} className={classes.progressChart} />
    </div>
  );
};

const useStyles = makeStyles({
  progressChart: {
    height: "4rem",
  },
});

export default ProgressChart;
