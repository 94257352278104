import * as actions from "../action/project";

const initialState = {
  projects: [],
  project: {},
  headerInfo: {},
  accountingHours: {},
  cards: [],
  customer: {},
  dashboardReports: {},
  jpacInfo: {},
  contract: {},
};

const deleteProjects = (state, projectIds) => {
  const updateProjects = state.projects.filter(
    (project) => !projectIds.includes(project.id)
  );
  return { ...state, projects: updateProjects };
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.DELETE_PROJECTS:
      return deleteProjects(state, action.projectIds);
    case actions.SET_PROJECT:
      return {
        ...state,
        project: action.payload,
      };
    case actions.SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case actions.SET_PROJECT_HEADER_INFO:
      return {
        ...state,
        headerInfo: action.payload,
      };
    case actions.SET_ACCOUNTING_HOURS_INFO:
      return {
        ...state,
        accountingHours: action.payload,
      };
    case actions.SET_JPAC_INFO:
      return {
        ...state,
        jpacInfo: action.payload,
      };
    case actions.SET_CONTRACT_INFO:
      return {
        ...state,
        contract: action.payload,
      };
    case actions.SET_DASHBOARD_REPORTS:
      return {
        ...state,
        dashboardReports: action.payload,
      };
    default:
      return state;
  }
};

export default projectReducer;
