import server from "../api/server";
import { getIdsFromObjects } from "../utils/helperFunctions";

export const getTimeReports = async (filters) => {
  const response = await server({
    url: "/report/getTimeReport",
    method: "POST",
    data: {
      companyId: filters.companyId,
      subsidiariesIds: getIdsFromObjects(filters.subsidiariesIds),
      divisionsIds: getIdsFromObjects(filters.divisionsIds),
      outputValue: filters.outputValue,
      startDate: filters.startDate,
      endDate: filters.endDate,
      period: filters.period,
    },
  });
  let content;
  if (response.status === 204) {
    content = {
      requestedProjects: [],
      missingInformationProjects: [],
      timeReportsModel: [],
    };
  } else {
    content = response.data;
  }

  return content;
};

export const getTeamReports = async (filters) => {
  const response = await server({
    url: "/report/getProjectTeamReport",
    method: "POST",
    data: {
      companyId: filters.companyId,
      subsidiariesIds: getIdsFromObjects(filters.subsidiariesIds),
      divisionsIds: getIdsFromObjects(filters.divisionsIds),
      outputValue: filters.outputValue,
      startDate: filters.startDate,
      endDate: filters.endDate,
      period: filters.period,
      assigneesIds: getIdsFromObjects(filters.assigneesIds),
      roleIds: [parseInt(filters.roleIds)],
    },
  });
  let content;
  if (response.status === 204) {
    content = {
      requestedProjects: [],
      missingInformationProjects: [],
      assignees: [],
    };
  } else {
    content = response.data;
  }
  return content;
};

export const getTeamReportsExpandedTable = async (filters) => {
  const response = await server({
    url: "/report/getProjectTeamReport",
    method: "POST",
    data: {
      companyId: filters.companyId,
      subsidiariesIds: getIdsFromObjects(filters.subsidiariesIds),
      divisionsIds: getIdsFromObjects(filters.divisionsIds),
      outputValue: filters.outputValue,
      startDate: filters.startDate,
      endDate: filters.endDate,
      period: filters.period,
      assigneesIds: getIdsFromObjects(filters.assigneesIds),
      roleIds: [parseInt(filters.roleIds)],
    },
  });
  let content;
  if (response.status === 204) {
    content = {
      requestedProjects: [],
      missingInformationProjects: [],
      assignees: [],
    };
  } else {
    content = response.data;
  }

  return content;
};

export const getLocationReports = async (filters) => {
  const response = await server({
    url: "/report/GetLocationReport",
    method: "POST",
    data: {
      companyId: filters.companyId,
      subsidiariesIds: getIdsFromObjects(filters.subsidiariesIds),
      divisionsIds: getIdsFromObjects(filters.divisionsIds),
      outputValue: filters.outputValue,
      startDate: filters.startDate,
      endDate: filters.endDate,
      period: filters.period,
      stateIds: getIdsFromObjects(filters.stateIds),
      countyIds: getIdsFromObjects(filters.countyIds),
    },
  });
  let content;
  if (response.status === 204) {
    content = {
      locationReportModel: [],
      missingInformationProjects: [],
      states: [],
    };
  } else {
    content = response.data;
  }

  return content;
};

export const getLocationReportsExpandedTable = async (filters) => {
  const response = await server({
    url: "/report/GetLocationReport",
    method: "POST",
    data: {
      companyId: filters.companyId,
      subsidiariesIds: getIdsFromObjects(filters.subsidiariesIds),
      divisionsIds: getIdsFromObjects(filters.divisionsIds),
      outputValue: filters.outputValue,
      startDate: filters.startDate,
      endDate: filters.endDate,
      period: filters.period,
      stateIds: getIdsFromObjects(filters.stateIds),
      countyIds: getIdsFromObjects(filters.countyIds),
    },
  });
  let content;
  if (response.status === 204) {
    content = {
      locationReportModel: [],
      missingInformationProjects: [],
      states: [],
    };
  } else {
    content = response.data;
  }
  return content;
};

export const getCustomerReports = async (filters) => {
  const response = await server({
    url: "/report/getCustomerReport",
    method: "POST",
    data: {
      companyId: filters.companyId,
      subsidiariesIds: getIdsFromObjects(filters.subsidiariesIds),
      divisionsIds: getIdsFromObjects(filters.divisionsIds),
      outputValue: getOutputValue(filters.outputValue),
      startDate: filters.startDate,
      endDate: filters.endDate,
      period: filters.period,
      customerIds: getIdsFromObjects(filters.customerIds),
    },
  });
  let content;
  if (response.status === 204) {
    content = {
      locationReportModel: [],
      missingInformationProjects: [],
      customers: [],
    };
  } else {
    content = response.data;
  }

  return content;
};

export const getCustomerReportsExtendedTable = async (filters) => {
  const response = await server({
    url: "/report/getCustomerReport",
    method: "POST",
    data: {
      companyId: filters.companyId,
      subsidiariesIds: getIdsFromObjects(filters.subsidiariesIds),
      divisionsIds: getIdsFromObjects(filters.divisionsIds),
      outputValue: getOutputValue(filters.outputValue),
      startDate: filters.startDate,
      endDate: filters.endDate,
      period: filters.period,
      customerIds: getIdsFromObjects(filters.customerIds),
    },
  });
  let content;
  if (response.status === 204) {
    content = {
      locationReportModel: [],
      missingInformationProjects: [],
      customers: [],
    };
  } else {
    content = response.data;
  }

  return content;
};

const getOutputValue = (outputValue) => {
  switch (outputValue) {
    case "0":
      return "contractBidValue";
    case "1":
      return "totalCost";
    case "2":
      return "laborHours";
    default:
      return "contractBidValue";
  }
};

export const getMarketReports = async (filters) => {
  const response = await server({
    url: "/report/GetMarketSegmentReport",
    method: "POST",
    data: {
      companyId: filters.companyId,
      subsidiariesIds: getIdsFromObjects(filters.subsidiariesIds),
      divisionsIds: getIdsFromObjects(filters.divisionsIds),
      outputValue: filters.outputValue,
      startDate: filters.startDate,
      endDate: filters.endDate,
      period: filters.period,
      marketSegmentIds: getIdsFromObjects(filters.marketSegmentIds),
      marketCategoryIds: getIdsFromObjects(filters.marketCategoryIds),
    },
  });
  let content;
  if (response.status === 204) {
    content = {
      segments: [],
      missingInformationProjects: [],
      requestedProjects: null,
    };
  } else {
    content = response.data;
  }

  return content;
};
