import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Box, Paper } from "@material-ui/core";
import {
  Settings,
  Create,
  Add,
  Delete,
  Beenhere,
  Block,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  getAvailableDivisionsDataToStore,
  getCompanyDataToStore,
} from "../../actions/companyActions";
import NotificationPopUp from "../utils/NotificationPopUp";
//import { SubsidiaryContext } from "../../contexts/SubsidiaryContext";
//import { ResourcesContext } from "../../contexts/ResourcesContext";
import {
  getDivisionsData,
  updateDivisionStatusData,
} from "../../actions/divisionActions";
import { getSubsidiariesDataToStore } from "../../actions/subsidiaryActions";
import {
  getIndustriesDataToStore,
  getStatusesDataToStore,
} from "../../actions/resourcesAction";
import DivisionTable from "./DivisionTable";
import AdministrationTabBar from "../elements/AdministrationTabBar";
import * as store from "../../state";
//import { editDivision, setDivisions } from "../../state/action/division";

const DivisionDashboard = (props) => {
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const divisions = useSelector(store.selectors.division.selectDivisions);
  // const maxNumberOfDivisions = useSelector(
  //   store.selectors.company.selectMaxNumberOfDivisions
  // );
  const selectedCompany = useSelector(store.selectors.company.selectCompany);
  //const subsidiaryContext = useContext(SubsidiaryContext);
  const subsidiaries = useSelector(
    store.selectors.subsidiary.selectSubsidiaries
  );
  //const resourcesContext = useContext(ResourcesContext);
  const divisionStatuses = useSelector(
    store.selectors.resource.selectDivisionStatuses
  );
  const industries = useSelector(store.selectors.resource.selectIndustries);
  const companyId =
    props.view === "sysAdmin"
      ? parseInt(props.companyId)
      : loggedInUser.userCompany.id;
  const addDivisionUrl =
    props.view === "sysAdmin"
      ? `/systemAdministration/addDivision/${props.companyId}`
      : "/addDivision";
  const editDivisionUrl =
    props.view === "sysAdmin"
      ? `/systemAdministration/editDivision/${props.companyId}?divisionId=`
      : "/editDivision/";
  const { roleId } = loggedInUser;
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState([]);
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const isEditButtonDisable = selectedRows.length === 1;

  const isDeleteButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.statusId === 3).length !==
      selectedRows.length;

  const isActivateButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.statusId === 2).length !== 0;

  const isDeactivateButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.statusId === 1).length !== 0;

  const isDeleteOrDeactivateActionPossible = subsidiaries.map(
    (subsidiary) =>
      selectedRows.filter(
        (row) => row.statusId === 1 && row.subsidiaryId === subsidiary.id
      ).length ===
        subsidiary.divisions.filter((row) => row.statusId === 1).length &&
      subsidiary.divisions.filter((row) => row.statusId === 1).length !== 0
  );

  const isActivateActionPossible = subsidiaries.map(
    (subsidiary) =>
      selectedRows.filter(
        (row) => row.statusId === 2 && row.subsidiaryId === subsidiary.id
      ).length <=
        selectedCompany.maxNumberOfDivisions -
          subsidiary.divisions.filter((row) => row.statusId === 1).length &&
      subsidiary.divisions.filter((row) => row.statusId === 1).length !== 0 &&
      selectedRows.filter(
        (row) => row.statusId === 2 && row.subsidiaryId === subsidiary.id
      ).length !== 0
  );

  const getDivisionForActiveSubsidiaries = (divisions) =>
    divisions.filter((division) =>
      subsidiaries
        .filter((subsidiary) => subsidiary.statusId === 1)
        .map((subsidiary) => subsidiary.id)
        .includes(division.subsidiaryId)
    );

  const deleteDivision = () => {
    const divisionIds = selectedRows
      .filter((row) => row.statusId !== 3)
      .map((row) => row.id);
    updateDivisionStatusData({
      divisionIds,
      status: "DELETED",
    }).then(() => {
      dispatchRedux(
        store.actions.division.editDivision({
          divisionIds,
          statusId: 3,
        })
      );
    });
  };

  const activateDivision = () => {
    const divisionIds = selectedRows
      .filter((row) => row.statusId === 2)
      .map((row) => row.id);
    updateDivisionStatusData({
      divisionIds,
      status: "ACTIVE",
    }).then(() => {
      dispatchRedux(
        store.actions.division.editDivision({
          divisionIds,
          statusId: 1,
        })
      );
      setNotificationTitle("Division has been reactivated");
      setNotificationText(
        "The selected division has been successfully reactivated."
      );
      setNotificationType("success");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    });
  };

  const deactivateDivision = () => {
    const divisionIds = selectedRows
      .filter((row) => row.statusId === 1)
      .map((row) => row.id);
    updateDivisionStatusData({
      divisionIds,
      status: "INACTIVE",
    }).then(() => {
      dispatchRedux(
        store.actions.division.editDivision({
          divisionIds,
          statusId: 2,
        })
      );
    });
  };

  const areMultipleStatusesSelected = (requiredStatus) => {
    let multipleStatusesSelected = false;
    selectedRows.forEach((row) => {
      if (row.statusId !== requiredStatus) {
        multipleStatusesSelected = true;
      }
    });

    return multipleStatusesSelected;
  };

  const handleAdd = () => {
    history.push(addDivisionUrl);
  };

  const handleEdit = () => {
    history.push(editDivisionUrl + selectedRows[0].id);
  };

  const handleClose = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleDelete = () => {
    if (
      isDeleteOrDeactivateActionPossible.filter(
        (condition) => condition === true
      ).length === 0
    ) {
      setNotificationTitle("Delete Division");
      setNotificationText(
        "The division will be removed. Are you sure you want to delete the division?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "DELETE",
          action: () => {
            deleteDivision();
            handleClose();
          },
        },
        { name: "CANCEL", action: () => handleClose() },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      setNotificationTitle("Delete Division");
      setNotificationText(
        "Deletion cannot be performed. At least one division must remain active for each subsidiary."
      );
      setNotificationType("error");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    }
  };

  const handleActivateDivision = () => {
    if (
      isActivateActionPossible.filter((condition) => condition === true)
        .length === 0
    ) {
      setNotificationTitle("Division Limit");
      setNotificationText(
        "You have reached the maximum number of active divisions per subsidiary. Please contact our support for help."
      );
      setNotificationType("warning");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    } else if (areMultipleStatusesSelected(2)) {
      setNotificationTitle("Multiple statuses selected");
      setNotificationText(
        "Only INACTIVE divisions will be activated. Would you like to proceed?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "YES",
          action: () => {
            activateDivision();
            handleClose();
          },
        },
        {
          name: "NO",
          action: () => {
            handleClose();
          },
        },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      activateDivision();
    }
  };

  const handleDeactivateDivision = () => {
    if (
      isDeleteOrDeactivateActionPossible.filter(
        (condition) => condition === true
      ).length !== 0
    ) {
      setNotificationTitle("Deactivate Division");
      setNotificationText(
        "Deactivation cannot be performed. At least one division per subsidiary must remain active."
      );
      setNotificationType("error");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    } else if (areMultipleStatusesSelected(1)) {
      setNotificationTitle("Multiple statuses selected");
      setNotificationText(
        "Only ACTIVE divisions will be inactivated. Would you like to proceed?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "YES",
          action: () => {
            deactivateDivision();
            handleClose();
          },
        },
        {
          name: "NO",
          action: () => {
            handleClose();
          },
        },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      setNotificationTitle("Deactivate Division");
      setNotificationText(
        "Deactivating a division will make it unavailable for projects. Do you want to continue?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "DEACTIVATE",
          action: () => {
            deactivateDivision();
            handleClose();
          },
        },
        { name: "CANCEL", action: () => handleClose() },
      ]);
      setIsNotificationPopUpOpen(true);
    }
  };

  useEffect(() => {
    // getAvailableDivisions(companyContext.dispatch, companyId);
    // getSubsidiariesData(subsidiaryContext.dispatch, companyId);
    Promise.all([
      getAvailableDivisionsDataToStore(companyId, { dispatchRedux }),
      getSubsidiariesDataToStore(companyId, { dispatchRedux }),
    ]);

    setSelectedRows([]);
  }, [divisions]);

  useEffect(async () => {
    // getDivisions(companyId).then((response) => {
    //   dispatchRedux(setDivisions(response));
    // });

    // getAvailableDivisions(companyContext.dispatch, companyId);
    // getCompany(companyId, companyContext.dispatch);
    // getIndustries(resourcesContext.dispatch);
    // getStatuses(resourcesContext.dispatch);

    getDivisionsData(companyId).then(({ data }) => {
      dispatchRedux(store.actions.division.setDivisions(data));
    });

    await getAvailableDivisionsDataToStore(companyId, { dispatchRedux });
    await getCompanyDataToStore(companyId, { dispatchRedux });
    await getIndustriesDataToStore({ dispatchRedux });
    await getStatusesDataToStore({ dispatchRedux });
  }, []);

  return (
    <>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleClose}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        ADMINISTRATION
      </Box>
      <Divider className={classes.divider} />
      <AdministrationTabBar page="divisions" view={props.view} id={companyId} />
      <Box className={classes.pageSubTitle}>
        {selectedCompany.name}
        {" : Division Management"}
      </Box>
      <div className={classes.actionArea}>
        <div className={classes.actionButtons}>
          <div
            id="addButton"
            className={classes.actionButton}
            onClick={() => handleAdd()}
          >
            <Add className={classes.icon} />
            <b>Add</b>
          </div>
          <div
            id="editButton"
            className={
              isEditButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() => (isEditButtonDisable ? handleEdit() : null)}
          >
            <Create className={classes.icon} />
            <b>Edit</b>
          </div>
          <div
            id="deleteButton"
            className={
              isDeleteButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() => (isDeleteButtonDisable ? handleDelete() : null)}
          >
            <Delete className={classes.icon} />
            <b>Delete</b>
          </div>
          <div
            id="activateButton"
            className={
              isActivateButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isActivateButtonDisable ? handleActivateDivision() : null
            }
          >
            <Beenhere className={classes.icon} />
            <b>Activate Division</b>
          </div>
          <div
            id="deactivateButton"
            className={
              isDeactivateButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isDeactivateButtonDisable ? handleDeactivateDivision() : null
            }
          >
            <Block className={classes.icon} />
            <b>Deactivate Division</b>
          </div>
        </div>

        {/* COMMENTED THIS ONLY TEMPORARY, BA TEAM DOES NOT WANT IT NOW
                <div className={classes.labelArea}>
                    <b>Total Active Divisions: </b>
                    <label> {maxNumberOfDivisionsPerSubsidiary - availableDivisions} of {maxNumberOfDivisionsPerSubsidiary} subsidiaries</label>
                </div> */}
      </div>
      <DivisionTable
        setSelectedRows={setSelectedRows}
        rows={getDivisionForActiveSubsidiaries(divisions)}
        selectedRows={selectedRows}
        roleId={roleId}
        editDivisionUrl={editDivisionUrl}
        statuses={divisionStatuses}
        industries={industries}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  actionArea: {
    backgroundColor: "#CBD5E0",
    padding: ".75rem",
    display: "flow-root",
  },
  labelArea: {
    float: "right",
  },
  actionButtons: {
    display: "flex",
    textAlign: "left",
    float: "left",
  },
  actionButton: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    marginRight: "1.5rem",
  },
  disableActionButton: {
    display: "flex",
    cursor: "default",
    alignItems: "center",
    marginRight: "1.5rem",
    color: "grey",
  },
  icon: {
    fontSize: "1rem",
    marginRight: ".5rem",
    color: "#4A5568",
  },
  titleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& button": {
      minWidth: "8rem",
      marginRight: ".5rem",
      marginBottom: "2rem",
      borderRadius: "0rem",
      fontSize: theme.typography.h6.fontSize,
    },
  },
  projectTypeButtonSelected: {
    borderBottom: `0.2rem solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  projectTypeButtonNotSelected: {
    borderBottom: "0.2rem solid #BEE3F8",
    color: "#BEE3F8",
  },
}));

export default DivisionDashboard;
