import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Paper,
  Box,
  CardContent,
  CardActions,
  Collapse,
  Button,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DRAWER_BACKGROUND_COLOR } from "../../../constants";
import { getContactType } from "../../../utils/helperFunctions";
import PersonOfContactForm from "./PersonOfContactForm";
import * as store from "../../../state";
import { useSelector } from "react-redux";

const ContactCard = (props) => {
  const { tab, contact, onRemove, editContact, removeContact, setCustomer } =
    props;
  const currentProject = useSelector(store.selectors.project.selectProject);
  const { person, isCustomer, customer } = contact;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [form, setForm] = useState(false);

  const { name, contactTypeId } = customer;

  const { firstName, lastName, notes, email, phone, jobTitle } = person || {};

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onCancel = () => {
    setForm(false);
  };

  const handleContactRemove = () => {
    removeContact(contact);
  };

  if (!form) {
    return (
      <Paper elevation={3} className={classes.marginTop}>
        <CardContent>
          <div className={classes.topWrapper}>
            <div>
              <Box className={classes.type}>
                {getContactType(contactTypeId)}
              </Box>
              <Box className={classes.names}>{name}</Box>
              <Box className={classes.names}>
                {firstName} {lastName}
              </Box>
            </div>
            <div className={classes.cardButtonGroup}>
              {tab !== "generalInfo" && (
                <>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        color="primary"
                        checked={!!isCustomer}
                        disabled={!!isCustomer}
                        onChange={() => setCustomer(contact)}
                      />
                    }
                    label={isCustomer ? "Primary Customer" : "Make Customer"}
                  />
                  <Button
                    className={classes.buttonText}
                    onClick={() => {
                      setForm(!form);
                    }}
                    variant="text"
                    size="medium"
                    color="primary"
                  >
                    <Box>Edit</Box>
                  </Button>
                  {!isCustomer && (
                    <Button
                      className={clsx(classes.buttonText, classes.removeButton)}
                      onClick={handleContactRemove}
                      variant="text"
                      size="medium"
                      color="secondary"
                    >
                      <Box>Remove</Box>
                    </Button>
                  )}
                </>
              )}
              {tab === "generalInfo" && currentProject.projectId === 0 && (
                <Button
                  onClick={onRemove}
                  variant="contained"
                  size="medium"
                  color="primary"
                >
                  <Box>Change</Box>
                </Button>
              )}
            </div>
          </div>
          <Divider className={classes.divider} />
          <Box className={classes.info}>{phone}</Box>
          <Box className={classes.info}>{email}</Box>
          <Box className={classes.info}>{jobTitle}</Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box className={classes.names}>{notes}</Box>
          </Collapse>
        </CardContent>
        {notes && (
          <CardActions className={classes.cardActions}>
            <Button
              onClick={handleExpandClick}
              className={classes.button}
              aria-expanded={expanded}
              aria-label="show more"
              endIcon={
                <ExpandMoreIcon
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                />
              }
            >
              {expanded ? "hide" : "see more"}
            </Button>
          </CardActions>
        )}
      </Paper>
    );
  }
  return (
    <PersonOfContactForm
      isCustomer={isCustomer}
      person={person || null}
      customer={customer}
      onCancel={onCancel}
      editContact={editContact}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  names: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: "bold",
    margin: "0.5rem 0 0 0",
  },
  type: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.grey[400],
  },
  info: {
    fontSize: theme.typography.h6.fontSize,
    margin: "0.2rem 0 0 0",
    color: theme.palette.primary.main,
  },
  cardActions: {
    padding: "0",
    backgroundColor: DRAWER_BACKGROUND_COLOR,
    color: theme.palette.grey[400],
  },
  cardButtonGroup: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  divider: {
    margin: "1rem 0",
  },
  topWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  button: {
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: theme.palette.grey[600],
    flexGrow: "1",
  },
  marginTop: {
    marginTop: "1rem",
  },
  buttonText: {
    padding: "0",
    "& span": {
      justifyContent: "unset",
    },
    "& div": {
      borderBottom: "2px solid",
    },
  },
  removeButton: {
    color: "#FC0000",
  },
  checkbox: {
    "& span": {
      padding: "0",
    },
  },
}));
export default ContactCard;
