import * as actions from "../action/login";

import { encryptWithAES, decryptWithAES } from "../../utils/helperFunctions";

const initialState = {
  refreshToken: window.localStorage.getItem("refreshToken")
    ? window.localStorage.getItem("refreshToken")
    : null,
  accessToken: window.localStorage.getItem("accessToken")
    ? window.localStorage.getItem("accessToken")
    : null,
  companyId: window.localStorage.getItem("companyId")
    ? parseInt(decryptWithAES(window.localStorage.getItem("companyId")))
    : 21,
  loggedInUser: window.localStorage.getItem("loggedInUser")
    ? JSON.parse(decryptWithAES(window.localStorage.getItem("loggedInUser")))
    : { roleId: 0 },
};

const storeLoginTokens = (refreshToken, accessToken) => {
  window.localStorage.setItem("refreshToken", refreshToken);
  window.localStorage.setItem("accessToken", accessToken);
};

const storeLoggedInUserInfo = (loggedInUser) => {
  const encryptedLoggedInUser = encryptWithAES(JSON.stringify(loggedInUser));
  window.localStorage.setItem("loggedInUser", encryptedLoggedInUser);

  if (loggedInUser.companyId) {
    const encryptedCompanyId = encryptWithAES(loggedInUser.companyId);
    window.localStorage.setItem("companyId", encryptedCompanyId);
  }

  if (loggedInUser.userCompany) {
    const encryptedCompanyId = encryptWithAES(loggedInUser.userCompany.id);
    window.localStorage.setItem("companyId", encryptedCompanyId);
  }
};

const clearStore = () => {
  window.localStorage.clear();
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_INIT_STATE:
      clearStore();
      return {
        ...initialState,
      };
    case actions.LOGIN_SET_TOKENS:
      storeLoginTokens(action.payload.refreshToken, action.payload.accessToken);
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
        accessToken: action.payload.accessToken,
      };
    case actions.LOGIN_SET_LOGGED_IN_USER:
      storeLoggedInUserInfo(action.payload);
      return {
        ...state,
        loggedInUser: action.payload,
        companyId: action.payload.companyId
          ? action.payload.companyId
          : action.payload.userCompany
          ? action.payload.userCompany.id
          : null,
      };
    default:
      return state;
  }
};

export default loginReducer;
