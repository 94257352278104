import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Collapse,
  TextField,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TablePaginationActions from "../../../elements/TablePaginationActions";
import { formatNumberUS } from "../../../../utils/helperFunctions";

const CollapsibleTable = (props) => {
  const {
    data,
    query,
    setQuery,
    filters,
    expandTable,
    setChartSelectionFilter,
    setChartLevelSettings,
    chartSelectionFilter,
    chartLevelSettings,
  } = props;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [columnToQuery] = useState("name");
  const style = filters.outputValue === "laborHours" ? "hrs" : "$";
  const filterRows = (rows, query, columnToQuery, chartLevelSettings) => {
    let newRows = [];
    const lowerCaseQuery = query.toLowerCase();
    if (chartLevelSettings === 2) {
      rows.forEach((row) => {
        if (row.categories) {
          row.categories.forEach((category) => {
            if (category.periods) {
              category.periods = query
                ? category.periods.filter((x) =>
                    x.period.toLowerCase().includes(lowerCaseQuery)
                  )
                : category.periods;
            }
          });
          newRows.push(row);
        } else {
          newRows.push(row);
        }
      });
    } else if (chartLevelSettings === 1) {
      rows.forEach((row) => {
        if (row.categories) {
          row.categories = query
            ? row.categories.filter((x) =>
                x[columnToQuery].toLowerCase().includes(lowerCaseQuery)
              )
            : row.categories;
          newRows.push(row);
        } else {
          newRows.push(row);
        }
      });
    } else {
      newRows = query
        ? rows.filter((x) =>
            x[columnToQuery].toLowerCase().includes(lowerCaseQuery)
          )
        : rows;
    }
    return newRows;
  };
  const rows = filterRows(data, query, columnToQuery, chartLevelSettings);

  useEffect(() => {
    if (chartLevelSettings > 0) expandTable();
  }, [query, data]);

  useEffect(() => {
    if (query) {
      if (chartLevelSettings > 0) {
        expandTable();
      }
    }
  }, []);

  return (
    <>
      <TableContainer component={Paper}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            margin: "1rem",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Box>Filter by name</Box>
            <TextField
              margin="dense"
              size="small"
              variant="outlined"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Market Segment</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Backlog</TableCell>
              <TableCell align="right">Pipeline</TableCell>
              <TableCell align="right">Projects&nbsp;(No.)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
              : rows
            ).map((row) => (
              <Row
                key={row.id}
                style={style}
                row={row}
                setChartSelectionFilter={setChartSelectionFilter}
                setChartLevelSettings={setChartLevelSettings}
                chartSelectionFilter={chartSelectionFilter}
                chartLevelSettings={chartLevelSettings}
              />
            ))}
          </TableBody>
        </Table>
        <TablePaginationActions
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={Math.floor(rows.length / rowsPerPage + 1)}
        />
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      width: "14rem",
    },
  },
  rootCell: {
    "& .center-cell-content": {
      textAlign: "center",
    },
  },
  largeCell: {
    padding: 0,
    borderBottom: "0",
  },

  buttonCell: {
    width: "8rem",
  },
  container: {
    maxHeight: "16rem",
  },
}));

function Row(props) {
  const {
    row,
    style,
    setChartLevelSettings,
    setChartSelectionFilter,
    chartSelectionFilter,
    chartLevelSettings,
  } = props;
  const initialOpen = chartSelectionFilter
    ? chartSelectionFilter.marketSegmentId === row.id
    : false;

  const [open, setOpen] = useState(initialOpen);

  useEffect(() => {
    if (chartSelectionFilter.marketSegmentId === row.id) setOpen(true);
    else setOpen(false);
  }, [chartSelectionFilter]);

  const classes = useStyles();

  const handleClick = () => {
    if (open) {
      setChartSelectionFilter({
        marketSegmentId: null,
        marketCategoryId: null,
        marketName: null,
      });
      setChartLevelSettings(0);
    } else {
      setChartSelectionFilter({
        marketSegmentId: row.id,
        marketCategoryId: null,
        marketName: row.name,
      });
      setChartLevelSettings(1);
    }
    setOpen(!open);
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell className={classes.buttonCell}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleClick()}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{formatNumberUS(style, row.total)}</TableCell>
        <TableCell align="right">
          {formatNumberUS(style, row.backlog)}
        </TableCell>
        <TableCell align="right">
          {formatNumberUS(style, row.pipeline)}
        </TableCell>
        <TableCell align="right">{row.projectsCount}</TableCell>
      </TableRow>
      {chartLevelSettings >= 1 && row.categories && (
        <TableRow>
          <TableCell className={classes.largeCell} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table>
                <TableBody>
                  {row.categories.map((row) => (
                    <InnerRow
                      key={row.id}
                      style={style}
                      row={row}
                      setChartSelectionFilter={setChartSelectionFilter}
                      setChartLevelSettings={setChartLevelSettings}
                      chartSelectionFilter={chartSelectionFilter}
                      chartLevelSettings={chartLevelSettings}
                    />
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function InnerRow(props) {
  const {
    row,
    style,
    setChartLevelSettings,
    setChartSelectionFilter,
    chartSelectionFilter,
    chartLevelSettings,
  } = props;
  const initialOpen = chartSelectionFilter
    ? chartSelectionFilter.marketCategoryId === row.id
    : false;

  const [open, setOpen] = useState(initialOpen);

  useEffect(() => {
    if (chartSelectionFilter.marketCategoryId === row.id) setOpen(true);
    else setOpen(false);
  }, [chartSelectionFilter]);

  const classes = useStyles();

  const handleClick = () => {
    if (open) {
      setChartSelectionFilter({
        marketSegmentId: chartSelectionFilter.marketSegmentId,
        marketCategoryId: null,
        categoryName: null,
      });
      setChartLevelSettings(1);
    } else {
      setChartSelectionFilter({
        marketSegmentId: chartSelectionFilter.marketSegmentId,
        marketCategoryId: row.id,
        categoryName: row.name,
      });
      setChartLevelSettings(2);
    }
    setOpen(!open);
  };

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell className={classes.buttonCell}>
          <IconButton
            style={{ marginLeft: "1.5rem" }}
            aria-label="expand row"
            size="small"
            onClick={() => handleClick()}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{formatNumberUS(style, row.total)}</TableCell>
        <TableCell align="right">
          {formatNumberUS(style, row.backlog)}
        </TableCell>
        <TableCell align="right">
          {formatNumberUS(style, row.pipeline)}
        </TableCell>
        <TableCell align="right">{row.projectsCount}</TableCell>
      </TableRow>
      {chartLevelSettings >= 2 && row.periods && (
        <TableRow>
          <TableCell className={classes.largeCell} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableContainer className={classes.container}>
                <Table>
                  <TableBody>
                    {row.periods.map((row, index) => (
                      <TableRow className={classes.root} key={index}>
                        <TableCell className={classes.buttonCell} />
                        <TableCell component="th" scope="row">
                          {row.period}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumberUS(style, row.total)}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumberUS(style, row.backlog)}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumberUS(style, row.pipeline)}
                        </TableCell>
                        <TableCell align="right">{row.projectsCount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default CollapsibleTable;
