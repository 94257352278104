import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Box, IconButton } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import * as constants from "../../constants";
import Logo from "./Logo";

const FileDropZone = (props) => {
  const classes = useStyles();
  const { formik } = props;
  const [fileChose, setFileChosen] = useState(Boolean(formik.values.logo));
  const [hover, setHover] = useState(false);

  const handleHoverEnter = () => {
    if (fileChose) {
      setHover(true);
    }
  };

  const handleHoverLeave = () => {
    if (fileChose) {
      setHover(false);
    }
  };

  const changeFile = (event) => {
    handleFiles(event.currentTarget.files);
  };

  const deleteFile = () => {
    formik.setFieldValue("logo", null);
  };

  useEffect(() => {
    setFileChosen(Boolean(formik.values.logo));
  }, [formik.values.logo]);

  const handleFiles = (files) => {
    const file = files[0];
    if (validateFile(file)) {
      if (validateFileSize(file.size)) {
        formik.setFieldValue("logo", file);
      } else {
        formik.setFieldError("logo", "File size must be bellow 200KB");
        formik.setFieldTouched("logo", true, false);
      }
    } else {
      formik.setFieldError("logo", "Invalid Type Provided");
      formik.setFieldTouched("logo", true, false);
    }
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    if (files.length) {
      handleFiles(files);
    }
  };
  return (
    <div
      style={{ width: "12rem", marginRight: "2rem" }}
      id="container"
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
    >
      <div
        id="drop-container"
        className={classes.dropContainer}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        {!fileChose ? (
          <div
            id="empty-upload-container"
            className={classes.emptyUploadContainer}
          >
            <Box className={classes.emptyUploadContainerTitle}>
              Company Logo
            </Box>
            <Box className={classes.emptyUploadContainerText}>
              Drop the image file with your company here or click “ADD”.
            </Box>
            <div id="upload-icon">
              <TextField
                fullWidth
                type="file"
                size="small"
                variant="standard"
                id="logo"
                name="logo"
                style={{ display: "none" }}
                onBlur={formik.handleBlur}
                onChange={changeFile}
              />
              <label htmlFor="logo">
                <IconButton aria-label="upload picture" component="span">
                  <AddCircleIcon className={classes.addButton} />
                </IconButton>
              </label>
            </div>
            <Box>ADD</Box>
            <Box className={classes.emptyUploadContainerText}>
              File formats: JPEG, PNG, TIFF, BMP, etc.
            </Box>
          </div>
        ) : (
          <Logo
            file={formik.values.logo}
            errors={formik.errors.logo}
            hover={hover}
            changeFile={changeFile}
            deleteFile={deleteFile}
          />
        )}
      </div>
    </div>
  );
};

const dragOver = (e) => {
  e.preventDefault();
};

const dragEnter = (e) => {
  e.preventDefault();
};

const dragLeave = (e) => {
  e.preventDefault();
};

const validateFile = (file) => {
  if (file) {
    if (constants.SUPPORTED_FORMATS.indexOf(file.type) !== -1) {
      return true;
    }
  }
  return false;
};

const validateFileSize = (size) => {
  if (size > 0 && size < 1024 * 200) {
    return true;
  }
  return false;
};

const useStyles = makeStyles(() => ({
  dropContainer: {
    border: " dashed 3px #BDBDBD",
    height: "9.5rem",
    width: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyUploadContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyUploadContainerText: {
    textAlign: "center",
    fontSize: "10px",
  },
  emptyUploadContainerTitle: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "bold",
  },
  addButton: {
    color: "#3182CE",
  },
}));

export default FileDropZone;
