import React from "react";
import { Box, TextField, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import { formatDateUS, getStatusLabel } from "../../../utils/helperFunctions";
import DashboardTable from "../../elements/DashboardTable";

const AccountsTable = (props) => {
  const { rows, setSelectedRows, statuses, selectedRows, handleFakeLogin } =
    props;
  const classes = useStyles();
  const columns = [
    {
      field: "logo",
      headerName: " ",
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <div key="picture" className={classes.image}>
          <img className={classes.imageSrc} src={params.value} alt="" />
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Account Name",
      width: 370,
      renderCell: (params) => (
        <Link to={`/systemAdministration/company/${params.row.id}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "statusId",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Box
          fontSize="subtitle2.fontSize"
          className={clsx(
            classes[getStatusLabel(params.value, statuses)],
            classes.chip
          )}
        >
          {getStatusLabel(params.value, statuses)}
        </Box>
      ),
      filterOperators: companiesOperator("status", statuses),
    },
    {
      field: "createdAt",
      headerName: "Created On",
      width: 200,
      type: "date",
      valueFormatter: (params) =>
        params.value
          ? formatDateUS(params.value) === "1/1/1"
            ? "-"
            : formatDateUS(params.value)
          : "-",
      filterOperators: dateOperator,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 200,
      valueFormatter: (params) =>
        params.value
          ? `${params.value.firstName} ${params.value.lastName}`
          : "-",
      sortComparator: (v1, v2) =>
        (v1 ? `${v1.firstName}${v1.lastName}` : "-").localeCompare(
          v2 ? `${v2.firstName}${v2.lastName}` : "-"
        ),
      filterOperators: companiesOperator("createdBy"),
    },
    {
      field: "login",
      headerName: "Login",
      filterable: false,
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleFakeLogin(params.row.id)}>
          <ExitToAppRoundedIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <DashboardTable
        rows={rows}
        columns={columns}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

const companiesOperator = (type, neededArray) => [
  {
    label: "Contains",
    value: "contains",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "status"
          ? getStatusLabel(params.value, neededArray)
          : params.value
          ? `${params.value.firstName} ${params.value.lastName}`
          : "-";
        return rowValue.toUpperCase().includes(filterItem.value.toUpperCase());
      };
    },
    InputComponent: InputValue,
    InputComponentProps: { type: "string" },
  },
  {
    label: "Equals",
    value: "equals",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "status"
          ? getStatusLabel(params.value, neededArray)
          : params.value
          ? `${params.value.firstName} ${params.value.lastName}`
          : "-";
        return rowValue.toUpperCase() === filterItem.value.toUpperCase();
      };
    },
    InputComponent: InputValue,
    InputComponentProps: { type: "string" },
  },
  {
    label: "Starts with",
    value: "startsWith",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "status"
          ? getStatusLabel(params.value, neededArray)
          : params.value
          ? `${params.value.firstName} ${params.value.lastName}`
          : "-";
        return rowValue
          .toUpperCase()
          .startsWith(filterItem.value.toUpperCase());
      };
    },
    InputComponent: InputValue,
    InputComponentProps: { type: "string" },
  },
  {
    label: "Ends with",
    value: "endsWith",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "status"
          ? getStatusLabel(params.value, neededArray)
          : params.value
          ? `${params.value.firstName} ${params.value.lastName}`
          : "-";
        return rowValue.toUpperCase().endsWith(filterItem.value.toUpperCase());
      };
    },
    InputComponent: InputValue,
    InputComponentProps: { type: "string" },
  },
];

const dateOperator = [
  {
    label: "Is",
    value: "is",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) === filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
  {
    label: "Is After",
    value: "isAfter",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) > filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
  {
    label: "Is Before",
    value: "isBefore",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) < filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
];

function InputValue(props) {
  const classes = useStyles();
  const { item, applyValue } = props;

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <div>
      <TextField
        className={classes.dateInput}
        name="custom-rating-filter-operator"
        placeholder="Filter value"
        value={item.value}
        onChange={handleFilterChange}
      />
    </div>
  );
}

function DateInputValue(props) {
  const classes = useStyles();
  const { item, applyValue } = props;

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <div>
      <TextField
        className={classes.dateInput}
        name="custom-rating-filter-operator"
        placeholder="Filter value"
        value={item.value}
        onChange={handleFilterChange}
        type="date"
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  dateInput: {
    height: "48px",
    "& div": {
      height: "100%",
    },
  },
  chip: {
    fontWeight: "bold",
    width: "8rem",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
  INACTIVE: {
    color: "#757575",
  },
  ACTIVE: {
    color: "#7FB49B",
  },
  image: {
    width: "4.27rem",
    height: "2.25rem",
    position: "relative",
    padding: "1px",
    marginTop: "1px",
    left: 0,
    right: 0,
    bottom: 0,
    color: "#4A5568",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
  },
  imageSrc: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  tablecellTrucate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default AccountsTable;
