export const selectProjects = (state) => {
  return state.project.projects;
};

export const selectProject = (state) => {
  return state.project.project;
};

export const selectProjectHeaderInfo = (state) => {
  return state.project.headerInfo;
};

export const selectAccountingHoursInfo = (state) => {
  return state.project.accountingHours;
};

export const selectJPACInfo = (state) => {
  return state.project.jpacInfo;
};

export const selectContractInfo = (state) => {
  return state.project.contract;
};

export const selectDashboardReports = (state) => {
  return state.project.dashboardReports;
};
