export const DELETE_PROJECTS = "DELETE_PROJECTS";
export const SET_PROJECT = "SET_PROJECT";
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_PROJECT_HEADER_INFO = "SET_PROJECT_HEADER_INFO";
export const SET_ACCOUNTING_HOURS_INFO = "SET_ACCOUNTING_HOURS_INFO";
export const SET_JPAC_INFO = "SET_JPAC_INFO";
export const SET_CONTRACT_INFO = "SET_CONTRACT_INFO";
export const SET_DASHBOARD_REPORTS = "SET_DASHBOARD_REPORTS";

export const deleteProject = (payload) => ({
  type: DELETE_PROJECTS,
  payload,
});

export const setProjects = (payload) => ({
  type: SET_PROJECTS,
  payload,
});

export const setProject = (payload) => ({
  type: SET_PROJECT,
  payload,
});

export const setProjectHeaderInfo = (payload) => ({
  type: SET_PROJECT_HEADER_INFO,
  payload,
});

export const setAccountingHoursInfo = (payload) => ({
  type: SET_ACCOUNTING_HOURS_INFO,
  payload,
});

export const setJPACInfo = (payload) => ({
  type: SET_JPAC_INFO,
  payload,
});

export const setContractInfo = (payload) => ({
  type: SET_CONTRACT_INFO,
  payload,
});

export const setDashboardReports = (payload) => ({
  type: SET_DASHBOARD_REPORTS,
  payload,
});
