import * as actions from "../action/user";

const initialState = {
  users: [],
};

const editUserStatus = (state, status, userIds) => {
  const updatedUsers = state.users.map((user) =>
    userIds.indexOf(user.id) > -1 ? { ...user, status } : user
  );
  return { ...state, users: updatedUsers };
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.USER_SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case actions.EDIT_USER_STATUS:
      return editUserStatus(
        state,
        action.payload.status,
        action.payload.userIds
      );
    default:
      return state;
  }
};

export default userReducer;
