import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useFormik } from "formik";
import { TextField, Box, Paper, Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { login } from "../../actions/loggedInUserActions";
import * as constants from "../../constants";
import * as store from "../../state";
import { getUser } from "../../actions/usersAction";
import { getCompanyData } from "../../actions/companyActions";
import { updateJpacProjectsData } from "../../actions/resourcesAction";

const PersonOfContactForm = () => {
  const dispatchRedux = useDispatch();
  const classes = useStyles();
  const [loginError, setLoginError] = useState("");

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
      version: constants.FE_VERSION,
    },
    validationSchema,
    onSubmit: (values) => {
      setLoginError("");
      login(values)
        .then((response) => {
          const loginTokens = {
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
          };
          dispatchRedux(store.actions.login.setLoginTokens(loginTokens));

          getUser(response.userId).then((loggedInUser) => {
            if (loggedInUser.roleId !== 1) {
              getCompanyData(loggedInUser.companyId, null, true).then(
                ({ data: userCompany }) => {
                  dispatchRedux(
                    store.actions.login.setLoggedInUser({
                      ...loggedInUser,
                      userCompany,
                    })
                  );
                }
              );
            } else {
              dispatchRedux(
                store.actions.login.setLoggedInUser({
                  ...loggedInUser,
                  userCompany: null,
                })
              );
            }

            updateJpacProjectsData(loggedInUser.companyId);
          });
        })
        .catch((error) => {
          setLoginError(
            error.data.Message ||
              "Unexpected error. Please contact the administrator."
          );
        });
    },
  });

  return (
    <Paper elevation={3} className={classes.formCard}>
      <div className={classes.top}>
        <Box>DCI&#8482; Login</Box>
      </div>
      <form className={classes.form}>
        <Box className={classes.label}>User Name</Box>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          id="userName"
          name="userName"
          value={formik.values.userName}
          onChange={(event) => {
            formik.setFieldValue("userName", event.target.value);
          }}
          error={formik.touched.userName && Boolean(formik.errors.userName)}
          helperText={formik.touched.userName && formik.errors.userName}
        />

        <Box className={classes.label}>Password</Box>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          id="password"
          name="password"
          type="password"
          value={formik.values.password}
          onChange={(event) => {
            formik.setFieldValue("password", event.target.value);
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        {Boolean(loginError) && (
          <Box className={clsx(classes.errorMessage, classes.label)}>
            {loginError}
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={formik.handleSubmit}
        >
          Log In
        </Button>
        <Divider />
        <Button
          color="primary"
          variant="text"
          className={classes.button}
          disabled
        >
          Forgot Password
        </Button>
      </form>
    </Paper>
  );
};

const validationSchema = yup.object({
  userName: yup
    .string("Complete user name")
    .required("User name is required")
    .nullable(),
  password: yup
    .string("Complete user name")
    .required("Password is required")
    .nullable(),
});

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.7rem 0 0 0",
  },
  top: {
    color: "#fff",
    backgroundColor: "#2C5282",
    borderRadius: "1rem 1rem 0 0",
    margin: "0 -1rem",
    minHeight: "3.5rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& div": {
      fontSize: theme.typography.h6.fontSize,
      flexGrow: 1,
      textAlign: "center",
    },
  },
  formCard: {
    minHeight: "12rem",
    minWidth: "20rem",
    margin: "1rem",
    borderRadius: "1rem",
    padding: "0 1rem 1rem 1rem",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
  },
  button: {
    flexGrow: 1,
  },
  errorMessage: {
    color: constants.ASTERISK_COLOR,
    textAlign: "center",
  },
}));

export default PersonOfContactForm;
