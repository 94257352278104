import server from "../api/server";
import * as API from "./_apiCalls";

export const addDivisionData = async (division, handler) => {
  return API.postData("/division/addDivision", division, handler);

  // const response = await server({
  //   url: "/division/addDivision",
  //   method: "POST",
  //   data: division,
  // });
  // return { ...division, id: response.data.id };
};

// export const addDivision = async (division) => {
//   const response = await server({
//     url: "/division/addDivision",
//     method: "POST",
//     data: division,
//   });
//   return { ...division, id: response.data.id };
// };

export const removeDivisionsData = async (divisions, handler) => {
  return API.postData("/division/removeDivisions", divisions, handler);
  // await server({
  //   url: "/division/removeDivisions",
  //   method: "PUT",
  //   data: divisions,
  // });

  // return divisions;
};

// export const removeDivisions = async (divisions) => {
//   await server({
//     url: "/division/removeDivisions",
//     method: "PUT",
//     data: divisions,
//   });

//   return divisions;
// };

export const editDivisionData = async (division, callback) => {
  return API.postData("/division/updateDivision", division, callback);
  // const response = await server({
  //   url: "/division/updateDivision",
  //   method: "PUT",
  //   data: division,
  // });

  // handleSucces && handleSucces();

  // if (response.status === 200) {
  //   return division;
  // }
};

// export const editDivision = async (division, handleSucces) => {
//   const response = await server({
//     url: "/division/updateDivision",
//     method: "PUT",
//     data: division,
//   });

//   handleSucces && handleSucces();

//   if (response.status === 200) {
//     return division;
//   }
// };

export const getDivisionsData = async (companyId, handler) => {
  return API.getData(`/division/getDivisions?companyId=${companyId}`, handler);
  // const response = await server({
  //   url: `/division/getDivisions/?companyId=${companyId}`,
  //   method: "GET",
  // });

  // return response.data;
};

// export const getDivisions = async (companyId) => {
//   const response = await server({
//     url: `/division/getDivisions/?companyId=${companyId}`,
//     method: "GET",
//   });

//   return response.data;
// };

export const getDivisionData = async (divisionId) => {
  const response = await server({
    url: `/division/getDivision/${divisionId}`,
    method: "GET",
  });

  return response.data;
};
// export const getDivision = async (divisionId) => {
//   const response = await server({
//     url: `/division/getDivision/${divisionId}`,
//     method: "GET",
//   });

//   return response.data;
// };

export const getDivisionBySubsidiariesData = async (subsidiaries, callback) => {
  let queryParam = "";
  if (Array.isArray(subsidiaries) && subsidiaries.length > 0) {
    const subidiaryIds = subsidiaries.map((subsidiary) => subsidiary.id);

    queryParam = `?subsidiaryids=${subidiaryIds.join("&subsidiaryids=")}`;
  }

  return API.getData(
    `subsidiary/GetDivisionsBySubsidiaries${queryParam}`,
    callback
  );

  // const response = await server({
  //   url: `subsidiary/GetDivisionsBySubsidiaries${queryParam}`,
  //   method: "GET",
  // });

  // return response.data;
};

// export const getDivisionBySubsidiaries = async (subsidiaries) => {
//   let queryParam = "";
//   if (Array.isArray(subsidiaries) && subsidiaries.length > 0) {
//     const subidiaryIds = subsidiaries.map((subsidiary) => subsidiary.id);

//     queryParam = `?subsidiaryids=${subidiaryIds.join("&subsidiaryids=")}`;
//   }

//   const response = await server({
//     url: `subsidiary/GetDivisionsBySubsidiaries${queryParam}`,
//     method: "GET",
//   });

//   return response.data;
// };

export const updateDivisionStatusData = async (
  { divisionIds, status },
  callback
) => {
  const baseUrl = `/division/updateDivisionStatus?status=${status}`;

  return API.postData(baseUrl, JSON.stringify(divisionIds), callback);

  // const rawResponse = await server({
  //   url: baseUrl,
  //   method: "PUT",
  //   data: JSON.stringify(divisionIds),
  // });

  // if (rawResponse.status === 200) {
  //   return divisionIds;
  // }

  // return [];
};

// export const updateDivisionStatus = async (divisionIds, status) => {
//   const baseUrl = `/division/updateDivisionStatus?status=${status}`;

//   const rawResponse = await server({
//     url: baseUrl,
//     method: "PUT",
//     data: JSON.stringify(divisionIds),
//   });

//   if (rawResponse.status === 200) {
//     return divisionIds;
//   }

//   return [];
// };
