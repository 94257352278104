import axios from "axios";
import { API, FE_VERSION } from "../constants";
import store from "../state/index";
import * as actions from "../state/action/index";

export const server = axios.create({
  baseURL: API,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => status >= 200 && status < 300,
});

// request interceptor to add the auth token header to requests
server.interceptors.request.use(
  (config) => {
    store.dispatch({ type: actions.app.APP_SHOW_APP_LOADER });
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response interceptor to refresh token on receiving token expired error
server.interceptors.response.use(
  (response) => {
    store.dispatch({ type: actions.app.APP_HIDE_APP_LOADER });
    return response;
  },
  (error) => {
    store.dispatch({ type: actions.app.APP_HIDE_APP_LOADER });
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem("refreshToken");
    const accessToken = localStorage.getItem("accessToken");
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch({ type: actions.app.APP_SHOW_APP_LOADER });
      return axios
        .post(`${API}/user/refreshToken`, {
          refreshToken,
          ExpiredAccessToken: accessToken,
          version: FE_VERSION,
        })
        .then((res) => {
          store.dispatch({ type: actions.app.APP_HIDE_APP_LOADER });
          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            originalRequest.headers.Authorization = `Bearer ${res.data.accessToken}`;
            return axios(originalRequest);
          }
        })
        .catch(() => {
          store.dispatch({ type: actions.app.APP_HIDE_APP_LOADER });
          window.localStorage.clear();
          window.location.reload();
        });
    }
    store.dispatch({ type: actions.app.APP_HIDE_APP_LOADER });
    return Promise.reject(error.response);
  }
);

export default server;
