import React from "react";
import { Box, TextField } from "@material-ui/core";
import { DataGrid, useGridSlotComponentProps } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  formatDateUS,
  formatNumberUS,
  getLabelTitle,
  getLabel,
} from "../../utils/helperFunctions";
import TablePaginationActions from "../elements/TablePaginationActions";

const ProjectsTable = (props) => {
  const { rows } = props;
  const classes = useStyles();
  const columns = [
    {
      field: "proposalNumber",
      headerName: "Proposal ID",
      width: 200,
    },
    {
      field: "name",
      headerName: "Project Name",
      width: 300,
      renderCell: (params) => (
        <Link
          to={
            params.row.projectStatusTypeId < 7
              ? "/projectDetails/generalInformation/" + params.row.id
              : "/projectDetails/contractInformation/" + params.row.id
          }
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "projectStatusTypeId",
      headerName: "Status",
      headerAlign: "center",
      width: 120,
      renderCell: (params) => (
        <Box
          fontSize="subtitle2.fontSize"
          className={clsx(
            classes[getLabel(params.row.projectStatusTypeId)],
            classes.chip
          )}
        >
          {getLabelTitle(params.row.projectStatusTypeId)}
        </Box>
      ),
      valueGetter: (params) => getLabelTitle(params.row.projectStatusTypeId),
      valueFormatter: (params) => getLabelTitle(params.row.projectStatusTypeId),
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 250,
      valueFormatter: (params) =>
        params.row.customer ? params.row.customer.name : "-",
      valueGetter: (params) =>
        params.row.customer ? params.row.customer.name : null,
    },
    {
      field: "projectNumber",
      headerName: "Project Number",
      width: 200,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "expectedStartDate",
      headerName: "Expected Start Date",
      width: 200,
      type: "date",
      valueFormatter: (params) =>
        params.value ? formatDateUS(params.value) : "-",
      filterOperators: dateOperator,
    },
    {
      field: "expectedEndDate",
      headerName: "Expected End Date",
      width: 200,
      type: "date",
      valueFormatter: (params) =>
        params.value ? formatDateUS(params.value) : "-",
      filterOperators: dateOperator,
    },
    {
      field: "description",
      headerName: "Project Description",
      width: 200,
      valueFormatter: (params) => params.value,
    },
    {
      field: "bidDueDate",
      headerName: "Bid Due by",
      width: 200,
      type: "date",
      valueFormatter: (params) =>
        params.value ? formatDateUS(params.value) : "-",
      filterOperators: dateOperator,
    },
    {
      field: "bidValue",
      headerName: "Bid Value",
      width: 200,
      valueFormatter: (params) =>
        params.value ? formatNumberUS("$", params.value) : "-",
    },
    {
      field: "estimatedCost",
      headerName: "Est. Total Cost",
      width: 200,
      type: "number",
      valueFormatter: (params) =>
        params.value ? formatNumberUS("$", params.value) : "-",
    },
    {
      field: "estimatedLaborHours",
      headerName: "Est. Labor Hrs",
      width: 200,
      type: "number",
      valueFormatter: (params) =>
        params.value ? formatNumberUS("", params.value) : "-",
    },
    {
      field: "estimatedCompositeRate",
      headerName: "Est. Comp. Rate",
      width: 200,
      type: "number",
      valueFormatter: (params) => {
        const { row } = params;
        return !row.estimatedLaborCost ||
          !row.estimatedLaborHours ||
          isNaN(row.estimatedLaborCost / row.estimatedLaborHours)
          ? "-"
          : formatNumberUS(
              "$",
              (
                Math.round(
                  (row.estimatedLaborCost / row.estimatedLaborHours) * 100
                ) / 100
              ).toFixed(2),
              true
            );
      },
    },
    {
      field: "leadEstimator",
      headerName: "Lead Estimator",
      width: 200,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "leadProjectManager",
      headerName: "Project Manager",
      width: 200,
      valueFormatter: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "contractValue",
      headerName: "Contract Value",
      width: 200,
      type: "number",
      valueFormatter: (params) =>
        params.value ? formatNumberUS("$", params.value) : "-",
    },
    {
      field: "awardedDate",
      headerName: "Awarded Date",
      width: 200,
      type: "date",
      valueFormatter: (params) =>
        params.value ? formatDateUS(params.value) : "-",
      filterOperators: dateOperator,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 200,
      type: "date",
      valueFormatter: (params) =>
        params.value ? formatDateUS(params.value) : "-",
      filterOperators: dateOperator,
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 200,
      type: "date",
      valueFormatter: (params) =>
        params.value ? formatDateUS(params.value) : "-",
      filterOperators: dateOperator,
    },
    {
      field: "totalHoursToDate",
      headerName: "Total Hrs To-Date",
      width: 200,
      type: "number",
      valueFormatter: (params) =>
        params.value ? formatNumberUS("$", params.value) : "-",
    },
    {
      field: "totalCostToDate",
      headerName: "Total Cost To-Date",
      width: 200,
      type: "number",
      valueFormatter: (params) =>
        params.value ? formatNumberUS("$", params.value) : "-",
    },
    {
      field: "currentCompRate",
      headerName: "Current Comp. Rate",
      width: 200,
      type: "number",
      valueFormatter: (params) =>
        params.value ? formatNumberUS("$", params.value) : "-",
    },
    {
      field: "completion",
      headerName: "% Complete",
      width: 200,
      type: "number",
      valueGetter: (params) =>
        params.row.completion ? params.row.completion : null,
      valueFormatter: (params) =>
        params.value ? `${params.value.toFixed(0)}%` : "-",
    },
  ];

  return (
    <div className={classes.root}>
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
          headerClassName: classes.headerRoot,
        }))}
        scrollbarSize={5}
        pagination
        pageSize={10}
        components={{
          Pagination: CustomPagination,
        }}
      />
    </div>
  );
};

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();
  const setPage = (page) => {
    apiRef.current.setPage(page - 1);
  };
  return (
    <TablePaginationActions
      page={state.pagination.page + 1}
      setPage={setPage}
      rowsPerPage={
        state.pagination.pageSize !== 100 ? state.pagination.pageSize : ""
      }
      setRowsPerPage={apiRef.current.setPageSize}
      count={state.pagination.pageCount}
    />
  );
}

const dateOperator = [
  {
    label: "Is",
    value: "is",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) === filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
  {
    label: "Is After",
    value: "isAfter",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) > filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
  {
    label: "Is Before",
    value: "isBefore",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) < filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
];

function DateInputValue(props) {
  const classes = useStyles();
  const { item, applyValue } = props;

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <div>
      <TextField
        className={classes.dateInput}
        name="custom-rating-filter-operator"
        placeholder="Filter value"
        value={item.value}
        onChange={handleFilterChange}
        type="date"
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "43rem",
  },
  dateInput: {
    height: "48px",
    "& div": {
      height: "100%",
    },
  },
  headerRoot: {
    backgroundColor: "#fafafa",
    fontSize: "1rem",
  },
  chip: {
    fontWeight: "bold",
    width: "8rem",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
  requested: {
    color: "rgba(203, 213, 224, 0.8)",
  },
  new: {
    color: `${theme.palette.secondary.dark}`,
  },
  estimating: {
    color: "rgba(118, 169, 250, 0.7)",
  },
  noBfield: {
    color: "#c15700",
  },
  active: {
    color: `${theme.palette.success.dark}`,
  },
  bidWon: {
    color: `${theme.palette.success.main}`,
  },
  bidLost: {
    color: "rgba(255,0,0,1)",
  },
  bidPending: {
    color: `${theme.palette.info.dark}`,
  },
  closed: {
    color: "black",
  },
}));

export default ProjectsTable;
