import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, MenuItem } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

const TablePaginationActions = (props) => {
  const classes = useStyles();
  const { count, page, rowsPerPage, setPage, setRowsPerPage } = props;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div className={classes.root}>
      <Pagination
        count={count}
        page={page}
        defaultPage={1}
        siblingCount={1}
        boundaryCount={1}
        showFirstButton
        showLastButton
        onChange={handleChangePage}
        classes={{ ul: classes.ul }}
      />
      <div className={classes.pageCount}>
        <TextField
          margin="dense"
          size="small"
          variant="outlined"
          id="rowsPerPage"
          select
          name="rowsPerPage"
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
        </TextField>
        <Box className={classes.pagesBox}>{`${page} of ${count} pages`}</Box>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    padding: "1rem",
    width: "100%",
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  outlined: {
    "& .MuiSelected-outlined": {
      padding: "0.5rem 2rem",
    },
  },
  pagesBox: {
    paddingRight: "2rem",
  },
  pageCount: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "13rem",
  },
  ul: {
    "&  .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#4299E1",
      color: "#fff",
    },
  },
}));

export default TablePaginationActions;
