import React from "react";
import { toUpper } from "lodash";
import { useHistory } from "react-router-dom";
import { ListItem, Box } from "@material-ui/core";
import { BarChart } from "@material-ui/icons";

const DCIInterconnectionListItems = (props) => {
  const history = useHistory();
  const { classes, disabled, projectId } = props;

  const handleClickJPAC = () => {
    history.push("/projectDetails/interconnection/JPAC/" + projectId);
  };

  return (
    <div>
      <div>
        <Box
          textAlign="left"
          fontWeight="fontWeightBold"
          letterSpacing={1}
          className={classes.title}
        >
          <BarChart />
          {toUpper("DCI INTERCONNECTION")}
        </Box>
      </div>
      <ListItem
        id="contactsButton"
        disabled={disabled}
        button
        onClick={handleClickJPAC}
      >
        <Box className={classes.item}>JPAC</Box>
      </ListItem>
    </div>
  );
};

export default DCIInterconnectionListItems;
