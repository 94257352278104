import React from "react";
import * as store from "../../../../state";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import ContactCard from "./ContactCard";
import { checkPermission } from "../../../../utils/helperFunctions";

const OtherContactCards = (props) => {
  const { contacts, formik } = props;
  const classes = useStyles();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);

  const { roleId } = loggedInUser;

  const handleChangeCustomer = (newCustomer) => {
    const oldCustomerIndex = formik.values.contacts.findIndex(
      (element) => element.isCustomer
    );
    const newCustomerIndex = formik.values.contacts.findIndex(
      (element) =>
        element.customer.id === newCustomer.customer.id &&
        (newCustomer.person
          ? element.person.id === newCustomer.person.id
          : !element.person)
    );
    const newContacts = formik.values.contacts;
    newContacts[oldCustomerIndex].isCustomer = false;
    newContacts[newCustomerIndex].isCustomer = true;
    formik.setFieldValue("contacts", newContacts);
  };

  const editContact = (contact) => {
    const contactIndex = formik.values.contacts.findIndex(
      (element) => element.customer.id === contact.customer.id
    );
    const newContacts = formik.values.contacts;
    newContacts[contactIndex] = contact;
    formik.setFieldValue("contacts", newContacts);
  };

  const removeContact = (contact) => {
    const contactIndex = formik.values.contacts.findIndex(
      (element) =>
        element.customer.id === contact.customer.id &&
        (contact.person ? element.personId === contact.person.id : true)
    );
    const newContacts = formik.values.contacts;
    newContacts.splice(contactIndex, 1);
    formik.setFieldValue("contacts", newContacts);
  };

  return (
    <>
      <Box className={classes.topLabel}>Other Contacts</Box>
      {contacts &&
        contacts.map((contact) => (
          <>
            {contact && (
              <ContactCard
                contact={{ ...contact, isCustomer: false }}
                tab={checkPermission([5], roleId) ? "generalInfo" : "contacts"}
                setCustomer={handleChangeCustomer}
                removeContact={removeContact}
                editContact={editContact}
              />
            )}
          </>
        ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.7rem 0",
  },
  topLabel: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: "bold",
    margin: "0.7rem 0",
  },
  customer: {
    padding: "1rem",
    minHeight: "12rem",
    margin: "0.5rem 0",
  },
  addButtonContainer: {
    marginTop: "0.5rem",
    display: "flex",
    flexDirection: "row-reverse",
  },
  addButton: {
    backgroundColor: theme.palette.info.dark,
    margin: "0rem",
  },
}));

export default OtherContactCards;
