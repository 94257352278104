import React, { useEffect, useState } from "react";
import ProjectHeader from "../ProjectHeader";
import { makeStyles } from "@material-ui/core/styles";
import ProjectTitle from "../ProjectTitle";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import * as store from "../../../../state/index";
import { useHistory } from "react-router";
import {
  getContractToStore,
  deleteProjects,
  createContract,
  updateContract,
} from "../../../../actions/projectActions";
import NotificationPopUp from "../../../utils/NotificationPopUp";
import Financials from "./Financials";
import Details from "./Details";
import { contractInformationSchema } from "../../validationSchemas/validationSchema";

const ContractInformation = (props) => {
  const { projectId } = props;

  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const history = useHistory();

  const contract = useSelector(store.selectors.project.selectContractInfo);
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const projectHeaderInfo = useSelector(
    store.selectors.project.selectProjectHeaderInfo
  );
  const companyId = loggedInUser.userCompany?.id;

  const initialValuesForContract = {
    projectStatusId:
      projectHeaderInfo.projectStatusTypeId < 8
        ? 8
        : projectHeaderInfo.projectStatusTypeId,
    projectId: parseInt(projectId),
    awardedDate: "",
    id: 0,
    projectNumber: "",
    isTaxExempt: "",
    startDate: "",
    endDate: "",
    contractTypeId: "",
    originalContractAmount: "",
    originalEstimatedLaborCost: "",
    originalEstimatedLaborHours: "",
    originalEstimatedTotalCost: "",
  };

  const [initialValues, setInitialValues] = useState(initialValuesForContract);
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);

  const onSuccess = () => {
    setNotificationTitle("Project updated");
    setIsNotificationPopUpOpen(true);
    setNotificationText(<p>Project successfully updated.</p>);
    setNotificationType("success");
    setNotificationActions([
      {
        name: "OK",
        action: () => {
          handleCloseModal();
        },
      },
    ]);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: contractInformationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const contract = {
        ...values,
        awardedDate: values.awardedDate ? values.awardedDate : null,
        startDate: values.startDate ? values.startDate : null,
        endDate: values.endDate ? values.endDate : null,
        projectNumber: values.projectNumber ? values.projectNumber : null,
        isTaxExempt: values.isTaxExempt ? values.isTaxExempt === "0" : null,
        contractTypeId: parseInt(values.contractTypeId, 10) || null,
        originalContractAmount: values.originalContractAmount
          ? values.originalContractAmount === "0"
            ? 0
            : parseFloat(values.originalContractAmount)
          : null,
        originalEstimatedLaborCost: values.originalEstimatedLaborCost
          ? values.originalEstimatedLaborCost === "0"
            ? 0
            : parseFloat(values.originalEstimatedLaborCost)
          : null,
        originalEstimatedLaborHours: values.originalEstimatedLaborHours
          ? values.originalEstimatedLaborHours === "0"
            ? 0
            : parseFloat(values.originalEstimatedLaborHours)
          : null,
        originalEstimatedTotalCost: values.originalEstimatedTotalCost
          ? values.originalEstimatedTotalCost === "0"
            ? 0
            : parseFloat(values.originalEstimatedTotalCost)
          : null,
      };
      if (contract.id === 0) {
        createContract(contract, dispatchRedux, onSuccess);
      } else {
        updateContract(contract, dispatchRedux, onSuccess);
      }
    },
  });

  const deleteProject = (id) => {
    deleteProjects([parseInt(id)], companyId, dispatchRedux);
  };

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleDeleteProject = () => {
    setNotificationTitle("Delete Project");
    setNotificationText(
      "Deleting the project will erase all information. Are you sure you want to delete the project?"
    );
    setNotificationType("warning");
    setNotificationActions([
      {
        name: "DELETE PROJECT",
        action: () => {
          deleteProject(projectId);
          handleCancel();
        },
      },
      { name: "CANCEL", action: () => handleCloseModal() },
    ]);
    setIsNotificationPopUpOpen(true);
  };

  const handleCancel = () => {
    history.push("/projects");
  };

  useEffect(() => {
    if (contract && contract.id) {
      setInitialValues({
        ...contract,
        projectStatusId:
          contract.projectStatusId < 8 ? 8 : contract.projectStatusId,
        isTaxExempt: contract.isTaxExempt ? "0" : "1",
        bidValue:
          contract.bidValue === 0
            ? 0
            : contract.bidValue
            ? `${contract.bidValue}`
            : "",
        estimatedCost:
          contract.estimatedCost === 0
            ? 0
            : contract.estimatedCost
            ? `${contract.estimatedCost}`
            : "",
        estimatedLaborCost:
          contract.estimatedLaborCost === 0
            ? 0
            : contract.estimatedLaborCost
            ? `${contract.estimatedLaborCost}`
            : "",
        estimatedLaborHours:
          contract.estimatedLaborHours === 0
            ? 0
            : contract.estimatedLaborHours
            ? `${contract.estimatedLaborHours}`
            : "",
      });
    } else {
      setInitialValues(initialValuesForContract);
    }
  }, [contract]);

  useEffect(() => {
    if (projectHeaderInfo.projectStatusTypeId < 8) {
      setIsStatusDisabled(true);
    } else {
      setIsStatusDisabled(false);
    }
  }, [projectHeaderInfo.projectStatusTypeId]);

  useEffect(async () => {
    await getContractToStore(projectId, dispatchRedux);
  }, []);

  return (
    <>
      <ProjectHeader projectId={projectId} />
      <div className={classes.container}>
        <ProjectTitle
          pageTitle={"Contract Information"}
          currentStatus={initialValuesForContract.projectStatusId}
          nameOfStatusField={"projectStatusId"}
          isStatusDisabled={isStatusDisabled}
          formik={formik}
          isForContract={true}
          actions={[
            {
              name: "Delete Project",
              action: () => handleDeleteProject(),
              type: "error",
            },
            {
              name: "Cancel",
              action: () => handleCancel(),
              type: "default",
            },
            {
              name: "Save",
              action: () => formik.handleSubmit(),
              type: "success",
            },
          ]}
        />
        <Details formik={formik} />
        <Financials formik={formik} />
      </div>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
}));

export default ContractInformation;
