import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import {
  Box,
  MenuItem,
  Button,
  IconButton,
  InputAdornment,
  Slide,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";
import * as constants from "../../constants";
import AdministrationTabBar from "../elements/AdministrationTabBar";
import FileDropZone from "../elements/FileDropZone";
import IndustryTreeView from "../elements/IndustryTreeView";
import CustomTextField from "../elements/CustomTextField";
// import { ResourcesContext } from "../../contexts/ResourcesContext";
import {
  getIndustriesDataToStore,
  getStatesDataToStore,
  getCountriesDataToStore,
  getStatusesDataToStore,
} from "../../actions/resourcesAction";
import StatusSelect from "../elements/StatusSelect";
import { checkPermission } from "../../utils/helperFunctions";
import * as store from "../../state";

const CompanyForm = (props) => {
  const {
    onSubmit,
    initialValues,
    handleCancel,
    page,
    view,
    id,
    setOpen,
    setNotificationActions,
    setNotificationTitle,
    setNotificationText,
  } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  //const { dispatch, state } = useContext(ResourcesContext);
  const divisionStatuses = useSelector(
    store.selectors.resource.selectDivisionStatuses
  );
  const industries = useSelector(store.selectors.resource.selectIndustries);
  const states = useSelector(store.selectors.resource.selectStates);
  const countries = useSelector(store.selectors.resource.selectCountries);
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const { roleId } = loggedInUser;
  const history = useHistory();

  const [successUpdate, setSuccessUpdate] = useState(false);
  const [availableStatusesForSelection, setAvailableStatusesForSelection] =
    useState([]);

  const getAvailableStatusesByStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return [statusId, 2];
      case 2:
        return [statusId, 1];
      default:
        return [statusId];
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      let { phone } = values;
      if (values.phone && values.phone !== "") {
        phone = values.phone.replace(/[(|)| |-]/g, "");
      }

      onSubmit({ ...values, phone }, () => {
        resetForm({
          values,
          touched: { address: true },
        });
        setSuccessUpdate(true);
        setTimeout(() => {
          setSuccessUpdate(false);
        }, 5000);
      });
    },
  });

  useEffect(() => {
    if (
      Array.isArray(divisionStatuses) &&
      divisionStatuses.length > 0 &&
      initialValues.statusId
    ) {
      const availableStatuses = divisionStatuses.filter((status) =>
        getAvailableStatusesByStatus(initialValues.statusId).includes(status.id)
      );
      setAvailableStatusesForSelection(availableStatuses);
    } else {
      setAvailableStatusesForSelection([]);
    }
  }, [divisionStatuses, initialValues]);

  useEffect(() => {
    if (
      states.length > 0 &&
      states.filter((state) => state.id === formik.values.stateId).length === 0
    ) {
      formik.setFieldValue("stateId", "");
    }
  }, [states]);

  useEffect(async () => {
    // getIndustries(dispatch);
    // getStates(dispatch);
    // getCounties(dispatch);
    // getCountries(dispatch);
    // getStatuses(dispatch);

    await getIndustriesDataToStore({ dispatchRedux });
    await getCountriesDataToStore({ dispatchRedux });
    await getStatusesDataToStore({ dispatchRedux });
  }, []);

  useEffect(async () => {
    //getStates(dispatch, formik.values.country);
    await getStatesDataToStore(formik.values.countryId, { dispatchRedux });
  }, [formik.values.countryId]);

  const handleClick = (e) => {
    formik.setFieldValue("industryClassificationId", e.target.value);
  };

  const handleCompanyClick = () => {
    if (formik.dirty) {
      setOpen(true);
    }
    history.push(
      view === "sysAdmin" ? `/systemAdministration/company/${id}` : "/company"
    );
  };
  // THIS HAS TO BE CHANGED ACCORDINGLY
  const handleSubsidiaryClick = () => {
    history.push(
      view === "sysAdmin"
        ? `/systemAdministration/subsidiaries/${id}`
        : "/subsidiaries"
    );
  };
  const handleDivisionClick = () => {
    history.push(
      view === "sysAdmin"
        ? `/systemAdministration/divisions/${id}`
        : "/divisions"
    );
  };
  const handleUsersClick = () => {
    history.push(
      view === "sysAdmin" ? `/systemAdministration/users/${id}` : "/users"
    );
  };
  const handleAccountSettingsClick = () => {
    history.push(
      view === "sysAdmin"
        ? `/systemAdministration/company/accountSettings/${id}`
        : "/"
    );
  };
  const handleInterconnectionClick = () => {
    history.push(view === "sysAdmin" ? "/systemAdministration" : "/");
  };

  const handleChangeRoute = (actionFunction) => {
    if (formik.dirty) {
      setOpen(true);
      setNotificationText(
        "We detected unsaved changes. Leaving this page will discard all changes. Do you want to continue? "
      );
      setNotificationTitle("Unsaved Changes");
      setNotificationActions([
        {
          name: "CONFIRM",
          action: actionFunction,
        },
        {
          name: "CANCEL",
          action: () => {
            setOpen(false);
          },
        },
      ]);
    } else {
      actionFunction();
    }
  };

  return (
    <div>
      <form>
        <div>
          {page === "edit" && (
            <>
              <AdministrationTabBar
                page="company"
                view={view}
                id={id}
                handleCompanyClick={() => handleChangeRoute(handleCompanyClick)}
                handleSubsidiaryClick={() =>
                  handleChangeRoute(handleSubsidiaryClick)
                }
                handleDivisionClick={() =>
                  handleChangeRoute(handleDivisionClick)
                }
                handleUsersClick={() => handleChangeRoute(handleUsersClick)}
                handleAccountSettingsClick={() =>
                  handleChangeRoute(handleAccountSettingsClick)
                }
                handleInterconnectionClick={() =>
                  handleChangeRoute(handleInterconnectionClick)
                }
              />
              <Box className={classes.pageSubTitle}>
                {`${formik.values.name}: Account Information`}
              </Box>
            </>
          )}
          <div className={classes.topContainer}>
            <div className={clsx(classes.logoContainer)}>
              <FileDropZone formik={formik} />
              {formik.touched.logo && Boolean(formik.errors.logo) && (
                <Box className={classes.asterisk}>{formik.errors.logo}</Box>
              )}
            </div>
            <div>
              {page !== "add" &&
              view === "sysAdmin" &&
              checkPermission([1], roleId) ? (
                <div className={classes.flexRow}>
                  <div
                    className={clsx(classes.inputContainer, classes.flexCol)}
                  >
                    <Box className={classes.label}>Company Name*</Box>
                    <CustomTextField
                      name="name"
                      label="Enter Company Name"
                      formik={formik}
                    />
                    <Box className={classes.label}>
                      Industry Classification*
                    </Box>
                    <CustomTextField
                      name="industryClassificationId"
                      label=""
                      formik={formik}
                      style={{ display: "none" }}
                    />
                    <IndustryTreeView
                      value={formik.values.industryClassificationId}
                      errors={formik.errors.industryClassificationId}
                      touched={formik.touched.industryClassificationId}
                      industries={industries}
                      handleClick={handleClick}
                    />
                  </div>
                  <div className={clsx(classes.flexCol, classes.width30)}>
                    <StatusSelect
                      disabled={initialValues.statusId === 3}
                      value={formik.values.statusId}
                      options={availableStatusesForSelection}
                      handleChange={formik.handleChange}
                      error={
                        formik.touched.statusId &&
                        Boolean(formik.errors.statusId)
                      }
                      helperText={
                        formik.touched.statusId && formik.errors.statusId
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className={clsx(classes.inputContainer)}>
                  <Box className={classes.label}>Company Name*</Box>
                  <CustomTextField
                    name="name"
                    label="Enter Company Name"
                    formik={formik}
                  />
                  <Box className={classes.label}>Industry Classification*</Box>
                  <CustomTextField
                    name="industryClassificationId"
                    label=""
                    formik={formik}
                    style={{ display: "none" }}
                  />
                  <IndustryTreeView
                    value={formik.values.industryClassificationId}
                    errors={formik.errors.industryClassificationId}
                    touched={formik.touched.industryClassificationId}
                    industries={industries}
                    handleClick={handleClick}
                  />
                </div>
              )}
            </div>
            <div className={classes.buttonGoup}>
              <Button
                variant="contained"
                className={classes.saveButton}
                onClick={() => formik.handleSubmit()}
              >
                {page === "add" ? "Add Account" : "Save"}
              </Button>
              {page === "add" && (
                <Button
                  variant="contained"
                  className={classes.cancelButton}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
          <div className={classes.inputContainer}>
            <Box className={classes.label}>Contact Information</Box>
            <CustomTextField
              name="address"
              label="Enter Street Address*"
              formik={formik}
            />
            <div className={classes.flexRow}>
              <div className={clsx(classes.flexCol, classes.width50)}>
                <CustomTextField
                  name="city"
                  label="Enter City Name*"
                  formik={formik}
                />
                <CustomTextField
                  name="phone"
                  label="Enter Phone Number"
                  formik={formik}
                />
              </div>
              <div className={clsx(classes.flexCol, classes.width50)}>
                <div className={classes.flexRow}>
                  <CustomTextField
                    name="zipCode"
                    label="Enter Zip Code*"
                    formik={formik}
                    style={{ width: "50%" }}
                  />
                  <CustomTextField
                    name="stateId"
                    label="Select State*"
                    formik={formik}
                    style={{ width: "50%" }}
                    disabled={!formik.values.countryId}
                    select
                    options={states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  />
                </div>
                <CustomTextField
                  name="website"
                  label="Enter Website"
                  formik={formik}
                />
              </div>
            </div>
            <CustomTextField
              name="countryId"
              label="Select Country*"
              formik={formik}
              select
              options={countries.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            />
          </div>
          {page === "edit" && (
            <Slide
              timeout={{ enter: 500, exit: 500 }}
              direction="left"
              in={successUpdate}
              mountOnEnter
              unmountOnExit
            >
              <Paper elevation={4} className={classes.notificationPaper}>
                <CheckCircleIcon className={classes.icon} />
                <Box className={classes.successNotificationIconText}>
                  Account Successfully Updated
                </Box>
              </Paper>
            </Slide>
          )}
        </div>
        {page === "add" && (
          <div>
            <Box className={classes.label}>Account Settings*</Box>
            <div className={classes.flexCol}>
              <div className={clsx(classes.flexRow)}>
                <Box className={classes.label}>User Limit*</Box>
                <CustomTextField
                  name="maxNumberOfUsers"
                  label="Enter Number of Users"
                  formik={formik}
                  InputProps={{
                    endAdornment: returnEndAdornment(
                      formik,
                      "maxNumberOfUsers"
                    ),
                  }}
                />
              </div>
            </div>
            <div className={clsx(classes.flexRow)}>
              <Box className={classes.label}>Subsidiaries*</Box>
              <CustomTextField
                name="maxNumberOfSubsidiaries"
                label="Enter Number of Subsidiaries"
                formik={formik}
                InputProps={{
                  endAdornment: returnEndAdornment(
                    formik,
                    "maxNumberOfSubsidiaries"
                  ),
                }}
              />
              <Box className={clsx(classes.label, classes.marginLeft)}>
                Divisions*
              </Box>
              <CustomTextField
                name="maxNumberOfDivisions"
                label="Enter Number of Divisions"
                formik={formik}
                InputProps={{
                  endAdornment: returnEndAdornment(
                    formik,
                    "maxNumberOfDivisions"
                  ),
                }}
              />
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

const returnEndAdornment = (formik, field) => (
  <InputAdornment position="end">
    <IconButton
      disableRipple
      disabled={parseInt(formik.values[field]) === 1}
      id="decrease-value"
      onClick={() => {
        formik.setFieldValue(
          `${field}`,
          `${parseInt(formik.values[field]) - 1}`
        );
      }}
    >
      <ArrowDropDownIcon />
    </IconButton>
    <IconButton
      disableRipple
      id="increase-value"
      disabled={parseInt(formik.values[field]) >= 99999}
      onClick={() => {
        formik.setFieldValue(
          `${field}`,
          `${parseInt(formik.values[field]) + 1}`
        );
      }}
    >
      <ArrowDropUpIcon />
    </IconButton>
  </InputAdornment>
);

const validationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required").max(40),
  address: yup
    .string("Enter your address")
    .max(255, "Maximum number of characters")
    .required("Address is required"),
  address2: yup.string().max(255),
  city: yup.string("Enter city name").required("City is required"),
  stateId: yup
    .string()
    .required("State is required")
    .matches(/^[0-9]*$/, "Please enter valid State"),
  countryId: yup
    .string()
    .required("Country is required")
    .matches(/^[0-9]*$/, "Please enter valid Country"),
  zipCode: yup.string().required("Zip Code is required"),
  phone: yup
    .string()
    .matches(constants.PHONE_REG_EXP, "Follow the format (AAA) XXX-YYYY AAA"),
  website: yup
    .string()
    .nullable()
    .matches(constants.WEBSITE_REG_EXP, "Please enter a valid website URL"),
  industryClassificationId: yup
    .number()
    .typeError("Industry classification ID is required")
    .required("Industry classification ID is required"),
  logo: yup
    .mixed()
    .test("fileSize", "File Size is too large", (value) => {
      if (typeof value === "object" && value != null) {
        return value.size <= constants.FILE_SIZE;
      }
      return true;
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (typeof value === "object" && value != null) {
        return constants.SUPPORTED_FORMATS.includes(value.type);
      }
      return true;
    }),
  maxNumberOfUsers: yup
    .number()
    .positive()
    .integer()
    .typeError("The value must be a number.")
    .min(1)
    .max(99999)
    .required("Max number of user seats is required"),
  maxNumberOfSubsidiaries: yup
    .number()
    .positive()
    .integer()
    .typeError("The value must be a number.")
    .min(1)
    .max(99999)
    .required("Max number of subsidiaries is required"),
  maxNumberOfDivisions: yup
    .number()
    .positive()
    .integer()
    .typeError("The value must be a number.")
    .min(1)
    .max(99999)
    .required("Max number of divisions is required"),
});

const useStyles = makeStyles((theme) => ({
  cardContent: {
    maxHeight: "40rem",
    overflowY: "scroll",
  },
  inputContainer: {
    padding: "1rem 0",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginBottom: "1rem",
    width: "35rem",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.5rem 1.5rem 0.5rem 0",
    fontWeight: "bold",
    minWidth: "100px",
  },
  treeView: {
    margin: "0.5rem 0 0 0",
    borderColor: "rgba(0, 0, 0, 0.26)",
  },
  asterisk: {
    color: constants.ASTERISK_COLOR,
  },
  button: {
    backgroundColor: theme.palette.info.light,
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  width50: {
    width: "50%",
  },
  width30: {
    width: "30%",
  },
  width70: {
    width: "70%",
  },
  logoContainer: {
    padding: "1rem 0",
  },
  marginTop: {
    marginTop: "4rem",
  },
  buttonGoup: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-end",
    "& button": {
      width: "10rem",
    },
  },
  cancelButton: {
    backgroundColor: "#CBD5E0",
  },
  saveButton: {
    backgroundColor: "#3182CE",
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#4A5568",
  },
  notificationPaper: {
    display: "flex",
    flexDirection: "row",
    padding: "1rem",
    alignItems: "center",
    height: "4rem",
    width: "24rem",
    borderRadius: "4px",
    zIndex: "1000",
    position: "relative",
    bottom: "6rem",
    left: "37rem",
  },
  icon: {
    fontSize: 30,
    color: "#AFDA95",
  },
  successNotificationIconText: {
    fontSize: "16px",
    fontWeight: "bold",
    marginLeft: "2rem",
  },
  marginLeft: {
    marginLeft: "2rem",
  },
}));

export default CompanyForm;
