import * as actions from "../action/resource";

const initialState = {
  states: [],
  counties: [],
  countries: [],
  industries: {
    childIndustries: [],
  },
  marketSegments: [],
  marketCategories: [],
  marketSubcategories: [],
  projectStatusTypes: [],
  workTypes: [],
  contractTypes: [],
  contactTypes: [],
  jobTypes: [],
  divisionStatuses: [],
};

const resourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RESOURCE_SET_DIVISION_STATUSES:
      return {
        ...state,
        divisionStatuses: action.payload,
      };
    case actions.RESOURCE_SET_INDUSTRIES:
      return { ...state, industries: action.payload };
    case actions.RESOURCE_SET_JOB_TYPES:
      return { ...state, jobTypes: action.payload };
    case actions.RESOURCE_SET_CONTACT_TYPES:
      return { ...state, contactTypes: action.payload };
    case actions.RESOURCE_SET_CONTRACT_TYPES:
      return { ...state, contractTypes: action.payload };
    case actions.RESOURCE_SET_WORK_TYPES:
      return { ...state, workTypes: action.payload };
    case actions.RESOURCE_SET_PROJECT_STATUS_TYPES:
      return { ...state, projectStatusTypes: action.payload };
    case actions.RESOURCE_SET_MARKET_SUBCATEGORIES:
      return { ...state, marketSubcategories: action.payload };
    case actions.RESOURCE_SET_MARKET_CATEGORIES:
      return { ...state, marketCategories: action.payload };
    case actions.RESOURCE_SET_MARKET_SEGMENTS:
      return { ...state, marketSegments: action.payload };
    case actions.RESOURCE_SET_CONTRIES:
      return { ...state, countries: action.payload };
    case actions.RESOURCE_SET_COUNTIES:
      return { ...state, counties: action.payload };
    case actions.RESOURCE_SET_STATES:
      return { ...state, states: action.payload };
    default:
      return state;
  }
};

export default resourceReducer;
