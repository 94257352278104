import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, IconButton, Box } from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ClearIcon from "@material-ui/icons/Clear";

import { getIndustryTitle } from "../../utils/helperFunctions";

const IndustriesTreeView = (props) => {
  const { industries, handleClick, value, errors, touched } = props;
  const [selected, setSelected] = useState(Boolean(value));
  const classes = useStyles();

  useEffect(() => {
    setSelected(Boolean(value));
  }, [value]);

  const handleUnselectClick = () => {
    const mockEvent = { target: { value: null } };
    handleClick(mockEvent);
    setSelected(false);
  };
  const handleSeletedClick = (e) => {
    handleClick(e);
    setSelected(true);
  };
  return (
    <div style={{ marginTop: "0.5rem" }}>
      {selected ? (
        <div className={classes.selected}>
          <IconButton size="small" onClick={handleUnselectClick}>
            <ClearIcon />
          </IconButton>
          <span>
            {industries.id && value && getIndustryTitle(value, industries)}
          </span>
        </div>
      ) : (
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {props.industries.childIndustries.length
            ? displayIndustriesTree(industries, handleSeletedClick)
            : ""}
        </TreeView>
      )}
      {Boolean(errors) && touched && (
        <Box className={classes.errorMessage}>{errors}</Box>
      )}
    </div>
  );
};

const displayIndustriesTree = (industries, handleClick) => {
  if (industries.childIndustries.length === 0) {
    return (
      <MenuItem key={industries.id} value={industries.id} onClick={handleClick}>
        {`${industries.code} - ${industries.title}`}
      </MenuItem>
    );
  }
  return (
    <TreeItem
      nodeId={industries.code}
      key={industries.code}
      label={`${industries.code} - ${industries.title}`}
    >
      {industries.childIndustries.map((industry) => (
        <>{displayIndustriesTree(industry, handleClick)}</>
      ))}
    </TreeItem>
  );
};

const useStyles = makeStyles({
  root: {
    "& .MuiMenuItem-root": {
      whiteSpace: "initial",
      paddingBottom: "3px",
      paddingTop: "3px",
      lineHeight: "1.25",
    },
  },
  errorMessage: {
    fontSize: "12px",
    color: "#FF0000",
  },
});

export default IndustriesTreeView;
