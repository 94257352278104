import * as API from "./_apiCalls";
import * as store from "../state";

export const getJpacProjectsDataToStore = async (
  jpacCompanyId,
  dispatchRedux
) => {
  const baseUrl = `/project/getJpacProjects?jpacCompanyId=${jpacCompanyId}`;
  const jpacData = await API.getData(baseUrl);

  dispatchRedux(store.actions.interconnection.setJPACProjects(jpacData.data));

  return jpacData.data;
};

export const getProjectInfoFromJpacDataToStore = async (
  jpacProjectId,
  dispatchRedux
) => {
  const baseUrl = `/project/getProjectInfoFromJpac?jpacProjectId=${jpacProjectId}`;
  const data = await API.getData(baseUrl);

  dispatchRedux(store.actions.interconnection.setJPACProjectInfo(data));

  return data;
};
