import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkPermission } from "../../utils/helperFunctions";
import * as store from "../../state";

const AppRoutes = (props) => {
  const { allowedRoles, render, path, exact, ...rest } = props;
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const refreshToken = localStorage.getItem("refreshToken");
  const history = useHistory();

  useEffect(() => {
    if (!refreshToken) {
      history.push("/login");
    } else if (loggedInUser.roleId === 1 && loggedInUser.userCompany === null) {
      history.push("/systemAdministration");
    } else if (
      Boolean(loggedInUser.roleId) &&
      Boolean(allowedRoles) &&
      !checkPermission(allowedRoles, loggedInUser.roleId)
    ) {
      history.push("/projects");
    }
  }, [path, loggedInUser]);

  return (
    <>
      <Route
        path={path}
        exact={!!exact}
        render={(props) => render(props)}
        {...rest}
      />
    </>
  );
};

export default AppRoutes;
