import * as yup from "yup";
import * as constants from "../../../constants";
import { formatDatePicker } from "../../../utils/helperFunctions";

const validationSchemaNew = yup.object({
  // general information
  projectStatusTypeId: yup.number(),
  name: yup
    .string("Enter the Name")
    .required("Name is required")
    .max(35, "Maximum of 35 characters allowed"),
  projectNumber: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 8,
    then: yup
      .string()
      .required("Project Number is required")
      .min(1, "At least one character needed")
      .max(35, "Maximum of 35 characters allowed"),
    otherwise: yup
      .string()
      .min(1, "At least one character needed")
      .max(35, "Maximum of 35 characters allowed"),
  }),
  subsidiaryId: yup
    .string()
    .required("Subsidiary is required")
    .matches(/^[0-9']*$/, "Please enter valid subsidiary"),
  divisionId: yup
    .string()
    .required("Division is required")
    .matches(/^[0-9']*$/, "Please enter valid division"),
  description: yup
    .string("Enter a Description")
    .required("Description is required")
    .max(1000, "Maximum of 1000 characters allowed"),
  customer: yup.mixed("Select a customer").required("Customer is required"),
  // project Details
  expectedStartDate: yup.date().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .date("Value must be a valid date")
      .required("Start Date is required"),
    otherwise: yup.date("Value must be a valid date"),
  }),
  expectedEndDate: yup.date().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .date("Value must be a valid date")
      .min(yup.ref("expectedStartDate"), " End date can't be before start date")
      .required("End Date is required"),
    otherwise: yup
      .date("Value must be a valid date")
      .min(
        yup.ref("expectedStartDate"),
        " End date can't be before start date"
      ),
  }),
  address: yup
    .string("Enter your address")
    .max(255, "Please fill second address line")
    .matches(/^[A-Za-z0-9',.\- ]*$/, "Please enter valid Adress"),
  address2: yup
    .string()
    .max(255)
    .matches(/^[A-Za-z0-9',.\- ]*$/, "Please enter valid Adress"),
  city: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .string("Enter city name")
      .required("City is required")
      .matches(/^[A-Za-z0-9'.\- ]*$/, "Please enter valid City Name"),
    otherwise: yup
      .string("Enter city name")
      .matches(/^[A-Za-z0-9'.\- ]*$/, "Please enter valid City Name"),
  }),
  country: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .string()
      .required("Country is required")
      .matches(/^[0-9']*$/, "Please enter valid Country"),
    otherwise: yup.string().matches(/^[0-9']*$/, "Please enter valid Country"),
  }),
  county: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .string()
      .required("County is required")
      .matches(/^[0-9']*$/, "Please enter valid County"),
    otherwise: yup.string().matches(/^[0-9']*$/, "Please enter valid County"),
  }),
  state: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .string()
      .required("State is required")
      .matches(/^[0-9]*$/, "Please enter valid State"),
    otherwise: yup.string().matches(/^[0-9]*$/, "Please enter valid State"),
  }),
  zipCode: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .string()
      .required("Zip Code is required")
      .matches(/^[1-9][0-9]{4}$/, "Must be a valid zip code"),
    otherwise: yup
      .string()
      .matches(/^[1-9][0-9]{4}$/, "Must be a valid zip code"),
  }),
  typeOfWorkId: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .string()
      .required("Must select the type of work")
      .oneOf(["1", "2"]),
    otherwise: yup.string().oneOf(["1", "2"]),
  }),
  constructionTypeId: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .string()
      .required("Must select the type of construction")
      .oneOf(["1", "2"]),
    otherwise: yup.string().oneOf(["1", "2"]),
  }),
  marketSectorId: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup
      .string()
      .required("Must select the market sector")
      .oneOf(["1", "2"]),
    otherwise: yup.string().oneOf(["1", "2"]),
  }),
  isMinorityParticipationGoal: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup.string().required("Minority Participation is required"),
    otherwise: yup.string(),
  }),
  marketSegmentId: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup.string().nullable().required("Market segment is required"),
    otherwise: yup.string().nullable(),
  }),
  marketCategoryId: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup.string().nullable().required("Market category is required"),
    otherwise: yup.string().nullable(),
  }),
  marketSubcategoryId: yup.string().when("projectStatusTypeId", {
    is: (value) => value >= 2,
    then: yup.string().nullable().required("Market subcategory is required"),
    otherwise: yup.string().nullable(),
  }),
  // estimate/bidding
  bidDueDate: yup.date().when("projectStatusTypeId", {
    is: (value) => value >= 4,
    then: yup
      .date()
      .typeError("Value must be a valid date")
      .required("Due Date is required"),
    otherwise: yup.date().typeError("Value must be a valid date"),
  }),
  notes: yup.string().max(1000, "Maximum of 1000 characters allowed"),
  leadEstimator: yup.object().when("projectStatusTypeId", {
    is: (value) => value >= 4,
    then: yup
      .object()
      .typeError("Lead Estimator is required")
      .required("Lead Estimator is required"),
    otherwise: yup.object().nullable(),
  }),
  leadProjectManager: yup.object().when("projectStatusTypeId", {
    is: (value) => value >= 4,
    then: yup
      .object()
      .typeError("Lead Project Manager is required")
      .required("Lead Project Manager is required"),
    otherwise: yup.object().nullable(),
  }),
  projectCoordinators: yup.array().of(yup.object()),
  projectManagers: yup.array().of(yup.object()),
  estimators: yup.array().of(yup.object()),
  bidValue: yup.number().when("projectStatusTypeId", {
    is: (value) => value >= 6,
    then: yup
      .number()
      .required("Bid Value is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  estimatedCost: yup.number().when("projectStatusTypeId", {
    is: (value) => value >= 6,
    then: yup
      .number()
      .required("Estimated Cost is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  estimatedLaborCost: yup.number().when("projectStatusTypeId", {
    is: (value) => value >= 6,
    then: yup
      .number()
      .required("Estimated Labor Cost is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  estimatedLaborHours: yup.number().when("projectStatusTypeId", {
    is: (value) => value >= 6,
    then: yup
      .number()
      .required("Estimated Labor Hours is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  //contract information
  awardedDate: yup.date().when("projectStatusTypeId", {
    is: (value) => value >= 8,
    then: yup
      .date("Value must be a valid date")
      .max(formatDatePicker(Date.now() + 1), "Awarded date must be in the past")
      .required("Awarded Date is required"),
    otherwise: yup
      .date("Value must be a valid date")
      .max(
        formatDatePicker(Date.now() + 1),
        "Awarded date must be in the past"
      ),
  }),
  contractType: yup.number().when("projectStatusTypeId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Contract Type is required")
      .typeError("Please enter a valid choice")
      .integer("Select a valid contract type"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid choice")
      .integer("Select a valid contract type"),
  }),
  taxExempt: yup.string().oneOf(["0", "1"]),
  startDate: yup.date().when("projectStatusTypeId", {
    is: (value) => value >= 8,
    then: yup
      .date("Value must be a valid date")
      .required("Start Date is required"),
    otherwise: yup.date("Value must be a valid date"),
  }),
  endDate: yup.date().when("projectStatusTypeId", {
    is: (value) => value >= 8,
    then: yup
      .date("Value must be a valid date")
      .min(yup.ref("startDate"), " End date can't be before start date")
      .required("End Date is required"),
    otherwise: yup
      .date("Value must be a valid date")
      .min(yup.ref("startDate"), " End date can't be before start date"),
  }),
  originalContractAmount: yup.number().when("projectStatusTypeId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Original Contract Amount is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  originalEstimatedTotalCost: yup.number().when("projectStatusTypeId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Original Estimated Total Cost is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  originalEstimatedLaborCost: yup.number().when("projectStatusTypeId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Original Estimated Labor Cost is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  originalEstimatedLaborHours: yup.number().when("projectStatusTypeId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Original Estimated Labor Hours is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  // contacts
  contactType: yup.number().integer("Select a valid contact type"),
  companyName: yup
    .string("Enter name")
    .matches(/^[A-Za-z0-9/,'_` ]*$/, "Please enter valid Name")
    .max(40),
  firstName: yup
    .string("Enter name")
    .matches(/^[A-Za-z]*$/, "Please enter valid Name")
    .max(40),
  lastName: yup
    .string("Enter name")
    .matches(/^[A-Za-z]*$/, "Please enter valid Name")
    .max(40),
  jobTitle: yup
    .string("Enter job title")
    .matches(/^[A-Za-z0-9/,'_` ]*$/, "Please enter valid Job Title")
    .max(40),
  contactPhone: yup
    .string()
    .matches(constants.PHONE_REG_EXP, "Please enter a valid phone number"),
  contactEmail: yup
    .string()
    .matches(constants.EMAIL_REG_EXP, "Please enter a valid email"),
  contactNotes: yup
    .string("Enter a Note")
    .max(1000, "Maximum of 1000 characters allowed"),
  // dci interconnection
  JpacProjectId: yup
    .number()
    .integer("Select a valid project number")
    .nullable(),
});

export default validationSchemaNew;

export const projectDetailsValidationSchema = yup.object({
  // general information
  projectStatusTypeId: yup.number(),
  name: yup
    .string("Enter the Name")
    .required("Name is required")
    .max(35, "Maximum of 35 characters allowed"),
  subsidiaryId: yup
    .string()
    .required("Subsidiary is required")
    .matches(/^[0-9']*$/, "Please enter valid subsidiary"),
  divisionId: yup
    .string()
    .required("Division is required")
    .matches(/^[0-9']*$/, "Please enter valid division"),
  description: yup
    .string("Enter a Description")
    .required("Description is required")
    .max(1000, "Maximum of 1000 characters allowed"),
  customer: yup.mixed("Select a customer").required("Customer is required"),
  // project Details
  expectedStartDate: yup.date().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup
      .date("Value must be a valid date")
      .required("Start Date is required"),
    otherwise: yup.date("Value must be a valid date"),
  }),
  expectedEndDate: yup.date().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup
      .date("Value must be a valid date")
      .min(yup.ref("expectedStartDate"), " End date can't be before start date")
      .required("End Date is required"),
    otherwise: yup
      .date("Value must be a valid date")
      .min(
        yup.ref("expectedStartDate"),
        " End date can't be before start date"
      ),
  }),
  address: yup
    .string("Enter your address")
    .max(255, "Please fill second address line")
    .nullable()
    .matches(/^[A-Za-z0-9',.\- ]*$/, "Please enter valid Adress"),
  address2: yup
    .string()
    .max(255)
    .nullable()
    .matches(/^[A-Za-z0-9',.\- ]*$/, "Please enter valid Adress"),
  city: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup
      .string("Enter city name")
      .required("City is required")
      .matches(/^[A-Za-z0-9'.\- ]*$/, "Please enter valid City Name"),
    otherwise: yup
      .string("Enter city name")
      .matches(/^[A-Za-z0-9'.\- ]*$/, "Please enter valid City Name"),
  }),
  countryId: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup
      .string()
      .required("Country is required")
      .matches(/^[0-9']*$/, "Please enter valid Country"),
    otherwise: yup.string().matches(/^[0-9']*$/, "Please enter valid Country"),
  }),
  countyId: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup
      .string()
      .required("County is required")
      .matches(/^[0-9']*$/, "Please enter valid County"),
    otherwise: yup.string().matches(/^[0-9']*$/, "Please enter valid County"),
  }),
  stateId: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup
      .string()
      .required("State is required")
      .matches(/^[0-9]*$/, "Please enter valid State"),
    otherwise: yup.string().matches(/^[0-9]*$/, "Please enter valid State"),
  }),
  postalCode: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup
      .string()
      .required("Zip Code is required")
      .matches(/^[1-9][0-9]{4}$/, "Must be a valid zip code"),
    otherwise: yup
      .string()
      .matches(/^[1-9][0-9]{4}$/, "Must be a valid zip code"),
  }),
  constructionTypeId: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup
      .string()
      .required("Must select the type of construction")
      .oneOf(["1", "2"]),
    otherwise: yup.string(),
  }),
  marketSectorId: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup
      .string()
      .required("Must select the market sector")
      .oneOf(["1", "2"]),
    otherwise: yup.string(),
  }),
  isMinorityParticipationGoal: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup.string().required("Minority Participation is required"),
    otherwise: yup.string(),
  }),
  marketSegmentId: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup.string().required("Market segment is required"),
    otherwise: yup.string(),
  }),
  marketCategoryId: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup.string().required("Market category is required"),
    otherwise: yup.string(),
  }),
  marketSubcategoryId: yup.string().when("projectStatusTypeId", {
    is: (value) => value > 1,
    then: yup.string().required("Market subcategory is required"),
    otherwise: yup.string(),
  }),
});

export const proposalDetailsSchema = yup.object({
  bidDueDate: yup.date().when("statusTypeId", {
    is: (value) => value >= 4,
    then: yup
      .date()
      .typeError("Value must be a valid date")
      .required("Bid Due Date is required"),
    otherwise: yup.date().typeError("Value must be a valid date"),
  }),
  notes: yup
    .string()
    .max(1000, "Maximum of 1000 characters allowed")
    .required("Notes are required"),
  description: yup
    .string()
    .max(1000, "Maximum of 1000 characters allowed")
    .required("Description is required"),
  proposalWorkTypes: yup
    .array()
    .of(yup.object())
    .min(1, "Project Type is required")
    .typeError("Project type is required")
    .required("Project type is required"),
  leadEstimator: yup.object().when("statusTypeId", {
    is: (value) => value >= 4,
    then: yup
      .object()
      .typeError("Lead Estimator is required")
      .required("Lead Estimator is required"),
    otherwise: yup.object().nullable(),
  }),
  leadProjectManager: yup.object().when("statusTypeId", {
    is: (value) => value >= 4,
    then: yup
      .object()
      .typeError("Lead Project Manager is required")
      .required("Lead Project Manager is required"),
    otherwise: yup.object().nullable(),
  }),
  projectCoordinators: yup.array().of(yup.object()),
  projectManagers: yup.array().of(yup.object()),
  estimators: yup.array().of(yup.object()),
  bidValue: yup.number().when("statusTypeId", {
    is: (value) => value >= 6,
    then: yup
      .number()
      .required("Bid Value is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  estimatedCost: yup.number().when("statusTypeId", {
    is: (value) => value >= 6,
    then: yup
      .number()
      .required("Estimated Cost is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  estimatedLaborCost: yup.number().when("statusTypeId", {
    is: (value) => value >= 6,
    then: yup
      .number()
      .required("Estimated Labor Cost is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  estimatedLaborHours: yup.number().when("statusTypeId", {
    is: (value) => value >= 6,
    then: yup
      .number()
      .required("Estimated Labor Hours is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
});

export const contractInformationSchema = yup.object({
  projectNumber: yup.string().when("projectStatusId", {
    is: (value) => value >= 8,
    then: yup
      .string()
      .required("Project Number is required")
      .min(1, "At least one character needed")
      .max(35, "Maximum of 35 characters allowed"),
    otherwise: yup
      .string()
      .nullable()
      .min(1, "At least one character needed")
      .max(35, "Maximum of 35 characters allowed"),
  }),
  awardedDate: yup.date().when("projectStatusId", {
    is: (value) => value >= 8,
    then: yup
      .date("Value must be a valid date")
      .max(formatDatePicker(Date.now() + 1), "Awarded date must be in the past")
      .required("Awarded Date is required"),
    otherwise: yup
      .date("Value must be a valid date")
      .max(
        formatDatePicker(Date.now() + 1),
        "Awarded date must be in the past"
      ),
  }),
  contractTypeId: yup.number().when("projectStatusId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Contract Type is required")
      .typeError("Please enter a valid choice")
      .integer("Select a valid contract type"),
    otherwise: yup
      .number()
      .nullable()
      .typeError("Please enter a valid choice")
      .integer("Select a valid contract type"),
  }),
  isTaxExempt: yup.string().required("Please select a choice."),
  startDate: yup.date().when("projectStatusId", {
    is: (value) => value >= 8,
    then: yup
      .date("Value must be a valid date")
      .required("Start Date is required"),
    otherwise: yup.date("Value must be a valid date"),
  }),
  endDate: yup.date().when("projectStatusId", {
    is: (value) => value >= 8,
    then: yup
      .date("Value must be a valid date")
      .min(yup.ref("startDate"), " End date can't be before start date")
      .required("End Date is required"),
    otherwise: yup
      .date("Value must be a valid date")
      .min(yup.ref("startDate"), " End date can't be before start date"),
  }),
  originalContractAmount: yup.number().when("projectStatusId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Original Contract Amount is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  originalEstimatedTotalCost: yup.number().when("projectStatusId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Original Estimated Total Cost is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  originalEstimatedLaborCost: yup.number().when("projectStatusId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Original Estimated Labor Cost is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
  originalEstimatedLaborHours: yup.number().when("projectStatusId", {
    is: (value) => value >= 8,
    then: yup
      .number()
      .required("Original Estimated Labor Hours is required")
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
    otherwise: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Min value 0.")
      .max(999999999.99, "Max value 999999999.99"),
  }),
});
