import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as constants from "../../../../constants";
import PhoneInputMask from "../../../elements/PhoneInputMask";

const PersonAutocompleteAdd = (props) => {
  const { open, handleClose, title, customerId, onSubmit } = props;

  const classes = useStyles();
  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      customerId,
      firstName: "",
      lastName: "",
      jobTitle: "",
      phone: "",
      email: "",
      notes: "",
    },
    onSubmit: (values) => {
      let { phone } = values;
      if (values.phone && values.phone !== "") {
        phone = values.phone.replace(/[(|)| |-]/g, "");
      }

      onSubmit({ ...values, phone });
      handleClose();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <form style={{ minWidth: "30rem" }}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <>
              <Box className={classes.label}>First Name</Box>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                id="firstName"
                name="firstName"
                select={false}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <Box className={classes.label}>Last Name</Box>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                id="lastName"
                name="lastName"
                select={false}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />

              <Box className={classes.label}>Job Title</Box>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                id="jobTitle"
                name="jobTitle"
                select={false}
                onBlur={formik.handleBlur}
                value={formik.values.jobTitle}
                onChange={formik.handleChange}
                error={
                  formik.touched.jobTitle && Boolean(formik.errors.jobTitle)
                }
                helperText={formik.touched.jobTitle && formik.errors.jobTitle}
              />
              <Box className={classes.label}>Email</Box>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                id="email"
                name="email"
                select={false}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Box className={classes.label}>Phone #</Box>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                id="phone"
                name="phone"
                select={false}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                InputProps={{
                  inputComponent: PhoneInputMask,
                }}
              />
              <Box className={classes.label}>Notes</Box>
              <TextField
                fullWidth
                multiline
                margin="dense"
                size="small"
                variant="outlined"
                id="notes"
                name="notes"
                onBlur={formik.handleBlur}
                value={formik.values.notes}
                onChange={formik.handleChange}
                error={formik.touched.notes && Boolean(formik.errors.notes)}
                helperText={formik.touched.notes && formik.errors.notes}
                className={classes.notes}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const validationSchema = yup.object({
  firstName: yup.string("Enter your name").required("Name is required"),
  lastName: yup.string("Enter your name").required("Name is required"),
  email: yup
    .string()
    .matches(constants.EMAIL_REG_EXP, "Please enter a valid email"),
  phone: yup
    .string()
    .matches(constants.PHONE_REG_EXP, "Please enter a valid phone number"),
  notes: yup
    .string("Enter your notes")
    .min(10, "Notes should be of minimum 10 characters ")
    .max(100, "Notes should be of maximum 100 characters ")
    .required("Notes is required"),
});

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.7rem 0 0 0",
  },
  topLabel: {
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: "bold",
    margin: "0.7rem 0",
  },
  customer: {
    padding: "1rem",
    minHeight: "12rem",
    margin: "0.5rem 0",
  },
  addButtonContainer: {
    marginTop: "0.5rem",
    display: "flex",
    flexDirection: "row-reverse",
  },
  addButton: {
    backgroundColor: theme.palette.info.dark,
  },
}));
export default PersonAutocompleteAdd;
