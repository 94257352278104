import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from "@material-ui/core";

const validationSchema = yup.object({
  name: yup.string("Enter your name").required("Name is required"),
  description: yup
    .string("Enter your description")
    .min(10, "Description should be of minimum 10 characters ")
    .max(100, "Description should be of maximum 100 characters ")
    .required("Description is required"),
});

const CustomAutocompleteAdd = (props) => {
  const { open, handleClose, title, companyId, onSubmit, name } = props;
  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      name,
      companyId,
      description: "",
    },
    onSubmit: (values) => {
      onSubmit(values);
      handleClose();
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={formik.handleSubmit} style={{ minWidth: "30rem" }}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <Box fontSize="subtitle1.fontSize">Name</Box>
            <TextField
              fullWidth
              margin="dense"
              size="small"
              variant="outlined"
              id="name"
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <Box fontSize="subtitle1.fontSize">Description</Box>
            <TextField
              fullWidth
              margin="dense"
              size="small"
              variant="outlined"
              id="description"
              name="description"
              onBlur={formik.handleBlur}
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" type="submit">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default CustomAutocompleteAdd;
