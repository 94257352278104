import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInputMask from "./PhoneInputMask";

const CustomTextField = (props) => {
  const {
    formik,
    name,
    label,
    style,
    select,
    options,
    InputProps,
    disabled,
    className,
    multiline,
    type,
  } = props;
  const classes = useStyles();

  const getInputFieldByName = () =>
    name && name === "phone" ? (
      <TextField
        fullWidth
        size="small"
        variant="standard"
        id={name}
        disabled={disabled || false}
        name={name}
        label={label}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        InputProps={{
          inputComponent: PhoneInputMask,
        }}
      />
    ) : (
      <TextField
        fullWidth
        size="small"
        variant="standard"
        id={name}
        disabled={disabled || false}
        InputProps={InputProps || {}}
        name={name}
        label={label}
        type={type}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        multiline={multiline ? multiline : false}
      />
    );

  return (
    <div
      className={className ? className : classes.customField}
      style={style || {}}
    >
      {select ? (
        <TextField
          fullWidth
          select
          disabled={disabled || false}
          size="small"
          variant="standard"
          id={name}
          name={name}
          label={label}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          onChange={formik.handleChange}
          error={formik.touched[name] && Boolean(formik.errors[name])}
          helperText={formik.touched[name] && formik.errors[name]}
          multiline={multiline ? multiline : false}
        >
          {options}
        </TextField>
      ) : (
        getInputFieldByName()
      )}
    </div>
  );
};

const useStyles = makeStyles({
  customField: {
    minHeight: "4.25rem",
    margin: "0 0.5rem",
  },
});
export default CustomTextField;
