import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  RadioGroup,
  Box,
  Radio,
  FormControlLabel,
  TextField,
  MenuItem,
} from "@material-ui/core";
import CustomTextField from "../../../elements/CustomTextField";
import {
  checkPermission,
  formatDatePicker,
} from "../../../../utils/helperFunctions";
import * as store from "../../../../state";
import { getContractTypesData } from "../../../../actions/resourcesAction";

const Details = (props) => {
  const { formik } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const roleId = loggedInUser.roleId;
  const contractTypes = useSelector(
    store.selectors.resource.selectContractTypes
  );

  useEffect(() => {
    getContractTypesData().then(({ data }) => {
      dispatchRedux(store.actions.resource.setContractTypes(data));
    });
  }, []);

  return (
    <>
      <h4 className={classes.pageTitle}>Details</h4>
      <Grid xs item>
        <Grid xs container>
          <Grid xs item className={classes.item}>
            <CustomTextField
              disabled={checkPermission([5], roleId)}
              name="projectNumber"
              label={
                formik.values.projectStatusId >= 8
                  ? "Project Number*"
                  : "Project Number"
              }
              formik={formik}
              className={classes.customField}
            />
          </Grid>
          <Grid xs item className={classes.item}>
            <TextField
              fullWidth
              disabled={checkPermission([5], roleId)}
              size="small"
              label={
                formik.values.projectStatusId >= 8
                  ? "Contract Type*"
                  : "Contract Type"
              }
              id="contractTypeId"
              name="contractTypeId"
              select
              onBlur={formik.handleBlur}
              value={formik.values.contractTypeId}
              onChange={formik.handleChange}
              error={
                formik.touched.contractTypeId &&
                Boolean(formik.errors.contractTypeId)
              }
              helperText={
                formik.touched.contractTypeId && formik.errors.contractTypeId
              }
            >
              {contractTypes.map((contractType) => (
                <MenuItem key={contractType.id} value={contractType.id}>
                  {contractType.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid xs item className={classes.item}>
            <p className={classes.label}>
              {formik.values.projectStatusId >= 8
                ? "Tax Exempt? *"
                : "Tax Exempt?"}
            </p>
            <RadioGroup
              row
              id="isTaxExempt"
              value={formik.values.isTaxExempt}
              aria-label="isTaxExempt"
              name="isTaxExempt"
              onChange={(event, value) => {
                formik.setFieldValue("isTaxExempt", value);
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  {formik.errors && formik.touched.isTaxExempt && (
                    <Box className={classes.errorMessage}>
                      {formik.errors.isTaxExempt}
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    disabled={checkPermission([5], roleId)}
                    value="0"
                    control={<Radio color="primary" />}
                    label="Yes"
                    classes={{
                      label: classes.radioLabel,
                    }}
                  />
                  <FormControlLabel
                    disabled={checkPermission([5], roleId)}
                    value="1"
                    control={<Radio color="primary" />}
                    label="No"
                    classes={{
                      label: classes.radioLabel,
                    }}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid xs={8} container className={classes.subContianer}>
          <Grid xs item className={classes.item}>
            <TextField
              id="awardedDate"
              label={
                formik.values.projectStatusId >= 8
                  ? "Awarded Date*"
                  : "Awarded Date"
              }
              disabled={checkPermission([5], roleId)}
              value={formatDatePicker(formik.values.awardedDate)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.awardedDate && Boolean(formik.errors.awardedDate)
              }
              helperText={
                formik.touched.awardedDate && formik.errors.awardedDate
              }
              type="date"
              className={classes.customFieldDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid xs item className={classes.item}>
            <Grid container spacing={1}>
              <Grid xs item>
                <TextField
                  id="startDate"
                  label={
                    formik.values.projectStatusId >= 8
                      ? "Start date*"
                      : "Start date"
                  }
                  disabled={checkPermission([5], roleId)}
                  value={formatDatePicker(formik.values.startDate)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.startDate && Boolean(formik.errors.startDate)
                  }
                  helperText={
                    formik.touched.startDate && formik.errors.startDate
                  }
                  type="date"
                  className={classes.customFieldDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs item>
                <TextField
                  id="endDate"
                  label={
                    formik.values.projectStatusId >= 8
                      ? "End date*"
                      : "End date"
                  }
                  disabled={checkPermission([5], roleId)}
                  value={formatDatePicker(formik.values.endDate)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endDate && Boolean(formik.errors.endDate)
                  }
                  helperText={formik.touched.endDate && formik.errors.endDate}
                  type="date"
                  className={classes.customFieldDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "4rem",
  },
  item: {
    minWidth: "9rem",
    marginRight: "2rem",
  },
  lastItem: {
    minWidth: "10rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    borderRadius: 5,
  },
  customField: {
    minHeight: "3rem",
    margin: "0",
    minWidth: "9rem",
    marginTop: "0.35rem",
    width: "100%",
  },
  customFieldDate: {
    minHeight: "3rem",
    margin: "0",
    maxWidth: "16rem",
    marginTop: "0.35rem",
    width: "100%",
  },
  radioLabel: {
    fontSize: "0.825rem",
  },
  label: {
    margin: 0,
  },
  errorMessage: {
    fontSize: "12px",
    color: "#FF0000",
  },
}));

export default Details;
