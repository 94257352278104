import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const Requested = () => {
  const classes = useStyles();
  return <div className={classes.orientation} />;
};

const useStyles = makeStyles(() => ({
  projectDottedButtonRoot: {
    minWidth: 0,
    maxWidth: 10,
  },
  orientation: {
    display: "flex",
    flexDirection: "row",
    minWidth: "10rem",
  },
}));

export default Requested;
