import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, Box, Paper } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import NotificationPopUp from "../utils/NotificationPopUp";
import DivisionForm from "./DivisionForm";
import { addDivisionData } from "../../actions/divisionActions";
import { getCompanyData } from "../../actions/companyActions";
import * as store from "../../state";

const AddDivision = (props) => {
  const dispatchRedux = useDispatch();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId =
    props.view === "sysAdmin"
      ? parseInt(props.companyId)
      : loggedInUser.userCompany.id;
  const divisionDashboardUrl =
    props.view === "sysAdmin"
      ? `/systemAdministration/divisions/${props.companyId}`
      : "/divisions";
  const classes = useStyles();
  const history = useHistory();
  const maxNrOfDivisionPerSubsidiary = useSelector(
    store.selectors.company.selectMaxNumberOfSubsidiaries
  );
  const [open, setOpen] = useState(false);
  const [notificationText, setNotificationText] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationType, setNotificationType] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    companyId,
    name: "",
    divisionId: "",
    industryClassificationId: "",
    subsidiaryId: "",
  };

  const onSubmit = async (values, subsidiary) => {
    if (
      subsidiary[0].divisions.filter((division) => division.statusId === 1)
        .length < maxNrOfDivisionPerSubsidiary
    ) {
      addDivisionData(values).then(({ data }) => {
        dispatchRedux(store.actions.division.addDivision(data));
        history.push(divisionDashboardUrl);
      });
    } else {
      setNotificationTitle("Division Limit");
      setNotificationText(
        "You have reached the maximum number of divisions per subsidiary. Please contact our support for help."
      );
      setNotificationType("warning");
      setNotificationActions([{ name: "OK", action: () => setOpen(false) }]);
      setOpen(true);
    }
  };

  const onCancel = () => {
    history.push(divisionDashboardUrl);
  };

  useEffect(() => {
    getCompanyData(companyId);
  }, []);

  return (
    <>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        SYSTEM ADMINISTRATION
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.pageSubTitle}>Create New Division</Box>
      <DivisionForm
        page="add"
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        companyId={companyId}
      />
      <NotificationPopUp
        isOpen={open}
        handleClose={handleClose}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  additionalComponent: {
    marginLeft: "2rem",
    "& p": {
      margin: "0",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "1rem",
    },
  },
}));

export default AddDivision;
