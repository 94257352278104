import React from "react";
import { useHistory } from "react-router";
import { ListItem, Box } from "@material-ui/core";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { toUpper } from "lodash";

const ReportsListItems = (props) => {
  const { classes, disabled } = props;
  const history = useHistory();
  return (
    <div>
      <div>
        <Box
          textAlign="left"
          fontWeight="fontWeightBold"
          letterSpacing={1}
          className={classes.title}
        >
          <EqualizerIcon />
          {toUpper("reports")}
        </Box>
      </div>
      <ListItem
        id="allReportsButton"
        disabled={disabled}
        button
        onClick={() => history.push("/reports/allReports")}
      >
        <Box className={classes.subTitle}>All Reports</Box>
      </ListItem>
      <ListItem id="pipelineAndBacklogButton" disabled={disabled} button>
        <Box className={classes.subTitle}>Pipeline & Backlog</Box>
      </ListItem>
      <ListItem
        id="timeButton"
        disabled={disabled}
        button
        onClick={() => history.push("/reports/time")}
      >
        <Box className={classes.item}>Time</Box>
      </ListItem>
      <ListItem
        id="projectTeamButton"
        disabled={disabled}
        button
        onClick={() => history.push("/reports/team")}
      >
        <Box className={classes.item}>Project Team</Box>
      </ListItem>
      <ListItem
        id="customerButton"
        disabled={disabled}
        button
        onClick={() => history.push("/reports/customer")}
      >
        <Box className={classes.item}>Customer</Box>
      </ListItem>
      <ListItem
        id="marketSegmentButton"
        disabled={disabled}
        button
        onClick={() => history.push("/reports/market")}
      >
        <Box className={classes.item}>Market Segment</Box>
      </ListItem>
      <ListItem
        id="locationButton"
        disabled={disabled}
        button
        onClick={() => history.push("/reports/location")}
      >
        <Box className={classes.item}>Location</Box>
      </ListItem>
      {/* this will come later, in phase 2 */}
      {/*
      <ListItem button>
        <Box className={classes.subTitle}>{"Bid Analysis"}</Box>
      </ListItem>
      <ListItem button>
        <Box className={classes.item}>{"Report A"}</Box>
      </ListItem>
      <ListItem button>
        <Box className={classes.item}>{"Report B"}</Box>
      </ListItem>
      <ListItem button>
        <Box className={classes.item}>{"Report C"}</Box>
      </ListItem> */}
    </div>
  );
};

export default ReportsListItems;
