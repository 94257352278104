import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, MenuItem } from "@material-ui/core";
import clsx from "clsx";

const StatusSelect = (props) => {
  const { disabled, value, options, handleChange, error, helperText } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.flexCol, classes.width50)}>
      <Box className={classes.contentBody}>
        <Box className={classes.label}>Status</Box>
        <TextField
          disabled={disabled}
          id="statusId"
          name="statusId"
          fullWidth
          select
          label=""
          size="small"
          value={value || ""}
          className={clsx(
            classes[
              options.find((status) => status.id === value)
                ? options.find((status) => status.id === value).name
                : ""
            ],
            classes.statusSelectInput
          )}
          onChange={handleChange}
          variant="filled"
          error={error}
          helperText={helperText}
        >
          {options.map((option) => (
            <MenuItem key={option.id} id={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box className={classes.contentBody}>
        {() => {
          const status = options.find((status) => status.id === value);
          if (status) {
            return (
              <Box
                className={clsx(
                  classes.statusSelectDescription,
                  classes[status.name]
                )}
              >
                {status.description}
              </Box>
            );
          }
          return <></>;
        }}
      </Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "1.5rem 1.5rem 0.5rem 0",
    fontWeight: "bold",
    minWidth: "100px",
  },
  statusSelectInput: {
    paddingTop: "1rem",
    width: "9rem",
    height: "1.5rem",
    textAlign: "center",
    "& div": {
      "& div": {
        padding: "5px",
        background: "#4A5568",
        color: "#fff",
      },
    },
  },
  ACTIVE: {
    "& div": {
      "& div, div:focus": {
        background: "#4D841D",
      },
    },
  },
  DELETED: {
    "& div": {
      "& div, div:focus": {
        background: "#FF0000",
      },
    },
  },
  INACTIVE: {
    "& div": {
      "& div, div:focus": {
        background: "#BDBDBD",
      },
    },
  },
}));

export default StatusSelect;
