import * as actions from "../action/report";
import _ from "lodash";

const initialState = {
  timeReports: {
    timeReportsModel: [],
    missingInformationProjects: [],
    requestedProjects: null,
  },
  teamReports: {
    requestedProjects: [],
    missingInformationProjects: [],
    assignees: [],
  },
  locationReports: {
    locationReportModel: [],
    missingInformationProjects: [],
    states: [],
  },
  customerReports: {
    missingInformationProjects: [],
    requestedProjects: null,
    customers: [],
  },
  marketReports: {
    missingInformationProjects: [],
    requestedProjects: null,
    segments: [],
  },
};

const setLocationReportExpandedTable = (state, locationReports) => {
  if (locationReports.states.length === 0) return state;
  const stateId = locationReports.states[0].id;
  const countyId = locationReports.states[0].counties
    ? locationReports.states[0].counties.length === 1
      ? locationReports.states[0].counties[0].id
      : null
    : null;
  const newLocationReports = Object.assign(state.locationReports);
  if (stateId) {
    const foundStateIndex = _.findIndex(newLocationReports.states, [
      "id",
      stateId,
    ]);
    if (countyId) {
      const foundCountyIndex = _.findIndex(
        newLocationReports.states[foundStateIndex].counties,
        ["id", countyId]
      );
      if (foundCountyIndex >= 0)
        newLocationReports.states[foundStateIndex].counties[foundCountyIndex] =
          locationReports.states[0].counties[0];
      else
        newLocationReports.states[foundStateIndex].counties =
          locationReports.states[0].counties;
    } else {
      newLocationReports.states[foundStateIndex] = locationReports.states[0];
    }
  }
  return { ...state, locationReports: newLocationReports };
};

const setTeamReportExpandedTable = (state, teamReports) => {
  if (teamReports.assignees.length === 0) return state;
  const assigneeId = teamReports.assignees[0].id;
  const newTeamReports = Object.assign(state.teamReports);
  if (assigneeId) {
    const foundAssigneeIndex = _.findIndex(newTeamReports.assignees, [
      "id",
      assigneeId,
    ]);
    newTeamReports.assignees[foundAssigneeIndex] = teamReports.assignees[0];
  }
  return { ...state, teamReports: newTeamReports };
};

const setCustomerReportExpandedTable = (state, customerReports) => {
  if (customerReports.customers.length === 0) return state;
  const customerId = customerReports.customers[0].id;
  const newCustomerReports = Object.assign(state.customerReports);
  if (customerId) {
    const foundCustomerIndex = _.findIndex(newCustomerReports.customers, [
      "id",
      customerId,
    ]);
    newCustomerReports.customers[foundCustomerIndex] =
      customerReports.customers[0];
  }
  return { ...state, customerReports: newCustomerReports };
};

const setMarketReportExpandedTable = (state, marketReports) => {
  if (marketReports.segments.length === 0) return state;
  const marketSegmentId = marketReports.segments[0].id;
  const categoryId = marketReports.segments[0].categories
    ? marketReports.segments[0].categories.length === 1
      ? marketReports.segments[0].categories[0].id
      : null
    : null;
  const newMarketReports = Object.assign(state.marketReports);
  if (marketSegmentId) {
    const foundSegmentIndex = _.findIndex(newMarketReports.segments, [
      "id",
      marketSegmentId,
    ]);
    if (categoryId) {
      const foundCategoryIndex = _.findIndex(
        newMarketReports.segments[foundSegmentIndex].categories,
        ["id", categoryId]
      );
      if (foundCategoryIndex >= 0)
        newMarketReports.segments[foundSegmentIndex].categories[
          foundCategoryIndex
        ] = marketReports.segments[0].categories[0];
      else
        newMarketReports.segments[foundSegmentIndex].categories =
          marketReports.segments[0].categories;
    } else {
      newMarketReports.segments[foundSegmentIndex] = marketReports.segments[0];
    }
  }
  return { ...state, marketReports: newMarketReports };
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.REPORT_SET_TIME_REPORT_ACTION:
      return {
        ...state,
        timeReports: action.payload,
      };
    case actions.REPORT_SET_TEAM_REPORT_ACTION:
      return {
        ...state,
        teamReports: action.payload,
      };
    case actions.REPORT_SET_LOCATION_REPORT_ACTION:
      return {
        ...state,
        locationReports: action.payload,
      };
    case actions.REPORT_SET_CUSTOMER_REPORT_ACTION:
      return {
        ...state,
        customerReports: action.payload,
      };
    case actions.REPORT_SET_MARKET_REPORT_ACTION:
      return {
        ...state,
        marketReports: action.payload,
      };
    case actions.REPORT_SET_TEAM_REPORTS_EXTENDED_TABLE:
      return setTeamReportExpandedTable(state, action.payload);
    case actions.REPORT_SET_LOCATION_REPORTS_EXTENDED_TABLE:
      return setLocationReportExpandedTable(state, action.payload);
    case actions.REPORT_SET_CUSTOMER_REPORTS_EXTENDED_TABLE:
      return setCustomerReportExpandedTable(state, action.payload);
    case actions.REPORT_SET_MARKET_REPORTS_EXTENDED_TABLE:
      return setMarketReportExpandedTable(state, action.payload);
    default:
      return {
        ...state,
      };
  }
};

export default reportReducer;
