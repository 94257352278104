import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Avatar } from "@material-ui/core";
import Logo from "./Logo";
import * as constants from "../../constants";
import profilePlaceholder from "../../resources/profilePlaceholder.png";

const AvatarDropZone = (props) => {
  const classes = useStyles();
  const { formik, placeholderInitials } = props;
  const [fileChose, setFileChosen] = useState(Boolean(formik.values.picture));
  const [hover, setHover] = useState(false);

  const handleHoverEnter = () => {
    setHover(true);
  };

  const handleHoverLeave = () => {
    setHover(false);
  };

  const changeFile = (event) => {
    handleFiles(event.currentTarget.files);
  };

  const deleteFile = () => {
    formik.setFieldValue("picture", null);
  };

  useEffect(() => {
    setFileChosen(Boolean(formik.values.picture));
  }, [formik.values.picture]);

  const handleFiles = (files) => {
    const file = files[0];
    if (validateFile(file)) {
      if (validateFileSize(file.size)) {
        formik.setFieldValue("picture", file);
      } else {
        formik.setFieldError("picture", "File size must be bellow 200KB");
        formik.setFieldTouched("picture", true, false);
      }
    } else {
      formik.setFieldError("picture", "Invalid Type Provided");
      formik.setFieldTouched("picture", true, false);
    }
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    if (files.length) {
      handleFiles(files);
    }
  };

  const getContentElement = () => {
    let placeholderElement = (
      <Avatar src={profilePlaceholder} className={classes.largeAvatar} />
    );
    if (!formik.values.picture && placeholderInitials) {
      placeholderElement = (
        <Avatar className={classes.largeAvatar}>{placeholderInitials}</Avatar>
      );
    }
    return (
      <Logo
        file={formik.values.picture}
        errors={formik.errors.picture}
        type="rounded"
        hover={hover}
        changeFile={changeFile}
        deleteFile={deleteFile}
      >
        {placeholderElement}
      </Logo>
    );
  };
  return (
    <div
      id="container"
      onMouseEnter={handleHoverEnter}
      onMouseLeave={handleHoverLeave}
    >
      <div
        id="drop-container"
        className={classes.dropContainer}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        {!fileChose && (
          <div
            id="empty-upload-container"
            className={classes.emptyUploadContainer}
          >
            <div id="upload-icon">
              <TextField
                fullWidth
                type="file"
                size="small"
                variant="standard"
                id="picture"
                name="picture"
                style={{ display: "none" }}
                onBlur={formik.handleBlur}
                onChange={changeFile}
              />
            </div>
          </div>
        )}
        {getContentElement()}
      </div>
    </div>
  );
};

const dragOver = (e) => {
  e.preventDefault();
};

const dragEnter = (e) => {
  e.preventDefault();
};

const dragLeave = (e) => {
  e.preventDefault();
};

const validateFile = (file) => {
  if (file) {
    if (constants.SUPPORTED_FORMATS.indexOf(file.type) !== -1) {
      return true;
    }
  }
  return false;
};

const validateFileSize = (size) => {
  if (size > 0 && size < 1024 * 200) {
    return true;
  }
  return false;
};

const useStyles = makeStyles((theme) => ({
  dropContainerParent: {
    width: "12rem",
    marginRight: "2rem",
  },
  dropContainer: {
    height: "9.5rem",
    width: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptyUploadContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  addButton: {
    color: "#3182CE",
  },
  largeAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

export default AvatarDropZone;
