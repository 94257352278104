import React from "react";
import { Typography, Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { formatDateUS } from "../../../../utils/helperFunctions";

const NoBid = (props) => {
  const { expectedStartDate, expectedEndDate, bidDueDate } = props.row;
  const classes = useStyles();
  return (
    <>
      <div>
        <Typography variant="subtitle2" color="textSecondary">
          Bid Due by
        </Typography>
        <Box fontSize="subtitle1.fontSize" fontWeight="fontWeightBold">
          {bidDueDate ? formatDateUS(bidDueDate) : "-"}
        </Box>
        <Typography variant="subtitle2" color="textSecondary">
          Exp. Start Date
        </Typography>
        <Box fontSize="subtitle1.fontSize" fontWeight="fontWeightBold">
          {expectedStartDate ? formatDateUS(expectedStartDate) : "-"}
        </Box>
      </div>
      <div className={classes.singleRow}>
        <Typography variant="subtitle2" color="textSecondary">
          Exp. End Date
        </Typography>
        <Box fontSize="subtitle1.fontSize" fontWeight="fontWeightBold">
          {expectedEndDate ? formatDateUS(expectedEndDate) : "-"}
        </Box>
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  singleRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
}));

export default NoBid;
