export const DIVISION_SET_DIVISIONS = "DIVISION_SET_DIVISIONS";
export const DIVISION_EDIT_DIVISION = "DIVISION_EDIT_DIVISION";
export const DIVISION_REMOVE_DIVISION = "DIVISION_REMOVE_DIVISION";
export const DIVISION_ADD_DIVISION = "DIVISION_ADD_DIVISION";
export const DIVISION_SET_DIVISION = "DIVISION_SET_DIVISION";

export const addDivision = (payload) => ({
  type: DIVISION_ADD_DIVISION,
  payload,
});

export const setDivisions = (payload) => ({
  type: DIVISION_SET_DIVISIONS,
  payload,
});

export const editDivision = (payload) => ({
  type: DIVISION_EDIT_DIVISION,
  payload,
});

export const removeDivision = (payload) => ({
  type: DIVISION_REMOVE_DIVISION,
  payload,
});
