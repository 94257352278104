import React from "react";
import { makeStyles, darken } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { InputBase } from "@material-ui/core";

import * as constants from "../../constants";

const CustomInput = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        id={props.id}
        onChange={props.onChange}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
};
export default CustomInput;

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: "1rem",
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: darken(theme.palette.secondary.light, 0.05),
    },
    marginRight: "1rem",
    marginLeft: 0,
    maxWidth: constants.DRAWER_WIDTH,
    [theme.breakpoints.up("sm")]: {
      marginLeft: "1.5rem",
      width: "auto",
    },
  },
  searchIcon: {
    padding: "0 1rem",
    color: "inherit",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: "0.5rem 0.5rem 0.5rem 0",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: `calc(1em + ${theme.spacing(2)}px)`,
      width: "10ch",
      "&:focus": {
        width: "15ch",
      },
    },
  },
}));
