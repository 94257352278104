import { APP_HIDE_APP_LOADER, APP_SHOW_APP_LOADER } from "../action/app";

const initialState = {
  appLoaderVisible: false,
  appLoaderLevel: 0,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_HIDE_APP_LOADER: {
      const level = state.appLoaderLevel - 1;
      return {
        ...state,
        appLoaderLevel: level < 0 ? 0 : level,
        appLoaderVisible: level > 0,
      };
    }
    case APP_SHOW_APP_LOADER: {
      const level = state.appLoaderLevel + 1;
      return {
        ...state,
        appLoaderLevel: level < 0 ? 1 : level,
        appLoaderVisible: level > 0,
      };
    }
    default:
      return state;
  }
};

export default appReducer;
