import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, Box, Paper } from "@material-ui/core";
import {
  Settings,
  Create,
  Add,
  Delete,
  Redo,
  Beenhere,
  Block,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  getSystemUsers,
  getUsersData,
  updateUserStatus,
} from "../../actions/usersAction";
import {
  getAvailableUserSeatsData,
  getCompanyData,
} from "../../actions/companyActions";
import UserTable from "./UserTable";
import AdministrationTabBar from "../elements/AdministrationTabBar";
import NotificationPopUp from "../utils/NotificationPopUp";
import SystemAdministrationTabs from "../systemAdministration/SystemAdministrationTabs";
import * as store from "../../state";

const UserDashboard = (props) => {
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const { sysAdmin } = props;
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const users = useSelector(store.selectors.user.selectUsers);
  const [availableUserSeats, setAvailabelUserSeats] = useState(0);
  const [maxOfUserSeats, setMaxOfUserSeats] = useState(0);
  const companyId =
    props.view === "sysAdmin"
      ? parseInt(props.companyId)
      : loggedInUser.userCompany
      ? loggedInUser.userCompany.id
      : null;
  const addUserUrl =
    props.view === "sysAdmin"
      ? `/systemAdministration/${props.companyId}/users/user`
      : "/users/user";
  const editUserUrl = (userId) =>
    props.view === "sysAdmin"
      ? `/systemAdministration/${props.companyId}/users/user/${userId}`
      : `/users/user/${userId}`;
  const editSysadminUserUrl = (userId) => `/sysadmin/user/${userId}`;
  const { roleId } = loggedInUser;
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState([]);

  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [sysUsers, setSysUsers] = useState([]);
  const [userReloadNeeded, setUserReloadNeeded] = useState(false);

  const isEditButtonDisable = selectedRows.length === 1;
  const isDeleteButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.status === "DELETED").length !==
      selectedRows.length;
  const isActivateButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter(
      (row) =>
        row.status === "INACTIVE" ||
        row.status === "PENDING" ||
        row.status === "EXPIRED"
    ).length !== 0;
  const isDeactivateButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter(
      (row) => row.status === "ACTIVE" || row.status === "EXPIRED"
    ).length !== 0;
  const isResendButtonDisable =
    selectedRows.length !== 0 &&
    selectedRows.filter((row) => row.status === "EXPIRED").length !== 0;

  const deleteUser = () => {
    updateUsers(
      selectedRows
        .filter((row) => row.id !== loggedInUser.id)
        .map((row) => row.id),
      "DELETED"
    );
  };

  const activateUser = () => {
    if (selectedRows.filter((row) => row.status === "INACTIVE").length !== 0) {
      updateUsers(
        selectedRows
          .filter((row) => row.status === "INACTIVE")
          .map((row) => row.id),
        "PENDING"
      );
    }
    if (
      selectedRows.filter(
        (row) => row.status === "PENDING" || row.status === "EXPIRED"
      ).length !== 0
    ) {
      updateUsers(
        selectedRows
          .filter((row) => row.status === "PENDING" || row.status === "EXPIRED")
          .map((row) => row.id),
        "ACTIVE"
      );
    }
  };

  const deactivateUser = () => {
    updateUsers(
      selectedRows
        .filter((row) => row.status === "ACTIVE" || row.status === "EXPIRED")
        .map((row) => row.id),
      "INACTIVE"
    );
  };

  const resendInvite = () => {
    updateUsers(
      selectedRows
        .filter((row) => row.status === "EXPIRED")
        .map((row) => row.id),
      "PENDING"
    );
  };

  const updateUsers = (userIds, status) => {
    updateUserStatus(userIds, status).then((userIds) => {
      dispatchRedux(store.actions.user.editUsers({ userIds, status }));
      setSelectedRows([]);
      setUserReloadNeeded(true);
    });
  };

  const handleAdd = () => {
    if (sysAdmin) {
      history.push("/sysadmin/user");
      return;
    }

    if (availableUserSeats <= 0) {
      setNotificationTitle("User Limit");
      setNotificationText(
        "You have reached the maximum number of users. Please contact our support for help."
      );
      setNotificationType("warning");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    } else {
      history.push(addUserUrl);
    }
  };

  const handleEdit = (userId) => {
    if (sysAdmin) {
      history.push(`/sysadmin/user/${userId}`);
      return;
    }

    history.push(editUserUrl(userId));
  };

  const handleDelete = () => {
    setNotificationTitle("Delete User");
    setNotificationText(
      "Deleting the user/s will remove access and all user/s information/s, and cannot be undone!" +
        "Are you sure you want to delete this user?"
    );
    setNotificationType("error");
    setNotificationActions([
      {
        name: "DELETE USER",
        action: () => {
          deleteUser();
          handleClose();
        },
      },
      { name: "CANCEL", action: () => handleClose() },
    ]);
    setIsNotificationPopUpOpen(true);
  };

  const handleActivateUser = () => {
    if (
      !sysAdmin &&
      availableUserSeats -
        selectedRows.filter((row) => row.status === "INACTIVE").length <
        0
    ) {
      setNotificationTitle("User Limit");
      setNotificationText(
        "You have reached the maximum number of users. Please contact our support for help."
      );
      setNotificationType("warning");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    } else {
      setNotificationTitle("Multiple statuses selected");
      setNotificationText(
        "Only INACTIVE, PENDING and EXPIRED users will be activated. Would you like to proceed?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "YES",
          action: () => {
            activateUser();
            handleClose();
          },
        },
        {
          name: "NO",
          action: () => {
            handleClose();
          },
        },
      ]);
      setIsNotificationPopUpOpen(true);
    }
  };

  const handleDeactivateUser = () => {
    setNotificationTitle("Deactivate User");
    setNotificationText(
      "Deactivation of the ACTIVE and EXPIRED users will revoke and block access to the application. " +
        "Are you sure you want to continue with those users?"
    );
    setNotificationType("warning");
    setNotificationActions([
      {
        name: "DEACTIVATE USER",
        action: () => {
          deactivateUser();
          handleClose();
        },
      },
      { name: "CANCEL", action: () => handleClose() },
    ]);
    setIsNotificationPopUpOpen(true);
  };

  const handleResendInvite = () => {
    if (
      !sysAdmin &&
      availableUserSeats -
        selectedRows.filter((row) => row.status === "EXPIRED").length <
        0
    ) {
      setNotificationTitle("User Limit");
      setNotificationText(
        "You have reached the maximum number of users. Please contact our support for help."
      );
      setNotificationType("warning");
      setNotificationActions([{ name: "OK", action: () => handleClose() }]);
      setIsNotificationPopUpOpen(true);
    } else if (
      selectedRows.filter((row) => row.status === "EXPIRED").length !==
      selectedRows.length
    ) {
      setNotificationTitle("Multiple statuses selected");
      setNotificationText(
        "Only EXPIRED users will receive the invitation. Would you like to proceed?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "YES",
          action: () => {
            resendInvite();
            handleClose();
          },
        },
        {
          name: "NO",
          action: () => {
            handleClose();
          },
        },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      resendInvite();
    }
  };

  const handleClose = () => {
    setIsNotificationPopUpOpen(false);
  };

  useEffect(() => {
    if (sysAdmin) {
      setSelectedRows([]);
      return;
    }

    getAvailableUserSeatsData(companyId).then((response) => {
      setAvailabelUserSeats(response.data.availableUserSeats);
    });
    setSelectedRows([]);
  }, [store.selectors.user.users]);

  useEffect(() => {
    if (sysAdmin) {
      loadUsers();
      setUserReloadNeeded(false);
      return;
    }

    getUsersData(companyId).then(({ data }) => {
      dispatchRedux(store.actions.user.setUsers(data));
    });
    getAvailableUserSeatsData(companyId).then(({ data }) => {
      setAvailabelUserSeats(data.availableUserSeats);
    });
    getCompanyData(companyId).then(({ data }) => {
      const { maxNumberOfUsers } = data;
      setMaxOfUserSeats(maxNumberOfUsers);
    });
  }, []);

  useEffect(() => {
    if (userReloadNeeded) {
      loadUsers();
      setUserReloadNeeded(false);
    }
  }, [userReloadNeeded]);

  const loadUsers = async () => {
    if (sysAdmin) {
      try {
        const loadedSysUsers = await getSystemUsers();
        const availableUser = loadedSysUsers.filter(
          (user) => user.status !== "DELETED"
        );
        setSysUsers(availableUser);
      } catch (e) {
        //
      }
    }
  };

  return (
    <>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleClose}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
      {sysAdmin ? (
        <SystemAdministrationTabs selectedPage={1} />
      ) : (
        <>
          <Box className={classes.pageTitle}>
            <Paper elevation={3}>
              <Settings />
            </Paper>
            ADMINISTRATION
          </Box>
          <Divider className={classes.divider} />
          <div id="addEditUser" />

          <AdministrationTabBar page="users" view={props.view} id={companyId} />
        </>
      )}
      <Box className={classes.pageSubTitle}>User Management</Box>
      <div className={classes.actionArea}>
        <div className={classes.actionButtons}>
          <div
            id="addButton"
            className={classes.actionButton}
            onClick={() => handleAdd()}
          >
            <Add className={classes.icon} />
            <b>Add</b>
          </div>
          <div
            id="editButton"
            className={
              isEditButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isEditButtonDisable ? handleEdit(selectedRows[0].id) : null
            }
          >
            <Create className={classes.icon} />
            <b>Edit</b>
          </div>
          <div
            id="deleteButton"
            className={
              isDeleteButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() => (isDeleteButtonDisable ? handleDelete() : null)}
          >
            <Delete className={classes.icon} />
            <b>Delete</b>
          </div>
          <div
            id="activateUserButton"
            className={
              isActivateButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isActivateButtonDisable ? handleActivateUser() : null
            }
          >
            <Beenhere className={classes.icon} />
            <b>Activate User</b>
          </div>
          <div
            id="decativateUserButton"
            className={
              isDeactivateButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isDeactivateButtonDisable ? handleDeactivateUser() : null
            }
          >
            <Block className={classes.icon} />
            <b>Deactivate User</b>
          </div>
          <div
            id="resendInviteButton"
            className={
              isResendButtonDisable
                ? classes.actionButton
                : classes.disableActionButton
            }
            onClick={() =>
              isResendButtonDisable ? handleResendInvite() : null
            }
          >
            <Redo className={classes.icon} />
            <b>Resend Invite</b>
          </div>
        </div>
        {!sysAdmin && (
          <div className={classes.labelArea}>
            <b>Total Users: </b>
            <label>
              {" "}
              {maxOfUserSeats - availableUserSeats} of {maxOfUserSeats} users
            </label>
          </div>
        )}
      </div>
      <UserTable
        setSelectedRows={setSelectedRows}
        rows={
          sysAdmin
            ? sysUsers
            : roleId === 1
            ? users.filter((user) => user.roleId !== "1")
            : users.filter(
                (user) => user.status !== "DELETED" && user.roleId !== "1"
              )
        }
        fullView={!sysAdmin}
        selectedRows={selectedRows}
        editUserUrl={!sysAdmin ? editUserUrl : editSysadminUserUrl}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  actionArea: {
    backgroundColor: "#CBD5E0",
    padding: ".75rem",
    display: "flow-root",
  },
  labelArea: {
    float: "right",
  },
  actionButtons: {
    display: "flex",
    textAlign: "left",
    float: "left",
  },
  actionButton: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    marginRight: "1.5rem",
  },
  disableActionButton: {
    display: "flex",
    cursor: "default",
    alignItems: "center",
    marginRight: "1.5rem",
    color: "grey",
  },
  icon: {
    fontSize: "1rem",
    marginRight: ".5rem",
    color: "#4A5568",
  },
  titleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& button": {
      minWidth: "8rem",
      marginRight: ".5rem",
      marginBottom: "2rem",
      borderRadius: "0rem",
      fontSize: theme.typography.h6.fontSize,
    },
  },
  projectTypeButtonSelected: {
    borderBottom: `0.2rem solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  projectTypeButtonNotSelected: {
    borderBottom: "0.2rem solid #BEE3F8",
    color: "#BEE3F8",
  },
}));

export default UserDashboard;
