export const DRAWER_WIDTH = 240;
export const DRAWER_BACKGROUND_COLOR = "#F7FAFC";
export const PIPELINE_COLOR = "#2d3748";
export const ASTERISK_COLOR = "#ff333f";
export const FILE_SIZE = 160 * 1024;
export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/svg",
];

export const USER_ACTIVITY_TIMEOUT = 60000;

export const FE_VERSION = `${process.env.REACT_APP_VERSION}_${process.env.REACT_APP_ENV}`;
export const DATE_FORMAT = "MM/DD/YYYY, h:mm:ss A";

export const API = process.env.REACT_APP_API;

export const secret = process.env.REACT_APP_SECRET;
export const PHONE_REG_EXP =
  /^(\([1-9][0-9]{2}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$|^[1-9][0-9]{9}$/;
export const WEBSITE_REG_EXP =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const EMAIL_REG_EXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REG_EXP =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
