import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const ReportTab = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { title, description, icon, disabled, path } = props;
  return (
    <div className={classes.container}>
      <Button
        variant="contained"
        size="large"
        className={classes.button}
        startIcon={icon}
        disabled={disabled}
        classes={{
          label: classes.label,
          startIcon: classes.startIcon,
          iconSizeLarge: classes.iconSizeLarge,
        }}
        onClick={() => history.push(path)}
      >
        <Box className={classes.title}>{title}</Box>
      </Button>

      <Box className={classes.description}>{description}</Box>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "12rem",
    margin: "0.5rem 3.5rem 6rem 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    color: "black",
    backgroundColor: "#fff",
    height: "12rem",
    borderRadius: "0.5rem",
    width: "inherit",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
  },
  label: {
    flexDirection: "column",
  },
  startIcon: {
    marginRight: "0",
    marginLeft: "0",
  },
  iconSizeLarge: {
    "& *:first-child": {
      fontSize: "8rem",
    },
  },
  link: {
    width: "100%",
    position: "relative",
    top: "28%",
    "& div": {
      height: "12rem",
    },
  },
  title: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: "bold",
  },
  description: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
}));

export default ReportTab;
