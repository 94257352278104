import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Box } from "@material-ui/core";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PeopleIcon from "@material-ui/icons/People";
import TimelineIcon from "@material-ui/icons/Timeline";
import PieChartIcon from "@material-ui/icons/PieChart";
import ReportTab from "./ReportTab";

const AllReports = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.pageTitle}>
        <EqualizerIcon />
        All Reports
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.pageTitle}>Pipeline & Backlog Report</Box>
      <Divider className={classes.divider} />
      <div className={classes.container}>
        <ReportTab
          title="Time"
          description="Chart shows pipeline and backlog over time"
          icon={<TimelineIcon />}
          disabled={false}
          path="/reports/time"
        />
        <ReportTab
          title="Project Team"
          description="Chart shows pipeline and backlog by project members"
          icon={<PeopleIcon />}
          disabled={false}
          path="/reports/team"
        />
        <ReportTab
          title="Customer"
          description="Chart shows pipeline and backlog by customer"
          icon={<SupervisedUserCircleIcon />}
          disabled={false}
          path="/reports/customer"
        />
        <ReportTab
          title="Market Segment"
          description="Chart shows pipeline and backlog by market segments."
          icon={<PieChartIcon />}
          disabled={false}
          path="/reports/market"
        />
        <ReportTab
          title="Location"
          description="Chart shows pipeline and backlog by location."
          icon={<LocationOnIcon />}
          disabled={false}
          path="/reports/location"
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.h3.fontSize,
    fontWeight: "bold",
    color: theme.palette.grey[600],
    "& svg": {
      fontSize: "3rem",
      marginRight: "0.3rem",
    },
  },
  backwardLink: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontSize: theme.typography.h5.fontSize,
    marginBottom: "2rem",
  },
  divider: {
    margin: "0.1rem 0 1rem 0",
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
}));

export default AllReports;
