import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import DeleteModal from "../elements/DeleteModal";
import {
  getDivisionsData,
  removeDivisionsData,
} from "../../actions/divisionActions";
import { useDispatch, useSelector } from "react-redux";
import * as store from "../../state/index";
//import { removeDivision, setDivisions } from "../../state/action/division";

const DeleteDivisions = (props) => {
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const { selected } = props;
  const divisions = useSelector(store.selectors.division.getDivisions);
  const [open, setOpen] = useState(true);
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId =
    props.view === "sysAdmin"
      ? parseInt(props.companyId)
      : loggedInUser.userCompany.id;

  const handleClose = () => {
    history.push("/divisions");
    setOpen(false);
  };
  const handleDelete = () => {
    removeDivisionsData(selected).then(() => {
      dispatchRedux(store.actions.division.removeDivision(selected));
    });
  };

  useEffect(() => {
    getDivisionsData(companyId).then(({ data }) => {
      dispatchRedux(store.actions.division.setDivisions(data));
    });
  }, []);

  const getSeletedElements = (ids, elements) => {
    const selected = _.takeWhile(elements, (o) => _.includes(ids, o.id));
    return selected;
  };

  const content = (
    <>
      {getSeletedElements(selected, divisions).map((sub) => (
        <div key={sub.id}>
          <span>
            Name:
            {sub.name}
          </span>
        </div>
      ))}
    </>
  );

  return (
    <div>
      <DeleteModal
        open={open}
        title="Delete Divisions"
        subtitle="Are you sure you want to delete these divisions"
        content={content}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default DeleteDivisions;
