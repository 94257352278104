export const SET_PROPOSALS = "SET_PROPOSALS";
export const SET_PROPOSAL = "SET_PROPOSAL";

export const setProposals = (payload) => ({
  type: SET_PROPOSALS,
  payload,
});

export const setProposal = (payload) => ({
  type: SET_PROPOSAL,
  payload,
});
