import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Grid, TextField, Button } from "@material-ui/core";
import CustomTextField from "../../../elements/CustomTextField";
import CustomAutocompleteAdd from "../../CustomAutocompleteAdd";
import { checkPermission } from "../../../../utils/helperFunctions";
import {
  getMaterialsData,
  getRequirementsData,
  addRequirementData,
  addMaterialData,
} from "../../../../actions/companyActions";
import { CheckboxGroup } from "../../../elements/CheckBoxGroup";
import SelectList from "../../../elements/SelectList";
import * as store from "../../../../state";
import { getWorkTypesData } from "../../../../actions/resourcesAction";

const ProposalDetails = (props) => {
  const { companyId, formik } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();

  const [requirementName, setRequirementName] = useState("");
  const [materialName, setMaterialName] = useState("");

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const requirements = useSelector(store.selectors.company.selectRequirements);
  const materials = useSelector(store.selectors.company.selectMaterials);
  const { roleId } = loggedInUser;

  const [openMaterial, toggleOpenMaterial] = useState(false);
  const [workTypes, setWorkTypes] = useState([]);
  const [openRequirement, toggleOpenRequirement] = useState(false);

  const handleCheckBoxChange = (event, workType) => {
    let proposalWorkTypes = formik.values.proposalWorkTypes;
    let newArray;
    if (event.target.checked) {
      newArray = proposalWorkTypes.concat(workType);
    } else {
      newArray = proposalWorkTypes.filter(
        (object) => object.id !== workType.id
      );
    }
    if (Array.isArray(newArray)) {
      formik.setFieldValue("proposalWorkTypes", newArray);
    } else {
      formik.setFieldValue("proposalWorkTypes", [newArray]);
    }
  };

  const getWorkTypeById = (id) => {
    return workTypes.filter((workType) => workType.id === id)[0];
  };

  const containsObject = (array, object) => {
    if (array) {
      return array.filter((param) => param.id === object?.id).length !== 0;
    }
    return false;
  };

  const checkBoxOptions = [
    {
      label: "Electrical",
      isChecked:
        formik.values.proposalWorkTypes?.length !== 0 &&
        containsObject(formik.values.proposalWorkTypes, getWorkTypeById(1)),
      name: "electrical",
      handleChange: (event) => handleCheckBoxChange(event, getWorkTypeById(1)),
    },
    {
      label: "Technology/Low Voltage",
      isChecked:
        formik.values.proposalWorkTypes?.length !== 0 &&
        containsObject(formik.values.proposalWorkTypes, getWorkTypeById(2)),
      name: "technologyLowVoltage",
      handleChange: (event) => handleCheckBoxChange(event, getWorkTypeById(2)),
    },
    {
      label: "Another Possible Option",
      isChecked:
        formik.values.proposalWorkTypes?.length !== 0 &&
        containsObject(formik.values.proposalWorkTypes, getWorkTypeById(3)),
      name: "anotherPossibleOption",
      handleChange: (event) => handleCheckBoxChange(event, getWorkTypeById(3)),
    },
  ];

  const updateMaterialList = (material) => {
    formik.setFieldValue("materials", [...formik.values.materials, material]);
  };

  const onSubmitMaterial = (material) => {
    Promise.all([
      addMaterialData(material, ({ data }) => {
        const newMaterial = { id: data.id, ...material };
        dispatchRedux(store.actions.company.addMaterial(newMaterial));
        updateMaterialList(newMaterial);
      }),
    ]);
  };

  const updateRequirementList = (requirement) => {
    formik.setFieldValue("requirements", [
      ...formik.values.requirements,
      requirement,
    ]);
  };

  const onSubmitRequirement = (requirement) => {
    Promise.all([
      addRequirementData(requirement, ({ data }) => {
        const newRequirement = { id: data.id, ...requirement };
        dispatchRedux(store.actions.company.addMaterial(newRequirement));
        updateRequirementList(newRequirement);
      }),
    ]);
  };

  const handleCloseMaterial = () => {
    toggleOpenMaterial(false);
  };

  const handleCloseRequirement = () => {
    toggleOpenRequirement(false);
  };

  useEffect(async () => {
    await Promise.all([
      getMaterialsData({ companyId, divisionId: null }, ({ data }) => {
        dispatchRedux(store.actions.company.setMaterials(data));
      }),
      getRequirementsData({ companyId, divisionId: null }, ({ data }) => {
        dispatchRedux(store.actions.company.setRequirement(data));
      }),
    ]);

    getWorkTypesData().then(({ data }) => {
      setWorkTypes(data);
    });
  }, []);

  return (
    <div className={classes.container}>
      <h4 className={classes.pageTitle}>Details</h4>
      <Grid container className={classes.content}>
        <Grid item xs={3} className={classes.item}>
          <TextField
            disabled={checkPermission([5], roleId)}
            id="bidDueDate"
            label={
              formik.values.statusTypeId >= 4 ? "Bid Due Date*" : "Bid Due Date"
            }
            value={formik.values.bidDueDate}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              formik.touched.bidDueDate && Boolean(formik.errors.bidDueDate)
            }
            helperText={formik.touched.bidDueDate && formik.errors.bidDueDate}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.customField}
          />
          <CheckboxGroup
            label={"Project Type"}
            error={
              formik.touched.proposalWorkTypes &&
              Boolean(formik.errors.proposalWorkTypes)
            }
            options={checkBoxOptions}
            errorText={formik.errors["proposalWorkTypes"]}
          />
        </Grid>
        <Grid item xs={3} className={classes.item}>
          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="description"
            label="Description*"
            multiline={true}
            formik={formik}
            className={classes.textArea}
          />
        </Grid>
        <Grid item xs className={classes.lastItem}>
          <Grid container>
            <Grid item xs>
              <SelectList
                text="Bid Requirements"
                name="requirements"
                options={requirements}
                value={formik.values.requirements}
                handleChange={(value) =>
                  formik.setFieldValue("requirements", value)
                }
                isMultiSelect={true}
                formik={formik}
                className={classes.customField}
                noOptionsText={
                  <>
                    <Button
                      color="primary"
                      variant="contained"
                      onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        toggleOpenRequirement(true);
                      }}
                    >
                      Add new Requirement
                    </Button>
                  </>
                }
                onInputChange={(event) => {
                  setRequirementName(event.target.value);
                }}
              />
              <CustomAutocompleteAdd
                name={requirementName}
                companyId={companyId}
                open={openRequirement}
                handleClose={handleCloseRequirement}
                title="Add a new Requirement"
                onSubmit={onSubmitRequirement}
              />
            </Grid>
            <Grid item xs>
              <SelectList
                text="Special Materials"
                name="materials"
                options={materials}
                value={formik.values.materials}
                handleChange={(value) =>
                  formik.setFieldValue("materials", value)
                }
                isMultiSelect={true}
                formik={formik}
                className={classes.customField}
                onInputChange={(event) => {
                  setMaterialName(event.target.value);
                }}
                noOptionsText={
                  <>
                    <Button
                      color="primary"
                      variant="contained"
                      onMouseDown={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        toggleOpenMaterial(true);
                      }}
                    >
                      Add new Material
                    </Button>
                  </>
                }
              />
              <CustomAutocompleteAdd
                name={materialName}
                companyId={companyId}
                open={openMaterial}
                handleClose={handleCloseMaterial}
                title="Add a new Material"
                onSubmit={onSubmitMaterial}
              />
            </Grid>
          </Grid>

          <CustomTextField
            disabled={checkPermission([5], roleId)}
            name="notes"
            label="Walkthrough / PM Notes*"
            multiline={true}
            handleChange={formik.handleChange}
            formik={formik}
            className={classes.textArea}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "4rem",
  },
  content: {
    paddingLeft: 15,
  },
  item: {
    minWidth: "10rem",
    marginRight: "2rem",
  },
  lastItem: {
    minWidth: "10rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    borderRadius: 5,
  },
  customField: {
    minHeight: "3rem",
    margin: "0",
    marginTop: "0.35rem",
    paddingRight: "0.25rem",
    width: "90%",
  },
  textArea: {
    resize: "both",
  },
  hr: {
    border: "solid 1px dimgrey !important",
  },
}));

export default ProposalDetails;
