import React, { useRef, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const CHART_ID = "population_chart";

const ProjectDashboardChart = (props) => {
  const chartRef = useRef(null);
  const { pipelineProjects, backlogProjects } = props;
  const data = [
    {
      status: "Pipeline",
      projects: pipelineProjects ? Math.trunc(pipelineProjects) : 0,
    },
    {
      status: "Backlog",
      projects: backlogProjects ? Math.trunc(backlogProjects) : 0,
    },
  ];
  useEffect(() => {
    if (!chartRef.current) {
      // Themes begin
      am4core.useTheme(am4themes_animated);
      // Themes end
      chartRef.current = am4core.create(CHART_ID, am4charts.PieChart);

      chartRef.current.data = data;

      // Create series
      const series = chartRef.current.series.push(new am4charts.PieSeries());
      series.dataFields.value = "projects";
      series.dataFields.category = "status";

      // change the cursor on hover to make it apparent the object can be interacted with
      series.slices.template.cursorOverStyle = [
        {
          property: "cursor",
          value: "pointer",
        },
      ];
      // change the colors on the series
      const colorSet = new am4core.ColorSet();
      colorSet.list = ["#7995E0", "#AFDA95"].map(
        (color) => new am4core.color(color)
      );
      series.colors = colorSet;

      // set the border around the categories
      //   series.slices.template.stroke = am4core.color("#4a2abb");
      series.slices.template.strokeWidth = 1;
      //   pieSeries.slices.template.adapter.add("stroke", (value, target, key) => {
      //     // return what color you want here...
      //     return chart.colors.getIndex(target.dataItem.index);
      // });

      // Disable ticks from label to chart
      series.ticks.template.disabled = true;

      // Disable labels on the chart
      series.labels.template.disabled = true;

      // change the value of the tooltip text
      /* eslint no-template-curly-in-string: "off" */ // amchart format for tooltipText
      series.slices.template.tooltipText = "{category}: ${value.value}";

      // disable hover effect
      const hs = series.slices.template.states.getKey("hover");
      hs.properties.scale = 1;

      // disable active/"click on it" effect
      const as = series.slices.template.states.getKey("active");
      as.properties.shiftRadius = 0;

      // hole radius of the chart
      chartRef.current.innerRadius = am4core.percent(60);

      // Add a legend
      chartRef.current.legend = new am4charts.Legend();

      // change the legend text
      chartRef.current.legend.valueLabels.template.text = "";
    }

    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  // Load data into chart
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data;
    }
  }, [data]);

  // Handle component unmounting, dispose chart
  useEffect(
    () => () => {
      chartRef.current && chartRef.current.dispose();
    },
    []
  );

  return (
    <div
      id={CHART_ID}
      style={{
        width: "100%",
        height: "300px",
      }}
    />
  );
};

export default ProjectDashboardChart;
