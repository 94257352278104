import server from "../api/server";
import {
  getContactsIdArrayFromCards,
  getIdsFromObjects,
} from "../utils/helperFunctions";
import * as store from "../state";
import * as API from "./_apiCalls";

export const addProject = async (
  project,
  modified,
  onSuccess,
  onSuccessEdit,
  roleId,
  projectType,
  dispatchRedux
) => {
  const newProject = {
    projectGeneralInfoModelWrapper: {
      projectGeneralInformationModel: {
        id: project.id,
        customer: project.customer,
        name: project.name,
        description: project.description,
        proposalNumber: project.proposalNumber,
        projectNumber: project.projectNumber || null,
        divisionId: project.divisionId,
        subsidiaryId: project.subsidiaryId,
        companyId: project.companyId,
        projectStatusTypeId: project.projectStatusTypeId,
      },
      isModified: modified.generalInformation,
    },

    projectDetailsModelWrapper: {
      projectDetailsModel: !modified.projectDetails
        ? null
        : {
            typeOfWorkId: parseInt(project.typeOfWorkId, 10),
            childProjects: getIdsFromObjects(project.childProjects),
            constructionTypeId: parseInt(project.constructionTypeId, 10),
            marketSectorId: parseInt(project.marketSectorId, 10),
            marketSegmentId: project.marketSegmentId,
            marketCategoryId: project.marketCategoryId,
            marketSubcategoryId: project.marketSubcategoryId,
            expectedEndDate: project.expectedEndDate || null,
            expectedStartDate: project.expectedStartDate || null,
            isMinorityParticipationGoal:
              project.isMinorityParticipationGoal === "1",
            location: {
              id: project.locationId,
              address1: project.address,
              address2: project.address2,
              city: project.city,
              stateId: project.state,
              postalCode: project.zipCode,
              countyId: project.county,
              projectRef: project.id,
            },
          },
      isModified: modified.projectDetails,
    },
    biddingModelWrapper: {
      biddingModel: !modified.bidding
        ? null
        : {
            id: project.biddingId,
            bidDueDate: project.bidDueDate || null,
            bidValue:
              project.bidValue === "0" || project.bidValue === 0
                ? 0
                : parseFloat(project.bidValue) || null,
            notes: project.notes,
            projectId: project.id,
            leadEstimator:
              project.leadEstimator === null ? 0 : project.leadEstimator.id,
            leadProjectManager:
              project.leadProjectManager === null
                ? 0
                : project.leadProjectManager.id,
            materials: getIdsFromObjects(project.materials),
            requirements: getIdsFromObjects(project.requirements),
            estimators: getIdsFromObjects(project.estimators),
            projectManagers: getIdsFromObjects(project.projectManagers),
            projectCoordinators: getIdsFromObjects(project.projectCoordinators),
            estimatedCost:
              project.estimatedCost === "0" || project.estimatedCost === 0
                ? 0
                : parseFloat(project.estimatedCost) || null,
            estimatedLaborCost:
              project.estimatedLaborCost === "0" ||
              project.estimatedLaborCost === 0
                ? 0
                : parseFloat(project.estimatedLaborCost) || null,
            estimatedLaborHours:
              project.estimatedLaborHours === "0" ||
              project.estimatedLaborHours === 0
                ? 0
                : parseFloat(project.estimatedLaborHours) || null,
          },
      isModified: modified.bidding,
    },
    contractInformationModelWrapper: {
      contractModel: !modified.contractInformation
        ? null
        : {
            id: project.contractId,
            projectId: project.id,
            awardedDate: project.awardedDate || null,
            isTaxExempt: project.isTaxExempt === "1",
            contractTypeId: parseInt(project.contractType, 10) || null,
            startDate: project.startDate || null,
            endDate: project.endDate || null,
            originalContractAmount:
              project.originalContractAmount === "0"
                ? 0
                : parseFloat(project.originalContractAmount),
            originalEstimatedLaborCost:
              project.originalEstimatedLaborCost === "0"
                ? 0
                : parseFloat(project.originalEstimatedLaborCost),
            originalEstimatedLaborHours:
              project.originalEstimatedLaborHours === "0"
                ? 0
                : parseFloat(project.originalEstimatedLaborHours),
            originalEstimatedTotalCost:
              project.originalEstimatedTotalCost === "0"
                ? 0
                : parseFloat(project.originalEstimatedTotalCost),
          },
      isModified: modified.contractInformation,
    },
    contactModelWrapper: {
      contactModel: !modified.contacts
        ? null
        : getContactsIdArrayFromCards(project.contacts, project.id),
      isModified:
        parseInt(project.id) === 0 || project.projectStatusTypeId === 1
          ? false
          : modified.contacts,
    },
    JpacInterconnectionWrapper: {
      JpacProjectDetailsModel: !modified.JpacInterconnection
        ? null
        : {
            ProjectId: project.id,
            JpacProjectId: project.JpacProjectId || null,
            ProjectName: project.JpacProjectName || null,
            ProjectNumber: project.JpacProjectNumber || null,
            Complete: project.JpacComplete || null,
            CompositeRate: project.JpacCompositeRate || null,
            WBSHours: project.JpacWBSHours || null,
            updateDate: project.JpacUpdateDate || null,
            ProductivityDifferential:
              project.JpacProductivityDifferential || null,
          },
      isModified: modified.JpacInterconnection,
    },
    AccountingWrapper: {
      AccountingModel: !modified.accounting
        ? null
        : {
            id: project.accountingId,
            ProjectId: project.id,
            totalCostToDate: parseFloat(project.totalCostToDate) || null,
            totalLaborCostIncurredToDate:
              parseFloat(project.totalLaborCostIncurredToDate) || null,
            totalLaborHoursToDate:
              parseFloat(project.totalLaborHoursToDate) || null,
            createdAt: project.createdAt,
            updatedAt: project.updatedAt,
          },
      isModified: modified.accounting,
    },
  };

  const response = await server({
    url: "/project/addproject",
    method: "POST",
    data: newProject,
  });

  if (response.status === 200) {
    const content = response.data;
    if (roleId === 1 || roleId === 2) {
      await Promise.all([
        getDashboardReportsToStore(project.companyId, { dispatchRedux }),
        getProjectsToStore(
          { companyId: project.companyId, projectType },
          {
            dispatchRedux,
          }
        ),
      ]);
    } else {
      await Promise.all([
        getProjectsToStore(
          {
            companyId: project.companyId,
            projectType,
          },
          {
            dispatchRedux,
          }
        ),
      ]);
    }

    if (
      content &&
      content.projectGeneralInformationModel &&
      content.projectGeneralInformationModel.id !== project.id
    ) {
      const proposalNumber = await getProjectProposalNumber(
        content.projectGeneralInformationModel.id
      );
      onSuccess(proposalNumber);
    } else {
      onSuccessEdit();
    }
  }
};

export const createProject = async (
  project,
  onSuccess,
  roleId,
  dispatchRedux
) => {
  const response = await server({
    url: "/project/createProject",
    method: "POST",
    data: project,
  });

  if (response.status === 200) {
    const content = response.data;

    if (roleId === 1 || roleId === 2) {
      await Promise.all([
        getDashboardReportsToStore(project.companyId, { dispatchRedux }),
        getProjectsToStore(
          { companyId: project.companyId },
          {
            dispatchRedux,
          }
        ),
      ]);
    } else {
      await Promise.all([
        getProjectsToStore(
          {
            companyId: project.companyId,
          },
          {
            dispatchRedux,
          }
        ),
      ]);
    }

    const proposalNumber = await getProjectProposalNumber(content.id);

    onSuccess(proposalNumber, project.name, content.id);
  }
};

export const updateProject = async (
  project,
  dispatchRedux,
  roleId,
  onSuccess
) => {
  const response = await server({
    url: "/project/updateProject",
    method: "POST",
    data: project,
  });

  if (response.status === 200) {
    onSuccess(project.name);

    if (roleId === 1 || roleId === 2) {
      await Promise.all([
        getDashboardReportsToStore(project.companyId, { dispatchRedux }),
        getProjectsToStore(
          { companyId: project.companyId },
          {
            dispatchRedux,
          }
        ),
      ]);
    } else {
      await Promise.all([
        getProjectsToStore(
          { companyId: project.companyId },
          {
            dispatchRedux,
          }
        ),
      ]);
    }
    await getProject(dispatchRedux, project.id);
    await getProjectHeaderInfoToStore(dispatchRedux, project.id);
  }
};

export const removeProjects = async (projects) => {
  await server({
    url: "/project/removeprojects",
    method: "PUT",

    data: projects,
  });
};

export const deleteProjects = async (projectIds) => {
  const baseUrl = "/project/deleteProjects";

  const rawResponse = await server({
    url: baseUrl,
    method: "PUT",
    data: JSON.stringify(projectIds),
  });

  if (rawResponse.status === 200) {
    return projectIds;
  }
  return [];
};

export const getProjectsToStore = async (
  { companyId, projectType, filter },
  { dispatchRedux }
) => {
  return await getProjects(companyId, projectType, filter).then((data) => {
    dispatchRedux(store.actions.project.setProjects(data));
  });
};

export const getProjects = async (companyId, projectType, filter) => {
  let baseUrl = "/project/getProjects";

  if (companyId) {
    baseUrl += `?companyId=${companyId}`;
  }

  if (projectType) {
    baseUrl += `&projectType=${projectType}`;
  }

  if (filter) {
    baseUrl += `&filter=${filter}`;
  }

  const response = await server({
    url: baseUrl,
    method: "GET",
  });
  const content = response.data;
  return content;
};

export const getProjectHeaderInfoToStore = async (dispatchRedux, projectId) => {
  const response = await server({
    url: `/project/getProjectHeaderInfo/${projectId}`,
    method: "GET",
  });
  const content = response.data;

  dispatchRedux(store.actions.project.setProjectHeaderInfo(content));
  return content;
};

export const getProject = async (dispatchRedux, projectId) => {
  const response = await server({
    url: `/project/getProject/${projectId}`,
    method: "GET",
  });
  const content = response.data;

  dispatchRedux(store.actions.project.setProject(content));

  return content;
};

export const addCard = async (values) => {
  const card = {
    customerId: values.customerId,
    projectId: values.projectId,
    personId: values.person.id,
    isCustomer: values.isCustomer,
  };
  await server({
    url: "/contactCard/AddContactCard",
    method: "POST",
    data: card,
  });
};

export const getGeneralInformation = async (projectId) => {
  const response = await server({
    url: `/project/getprojectGeneralinformation/${projectId}`,
    method: "GET",
  });
  const content = response.data;
  return content;
};

export const getProjectDetails = async (projectId) => {
  const response = await server({
    url: `/project/getProjectDetails/${projectId}`,
    method: "GET",
  });
  const content = response.data;
  return content;
};

export const getBidding = async (projectId) => {
  const response = await server({
    url: `/bidding/getBidding?projectId=${projectId}`,
    method: "GET",
  });
  if (response.status === 204) {
    return { id: 0 };
  }
  const content = response.data;
  return content;
};

//TO DO - to be removed after new modal is merged
export const getContractInformation = async (projectId) => {
  const response = await server({
    url: `/contract/getContract?projectId=${projectId}`,
    method: "GET",
  });
  if (response.status === 204) {
    return { id: 0 };
  }
  const content = response.data;
  return content;
};

export const getProjectContacts = async (projectId) => {
  try {
    const response = await server({
      url: `/contactcard/GetContactCards?projectId=${projectId}`,
      method: "GET",
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

//CONTRACT
export const getContractToStore = async (projectId, dispatchRedux) => {
  const data = await API.getData(
    `/contract/GetContract?projectId=${projectId}`
  );

  dispatchRedux(store.actions.project.setContractInfo(data.data));

  return data;
};

export const createContract = async (contract, dispatchRedux, onSuccess) => {
  const response = await server({
    url: "/contract/createContract",
    method: "POST",
    data: contract,
  });

  if (response.status === 200) {
    onSuccess();
    await getProjectHeaderInfoToStore(dispatchRedux, contract.projectId);
    const newContract = await API.getData(
      `/contract/GetContract?projectId=${contract.projectId}`
    );

    dispatchRedux(store.actions.project.setContractInfo(newContract.data));
  }
};

export const updateContract = async (contract, dispatchRedux, onSuccess) => {
  const response = await server({
    url: "/contract/updateContract",
    method: "POST",
    data: contract,
  });

  if (response.status === 200) {
    onSuccess();
    await getProjectHeaderInfoToStore(dispatchRedux, contract.projectId);
    dispatchRedux(store.actions.project.setContractInfo(contract));
  }
};

//JPAC
export const getProjectJpacInfoToStore = async (dispatchRedux, projectId) => {
  const content = await getProjectJpacInfo(projectId);

  dispatchRedux(store.actions.project.setJPACInfo(content));
  return content;
};

export const getProjectJpacInfo = async (projectId) => {
  const baseUrl = `/project/getProjectJpacInfo?projectId=${projectId}`;
  const response = await server({
    url: baseUrl,
    method: "GET",
  });
  const content = response.data;
  return content;
};

export const updateJpacInfo = async (jpac, dispatchRedux, onSuccess) => {
  const response = await server({
    url: "/project/UpdateProjectWithJpacInfo",
    method: "POST",
    data: jpac,
  });

  if (response.status === 200) {
    onSuccess();
    dispatchRedux(store.actions.project.setJPACInfo(jpac));
  }
};

//ACCOUNTING HOURS
export const getAccountingHoursToStore = async (dispatchRedux, projectId) => {
  const content = await getAccounting(projectId);

  dispatchRedux(store.actions.project.setAccountingHoursInfo(content));
  return content;
};

export const getAccounting = async (projectId) => {
  const baseUrl = `/accounting/getAccounting?projectId=${projectId}`;
  const response = await server({
    url: baseUrl,
    method: "GET",
  });
  const content = response.data;
  return content;
};

export const createAccountingHours = async (
  accountingHours,
  dispatchRedux,
  onSuccess
) => {
  const response = await server({
    url: "/accounting/createAccounting",
    method: "POST",
    data: accountingHours,
  });

  if (response.status === 200) {
    onSuccess();
    dispatchRedux(
      store.actions.project.setAccountingHoursInfo({
        ...accountingHours,
        updatedAt: new Date(),
      })
    );
  }
};

export const updateAccountingHours = async (
  accountingHours,
  dispatchRedux,
  onSuccess
) => {
  const response = await server({
    url: "/accounting/updateAccounting",
    method: "POST",
    data: accountingHours,
  });

  if (response.status === 200) {
    onSuccess();
    dispatchRedux(
      store.actions.project.setAccountingHoursInfo({
        ...accountingHours,
        updatedAt: new Date(),
      })
    );
  }
};

export const getDashboardReportsToStore = async (
  companyId,
  { dispatchRedux }
) => {
  return await getDashboardReports(companyId).then((data) => {
    dispatchRedux(store.actions.project.setDashboardReports(data));
  });
};

export const getDashboardReports = async (companyId) => {
  const response = await server({
    url: `/report/GetDashboardReport?companyId=${companyId}`,
    method: "GET",
  });
  return response.data;
};

export const getProjectProposalNumber = async (projectId) => {
  const response = await server({
    url: `/project/GetProjectProposalNumber?projectId=${projectId}`,
    method: "GET",
  });

  return response.data;
};
