import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let dashboardTheme = createMuiTheme({
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      light: "#1e88dd",
      main: "#1e88dd",
      dark: "#3182ce",
      contrastText: "#fff",
    },
    secondary: {
      light: "#e2e8f0",
      main: "#9e9e9e",
      dark: "#718096",
      contrastText: "#fff",
    },
    success: {
      light: "#40a56f",
      main: "#13a171",
      dark: "#057a55",
      contrastText: "#fff",
    },
    info: {
      light: "#63b3ed",
      main: "#64b5f6",
      dark: "#3182ce",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: "0rem",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
      },
      outlined: {
        color: "black",
        border: "1px solid #9e9e9e",
        margin: "1rem",
      },
      contained: {
        margin: "1rem",
        color: "white",
      },
    },
    MuiCardContent: {
      root: {
        padding: "1rem",
        "@media (max-width:800px)": {
          // smaller padding for smaller screen
          padding: "0.5rem",
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "1rem",
        overflowY: "hidden",
      },
      paperScrollPaper: {
        // added for the project modal
        display: "block",
        flexDirection: "row",
      },
      paperWidthMd: {
        maxWidth: "50rem",
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
      },
      root: {
        "&$disabled $notchedOutline": {
          borderColor: "none",
        },
      },
      disabled: {},
      notchedOutline: {},
    },
    MuiInputBase: {
      multiline: {
        minHeight: "4rem",
      },
      root: {
        "&$disabled ": {
          color: "inherit",
        },
      },
      disabled: {},
    },
    MuiInput: {
      underline: {
        "&$disabled:before ": {
          borderBottomStyle: "outset",
        },
      },
    },
    MuiTab: {
      wrapper: {
        flexDirection: "unset",
        justifyContent: "left",
      },
      root: {
        textTransform: "capitalize",
      },
    },
    MuiChip: {
      deleteIcon: {
        color: "inherit",
      },
    },
    MuiListItem: {
      root: {
        paddingTop: "0",
        paddingBottom: "0",
      },
    },
    MuiMenu: {
      paper: {
        maxHeight: "20rem",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: "bold",
        fontSize: "1rem",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$disabled ": {
          color: "#64b5f6",
        },
      },
      disabled: {},
    },
  },
});

dashboardTheme = responsiveFontSizes(dashboardTheme);

export default dashboardTheme;
