import React, { useState } from "react";
import { BrowserRouter, Redirect } from "react-router-dom";
import {
  makeStyles,
  useTheme,
  Container,
  CssBaseline,
  Toolbar,
  Drawer,
  Hidden,
} from "@material-ui/core";

import * as am4core from "@amcharts/amcharts4/core";
import * as constants from "../constants";
import Header from "./elements/Header";
import Login from "./login/Login";
import Sidebar from "./elements/Sidebar";
import ProfileSelect from "./elements/ProfileSelect";
import Profile from "./pages/Profile";
import AppRoute from "./customRoutes/AppRoute";
// Project
import AddProject from "./projects/addProject/AddProject";
import ProjectDashboard from "./projects/dashboard/ProjectDashboard";
//Project details
import GeneralInformation from "./projects/projectDetails/generalInformation/GeneralInformation";
import AccountingHours from "./projects/projectDetails/accountingHours/AccountingHours";
import ProposalsDashboard from "./projects/projectDetails/proposalsDashboard/ProposalsDashboard";
import EditProposal from "./projects/projectDetails/addEditProposal/EditProposal";
import Contacts from "./projects/projectDetails/contacts/Contacts";
import JPAC from "./projects/projectDetails/dciInterconnection/JPAC/JPAC";
// Company
import AddCompany from "./company/AddCompany";
import CompanyDashboard from "./company/CompanyDashboard";
import DeleteCompanies from "./company/DeleteCompanies";
import EditAccountSetting from "./company/accountSettings/EditAccountSetting";
// Subsidiary
import AddSubsidiary from "./subsidiaries/AddSubsidiary";
import EditSubsidiary from "./subsidiaries/EditSubsidiary";
import DeleteSubsidiaries from "./subsidiaries/DeleteSubsidiaries";
import SubsidiaryDashboard from "./subsidiaries/SubsidiaryDashboard";
// Division
import AddDivision from "./divisions/AddDivision";
import EditDivision from "./divisions/EditDivision";
import DeleteDivisions from "./divisions/DeleteDivisions";
import DivisionDashboard from "./divisions/DivisionDashboard";
// Reports
import TimeReport from "./reports/pipelineAndBacklog/TimeReport";
import TeamReport from "./reports/pipelineAndBacklog/TeamReport";
import LocationReport from "./reports/pipelineAndBacklog/LocationReport";
import MarketReport from "./reports/pipelineAndBacklog/MarketReport";
import CustomerReport from "./reports/pipelineAndBacklog/CustomerReport";
import AllReports from "./reports/AllReports";
import Loader from "./loader";
// Users
import AddEditUser from "./users/AddEditUser";
import UserDashboard from "./users/UserDashboard";
import AccountsDashboard from "./systemAdministration/accounts/AccountsDashboard";
import uiActivityMonitor from "./uiActivityMontitor";
import ContractInformation from "./projects/projectDetails/contractInformation/ContractInformation";

const App = (props) => {
  // Next line set amCharts to autoDispose
  am4core.options.autoDispose = true;

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();

  const container = (() => {
    const { window } = props;
    return window !== undefined ? () => window().document.body : undefined;
  })();

  uiActivityMonitor();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const NonModalRoutes = (props) => (
    <>
      <Hidden mdUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Toolbar>
            <ProfileSelect />
          </Toolbar>
          <Sidebar />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <Toolbar />
          <Sidebar />
        </Drawer>
      </Hidden>
      <main
        className={
          classes.content + " " + (props.noPadding ? classes.noPadding : "")
        }
      >
        <Toolbar />
        <Container
          maxWidth="none"
          className={classes.container}
          disableGutters={true}
        >
          {props.children}
        </Container>
      </main>
    </>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <BrowserRouter>
        <>
          <Header handleDrawerToggle={handleDrawerToggle} />
          {/* Projects */}
          <AppRoute
            path="/projects"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={(props) => (
              <NonModalRoutes>
                <ProjectDashboard {...props} />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/projectDetails/generalInformation/:id"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={(props) => {
              return (
                <NonModalRoutes noPadding>
                  <GeneralInformation projectId={props.match.params.id} />
                </NonModalRoutes>
              );
            }}
          />
          <AppRoute
            path="/projectDetails/proposalsDashboard/:id"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={(props) => {
              return (
                <NonModalRoutes noPadding>
                  <ProposalsDashboard projectId={props.match.params.id} />
                </NonModalRoutes>
              );
            }}
          />
          <AppRoute
            path="/projectDetails/contractInformation/:id"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={(props) => {
              return (
                <NonModalRoutes noPadding>
                  <ContractInformation projectId={props.match.params.id} />
                </NonModalRoutes>
              );
            }}
          />
          <AppRoute
            path="/projectDetails/:projectId/editProposal/:id"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={(props) => {
              return (
                <NonModalRoutes noPadding>
                  <EditProposal
                    projectId={props.match.params.projectId}
                    proposalId={props.match.params.id}
                  />
                </NonModalRoutes>
              );
            }}
          />
          <AppRoute
            path="/projectDetails/contacts/:projectId"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={(props) => {
              return (
                <NonModalRoutes noPadding>
                  <Contacts projectId={props.match.params.projectId} />
                </NonModalRoutes>
              );
            }}
          />
          <AppRoute
            path="/projectDetails/accountingHours/:id"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={(props) => {
              return (
                <NonModalRoutes noPadding>
                  <AccountingHours projectId={props.match.params.id} />
                </NonModalRoutes>
              );
            }}
          />
          <AppRoute
            path="/projectDetails/interconnection/JPAC/:id"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={(props) => {
              return (
                <NonModalRoutes noPadding>
                  <JPAC projectId={props.match.params.id} />
                </NonModalRoutes>
              );
            }}
          />
          <AppRoute
            exact
            path="/projects/addProject"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={() => {
              return <AddProject />;
            }}
          />
          {/* Company */}
          <AppRoute
            path="/company"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <CompanyDashboard {...props} view="companyAdmin" />
              </NonModalRoutes>
            )}
          />

          {/* Subsidiary */}
          <AppRoute
            path="/subsidiaries"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <SubsidiaryDashboard {...props} view="companyAdmin" />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/addSubsidiary"
            allowedRoles={[2, 1]}
            render={() => (
              <NonModalRoutes>
                <AddSubsidiary />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="deleteSubsidiaries"
            allowedRoles={[2, 1]}
            render={(props) => (
              <DeleteSubsidiaries selected={props.location.state.selected} />
            )}
          />
          <AppRoute
            path="/editSubsidiary/:id"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <EditSubsidiary
                  id={props.match.params.id}
                  view="companyAdmin"
                />
              </NonModalRoutes>
            )}
          />
          {/* division */}
          <AppRoute
            path="/divisions"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <DivisionDashboard {...props} view="companyAdmin" />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/addDivision"
            allowedRoles={[2, 1]}
            render={() => (
              <NonModalRoutes>
                <AddDivision />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/divisions/deleteDivisions"
            allowedRoles={[2, 1]}
            render={(props) => (
              <DeleteDivisions selected={props.location.state.selected} />
            )}
          />
          <AppRoute
            path="/editDivision/:id"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <EditDivision id={props.match.params.id} view="companyAdmin" />
              </NonModalRoutes>
            )}
          />
          {/* Users */}
          <AppRoute
            exact
            path="/users"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <UserDashboard {...props} view="companyAdmin" />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            exact
            path="/users/user"
            allowedRoles={[2, 1]}
            render={() => (
              <NonModalRoutes>
                <AddEditUser />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/users/user/:id"
            allowedRoles={[1, 2, 3, 4, 5]}
            render={(props) => (
              <NonModalRoutes>
                <AddEditUser userId={props.match.params.id} inEdit />
              </NonModalRoutes>
            )}
          />
          {/* Reports */}
          <AppRoute
            path="/reports/time"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <TimeReport {...props} />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/reports/team"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <TeamReport {...props} />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/reports/location"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <LocationReport {...props} />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/reports/customer"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <CustomerReport {...props} />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/reports/market"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <MarketReport {...props} />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/reports/allReports"
            allowedRoles={[2, 1]}
            render={(props) => (
              <NonModalRoutes>
                <AllReports {...props} />
              </NonModalRoutes>
            )}
          />
          {/* Super Admin */}
          <AppRoute
            path="/systemAdministration"
            exact
            allowedRoles={[1]}
            render={() => (
              <NonModalRoutes>
                <AccountsDashboard />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/systemAdministration/company/:id"
            exact
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <CompanyDashboard
                  {...props}
                  id={props.match.params.id}
                  view="sysAdmin"
                />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/systemAdministration/company/accountSettings/:id"
            exact
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <EditAccountSetting
                  {...props}
                  id={props.match.params.id}
                  view="sysAdmin"
                />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/systemAdministration/subsidiaries/:id"
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <SubsidiaryDashboard
                  {...props}
                  companyId={props.match.params.id}
                  view="sysAdmin"
                />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/systemAdministration/addSubsidiaries/:id"
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <AddSubsidiary
                  companyId={props.match.params.id}
                  view="sysAdmin"
                />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/systemAdministration/editSubsidiary/:id?"
            allowedRoles={[1]}
            render={(props) => {
              const subsidiaryId = new URLSearchParams(
                props.location.search
              ).get("subsidiaryId");
              return (
                <NonModalRoutes>
                  <EditSubsidiary
                    companyId={props.match.params.id}
                    subsidiaryId={subsidiaryId}
                    view="sysAdmin"
                  />
                </NonModalRoutes>
              );
            }}
          />
          <AppRoute
            path="/systemAdministration/divisions/:id"
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <DivisionDashboard
                  {...props}
                  companyId={props.match.params.id}
                  view="sysAdmin"
                />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/systemAdministration/addDivision/:id"
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <AddDivision
                  {...props}
                  companyId={props.match.params.id}
                  view="sysAdmin"
                />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/systemAdministration/editDivision/:id?"
            allowedRoles={[1]}
            render={(props) => {
              const divisionId = new URLSearchParams(props.location.search).get(
                "divisionId"
              );
              return (
                <NonModalRoutes>
                  <EditDivision
                    {...props}
                    companyId={props.match.params.id}
                    divisionId={divisionId}
                    view="sysAdmin"
                  />
                </NonModalRoutes>
              );
            }}
          />
          <AppRoute
            path="/systemAdministration/users/:id"
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <UserDashboard
                  {...props}
                  companyId={props.match.params.id}
                  view="sysAdmin"
                />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            exact
            path="/systemAdministration/:id/users/user"
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <AddEditUser
                  {...props}
                  companyId={props.match.params.id}
                  view="sysAdmin"
                />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/systemAdministration/:companyId/users/user/:id"
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <AddEditUser
                  {...props}
                  companyId={props.match.params.companyId}
                  view="sysAdmin"
                  userId={props.match.params.id}
                  inEdit
                />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/sysadmin/users"
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <UserDashboard {...props} sysAdmin />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            exact
            path="/sysadmin/user"
            allowedRoles={[1]}
            render={() => (
              <NonModalRoutes>
                <AddEditUser sysAdmin />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/sysadmin/user/:id"
            allowedRoles={[1]}
            render={(props) => (
              <NonModalRoutes>
                <AddEditUser userId={props.match.params.id} inEdit sysAdmin />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/deleteCompanies"
            allowedRoles={[1]}
            render={(props) => (
              <DeleteCompanies selected={props.location.state.selected} />
            )}
          />
          <AppRoute
            path="/addCompany"
            allowedRoles={[1]}
            render={() => (
              <NonModalRoutes>
                <AddCompany />
              </NonModalRoutes>
            )}
          />
          <AppRoute
            path="/user"
            allowedRoles={[2, 1]}
            exact
            component={Profile}
          />
          {/* Other */}
          <AppRoute
            exact
            path="/"
            render={() => <Redirect to={{ pathname: "/projects" }} />}
          />
          <AppRoute exact path="/login" render={() => <Login />} />
        </>
      </BrowserRouter>
      <Loader></Loader>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  content: {
    [theme.breakpoints.up("md")]: {
      marginLeft: constants.DRAWER_WIDTH,
      padding: theme.spacing(3),
    },
    flexGrow: 1,
    height: "100%",
    overflow: "auto",
  },
  container: {
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },
  drawerPaper: {
    width: constants.DRAWER_WIDTH,
    backgroundColor: constants.DRAWER_BACKGROUND_COLOR,
  },
  noPadding: {
    padding: 0,
  },
}));

export default App;
