import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, darken } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Typography,
  Box,
  Icon,
} from "@material-ui/core";
import ProfileSelect from "./ProfileSelect";
import CreateProjectButton from "./CreateProjectButton";
import { checkPermission } from "../../utils/helperFunctions";
import MenuSelect from "./MenuSelect";
import * as store from "../../state";

const Header = (props) => {
  const classes = useStyles();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);

  const projectOptions = [
    {
      name: "Dashboards",
      permissions: [1, 2, 3, 4],
      options: [{ name: "Projects", link: "/projects" }],
    },
    {
      name: "Reports",
      permissions: [1, 2],
      options: [
        { name: "Time", link: "/reports/time" },
        { name: "Project Team", link: "/reports/team" },
        { name: "Customer", link: "/reports/customer" },
        { name: "Market Segment", link: "/reports/market" },
        { name: "Location", link: "/reports/location" },
      ],
    },
    {
      name: "Settings",
      permissions: [1, 2],
      options: [
        { name: "Company", link: "/company" },
        { name: "Subsidiary", link: "/subsidiaries" },
        { name: "Divisions", link: "/divisions" },
        { name: "Users", link: "/users" },
      ],
    },
    {
      name: "Admin Users",
      permissions: [1],
      options: [
        { name: "Accounts", link: "/systemAdministration" },
        { name: "Users", link: "/sysadmin/users" },
      ],
    },
  ];

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftHeaderComponents}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Icon
            className={clsx("fas fa-th-large", classes.logoIcon)}
            fontSize="large"
          />
          <Typography
            component="h1"
            variant="h4"
            noWrap
            className={classes.applicationName}
          >
            <Box>DCI&#8482;</Box>
          </Typography>
          <>
            {projectOptions.map(
              (projectOption) =>
                checkPermission(
                  projectOption.permissions,
                  loggedInUser.roleId
                ) &&
                loggedInUser.userCompany && (
                  <MenuSelect
                    key={projectOption.name}
                    name={projectOption.name}
                    options={projectOption.options}
                  />
                )
            )}
          </>
          <Hidden smDown implementation="css">
            {checkPermission([1, 2, 3], loggedInUser.roleId) &&
              loggedInUser.userCompany && (
                <CreateProjectButton
                  name={"Create project"}
                  link={"/projects/addProject"}
                />
              )}
          </Hidden>
        </div>

        {loggedInUser.userCompany && loggedInUser.roleId === 1 && (
          <Typography
            component="h1"
            variant="h4"
            noWrap
            className={classes.companyName}
          >
            {loggedInUser.userCompany.name}
          </Typography>
        )}
        <Hidden smDown implementation="css">
          <ProfileSelect />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  helpIcon: {
    padding: 0,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  toolbar: theme.mixins.toolbar,
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    color: darken(theme.palette.secondary.main, 0.2),
    background: "white",
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logoIcon: {
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  leftHeaderComponents: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1,
  },
  applicationName: {
    margin: "0 1rem 0 1rem",
    color: "inherit",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
    },
  },
  companyName: {
    marginRight: "1rem",
    color: "inherit",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      margin: "0",
    },
  },
}));

export default Header;
