import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
} from "@material-ui/core";
import TablePaginationActions from "../../../elements/TablePaginationActions";
import { formatNumberUS } from "../../../../utils/helperFunctions";

const TimeReportsTable = (props) => {
  const { data, filters, setQuery, query } = props;
  const classes = useStyles();
  const style = filters.outputValue === "laborHours" ? "hrs" : "$";
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rows = data;
  return (
    <Paper elevation={3}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          margin: "1rem",
          paddingTop: "1rem",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Box>Filter: </Box>
          <TextField
            margin="dense"
            size="small"
            variant="outlined"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
      </div>
      <TableContainer>
        <Table stickyHeader aria-label="time table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Time Period</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Backlog</TableCell>
              <TableCell align="right">Pipeline</TableCell>
              <TableCell align="right">Projects&nbsp;(No.)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
              : rows
            ).map((row, index) => (
              <TableRow className={classes.root} key={index}>
                <TableCell align="right" component="th" scope="row">
                  {row.period}
                </TableCell>
                <TableCell align="right">
                  {formatNumberUS(style, row.total)}
                </TableCell>
                <TableCell align="right">
                  {formatNumberUS(style, row.backlog)}
                </TableCell>
                <TableCell align="right">
                  {formatNumberUS(style, row.pipeline)}
                </TableCell>
                <TableCell align="right">{row.projectsCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePaginationActions
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          count={Math.floor(rows.length / rowsPerPage + 1)}
        />
      </TableContainer>
    </Paper>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      width: "14rem",
    },
  },
  rootCell: {
    "& .center-cell-content": {
      textAlign: "center",
    },
  },

  container: {
    height: "21rem",
    overflow: "scroll",
  },
}));

export default TimeReportsTable;
