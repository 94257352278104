import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

const CreateProjectButton = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = () => {
    history.push(props.link);
  };
  return (
    <Button
      id="createProjectButton"
      variant="contained"
      size="medium"
      className={classes.button}
      onClick={handleClick}
      disabled={props.disabled}
    >
      <Box>{props.name}</Box>
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "0 1rem 0 2rem",
    backgroundColor: theme.palette.info.main,
  },
}));

export default CreateProjectButton;
