import React, { useState, useEffect } from "react";
import { TextField, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import * as store from "../../../state";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import * as constants from "../../../constants";
import {
  getCustomersDataToStore,
  addCustomerData,
} from "../../../actions/companyActions";
import { getSubsidiariesDataToStore } from "../../../actions/subsidiaryActions";
import { checkPermission } from "../../../utils/helperFunctions";
import { getDivisionBySubsidiariesData } from "../../../actions/divisionActions";
import ContactCard from "./ContactCard";
import CustomerAutocompleteAdd from "./CustomerAutocompleteAdd";
import CustomTextField from "../../elements/CustomTextField";

const AddProjectForm = (props) => {
  const { formik, companyId } = props;

  const [openCustomer, setOpenCustomer] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [availableDivisions, setAvailableDivisions] = useState([]);
  const [activeDivisions, setActiveDivisions] = useState([]);
  const [activeSubsidiaries, setActiveSubsidiaries] = useState([]);

  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const subsidiaries = useSelector(
    store.selectors.subsidiary.selectSubsidiaries
  );
  const customers = useSelector(store.selectors.company.selectCustomers);
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const { roleId } = loggedInUser;

  const onSubmitCustomer = (customer) => {
    addCustomerData(customer, ({ data }) => {
      const newCustomer = { id: data.id, ...customer };
      handleCustomerAdd(newCustomer);
      dispatchRedux(store.actions.company.addCustomer(newCustomer));
    });
  };

  const onRemoveCustomer = () => {
    formik.setFieldValue("customer", null);
  };

  const handleCustomerAdd = (customer) => {
    changeCustomer(customer);
  };

  const changeCustomer = (customer) => {
    formik.setFieldValue("customer", customer);
  };

  useEffect(async () => {
    await Promise.all([
      getCustomersDataToStore(companyId, { dispatchRedux }),
      getSubsidiariesDataToStore(companyId, { dispatchRedux }),
    ]);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const newDivisions = await getDivisionBySubsidiariesData([
        { id: [formik.values.subsidiary.id] },
      ]);
      if (Array.isArray(newDivisions.data) && newDivisions.data.length > 0) {
        setAvailableDivisions(newDivisions.data[0].divisions || []);
      }
    }

    if (formik.values.subsidiary) {
      fetchData();
    } else {
      setAvailableDivisions([]);
    }

    formik.setFieldValue("division", null);
  }, [formik.values.subsidiary]);

  useEffect(() => {
    if (Array.isArray(activeDivisions) && activeDivisions.length === 1) {
      formik.setFieldValue("division", activeDivisions[0]);
    } else {
      formik.setFieldValue("division", null);
    }
  }, [activeDivisions]);

  useEffect(() => {
    if (Array.isArray(activeSubsidiaries) && activeSubsidiaries.length === 1) {
      formik.setFieldValue("subsidiary", activeSubsidiaries[0]);
    } else {
      formik.setFieldValue("subsidiary", null);
    }
  }, [activeSubsidiaries]);

  useEffect(() => {
    setActiveSubsidiaries(
      subsidiaries.filter((subsidiary) => subsidiary.statusId === 1)
    );
  }, [subsidiaries]);

  useEffect(() => {
    setActiveDivisions(
      availableDivisions.filter((division) => division.statusId === 1)
    );
  }, [availableDivisions]);

  return (
    <>
      <div>
        <CustomTextField
          disabled={checkPermission([5], roleId)}
          name="name"
          label="Project Name*"
          formik={formik}
          className={classes.customField}
        />
        {Array.isArray(activeSubsidiaries) &&
          activeSubsidiaries.length !== 1 && (
            <Autocomplete
              style={{ marginTop: "0.5rem" }}
              id="subsidiary"
              name="subsidiary"
              formik={formik}
              value={formik.values.subsidiary}
              options={activeSubsidiaries}
              getOptionLabel={(option) => `${option.name}`}
              onChange={(event, value) =>
                value
                  ? formik.setFieldValue("subsidiary", value)
                  : formik.setFieldValue("subsidiary", null)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="standard"
                  label={"Subsidiary*"}
                  className={classes.autoCompleteInput}
                  error={
                    formik
                      ? formik.touched["subsidiary"] &&
                        Boolean(formik.errors["subsidiary"])
                      : null
                  }
                  helperText={
                    formik
                      ? formik.touched["subsidiary"] &&
                        formik.errors["subsidiary"]
                      : null
                  }
                />
              )}
            />
          )}
        {Array.isArray(activeDivisions) && activeDivisions.length !== 1 && (
          <Autocomplete
            style={{ marginTop: "0.5rem" }}
            id="division"
            name="division"
            formik={formik}
            value={formik.values.division}
            options={activeDivisions}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(event, value) =>
              value
                ? formik.setFieldValue("division", value)
                : formik.setFieldValue("division", null)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label={"Division*"}
                className={classes.autoCompleteInput}
                error={
                  formik
                    ? formik.touched["division"] &&
                      Boolean(formik.errors["division"])
                    : null
                }
                helperText={
                  formik
                    ? formik.touched["division"] && formik.errors["division"]
                    : null
                }
              />
            )}
          />
        )}

        {formik.values.customer ? (
          <ContactCard
            contact={{
              customer: formik.values.customer,
              isCustomer: true,
              person: formik.values.customer,
            }}
            tab="generalInfo"
            onRemove={onRemoveCustomer}
          />
        ) : (
          <>
            <Autocomplete
              disableCloseOnSelect
              disabled={checkPermission([5], roleId)}
              style={{ marginTop: "0.5rem" }}
              id="customer"
              value={formik.values.customer}
              options={customers}
              getOptionLabel={(option) => `${option.name}`}
              onChange={(event, value) => {
                changeCustomer(value);
              }}
              onInputChange={(event) => {
                setCustomerName(event.target.value);
              }}
              noOptionsText={
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onMouseDown={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setOpenCustomer(true);
                    }}
                  >
                    Add new Customer
                  </Button>
                </>
              }
              renderInput={(params) => (
                <TextField
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.customer && Boolean(formik.errors.customer)
                  }
                  helperText={formik.touched.customer && formik.errors.customer}
                  {...params}
                  label={" Customer* (Bid Proposal Requestor)"}
                  className={classes.autoCompleteInput}
                />
              )}
            />
            <CustomerAutocompleteAdd
              name={customerName}
              companyId={companyId}
              open={openCustomer}
              handleClose={() => setOpenCustomer(false)}
              title="Add a new Customer"
              onSubmit={onSubmitCustomer}
            />
          </>
        )}

        <CustomTextField
          disabled={checkPermission([5], roleId)}
          name="description"
          label="Description*"
          formik={formik}
          className={classes.customField}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.7rem 0 0 0",
  },
  treeView: {
    margin: "0.5rem 0 0 0",
  },
  asterisk: {
    color: constants.ASTERISK_COLOR,
  },
  button: {
    backgroundColor: theme.palette.info.light,
  },
  logoInput: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  customer: {
    padding: "1rem",
    minHeight: "12rem",
    margin: "0.5rem 0",
  },
  selectedCustomer: {
    padding: "1rem",
    minHeight: "2rem",
    margin: "0.5rem 0",
  },
  addButton: {
    backgroundColor: theme.palette.info.dark,
    margin: "0rem",
  },
  customField: {
    minHeight: "3rem",
    margin: "0",
    marginTop: "0.35rem",
  },
}));

export default AddProjectForm;
