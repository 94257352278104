export const REPORT_SET_TIME_REPORT_ACTION = "REPORT_SET_TIME_REPORT_ACTION";
export const REPORT_SET_TEAM_REPORT_ACTION = "REPORT_SET_TEAM_REPORT_ACTION";
export const REPORT_SET_CUSTOMER_REPORT_ACTION =
  "REPORT_SET_CUSTOMER_REPORT_ACTION";
export const REPORT_SET_LOCATION_REPORT_ACTION =
  "REPORT_SET_LOCATION_REPORT_ACTION";
export const REPORT_SET_MARKET_REPORT_ACTION =
  "REPORT_SET_MARKET_REPORT_ACTION";
export const REPORT_SET_TEAM_REPORTS_EXTENDED_TABLE =
  "REPORT_SET_TEAM_REPORTS_EXTENDED_TABLE";
export const REPORT_SET_LOCATION_REPORTS_EXTENDED_TABLE =
  "REPORT_SET_LOCATION_REPORTS_EXTENDED_TABLE";
export const REPORT_SET_MARKET_REPORTS_EXTENDED_TABLE =
  "REPORT_SET_MARKET_REPORTS_EXTENDED_TABLE";
export const REPORT_SET_CUSTOMER_REPORTS_EXTENDED_TABLE =
  "REPORT_SET_CUSTOMER_REPORTS_EXTENDED_TABLE";

export const setTimeReport = (payload) => ({
  type: REPORT_SET_TIME_REPORT_ACTION,
  payload,
});

export const setTeamReport = (payload) => ({
  type: REPORT_SET_TEAM_REPORT_ACTION,
  payload,
});

export const setCustomerReport = (payload) => ({
  type: REPORT_SET_CUSTOMER_REPORT_ACTION,
  payload,
});

export const setLocationReport = (payload) => ({
  type: REPORT_SET_LOCATION_REPORT_ACTION,
  payload,
});

export const setMarketReport = (payload) => ({
  type: REPORT_SET_MARKET_REPORT_ACTION,
  payload,
});

export const setTeamExpendableTableReport = (payload) => ({
  type: REPORT_SET_TEAM_REPORTS_EXTENDED_TABLE,
  payload,
});

export const setLocationExpendableTableReport = (payload) => ({
  type: REPORT_SET_LOCATION_REPORTS_EXTENDED_TABLE,
  payload,
});

export const setCustomerExpendableTableReport = (payload) => ({
  type: REPORT_SET_CUSTOMER_REPORTS_EXTENDED_TABLE,
  payload,
});

export const setMarketExpendableTableReport = (payload) => ({
  type: REPORT_SET_MARKET_REPORTS_EXTENDED_TABLE,
  payload,
});
