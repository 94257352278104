import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import ProjectHeader from "../ProjectHeader";
import ProjectTitle from "../ProjectTitle";
import ContactCard from "./ContactCard";
import {
  getCustomerFromCards,
  getOtherContactsFromCards,
  checkPermission,
} from "../../../../utils/helperFunctions";
import NotificationPopUp from "../../../utils/NotificationPopUp";
import * as store from "../../../../state";
import {
  getContactsData,
  updateContactCards,
} from "../../../../actions/contactsActions";
import OtherContactCards from "./OtherContactCards";
import AddContactCard from "./AddContactCard";

const Contacts = (props) => {
  const { projectId } = props;
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const classes = useStyles();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const contacts = useSelector(store.selectors.contacts.selectContacts);

  const { roleId } = loggedInUser;

  const initialValuesForContacts = {
    id: projectId,
    contacts: [],
  };

  const [initialValues, setInitialValues] = useState(initialValuesForContacts);
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const onSuccess = () => {
    setNotificationTitle("Contacts updated");
    setIsNotificationPopUpOpen(true);
    setNotificationText(<p>Contacts successfully updated.</p>);
    setNotificationType("success");
    setNotificationActions([
      {
        name: "OK",
        action: () => {
          handleCloseModal();
        },
      },
    ]);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateContactCards(dispatchRedux, values.contacts, onSuccess);
    },
  });

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleCancel = () => {
    history.push("/projects");
  };

  const editContact = (contact) => {
    const contactIndex = formik.values.contacts.findIndex(
      (element) => element.customer.id === contact.customer.id
    );
    const newContacts = formik.values.contacts;
    newContacts[contactIndex] = contact;
    formik.setFieldValue("contacts", newContacts);
  };

  useEffect(() => {
    if (contacts && contacts[0]) {
      setInitialValues({
        ...initialValuesForContacts,
        contacts: contacts,
      });
    }
  }, [contacts]);

  useEffect(() => {
    async function fetchData() {
      await getContactsData(dispatchRedux, projectId);
    }
    fetchData();
  }, []);

  return (
    <>
      <ProjectHeader projectId={projectId} />
      <div className={classes.container}>
        <ProjectTitle
          pageTitle={"Contacts"}
          actions={[
            {
              name: "Cancel",
              action: () => handleCancel(),
              type: "default",
            },
            {
              name: "Save",
              action: () => formik.handleSubmit(),
              type: "success",
            },
          ]}
        />

        {getCustomerFromCards(formik.values.contacts) && (
          <ContactCard
            contact={getCustomerFromCards(formik.values.contacts)}
            tab={checkPermission([5], roleId) ? "generalInfo" : "contacts"}
            editContact={editContact}
          />
        )}
        <OtherContactCards
          contacts={getOtherContactsFromCards(formik.values.contacts)}
          formik={formik}
        />
        <AddContactCard projectId={projectId} formik={formik} />
      </div>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
}));

export default Contacts;
