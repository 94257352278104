import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Divider, Box, Paper, Button } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const pageTypes = ["Accounts", "Users"];

const SystemAdministrationTabs = (props) => {
  const history = useHistory();
  const [selectedPage, setSelectedPage] = useState(pageTypes[0]);
  const classes = useStyles();

  const handleAccounts = () => {
    setSelectedPage(pageTypes[0]);
    history.push("/systemAdministration");
  };

  const handleUsers = () => {
    setSelectedPage(pageTypes[1]);
    history.push("/sysadmin/users");
  };

  useEffect(() => {
    setSelectedPage(pageTypes[props.selectedPage]);
  }, [props.selectedPage]);

  return (
    <>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        SYSTEM ADMINISTRATION
      </Box>
      <Divider className={classes.divider} />
      <div className={classes.titleButtons}>
        <Button
          variant="text"
          size="large"
          className={
            selectedPage === pageTypes[0]
              ? classes.projectTypeButtonSelected
              : classes.projectTypeButtonNotSelected
          }
          onClick={() => handleAccounts()}
        >
          ACCOUNTS
        </Button>
        <Button
          variant="text"
          size="large"
          className={
            selectedPage === pageTypes[1]
              ? classes.projectTypeButtonSelected
              : classes.projectTypeButtonNotSelected
          }
          onClick={() => handleUsers()}
        >
          USERS
        </Button>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  divider: {
    margin: "1rem 0",
  },
  titleButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& button": {
      minWidth: "8rem",
      marginRight: ".5rem",
      marginBottom: "2rem",
      borderRadius: "0rem",
      fontSize: theme.typography.h6.fontSize,
    },
  },
  projectTypeButtonSelected: {
    borderBottom: `0.2rem solid ${theme.palette.info.main}`,
    color: theme.palette.info.main,
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  projectTypeButtonNotSelected: {
    borderBottom: "0.2rem solid #BEE3F8",
    color: "#BEE3F8",
  },
}));

export default SystemAdministrationTabs;
