import * as actions from "../action/company";
import _ from "lodash";

const initialState = {
  customers: [],
  persons: [],
  materials: [],
  requirements: [],
  companies: [],
  company: {
    maxNumberOfSubsidiaries: 0,
  },
  availableDivisions: 0,
  availableUserSeats: 0,
  availableSubsidiaries: 0,
};

const setProperty = (state, propName, action) => {
  return {
    ...state,
    [propName]: action.payload,
  };
};

const editCompanyStatus = (state, statusId, companyIds) => {
  let updateCompanies;
  if (statusId === 3) {
    updateCompanies = state.companies.filter(
      (company) => !companyIds.includes(company.id)
    );
  } else {
    updateCompanies = state.companies.map((company) =>
      companyIds.indexOf(company.id) > -1 ? { ...company, statusId } : company
    );
  }
  return { ...state, companies: updateCompanies };
};

const addCompany = (state, company) => {
  const newCompanay = {
    Name: company.name,
    Address: company.address + (company.address2 ? company.address2 : ""),
    City: company.city,
    StateId: company.state,
    ZipCode: company.zipCode,
    Phone: company.phone,
    IndustryClassificationId: company.industryClassificationId,
    MaxNumberOfUsers: company.maxNumberOfUsers,
    MaxNumberOfSubsidiaries: company.maxNumberOfSubsidiaries,
    MaxNumberOfDivisions: company.maxNumberOfDivisions,
  };
  return { ...state, companies: [...state.companies, newCompanay] };
};

const removeCompanies = (state, companies) => {
  const newCompanies = state.companies.filter(
    (company) => _.findIndex(companies, (o) => o.id === company.id) < 0
  );
  return { ...state, companies: newCompanies };
};

const addCustomer = (state, customer) => ({
  ...state,
  customers: [...state.customers, customer],
});

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case actions.EDIT_COMPANY_STATUS: {
      const { statusId, companyIds } = action.payload;
      return editCompanyStatus(state, statusId, companyIds);
    }
    case actions.GET_COMPANY: {
      return {
        ...state,
        company: action.payload,
      };
    }
    case actions.GET_AVAILABLE_SUBSIDIARIES:
      return {
        ...state,
        availableSubsidiaries: action.payload.availableSubsidiaries,
      };
    case actions.ADD_COMPANY:
      return addCompany(state, action.payload);
    case actions.GET_CUSTOMERS:
      return setProperty(state, "customers", action);
    case actions.REMOVE_COMPANIES:
      return removeCompanies(state, action.payload);
    case actions.GET_MATERIALS:
      return setProperty(state, "materials", action);
    case actions.GET_REQUIREMENTS:
      return setProperty(state, "requirements", action);
    case actions.GET_PERSONS:
      return setProperty(state, "persons", action);
    case actions.ADD_CUSTOMERS:
      return addCustomer(state, action.payload);
    case actions.GET_AVAILABLE_DIVISIONS:
      return setProperty(state, "availableDivisions", action);
    case actions.ADD_PERSONS:
      return {
        ...state,
        persons: [...state.persons, action.payload],
      };
    case actions.EDIT_COMPANY:
    case actions.ADD_MATERIALS:
      return {
        ...state,
        materials: [...state.materials, action.payload],
      };
    case actions.ADD_REQUIREMENTS:
      return {
        ...state,
        requirements: [...state.requirements, action.payload],
      };
    case actions.GET_AVAILABLE_USER_SEATS:
      throw "Not implemented";
    default:
      return state;
  }
};

export default companyReducer;
