import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Box,
  Paper,
  Button,
  TextField,
  IconButton,
  MenuItem,
  Link,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Tooltip,
  Grid,
} from "@material-ui/core";
import { Settings, VisibilityOff, Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import {
  addUser,
  getTitles,
  getUser,
  updateUser,
  updateUserStatus,
  getRoles,
  getUserStatuses,
} from "../../actions/usersAction";
import { getDivisionBySubsidiariesData } from "../../actions/divisionActions";
import {
  checkPermission,
  dateFormat,
  makeBase64,
} from "../../utils/helperFunctions";
import AvatarDropZone from "../elements/AvatarDropZone";
import * as constants from "../../constants";
import NotificationPopUp from "../utils/NotificationPopUp";
import { getCompanyData } from "../../actions/companyActions";
import PhoneInputMask from "../elements/PhoneInputMask";
import { getSubsidiariesData } from "../../actions/subsidiaryActions";
import * as store from "../../state";
import SelectList from "../elements/SelectList";

const AddEditUser = (props) => {
  const { sysAdmin } = props;
  const dispatchRedux = useDispatch();
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      inEdit: false,
      titleId: null,
      roleId: null,
      statusId: null,
      firstName: null,
      lastName: null,
      email: null,
      cellphone: null,
      companyId: null,
      jobTitle: null,
      divisionId: [],
      subsidiaryId: [],
      password: null,
      confirmPassword: null,
      picture: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      const subsidiariesWithDivisions = [];
      values.divisionId.forEach((item) => {
        if (
          !subsidiariesWithDivisions.find((o) => o.subsidiaryId === item.subsId)
        ) {
          const divs = values.divisionId.filter(
            (element) => element.subsId === item.subsId
          );
          if (Array.isArray(divs)) {
            subsidiariesWithDivisions.push({
              subsidiaryId: item.subsId,
              subsidiaryName: item.subsName,
              divisions: divs.map((d) => ({ id: d.id, name: d.name })),
            });
          }
        }
      });
      let avatar = null;
      if (values.picture) {
        avatar = await makeBase64(values.picture);
      }

      let { cellphone } = values;
      if (values.cellphone) {
        cellphone = values.cellphone.replace(/[(|)| |-]/g, "");
      }

      const toSave = {
        ...values,
        subsidiariesWithDivisions,
        picture: avatar,
        cellphone,
      };
      delete toSave.subsidiaryId;
      delete toSave.divisionId;
      delete toSave.confirmPassword;
      delete toSave.inEdit;

      try {
        if (inEdit) {
          await updateUser({ ...toEdit, ...toSave });
        } else {
          await addUser(toSave);
        }
        handleClose();
      } catch (err) {
        if (err.data && err.status === 400) {
          setNotificationTitle("Warning");
          setNotificationText(
            err.data && err.data.Message ? err.data.Message : ""
          );
          setNotificationType("warning");
          setNotificationActions([
            { name: "Ok", action: () => handlePopupClose() },
          ]);
          setIsNotificationPopUpOpen(true);

          formik.setFieldError("email", "Please enter a valid email");
          formik.setFieldTouched("email", true, false);
        }
      }
    },
  });
  const history = useHistory();
  const [inEdit, setInEdit] = useState(false);
  const [toEdit, setToEdit] = useState(null);
  const classes = useStyles();
  const [passwordReadable, setPasswordReadable] = useState(false);
  const [confirmPasswordReadable, setConfirmPasswordReadable] = useState(false);
  const [companyField, setCompanyField] = useState("");
  const [userCreatedByName, setUserCreatedByName] = useState("");
  const [userUpdatedByName, setUserUpdatedByName] = useState("");

  // Component values
  const [titles, setTitles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [availableStatusesForSelection, setAvailableStatusesForSelection] =
    useState([]);
  const [subsidiaries, setSubsidiaries] = useState([]);
  const [unfilteredSubsidiaries, setUnfilteredSubsidiaries] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [divisionEditable, setDivisionEditable] = useState(false);
  const inEditRoleAndStatusDisabled =
    (checkPermission([1, 2], loggedInUser.roleId) && !inEdit) ||
    (checkPermission([1, 2], loggedInUser.roleId) &&
      (inEdit && toEdit ? loggedInUser.id !== toEdit.id : false));

  useEffect(() => {
    getRoles().then((response) => {
      if (sysAdmin) {
        const sysAdminRole = response.filter((role) => role.id === 1);
        setRoles(sysAdminRole);
        return;
      }

      const rolesWitoutSysAdmin = response.filter((role) => role.id !== 1);
      setRoles(rolesWitoutSysAdmin);
    });

    getUserStatuses().then((response) => {
      setStatuses(response);
      setAvailableStatusesForSelection(response);
    });

    getTitles().then((response) => {
      setTitles(response);
    });

    if (sysAdmin) {
      return;
    }

    const companyId =
      props.view === "sysAdmin"
        ? parseInt(props.companyId)
        : loggedInUser.userCompany?.id;
    if (companyId) {
      getCompanyData(companyId).then(({ data: company }) => {
        if (company) {
          formik.setFieldValue("companyId", company.id);
          setCompanyField(company.name);

          getSubsidiariesData(company.id).then(({ data }) => {
            setUnfilteredSubsidiaries(data);
            const activeSubs = data.filter((subs) => subs.statusId === 1);
            setSubsidiaries(activeSubs);
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleEditUserLoad = async (props) => {
      if (props && props.inEdit && props.userId) {
        setInEdit(props.inEdit);
        formik.setFieldValue("inEdit", props.inEdit);
        const userToEdit = await getUser(props.userId);
        setToEdit(userToEdit);

        Object.keys(formik.values).forEach((key) => {
          if (Object.keys(userToEdit).includes(key)) {
            formik.setFieldValue(key, userToEdit[key]);
          }
        });

        const createdByName = await pritifyNameByUserId(userToEdit.createdBy);
        setUserCreatedByName(createdByName);
        const updatedByName = await pritifyNameByUserId(userToEdit.updatedBy);
        setUserUpdatedByName(updatedByName);

        const userRoles = await getRoles();
        const roleFilteredByUser = sysAdmin
          ? userRoles.filter((role) => role.id === 1)
          : userRoles.filter(
              (role) =>
                role.id !== 1 ||
                (loggedInUser.roleId === 1 && loggedInUser.id === userToEdit.id)
            );
        setRoles(roleFilteredByUser);
      } else {
        setInEdit(false);
        formik.setFieldValue("inEdit", false);

        const userRoles = await getRoles();
        const availableRoles = sysAdmin
          ? userRoles.filter((role) => role.id === 1)
          : userRoles.filter((role) => role.id !== 1);
        setRoles(availableRoles);
      }
    };

    handleEditUserLoad(props);
  }, [props]);

  useEffect(() => {
    if (sysAdmin) {
      return;
    }

    if (
      toEdit &&
      Array.isArray(toEdit.subsidiariesWithDivisions) &&
      toEdit.subsidiariesWithDivisions.length > 0 &&
      subsidiaries &&
      Array.isArray(subsidiaries) &&
      subsidiaries.length > 0 &&
      formik.values.subsidiaryId.length === 0
    ) {
      const subsList = toEdit.subsidiariesWithDivisions.map((subs) =>
        unfilteredSubsidiaries.find((s) => s.id === subs.subsidiaryId)
      );
      formik.setFieldValue("subsidiaryId", subsList);
      handleSubsidiaryBlur(subsList);

      // subsidiary status == 1  means ACTIVE divisions;
      const activeOrSelectedSubs = unfilteredSubsidiaries.filter(
        (subs) =>
          subs.statusId === 1 ||
          toEdit.subsidiariesWithDivisions.findIndex(
            (s) => s.subsidiaryId === subs.id
          ) > -1
      );
      setSubsidiaries(activeOrSelectedSubs);
    }
  }, [subsidiaries, toEdit]);

  useEffect(() => {
    if (sysAdmin) {
      return;
    }

    if (
      toEdit &&
      Array.isArray(toEdit.subsidiariesWithDivisions) &&
      divisions &&
      Array.isArray(divisions) &&
      divisions.length > 0 &&
      formik.values.divisionId.length === 0
    ) {
      let divsList = [];
      toEdit.subsidiariesWithDivisions.forEach((subs) => {
        divsList = [
          ...divsList,
          ...subs.divisions.map((divs) =>
            divisions.find((s) => s.id === divs.id)
          ),
        ];
      });

      const selectedDivisions = divsList.filter(
        (selection) =>
          divisions.findIndex((divs) => selection.id === divs.id) > -1
      );
      formik.setFieldValue("divisionId", selectedDivisions);
    }
  }, [divisions, toEdit]);

  useEffect(() => {
    if (
      Array.isArray(statuses) &&
      statuses.length > 0 &&
      toEdit &&
      toEdit.statusId
    ) {
      const getAvailableStatusesByStatus = (statusId) => {
        switch (statusId) {
          case 1:
            return [statusId, 2];
          case 2:
            return [statusId, 4];
          case 4:
            return [statusId, 1];
          case 5:
            return [statusId, 2];
          default:
            return [statusId];
        }
      };

      const availableStatuses = statuses.filter((status) =>
        getAvailableStatusesByStatus(toEdit.statusId).includes(status.id)
      );
      setAvailableStatusesForSelection(availableStatuses);
    } else {
      setAvailableStatusesForSelection([]);
    }
  }, [statuses, toEdit]);

  const handleClose = () => {
    history.goBack();
  };

  const handleDelete = async () => {
    setNotificationTitle("Delete User");
    setNotificationText(
      "The user will be removed. Are you sure you want to continue?"
    );
    setNotificationType("warning");
    setNotificationActions([
      {
        name: "DELETE USER",
        action: () => {
          updateUserStatus([toEdit.id], "DELETED").then((userIds) => {
            dispatchRedux(store.actions.user.editUsers(userIds));
          });

          handleClose();
        },
      },
      { name: "CANCEL", action: () => setIsNotificationPopUpOpen(false) },
    ]);
    setIsNotificationPopUpOpen(true);
  };

  const handleClickShowPassword = () => {
    setPasswordReadable(!passwordReadable);
  };

  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordReadable(!confirmPasswordReadable);
  };

  const handleSubsidiaryBlur = async (subsList) => {
    if (sysAdmin) {
      return;
    }

    setDivisionEditable(false);
    const { data: divisionsBySubsidiaries } =
      await getDivisionBySubsidiariesData(
        subsList || formik.values.subsidiaryId
      );

    let divisionsConcat = [];
    if (
      Array.isArray(divisionsBySubsidiaries) &&
      divisionsBySubsidiaries.length > 0
    ) {
      divisionsBySubsidiaries.forEach((subsidiary) => {
        let divs = [];
        let activeDivisions = [];
        if (inEdit && toEdit) {
          const selectedSubsidiary = toEdit.subsidiariesWithDivisions.find(
            (subs) => subs.subsidiaryId === subsidiary.subsidiaryId
          );

          // division status == 1  means ACTIVE divisions;
          activeDivisions = subsidiary.divisions.filter(
            (division) =>
              division.statusId === 1 ||
              (selectedSubsidiary &&
                Array.isArray(selectedSubsidiary.divisions) &&
                selectedSubsidiary.divisions.findIndex(
                  (d) => d.id === division.id
                ) > -1)
          );
        } else {
          // division status == 1 means ACTIVE divisions;
          activeDivisions = subsidiary.divisions.filter(
            (division) => division.statusId === 1
          );
        }

        if (Array.isArray(activeDivisions)) {
          divs = activeDivisions.map((division) =>
            Object.assign(division, {
              subsName: subsidiary.subsidiaryName,
              subsId: subsidiary.subsidiaryId,
            })
          );
        }

        if (divs.length > 0) {
          divisionsConcat = [...divisionsConcat, ...divs];
        }
      });

      setDivisionEditable(true);
    }

    setDivisions(divisionsConcat);
  };

  const pritifyNameByUserId = async (userId) => {
    if (!userId) {
      return "";
    }
    const createdBy = await getUser(userId);
    return createdBy ? ` ${createdBy.firstName} ${createdBy.lastName}` : "";
  };

  const handleUnblockUser = async () => {
    const unblockedUser = await updateUser({
      ...toEdit,
      isLocked: false,
      failedAttempts: 0,
    });
    setToEdit(unblockedUser);
  };

  const handlePopupClose = () => {
    setIsNotificationPopUpOpen(false);
  };

  const warningIcon = <ErrorOutlinedIcon fontSize="small" />;
  const blockedIcon = <HighlightOffIcon fontSize="small" />;
  const unlockIcon = <LockOpenIcon fontSize="small" />;

  return (
    <div id="addEditUserContainer" className={classes.addEditUserContainer}>
      <NotificationPopUp
        id="userPopup"
        isOpen={isNotificationPopUpOpen}
        handleClose={handlePopupClose}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        ADMINISTRATION
      </Box>
      <Divider className={classes.divider} />
      <Grid container className={classes.pageContent}>
        <Grid item xs={9} className={classes.pageLargePanel}>
          <Grid container>
            <Grid
              item
              xs={12}
              id="addEditUserTitle"
              component="span"
              className={classes.contentTitle}
            >
              {inEdit
                ? `EDIT USER ${formik.values.firstName} ${formik.values.lastName}`
                : "Add NEW USER"}
            </Grid>
            <Grid item xs={12} className={classes.contentHeader}>
              Individual Information
            </Grid>
            <Grid item={12} className={classes.contentBody3}>
              <Grid container>
                <Grid item xs={12} sm={3} className={classes.profileContent}>
                  <Box>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      className={classes.avatarContainer}
                    >
                      <AvatarDropZone
                        formik={formik}
                        placeholderInitials={
                          formik.values.firstName && formik.values.lastName
                            ? formik.values.firstName.charAt(0) +
                              formik.values.lastName.charAt(0)
                            : null
                        }
                      />
                      {formik.touched.picture &&
                        Boolean(formik.errors.picture) && (
                          <Box className={classes.asterisk}>
                            {formik.errors.picture}
                          </Box>
                        )}
                    </IconButton>
                  </Box>
                  <Box className={classes.contentBody}>
                    <TextField
                      disabled={!inEditRoleAndStatusDisabled}
                      id="roleId"
                      name="roleId"
                      fullWidth
                      select
                      label={!formik.values.roleId ? "Select role*" : ""}
                      size="small"
                      value={formik.values.roleId || ""}
                      className={classes.roleSelectInput}
                      onChange={formik.handleChange}
                      variant="filled"
                      error={
                        formik.touched.roleId && Boolean(formik.errors.roleId)
                      }
                      helperText={formik.touched.roleId && formik.errors.roleId}
                    >
                      {roles.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                  {inEdit && toEdit && (
                    <>
                      <Box className={classes.contentBody}>
                        <TextField
                          disabled={
                            !inEditRoleAndStatusDisabled ||
                            (inEdit && toEdit && toEdit.statusId === 3)
                          }
                          id="statusId"
                          name="statusId"
                          fullWidth
                          select
                          label=""
                          size="small"
                          value={formik.values.statusId || ""}
                          className={clsx(
                            classes[
                              availableStatusesForSelection.find(
                                (status) => status.id === formik.values.statusId
                              )
                                ? availableStatusesForSelection.find(
                                    (status) =>
                                      status.id === formik.values.statusId
                                  ).name
                                : ""
                            ],
                            classes.statusSelectInput
                          )}
                          onChange={formik.handleChange}
                          variant="filled"
                          error={
                            formik.touched.statusId &&
                            Boolean(formik.errors.statusId)
                          }
                          helperText={
                            formik.touched.statusId && formik.errors.statusId
                          }
                        >
                          {availableStatusesForSelection.map((option) => (
                            <MenuItem
                              key={option.id}
                              id={option.id}
                              value={option.id}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      {!sysAdmin && (
                        <Box className={classes.contentBody}>
                          {() => {
                            const status = availableStatusesForSelection.find(
                              (status) => status.id === formik.values.statusId
                            );
                            if (status) {
                              return (
                                <Box
                                  className={clsx(
                                    classes.statusSelectDescription,
                                    classes[status.name]
                                  )}
                                >
                                  {status.description}
                                </Box>
                              );
                            }
                            return <></>;
                          }}
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  className={classes.fullWidthContainer}
                >
                  <Grid container>
                    <Grid item xs={12} className={classes.contentInputGroup}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={classes.shortInput}
                        >
                          <TextField
                            fullWidth
                            label="Title*"
                            margin="dense"
                            size="small"
                            variant="standard"
                            select
                            id="titleId"
                            name="titleId"
                            value={formik.values.titleId || ""}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.titleId &&
                              Boolean(formik.errors.titleId)
                            }
                            helperText={
                              formik.touched.titleId && formik.errors.titleId
                            }
                          >
                            {titles.map((title) => (
                              <MenuItem key={title.id} value={title.id}>
                                {title.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={classes.shortInput}
                        >
                          <TextField
                            fullWidth
                            label="First Name*"
                            margin="dense"
                            size="small"
                            variant="standard"
                            id="firstName"
                            name="firstName"
                            value={formik.values.firstName || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.firstName &&
                              Boolean(formik.errors.firstName)
                            }
                            helperText={
                              formik.touched.firstName &&
                              formik.errors.firstName
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={classes.shortInput}
                        >
                          <TextField
                            fullWidth
                            label="Last Name*"
                            margin="dense"
                            size="small"
                            variant="standard"
                            id="lastName"
                            name="lastName"
                            value={formik.values.lastName || ""}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.firstName &&
                              Boolean(formik.errors.lastName)
                            }
                            helperText={
                              formik.touched.lastName && formik.errors.lastName
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.contentInputGroup}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          className={classes.largeInput}
                        >
                          <TextField
                            fullWidth
                            label="Email Address*"
                            margin="dense"
                            size="small"
                            variant="standard"
                            id="email"
                            name="email"
                            value={formik.values.email || ""}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={classes.shortInput}
                        >
                          <TextField
                            fullWidth
                            label="Phone Number"
                            margin="dense"
                            size="small"
                            variant="standard"
                            id="cellphone"
                            name="cellphone"
                            value={formik.values.cellphone || ""}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.cellphone &&
                              Boolean(formik.errors.cellphone)
                            }
                            helperText={
                              formik.touched.cellphone &&
                              formik.errors.cellphone
                            }
                            InputProps={{
                              inputComponent: PhoneInputMask,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!sysAdmin && (
              <Grid item xs={12}>
                <Box
                  className={clsx(
                    classes.contentHeader,
                    classes.contentHeaderTopMargin
                  )}
                >
                  Company Information
                </Box>
                <Box className={classes.contentBody}>
                  <Grid container>
                    <Grid item className={classes.mediumInput}>
                      <TextField
                        fullWidth
                        disabled
                        label="Company*"
                        margin="dense"
                        size="small"
                        variant="standard"
                        id="companyId"
                        name="companyId"
                        value={companyField}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.companyId &&
                          Boolean(formik.errors.companyId)
                        }
                        helperText={
                          formik.touched.companyId && formik.errors.companyId
                        }
                      />
                    </Grid>
                    <Grid item className={classes.mediumInput}>
                      <TextField
                        fullWidth
                        label="Job Title"
                        margin="dense"
                        size="small"
                        variant="standard"
                        id="jobTitle"
                        name="jobTitle"
                        value={formik.values.jobTitle || ""}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.jobTitle &&
                          Boolean(formik.errors.jobTitle)
                        }
                        helperText={
                          formik.touched.jobTitle && formik.errors.jobTitle
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container className={classes.contentInputGroup}>
                    <Grid item className={classes.mediumInput}>
                      <SelectList
                        text="Subsidiary"
                        name="subsidiaryId"
                        options={subsidiaries}
                        value={formik.values.subsidiaryId}
                        handleChange={(value) =>
                          formik.setFieldValue("subsidiaryId", value)
                        }
                        handleBlur={() => handleSubsidiaryBlur()}
                        isMultiSelect={true}
                      />
                    </Grid>
                    <Grid item className={classes.mediumInput}>
                      <SelectList
                        text="Division"
                        name="divisionId"
                        options={divisions}
                        value={formik.values.divisionId}
                        handleChange={(value) =>
                          formik.setFieldValue("divisionId", value)
                        }
                        groupBy={(option) => option.subsName}
                        disabled={!divisionEditable}
                        getOptionSelected={(option, value) =>
                          option && value && option.id === value.id
                        }
                        isMultiSelect={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box
                className={clsx(
                  classes.contentHeader,
                  classes.contentHeaderTopMargin
                )}
              >
                Security & Privacy
              </Box>
              <Grid container className={classes.contentBody}>
                <Grid item xs={12} className={classes.contentInputGroup}>
                  <Grid container>
                    <Grid item className={classes.mediumInput}>
                      <FormControl className={classes.fullWidth}>
                        <InputLabel>Password*</InputLabel>
                        <Input
                          fullWidth
                          // autocomplete="off"
                          name="password"
                          margin="dense"
                          type={passwordReadable ? "text" : "password"}
                          value={formik.values.password || ""}
                          onChange={formik.handleChange}
                          inputProps={{
                            autoComplete: "new-password",
                          }}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {passwordReadable ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {formik.touched.password && formik.errors.password && (
                          <Box className={classes.pageContent}>
                            <Box className={classes.asterisk}>
                              {formik.errors.password}
                            </Box>
                            <Box className={classes.passwordTootlipIcon}>
                              <Tooltip
                                title={passwordTooltipLabel}
                                arrow
                                classes={{ tooltip: classes.tooltipWidth }}
                              >
                                {warningIcon}
                              </Tooltip>
                            </Box>
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.mediumInput}>
                      <FormControl className={classes.fullWidth}>
                        <InputLabel>Confirm Password*</InputLabel>
                        <Input
                          disabled={!formik.values.password}
                          fullWidth
                          margin="dense"
                          type={confirmPasswordReadable ? "text" : "password"}
                          id="confirmPassword"
                          name="confirmPassword"
                          values={formik.values.confirmPassword || ""}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.confirmPassword &&
                            Boolean(formik.errors.confirmPassword)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                              >
                                {confirmPasswordReadable ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword && (
                            <Box className={classes.asterisk}>
                              {formik.errors.confirmPassword}
                            </Box>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.userPaswordLastChange}>
                  Last changed:{" "}
                  {toEdit && toEdit.passwordLastChanged
                    ? dateFormat(
                        toEdit.passwordLastChanged,
                        constants.DATE_FORMAT
                      )
                    : "Never"}
                </Grid>
                {inEdit && toEdit && (
                  <>
                    <Box className={classes.userLoginDetails}>
                      <Box className={classes.lastSuccesgulLogin}>
                        Last Succesful Login:{" "}
                        <span>
                          {toEdit.lastSuccessfullLogin
                            ? dateFormat(
                                toEdit.lastSuccessfullLogin,
                                constants.DATE_FORMAT
                              )
                            : "Never"}
                        </span>
                      </Box>
                      {toEdit.failedAttempts < 3 && !toEdit.isLocked ? (
                        <Box className={classes.failedAttemptsLabel}>
                          {warningIcon}{" "}
                          <Box>
                            Failed Login Attempts:
                            {toEdit.failedAttempts}{" "}
                            <span>
                              (User account will be blocked temporarily after 3
                              failed logins.)
                            </span>
                          </Box>
                        </Box>
                      ) : (
                        <Box className={classes.failedAttemptsBlockedLabel}>
                          <Box className={classes.innerFailedAttemptsBlocked}>
                            {blockedIcon}{" "}
                            <Box>
                              Failed Login Attempts:
                              {toEdit.failedAttempts}
                            </Box>
                            <Link
                              color="primary"
                              onClick={handleUnblockUser}
                              style={{ marginLeft: "5rem" }}
                            >
                              {unlockIcon} Unlock User
                            </Link>
                          </Box>
                          <span>(User account is blocked.)</span>
                        </Box>
                      )}
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.pageSmallPanel}>
          <Button
            id="addUser"
            variant="contained"
            size="medium"
            color="primary"
            className={classes.actionBtn}
            onClick={formik.handleSubmit}
          >
            <Box>
              {inEdit ? "Save" : sysAdmin ? "Add System Admin" : "Add User"}
            </Box>
          </Button>
          <Button
            id="cancelUser"
            variant="contained"
            size="medium"
            color="default"
            className={classes.actionBtn}
            onClick={handleClose}
          >
            <Box>Cancel</Box>
          </Button>
          {inEdit && toEdit && (
            <>
              {toEdit.statusId !== 3 && toEdit.id !== loggedInUser.id && (
                <Button
                  id="deleteUser"
                  variant="contained"
                  size="medium"
                  color="secondary"
                  className={classes.deleteActionBtn}
                  onClick={handleDelete}
                  startIcon={<DeleteIcon />}
                >
                  <Box>Delete</Box>
                </Button>
              )}
              <Paper elevation={2} className={classes.paperDetails}>
                <Box className={classes.paperDetailsContainer}>
                  <Box className={classes.paperDetailsTitle}>
                    User created on
                  </Box>
                  <Box className={classes.paperDetailsValue}>
                    {dateFormat(toEdit.createdOn, constants.DATE_FORMAT)}
                  </Box>
                  <Box className={classes.paperDetailsTitle}>Created by</Box>
                  <Box className={classes.paperDetailsValue}>
                    {userCreatedByName || ""}
                  </Box>
                  <Box className={classes.paperDetailsTitle}>Last updated</Box>
                  <Box className={classes.paperDetailsValue}>
                    {toEdit.updatedOn && toEdit.updatedBy ? (
                      <>
                        <Box>
                          {dateFormat(toEdit.updatedOn, constants.DATE_FORMAT)}
                        </Box>
                        <Box>
                          by
                          {userUpdatedByName || ""}
                        </Box>
                      </>
                    ) : (
                      <Box>Never</Box>
                    )}
                  </Box>
                </Box>
              </Paper>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default AddEditUser;

const validationSchema = yup.object({
  titleId: yup.string("Select role").required("Role is required").nullable(),
  lastName: yup
    .string("Enter your name")
    .required("Name is required")
    .nullable()
    .max(40, "Maximum of 40 characters"),
  firstName: yup
    .string("Enter your name")
    .required("Name is required")
    .nullable()
    .max(40, "Maximum of 40 characters"),
  roleId: yup.string("Select role").required("Role is required").nullable(),
  email: yup
    .string("Enter your email")
    .required("Email is required")
    .nullable()
    .matches(constants.EMAIL_REG_EXP, "Please enter a valid email"),
  cellphone: yup
    .string("Enter phone number")
    .matches(constants.PHONE_REG_EXP, "Please enter a valid phone number")
    .nullable(),
  password: yup.string().when("inEdit", {
    is: (value) => !value,
    then: yup
      .string("Enter password")
      .required("Password is required")
      .matches(
        constants.PASSWORD_REG_EXP,
        "Invalid password. Requirements not met"
      )
      .nullable(),
    otherwise: yup
      .string("Enter password")
      .matches(constants.PASSWORD_REG_EXP, "Please enter a valid password")
      .nullable(),
  }),
  confirmPassword: yup.string().when("password", {
    is: (value) => value,
    then: yup
      .string("Please enter password confirmation")
      .oneOf([yup.ref("password"), null], "Passwords don't match")
      .required("Confirm Password is required")
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
});

const passwordTooltipLabel =
  "At least 8 characters—the more characters, the better \n A mixture of both uppercase and lowercase letters \n A mixture of letters and numbers \n Inclusion of at least one special character, e.g., ! @ # ? ]";

const useStyles = makeStyles((theme) => ({
  addEditUserContainer: {
    display: "flex",
    flexDirection: "column",
  },
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageContent: {
    display: "flex",
    flexDirection: "row",
  },
  pageLargePanel: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
  },
  pageSmallPanel: {
    width: "15%",
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
  },
  contentTitle: {
    minWidth: "0% !important",
    paddingTop: "2rem",
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "#4A5568",
    textAlign: "center",
  },
  contentHeader: {
    fontSize: "1rem",
    paddingTop: "1rem",
    color: "#4A5568",
    fontWeight: "bold",
  },
  contentHeaderTopMargin: {
    paddingTop: "3.5rem",
  },
  contentBody: {
    paddingLeft: "0.8rem",
  },
  contentBody3: {
    display: "flex",
    flexDirection: "row",
  },
  profileContent: {
    marginRight: "2.35rem",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  roleSelectInput: {
    width: "9rem",
    height: "1.5rem",
    "& label": {
      color: "white !important",
      transform: "translate(12px, 7px) scale(1) !important",
    },
    "& div": {
      "& div": {
        padding: "5px",
      },
      "& div, div:focus": {
        background: "#4A5568",
        color: "#fff",
      },
    },
  },
  statusSelectInput: {
    paddingTop: "1rem",
    width: "9rem",
    height: "1.5rem",
    "& div": {
      "& div": {
        padding: "5px",
        background: "#4A5568",
        color: "#fff",
      },
    },
  },
  ACTIVE: {
    "& div": {
      "& div, div:focus": {
        background: "#4D841D",
      },
    },
  },
  EXPIRED: {
    "& div": {
      "& div, div:focus": {
        background: "#FF0000",
      },
    },
  },
  DELETED: {
    "& div": {
      "& div, div:focus": {
        background: "#FF0000",
      },
    },
  },
  PENDING: {
    "& div": {
      "& div, div:focus": {
        background: "#C15700",
      },
    },
  },
  INACTIVE: {
    "& div": {
      "& div, div:focus": {
        background: "#BDBDBD",
      },
    },
  },
  statusSelectDescription: {
    marginTop: "1.5rem",
    "&$ACTIVE": {
      color: "#4D841D",
    },
    "&$EXPIRED": {
      color: "#FF0000",
    },
    "&$DELETED": {
      color: "#FF0000",
    },
    "&$PENDING": {
      color: "#C15700",
    },
    "&$INACTIVE": {
      color: "#BDBDBD",
    },
  },
  contentInputGroup: {
    display: "flex",
    flexDirection: "row",
  },
  shortInput: {
    width: "25%",
    paddingRight: "1rem",
    marginTop: "1rem",
    minWidth: "8rem",
  },
  mediumInput: {
    width: "33.33%",
    minWidth: "14rem",
    paddingRight: "1rem",
    marginTop: "1rem",
  },
  largeInput: {
    width: "50%",
    minWidth: "14rem",
    paddingRight: "1rem",
    marginTop: "1rem",
  },
  actionBtn: {
    width: "14rem",
  },
  deleteActionBtn: {
    width: "14rem",
    background: "#FF0000",
  },
  style: {
    margin: "1rem",
  },
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.7rem 0 0 0",
  },
  largeAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginTop: "1rem",
    marginRight: "2rem",
  },
  fullWidthContainer: {
    width: "100%",
  },
  innerChecklistContainer: {
    display: "flex",
    flexDirection: "column",
  },
  innerChecklistContent: {
    marginLeft: "0.2rem",
    display: "flex",
    flexDirection: "column",
  },
  paperDetails: {
    borderRadius: "1rem",
    background: "#E6E6E6",
    width: "14rem",
    marginLeft: "1rem",
  },
  paperDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0.5rem",
  },
  paperDetailsTitle: {
    fontSize: "0.8rem",
    fontWeight: "600",
  },
  paperDetailsValue: {
    fontSize: "0.8rem",
    fontWeight: "400",
  },
  userLoginDetails: {
    paddingTop: "2.5rem",
  },
  userPaswordLastChange: {
    color: "#BDBDBD",
    fontSize: "0.8rem",
  },
  failedAttemptsLabel: {
    marginTop: "0.5rem",
    display: "flex",
    color: "#FCB500",
    fontWeight: "600",
    fontSize: "0.9rem",

    "& span": {
      fontWeight: "400",
    },
  },
  failedAttemptsBlockedLabel: {
    marginTop: "0.5rem",
    display: "flex",
    flexDirection: "column",
    color: "#FC0000",
    fontWeight: "600",
    fontSize: "0.9rem",

    "& span": {
      fontWeight: "400",
    },
  },
  innerFailedAttemptsBlocked: {
    display: "flex",
    flexDirection: "row",
  },
  lastSuccesgulLogin: {
    color: "#4A5568",
    fontWeight: "600",
    fontSize: "0.8rem",

    "& span": {
      fontWeight: "400",
    },
  },
  avatarContainer: {
    "& span": {
      display: "flex",
      flexDirection: "column",
    },
  },
  asterisk: {
    color: "#FC0000",
    fontSize: "0.8rem",
    marginTop: "0.5rem",
  },
  fullWidth: {
    width: "100%",
  },
  passwordTootlipIcon: {
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
    color: "grey",
  },
  tooltipWidth: {
    maxWidth: "700px",
    whiteSpace: "pre-wrap",
  },
}));
