import MaskedInput from "react-text-mask";

const PhoneInputMask = (props) => {
  const { inputRef, ...other } = props;

  const handleOnClick = (e) => {
    if (!e || !e.target) {
      return;
    }

    const value = e.target.value
      ? e.target.value.replace(/[(|)|\s|-]/g, "")
      : "";

    let cursorPosition = 1;
    if (!value) {
      e.target.setSelectionRange(cursorPosition, cursorPosition);
      return;
    }

    switch (value.length) {
      case 1:
      case 2:
        cursorPosition = value.length + 1;
        break;
      case 3:
        cursorPosition = value.length + 2;
        break;
      case 4:
      case 5:
        cursorPosition = value.length + 3;
        break;
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        cursorPosition = value.length + 4;
        break;
      default:
        break;
    }

    e.target.setSelectionRange(cursorPosition, cursorPosition);
  };

  return (
    <MaskedInput
      id="makeInput"
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2007"}
      showMask
      onClick={handleOnClick}
    />
  );
};

export default PhoneInputMask;
