import React from "react";
import { formatDateUS } from "../../../../utils/helperFunctions";
import TooltipDisplay from "./TooltipDisplay";

const Active = (props) => {
  const {
    contractValue,
    startDate,
    endDate,
    leadProjectManager,
    estimatedCost,
    estimatedLaborCost,
    estimatedLaborHours,
    totalCostToDate,
    totalLaborCostIncurredToDate,
    totalHoursToDate,
  } = props.row;

  return (
    <>
      <div>
        <TooltipDisplay
          title=" Project Manager"
          text={leadProjectManager || "-"}
        />
        <TooltipDisplay
          title="Start Date"
          text={startDate ? formatDateUS(startDate) : "-"}
        />
      </div>
      <div>
        <TooltipDisplay
          title="Contract Value"
          text={
            contractValue
              ? `$${parseInt(contractValue).toLocaleString("en-US")}`
              : "-"
          }
        />
        <TooltipDisplay
          title="End Date"
          text={endDate ? formatDateUS(endDate) : "-"}
        />
      </div>
      <div>
        <TooltipDisplay
          title="Est. Total Cost"
          text={
            estimatedCost
              ? `$${parseInt(estimatedCost).toLocaleString("en-US")}`
              : "-"
          }
        />
        <TooltipDisplay
          title="Total Cost To-Date"
          text={
            totalCostToDate
              ? `$${parseInt(totalCostToDate).toLocaleString("en-US")}`
              : "-"
          }
        />
      </div>
      <div>
        <TooltipDisplay
          title="Est. Labor Hours"
          text={
            estimatedLaborHours
              ? `${parseInt(estimatedLaborHours).toLocaleString("en-US")} hours`
              : "-"
          }
        />
        <TooltipDisplay
          title="Total Hrs To-Date"
          text={
            totalHoursToDate
              ? `${parseInt(totalHoursToDate).toLocaleString("en-US")} hours`
              : "-"
          }
        />
      </div>
      <div>
        <TooltipDisplay
          title="Est. Comp. Rate"
          text={
            !estimatedLaborCost ||
            !estimatedLaborHours ||
            isNaN(estimatedLaborCost / estimatedLaborHours)
              ? "-"
              : `$${(
                  Math.round((estimatedLaborCost / estimatedLaborHours) * 100) /
                  100
                ).toFixed(2)}`
          }
        />
        <TooltipDisplay
          title="Current Comp Rate"
          text={
            !totalLaborCostIncurredToDate ||
            !totalHoursToDate ||
            isNaN(totalLaborCostIncurredToDate / totalHoursToDate)
              ? "-"
              : `$${Math.round(
                  totalLaborCostIncurredToDate / totalHoursToDate
                ).toFixed(2)}`
          }
        />
      </div>
    </>
  );
};

export default Active;
