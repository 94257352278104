import server from "../api/server";
import * as store from "../state";

export const getProposals = async ({ dispatchRedux }, projectId) => {
  const response = await server({
    url: `/proposal/getProposals?projectId=${projectId}`,
    method: "GET",
  });
  const content = response.data;

  dispatchRedux(store.actions.proposal.setProposals(content));

  return content;
};

export const getProposal = async ({ dispatchRedux }, proposalId) => {
  const response = await server({
    url: `/proposal/getProposal/` + proposalId,
    method: "GET",
  });
  const content = response.data;

  dispatchRedux(store.actions.proposal.setProposal(content));

  return content;
};

export const updateProposal = async (
  proposal,
  projectId,
  { dispatchRedux },
  onSuccess
) => {
  const response = await server({
    url: "/proposal/updateProposal",
    method: "POST",
    data: proposal,
  });

  if (response.status === 200) {
    onSuccess(proposal.proposalNumber);
    await Promise.all([getProposals({ dispatchRedux }, projectId)]);
    dispatchRedux(store.actions.proposal.setProposal(proposal));
  }
};
