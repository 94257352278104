import React, { useState, useEffect } from "react";
import { Divider, Box, Paper } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
// import { CompanyContext } from "../../../contexts/CompanyContext";
import AccountSettingsForm from "./AccountSettingsForm";
import {
  editCompanyData,
  getCompanyData,
} from "../../../actions/companyActions";
import AdministrationTabBar from "../../elements/AdministrationTabBar";
// import { useSelector } from "react-redux";

const EditAccountSetting = (props) => {
  const { id, view } = props;
  // const { dispatch } = useContext(CompanyContext);
  const companyId = parseInt(id);
  const classes = useStyles();

  const [initialValues, setInitialValues] = useState({
    maxNumberOfDivisions: "1",
    maxNumberOfSubsidiaries: "1",
    maxNumberOfUsers: "1",
  });
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    async function fetchData() {
      const { data: company } = await getCompanyData(companyId);
      setInitialValues(company);
      setCompanyName(company.name);
    }
    fetchData();
  }, [companyId]);

  const onSubmit = async (values) => {
    await editCompanyData(values);
  };

  return (
    <>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        SYSTEM ADMINISTRATION
      </Box>
      <Divider className={classes.divider} />
      <AdministrationTabBar page="accountSettings" view={view} id={id} />
      <Box id="accountSettingTitle" className={classes.pageSubTitle}>
        {`${companyName}: Account Settings`}
      </Box>
      <AccountSettingsForm initialValues={initialValues} onSubmit={onSubmit} />
    </>
  );
};

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#4A5568",
  },
  divider: {
    margin: "1rem 0",
  },
}));

export default EditAccountSetting;
