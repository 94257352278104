export const selectDivisionStatuses = (state) => {
  return state.resource.divisionStatuses;
};

export const selectIndustries = (state) => {
  return state.resource.industries;
};

export const selectContactTypes = (state) => {
  return state.resource.contactTypes;
};

export const selectContractTypes = (state) => {
  return state.resource.contractTypes;
};

export const selectCountries = (state) => {
  return state.resource.countries;
};

export const selectStates = (state) => {
  return state.resource.states;
};

export const selectCounties = (state) => {
  return state.resource.counties;
};

export const selectMarketSegments = (state) => {
  return state.resource.marketSegments;
};

export const selectMarketCategories = (state) => {
  return state.resource.marketCategories;
};
export const selectMarketSubcategories = (state) => {
  return state.resource.marketSubcategories;
};
export const selectJobTypes = (state) => {
  return state.resource.jobTypes;
};
