import React, { useEffect, useState } from "react";
import _ from "lodash";
import { DataGrid, useGridSlotComponentProps } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core/styles";
import TablePaginationActions from "./TablePaginationActions";

const DashboardTable = (props) => {
  const { rows, columns, setSelectedRows, selectedRows } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
          headerClassName: classes.headerRoot,
        }))}
        scrollbarSize={5}
        pagination
        checkboxSelection
        onSelectionModelChange={(e) => {
          const selectedIDs = new Set(e.selectionModel);
          const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
          if (!_.isEqual(selectedRowData, selectedRows)) {
            setSelectedRows(selectedRowData || []);
          }
        }}
        selectionModel={selectedRows.map((row) => row.id)}
        pageSize={5}
        className={classes.root}
        components={{
          Pagination: CustomPagination,
        }}
      />
    </div>
  );
};

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();

  const setPage = (page) => {
    apiRef.current.setPage(page - 1);
  };

  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    apiRef.current.setPageSize(rowsPerPage);
  }, [rowsPerPage]);

  return (
    <TablePaginationActions
      page={state.pagination.page + 1}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      count={state.pagination.pageCount}
    />
  );
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "26rem",
    "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
      backgroundColor: " #fafafa",
    },
    "& .MuiDataGrid-root .MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
      display: "none",
    },
    "& .MuiDataGrid-root .MuiDataGrid-colCellCenter .MuiDataGrid-colCellTitleContainer":
      {
        display: "none",
      },
    "& .MuiDataGrid-root .MuiDataGrid-colCellTitle": {
      fontSize: ".9rem",
      fontWeight: "bold",
    },
  },
  headerRoot: {
    backgroundColor: "#fafafa",
    fontSize: "1rem",
  },
}));

export default DashboardTable;
