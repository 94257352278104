import { React, useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Box, Button, TextField, MenuItem, Grid } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import CustomTextField from "../elements/CustomTextField";
import * as constants from "../../constants";
import IndustryTreeView from "../elements/IndustryTreeView";
//import { ResourcesContext } from "../../contexts/ResourcesContext";
import {
  getIndustriesDataToStore,
  getStatusesDataToStore,
} from "../../actions/resourcesAction";
import * as store from "../../state";

const SubsidiaryForm = (props) => {
  const {
    onSubmit,
    initialValues,
    onCancel,
    page,
    validationSchema,
    handleDelete,
    subsidiaryStatus,
  } = props;
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  //const { dispatch, state } = useContext(ResourcesContext);
  const divisionStatuses = useSelector(
    store.selectors.resource.selectDivisionStatuses
  );
  const industries = useSelector(store.selectors.resource.selectIndustries);
  const [availableStatusesForSelection, setAvailableStatusesForSelection] =
    useState([]);

  const getAvailableStatusesByStatus = (statusId) => {
    switch (statusId) {
      case 1:
        return [statusId, 2];
      case 2:
        return [statusId, 1];
      default:
        return [statusId];
    }
  };

  useEffect(() => {
    if (
      Array.isArray(divisionStatuses) &&
      divisionStatuses.length > 0 &&
      initialValues.statusId
    ) {
      const availableStatuses = divisionStatuses.filter((status) =>
        getAvailableStatusesByStatus(initialValues.statusId).includes(status.id)
      );
      setAvailableStatusesForSelection(availableStatuses);
    } else {
      setAvailableStatusesForSelection([]);
    }
  }, [divisionStatuses, initialValues]);

  useEffect(async () => {
    // getIndustries(dispatch);
    // getStatuses(dispatch);
    await getIndustriesDataToStore({ dispatchRedux });
    await getStatusesDataToStore({ dispatchRedux });
  }, []);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const handleClickSubsidiary = (e) => {
    formik.setFieldValue("industryClassificationId", e.target.value);
  };
  const handleClickDivision = (e) => {
    formik.setFieldValue("divisionIndustryClassificationId", e.target.value);
  };
  return (
    <div>
      <form>
        <div>
          <Grid cotainer className={classes.topContainer}>
            <Grid item xs={9}>
              <Grid container className={clsx(classes.inputContainer)}>
                <Grid item className={classes.flexRow}>
                  <Grid container>
                    <Grid item className={clsx(classes.flexCol)}>
                      <CustomTextField
                        name="subsidiaryId"
                        label="Subsidiary ID"
                        formik={formik}
                      />
                    </Grid>
                    <Grid item className={clsx(classes.flexCol)}>
                      <CustomTextField
                        name="name"
                        label="Subsidiary Name*"
                        formik={formik}
                      />
                    </Grid>
                    {page !== "add" && (
                      <Grid item className={clsx(classes.flexCol)}>
                        <Box className={classes.contentBody}>
                          <TextField
                            disabled={initialValues.statusId === 3}
                            id="statusId"
                            name="statusId"
                            fullWidth
                            select
                            label=""
                            size="small"
                            value={formik.values.statusId || ""}
                            className={clsx(
                              classes[
                                availableStatusesForSelection.find(
                                  (status) =>
                                    status.id === formik.values.statusId
                                )
                                  ? availableStatusesForSelection.find(
                                      (status) =>
                                        status.id === formik.values.statusId
                                    ).name
                                  : ""
                              ],
                              classes.statusSelectInput
                            )}
                            onChange={formik.handleChange}
                            variant="filled"
                            error={
                              formik.touched.statusId &&
                              Boolean(formik.errors.statusId)
                            }
                            helperText={
                              formik.touched.statusId && formik.errors.statusId
                            }
                          >
                            {availableStatusesForSelection.map((option) => (
                              <MenuItem
                                key={option.id}
                                id={option.id}
                                value={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                        <Box className={classes.contentBody}>
                          {() => {
                            const status = availableStatusesForSelection.find(
                              (status) => status.id === formik.values.statusId
                            );
                            if (status) {
                              return (
                                <Box
                                  className={clsx(
                                    classes.statusSelectDescription,
                                    classes[status.name]
                                  )}
                                >
                                  {status.description}
                                </Box>
                              );
                            }
                            return <></>;
                          }}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                  <Box className={classes.label}>
                    Industry Classification
                    <span className={classes.asterisk}> &#42;</span>
                  </Box>
                  <CustomTextField
                    name="industryClassificationId"
                    label=""
                    formik={formik}
                    style={{ display: "none" }}
                  />
                  <IndustryTreeView
                    value={formik.values.industryClassificationId}
                    errors={formik.errors.industryClassificationId}
                    touched={formik.touched.industryClassificationId}
                    industries={industries}
                    handleClick={handleClickSubsidiary}
                  />
                </Grid>
              </Grid>
              {page === "add" && (
                <Grid container className={clsx(classes.inputContainer)}>
                  <Box className={classes.pageSubTitle}>Add a Division</Box>
                  <Grid item>
                    <Grid container>
                      <Grid item className={clsx(classes.flexCol)}>
                        <CustomTextField
                          name="divisionId"
                          label="Division ID"
                          formik={formik}
                        />
                      </Grid>
                      <Grid item className={clsx(classes.flexCol)}>
                        <CustomTextField
                          name="divisionName"
                          label="Division Name*"
                          formik={formik}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.inputContainer}>
                    <Box className={classes.label}>
                      Industry Classification
                      <span className={classes.asterisk}> &#42;</span>
                    </Box>
                    <CustomTextField
                      name="divisionIndustryClassificationId"
                      label=""
                      formik={formik}
                      style={{ display: "none" }}
                    />
                    <IndustryTreeView
                      value={formik.values.divisionIndustryClassificationId}
                      errors={formik.errors.divisionIndustryClassificationId}
                      touched={formik.touched.divisionIndustryClassificationId}
                      industries={industries}
                      handleClick={handleClickDivision}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={3} className={classes.buttonGoup}>
              <Button
                variant="contained"
                className={classes.saveButton}
                onClick={() => formik.handleSubmit()}
              >
                {page === "add" ? "Add Subsidiary" : "Save"}
              </Button>
              <Button
                variant="contained"
                className={classes.cancelButton}
                onClick={() => onCancel(formik)}
              >
                Cancel
              </Button>
              {page === "edit" && subsidiaryStatus !== 3 && (
                <Button
                  variant="contained"
                  className={classes.deleteButton}
                  onClick={handleDelete}
                  startIcon={<DeleteIcon />}
                >
                  Delete Subsidiary
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  cardContent: {
    maxHeight: "40rem",
    overflowY: "scroll",
  },
  inputContainer: {
    padding: "1rem 0",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginBottom: "1rem",
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
  },
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.5rem 0",
    fontWeight: "bold",
    minWidth: "10rem",
  },
  treeView: {
    margin: "0.5rem 0 0 0",
    borderColor: "rgba(0, 0, 0, 0.26)",
  },
  asterisk: {
    color: constants.ASTERISK_COLOR,
  },
  button: {
    backgroundColor: theme.palette.info.light,
  },
  flexCol: {
    minWidth: "14rem",
    width: "14rem",
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  width50: {
    width: "50%",
  },
  width60: {
    minWidth: "60%",
  },
  logoContainer: {
    alignSelf: "center",
  },
  buttonGoup: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    alignItems: "flex-end",
    "& button": {
      width: "10rem",
    },
  },
  cancelButton: {
    backgroundColor: "#CBD5E0",
  },
  saveButton: {
    backgroundColor: "#3182CE",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
    fontSize: "13px",
  },
  pageSubTitle: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  statusSelectInput: {
    paddingTop: "1rem",
    width: "10.5rem",
    height: "1.5rem",
    textAlign: "center",
    "& div": {
      "& div": {
        padding: "5px",
        background: "#4A5568",
        color: "#fff",
      },
    },
  },
  ACTIVE: {
    "& div": {
      "& div, div:focus": {
        background: "#4D841D",
      },
    },
  },
  DELETED: {
    "& div": {
      "& div, div:focus": {
        background: "#FF0000",
      },
    },
  },
  INACTIVE: {
    "& div": {
      "& div, div:focus": {
        background: "#BDBDBD",
      },
    },
  },
  contentBody: {
    paddingLeft: "0.8rem",
  },
  statusSelectDescription: {
    marginTop: "1.5rem",
    "&$ACTIVE": {
      color: "#4D841D",
    },
    "&$DELETED": {
      color: "#FF0000",
    },
    "&$INACTIVE": {
      color: "#BDBDBD",
    },
  },
}));

export default SubsidiaryForm;
