import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { checkPermission } from "../../../../utils/helperFunctions";
import { getUsersBaseInfo } from "../../../../actions/usersAction";
import SelectList from "../../../elements/SelectList";
import * as store from "../../../../state";

const TeamAssignments = (props) => {
  const { formik } = props;
  const classes = useStyles();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const { roleId } = loggedInUser;
  const companyId = loggedInUser.userCompany?.id;

  const [activeUsers, setActiveUsers] = useState([]);

  useEffect(async () => {
    getUsersBaseInfo(companyId).then(({ data }) => {
      setActiveUsers(
        data
          .filter((user) => user.statusId === 1)
          .map((user) => ({
            ...user,
            name: `${user.firstName} ${user.lastName}`,
          }))
      );
    });
  }, []);

  return (
    <div className={classes.container}>
      <h4 className={classes.pageTitle}>Team Assignments</h4>
      <Grid container className={classes.content}>
        <Grid item xs className={classes.item}>
          <SelectList
            text={
              formik.values.statusTypeId >= 4
                ? "Lead Project Manager*"
                : "Lead Project Manager"
            }
            disabled={checkPermission([5], roleId)}
            name="leadProjectManager"
            options={activeUsers}
            value={formik.values.leadProjectManager}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            handleChange={(value) =>
              formik.setFieldValue("leadProjectManager", value)
            }
            isMultiSelect={false}
            formik={formik}
            className={classes.customField}
          />
          <SelectList
            text={"Project Managers"}
            disabled={checkPermission([5], roleId)}
            name="projectManagers"
            options={activeUsers}
            value={formik.values.projectManagers}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            handleChange={(value) =>
              formik.setFieldValue("projectManagers", value)
            }
            formik={formik}
            isMultiSelect={true}
            className={classes.customField}
          />
        </Grid>
        <Grid item xs className={classes.item}>
          <SelectList
            text={
              formik.values.statusTypeId >= 4
                ? "Lead Estimator*"
                : "Lead Estimator"
            }
            disabled={checkPermission([5], roleId)}
            name="leadEstimator"
            options={activeUsers}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            value={formik.values.leadEstimator}
            handleChange={(value) =>
              formik.setFieldValue("leadEstimator", value)
            }
            formik={formik}
            className={classes.customField}
          />

          <SelectList
            text={"Estimators"}
            disabled={checkPermission([5], roleId)}
            name="estimators"
            options={activeUsers}
            value={formik.values.estimators}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            handleChange={(value) => formik.setFieldValue("estimators", value)}
            formik={formik}
            isMultiSelect={true}
            className={classes.customField}
          />
        </Grid>
        <Grid item xs className={classes.item}>
          <SelectList
            text={" Project Coordinators"}
            disabled={checkPermission([5], roleId)}
            name="projectCoordinators"
            options={activeUsers}
            getOptionLabel={(option) =>
              `${option.firstName} ${option.lastName}`
            }
            value={formik.values.projectCoordinators}
            handleChange={(value) =>
              formik.setFieldValue("projectCoordinators", value)
            }
            formik={formik}
            isMultiSelect={true}
            className={classes.customField}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "4rem",
  },
  content: {
    paddingLeft: 15,
  },
  item: {
    minWidth: "9rem",
    marginRight: "2rem",
  },
  lastItem: {
    minWidth: "10rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    borderRadius: 5,
  },
}));

export default TeamAssignments;
