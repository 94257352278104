import React, { useState, useEffect } from "react";
import { Box, TextField, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  formatDateUS,
  getRoleName,
  getTooltipTitle,
} from "../../utils/helperFunctions";
import DashboardTable from "../elements/DashboardTable";
import { getRoles } from "../../actions/usersAction";

const UserTable = (props) => {
  const { rows, setSelectedRows, selectedRows, editUserUrl, fullView } = props;
  const classes = useStyles();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getRoles().then((response) => {
      setRoles(response);
    });
  }, []);

  const columns = [
    {
      field: "picture",
      headerName: " ",
      filterable: false,
      sortable: false,
      width: 70,
      renderCell: (params) => (
        <div key="picture" className={classes.image}>
          <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${params.value})`,
            }}
          >
            <b>
              {params.value === null &&
                params.row.firstName.charAt(0) + params.row.lastName.charAt(0)}
            </b>
          </span>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Full Name",
      width: 200,
      renderCell: (params) => (
        <Link to={editUserUrl(params.row.id)}>
          {`${params.row.firstName} ${params.row.lastName}`}
        </Link>
      ),
      filterOperators: usersOperator("fullName", null),
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        (
          cellParams1.row.firstName +
          " " +
          cellParams1.row.lastName
        ).localeCompare(
          cellParams2.row.firstName + " " + cellParams2.row.lastName
        ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
    },
    fullView && {
      field: "jobTitle",
      headerName: "Job Title",
      width: 120,
    },
    {
      field: "roleId",
      headerName: "Role",
      width: 130,
      renderCell: (params) => getRoleName(params.value, roles),
      filterOperators: usersOperator("role", roles),
      sortComparator: (v1, v2) =>
        getRoleName(v1, roles).localeCompare(getRoleName(v2, roles)),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Box
          fontSize="subtitle2.fontSize"
          className={clsx(classes[params.value], classes.chip)}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: "lastSuccessfullLogin",
      headerName: "Last Login",
      width: 130,
      type: "date",
      valueFormatter: (params) =>
        params.value
          ? formatDateUS(params.value) === "1/1/1"
            ? "-"
            : formatDateUS(params.value)
          : "-",
      filterOperators: dateOperator,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      width: 140,
      type: "date",
      valueFormatter: (params) =>
        params.value ? formatDateUS(params.value) : "-",
      filterOperators: dateOperator,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      valueFormatter: (params) => params.value,
    },
    fullView && {
      field: "subsidiaries",
      headerName: "Subsidiaries",
      width: 200,
      renderCell: (params) => {
        let finalString = getTooltipTitle(
          params.row.subsidiaries,
          "subsidiary"
        );
        return params.row.subsidiaries.length > 1 ? (
          <StyledTooltip
            title={params.row.subsidiaries
              .map((subsidiary) => subsidiary.subsidiary.name)
              .slice(1)}
          >
            <span className={classes.tablecellTrucate}>{finalString}</span>
          </StyledTooltip>
        ) : (
          finalString
        );
      },
      filterOperators: usersOperator("subsidiary", roles),
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        getTooltipTitle(
          cellParams1.row.subsidiaries,
          "subsidiary"
        ).localeCompare(
          getTooltipTitle(cellParams2.row.subsidiaries, "subsidiary")
        ),
    },
    fullView && {
      field: "divisions",
      headerName: "Divisions",
      width: 200,
      renderCell: (params) => {
        const finalString = getTooltipTitle(params.row.divisions, "divisions");

        return params.row.divisions.length > 1 ? (
          <StyledTooltip
            title={params.row.divisions
              .map((division) => `${division.division.name} `)
              .slice(1)}
          >
            <span className={classes.tablecellTrucate}>{finalString}</span>
          </StyledTooltip>
        ) : (
          finalString
        );
      },
      filterOperators: usersOperator("division", null),
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        getTooltipTitle(cellParams1.row.divisions, "divisions").localeCompare(
          getTooltipTitle(cellParams2.row.divisions, "divisions")
        ),
    },
  ];

  return (
    <>
      <DashboardTable
        rows={rows}
        columns={columns}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </>
  );
};

const usersOperator = (type, neededArray) => [
  {
    label: "Contains",
    value: "contains",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "fullName"
          ? params.row.firstName + " " + params.row.lastName
          : type === "role"
          ? getRoleName(params.value, neededArray)
          : type === "subsidiary"
          ? getTooltipTitle(params.row.subsidiaries, "subsidiary")
          : type === "division"
          ? getTooltipTitle(params.row.subsidiaries, "division")
          : "-";
        return rowValue.toUpperCase().includes(filterItem.value.toUpperCase());
      };
    },
    InputComponent: UserInputValue,
    InputComponentProps: { type: "string" },
  },
  {
    label: "Equals",
    value: "equals",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "fullName"
          ? params.row.firstName + " " + params.row.lastName
          : type === "role"
          ? getRoleName(params.value, neededArray)
          : type === "subsidiary"
          ? getTooltipTitle(params.row.subsidiaries, "subsidiary")
          : type === "division"
          ? getTooltipTitle(params.row.subsidiaries, "division")
          : "-";
        return rowValue.toUpperCase() === filterItem.value.toUpperCase();
      };
    },
    InputComponent: UserInputValue,
    InputComponentProps: { type: "string" },
  },
  {
    label: "Starts with",
    value: "startsWith",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "fullName"
          ? params.row.firstName + " " + params.row.lastName
          : type === "role"
          ? getRoleName(params.value, neededArray)
          : type === "subsidiary"
          ? getTooltipTitle(params.row.subsidiaries, "subsidiary")
          : type === "division"
          ? getTooltipTitle(params.row.subsidiaries, "division")
          : "-";
        return rowValue
          .toUpperCase()
          .startsWith(filterItem.value.toUpperCase());
      };
    },
    InputComponent: UserInputValue,
    InputComponentProps: { type: "string" },
  },
  {
    label: "Ends with",
    value: "endsWith",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : type === "fullName"
          ? params.row.firstName + " " + params.row.lastName
          : type === "role"
          ? getRoleName(params.value, neededArray)
          : type === "subsidiary"
          ? getTooltipTitle(params.row.subsidiaries, "subsidiary")
          : type === "division"
          ? getTooltipTitle(params.row.subsidiaries, "division")
          : "-";
        return rowValue.toUpperCase().endsWith(filterItem.value.toUpperCase());
      };
    },
    InputComponent: UserInputValue,
    InputComponentProps: { type: "string" },
  },
];

const dateOperator = [
  {
    label: "Is",
    value: "is",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) === filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
  {
    label: "Is After",
    value: "isAfter",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) > filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
  {
    label: "Is Before",
    value: "isBefore",
    getApplyFilterFn: (filterItem, column) => {
      if (
        !filterItem.columnField ||
        !filterItem.value ||
        !filterItem.operatorValue
      ) {
        return null;
      }
      return (params) => {
        const rowValue = column.valueGetter
          ? column.valueGetter(params)
          : params.value;
        return Boolean(rowValue) && Boolean(filterItem.value)
          ? rowValue.slice(0, 10) < filterItem.value.slice(0, 10)
          : null;
      };
    },
    InputComponent: DateInputValue,
    InputComponentProps: { type: "date" },
  },
];

function UserInputValue(props) {
  const classes = useStyles();
  const { item, applyValue } = props;

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <div>
      <TextField
        className={classes.dateInput}
        name="custom-rating-filter-operator"
        placeholder="Filter value"
        value={item.value}
        onChange={handleFilterChange}
      />
    </div>
  );
}

function DateInputValue(props) {
  const classes = useStyles();
  const { item, applyValue } = props;

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <div>
      <TextField
        className={classes.dateInput}
        name="custom-rating-filter-operator"
        placeholder="Filter value"
        value={item.value}
        onChange={handleFilterChange}
        type="date"
      />
    </div>
  );
}

const StyledTooltip = withStyles({
  tooltip: {
    marginTop: "0px",
    marginLeft: "100px",
  },
})(Tooltip);

const useStyles = makeStyles((theme) => ({
  dateInput: {
    height: "48px",
    "& div": {
      height: "100%",
    },
  },
  chip: {
    fontWeight: "bold",
    width: "8rem",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
  INACTIVE: {
    color: "#757575",
  },
  EXPIRED: {
    color: "#FC0000",
  },
  PENDING: {
    color: "#C15700",
  },
  ACTIVE: {
    color: "#7FB49B",
  },
  image: {
    width: "100%",
    position: "relative",
    borderRadius: "1.5rem",
    height: "12.8px",
    padding: "1px",
    marginTop: "1px",

    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "-9px",
    bottom: 0,
    backgroundSize: "cover",
    maxWidth: "2.25rem",
    height: "2.25rem",
    borderRadius: "inherit",
    backgroundPosition: "center",
    border: "1px solid #4A5568",
    boxSizing: "border-box",
    color: "#4A5568",
    textAlign: "center",
    lineHeight: "36px",
    backgroundColor: "#F7FAFC",
  },
  tablecellTrucate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default UserTable;
