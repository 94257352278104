import server from "../api/server";

export const getData = async (apiUrl, callback) => {
  const response = await server({
    url: apiUrl,
    method: "GET",
    headers: server.headers,
  });

  callback && callback(response);
  return response;
};
export const getDataById = async (apiUrl, id, callback) => {
  return await getData(`${apiUrl}/${id}`, callback);
};

export const postData = async (apiUrl, postData, callback) => {
  const response = await server({
    url: apiUrl,
    method: "POST",
    headers: server.headers,
    data: postData,
  });

  callback && callback(response);
  return response;
};

export const putData = async (apiUrl, putData, callback) => {
  const response = await server({
    url: apiUrl,
    method: "PUT",
    headers: server.headers,
    data: putData,
  });

  callback && callback(response);
  return response;
};
