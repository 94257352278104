import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
} from "@material-ui/core";

import * as constants from "../../constants";

const DeleteModal = (props) => {
  const { open, handleClose, handleDelete, title, subtitle, content } = props;
  const classes = useStyles();
  return ReactDOM.createPortal(
    <div onClick={handleClose}>
      <div onClick={(e) => e.stopPropagation()}>
        <Dialog
          open={open}
          fullWidth
          maxWidth={props.rightPannel ? "md" : "sm"}
          aria-labelledby="form-dialog-title"
          className={classes.root}
          onClose={handleClose}
          scroll="paper"
        >
          <div className={classes.singlePannel}>
            <DialogTitle id="form-dialog-title">
              <Box fontSize="h3.fontSize" fontWeight="bold">
                {title}
              </Box>
              <Box fontSize="h5.fontSize">{subtitle}</Box>
            </DialogTitle>
            <DialogContent className={classes.modalContent}>
              {content}
            </DialogContent>
            <DialogActions className={classes.modalActions}>
              <Button
                variant="outlined"
                size="medium"
                className={classes.cancelButton}
                onClick={handleClose}
              >
                <Box>Cancel</Box>
              </Button>
              <Button
                onClick={handleDelete}
                variant="contained"
                size="medium"
                className={classes.deleteButton}
              >
                <Box>Delete</Box>
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </div>,
    document.querySelector("#modal")
  );
};

const useStyles = makeStyles(() => ({
  singlePannel: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  modalContent: {
    height: "70%",
    overflowY: "auto",
  },
  deleteButton: {
    backgroundColor: constants.ASTERISK_COLOR,
  },
  cancelButton: {
    marginRight: "auto",
  },
}));

export default DeleteModal;
