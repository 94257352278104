export const SET_JPAC_PROJECTS = "SET_JPAC_PROJECTS";
export const SET_JPAC_PROJECT_INFO = "SET_JPAC_PROJECT_INFO";

export const setJPACProjects = (payload) => ({
  type: SET_JPAC_PROJECTS,
  payload,
});

export const setJPACProjectInfo = (payload) => ({
  type: SET_JPAC_PROJECT_INFO,
  payload,
});
