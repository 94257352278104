export const selectCompanies = (state) => {
  return state.company.companies;
};
export const selectCompany = (state) => {
  return state.company.company;
};

export const selectAvailableSubsidiaries = (state) => {
  return state.company.availableSubsidiaries;
};

export const selectMaxNumberOfSubsidiaries = (state) => {
  return state.company.company.maxNumberOfSubsidiaries;
};

export const selectCustomers = (state) => {
  return state.company.customers;
};

export const selectPersons = (state) => {
  return state.company.persons;
};

export const selectRequirements = (state) => {
  return state.company.requirements;
};

export const selectMaterials = (state) => {
  return state.company.materials;
};
