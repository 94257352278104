import _ from "lodash";
import CryptoJS from "crypto-js";
import moment from "moment";
import { secret } from "../constants";

const contactTypes = [
  {
    id: 1,
    name: "Owner",
  },
  {
    id: 2,
    name: "General Contractor",
  },
  {
    id: 3,
    name: "Engineer",
  },
  {
    id: 4,
    name: "Architect",
  },
  {
    id: 5,
    name: "Vendor",
  },
];

export const getCustomer = (customers, customerId) => {
  if (customers.length !== 0) {
    return _.find(customers, { id: customerId });
  }
  return {
    name: "",
    contacts: [],
  };
};

export const getObjectById = (array, id) => {
  if (array.length !== 0 && id) {
    return _.find(array, { id: id });
  }
  return "";
};

export const getDivisionForSubsidiaryById = (array, id) => {
  if (array[0].divisions.length !== 0) {
    return _.find(array[0].divisions, { id: id });
  }
};

export const getCountryName = (countries, countryId) => {
  if (countries.length !== 0) {
    return _.find(countries, { id: countryId }).name;
  }
  return "-";
};

export const getCountyName = (counties, countyId) => {
  if (counties.length !== 0) {
    return _.find(counties, { id: countyId }).name;
  }
  return "-";
};

export const getStateName = (states, stateId) => {
  if (states.length !== 0) {
    return _.find(states, { id: stateId }).name;
  }
  return "-";
};

export const getName = (arr, id) => {
  if (!arr.length) {
    return "-";
  }
  return _.find(arr, { id }).name;
};

export const getRoleName = (roleId, roles) => {
  if (!Array.isArray(roles)) {
    return "-";
  }

  const role = roles.find((role) => role.id === parseInt(roleId));

  if (role) {
    return role.name;
  }
  return "-";
};

export const getTooltipTitle = (array, type) => {
  const firstElement = array
    ? type === "subsidiary"
      ? array[0]?.subsidiary?.name
      : array[0]?.division?.name
    : "-";
  const elementsCount = array.length;
  const finalString =
    elementsCount > 1
      ? `${firstElement} + ${elementsCount - 1} more`
      : firstElement;

  return finalString ? finalString : "-";
};

export const getIndustryTitle = (
  industryClassificationId,
  industries,
  noCode
) => {
  // added here due to a bug in BE
  if (industryClassificationId === 0) {
    return noCode
      ? "New Multifamily Housing Construction (except For-Sale Builders)"
      : "236116 - New Multifamily Housing Construction (except For-Sale Builders)";
  }

  let found = null;
  const checkValid = (node, industryClassificationId) => {
    if (node.childIndustries.length === 0) {
      if (node.id === industryClassificationId) {
        found = noCode ? node.title : `${node.code} - ${node.title}`;
      }
    } else {
      for (let i = 0; i < node.childIndustries.length; i++) {
        const newNode = node.childIndustries[i];
        checkValid(newNode, industryClassificationId);
      }
    }
  };
  checkValid(industries, industryClassificationId);
  return found || "-";
};

export const getContactType = (id) => {
  if (contactTypes.length !== 0 && id) {
    return _.find(contactTypes, { id }).name;
  }
  return "-";
};

export const getDateFormat = (date) => {
  if (date) {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(new Date(date));
  }
  return "-";
};

export const arrayEqualityById = (arr1, arr2) => {
  if (!(Array.isArray(arr1) && Array.isArray(arr1))) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].id !== arr2[i].id) {
      return false;
    }
  }
  return true;
};

export const getIdsFromObjects = (arr) => {
  const ids = arr.map((a) => a.id);
  return ids;
};

export const getCustomerFromCards = (cards) =>
  _.find(cards, ["isCustomer", true]);

export const getOtherContactsFromCards = (cards) =>
  _.differenceBy(cards, [{ isCustomer: true }], "isCustomer");

export const getOtherCustomers = (customers, contacts) => {
  const otherCustomers = [];
  customers.forEach((customer) => {
    if (
      !contacts.find(
        (contact) =>
          contact.customer.id === customer.id && contact.person == null
      )
    ) {
      otherCustomers.push(customer);
    }
  });
  return otherCustomers;
};

export const getOtherPersons = (persons, contacts) => {
  const otherPersons = [];
  persons.forEach((person) => {
    if (
      !contacts.find((contact) =>
        contact.person ? contact.person.id === person.id : false
      )
    ) {
      otherPersons.push(person);
    }
  });
  return otherPersons;
};
export const validateCardAdd = (contacts, company, person) =>
  Boolean(
    contacts.find(
      (contact) => contact.customer.id === company.id && Boolean(contact.person)
    )
  ) && Boolean(!person);

export const getOtherProjects = (projects, projectId) =>
  _.differenceBy(projects, [{ id: projectId }], "id");

export const getProjectsByStatus = (projects, projectStatusTypeId) =>
  _.filter(projects, (o) => o.projectStatusTypeId === projectStatusTypeId);

export const getMonthAndYearLabel = (date) => {
  const month = new Array(12);
  month[0] = "Jan";
  month[1] = "Feb";
  month[2] = "Mar";
  month[3] = "Apr";
  month[4] = "May";
  month[5] = "Jun";
  month[6] = "Jul";
  month[7] = "Aug";
  month[8] = "Sep";
  month[9] = "Oct";
  month[10] = "Nov";
  month[11] = "Dec";

  const newDate = new Date(date);
  return `${month[newDate.getMonth()]}-${`${newDate.getFullYear()}`.slice(-4)}`;
};

export const getQuarter = (date) => {
  const newDate = new Date(date);
  const q = Math.floor(newDate.getMonth() / 3);
  return q > 4 ? q - 4 : q;
};

export const getQuarterAndYearLabel = (q, year) => {
  const quarter = new Array(4);
  quarter[0] = "Q1";
  quarter[1] = "Q2";
  quarter[2] = "Q3";
  quarter[3] = "Q4";
  return `${quarter[q]}-${year}`;
};

export const getYearLabel = (date) => `${new Date(date).getFullYear}`;

export const formatDateUS = (date) => {
  const newDate = new Date(date);
  return `${
    newDate.getMonth() + 1
  }/${newDate.getDate()}/${newDate.getFullYear()}`;
};

export const formatDatePicker = (date) => {
  const newDate = new Date(date);
  const month =
    (newDate.getMonth() + 1).toString().length === 1
      ? "0" + (newDate.getMonth() + 1)
      : newDate.getMonth() + 1;
  const day =
    newDate.getDate().toString().length === 1
      ? "0" + newDate.getDate()
      : newDate.getDate();
  return newDate.getFullYear() + "-" + month + "-" + day;
};

export const dateFormat = (date, format) => {
  if (!date || !format) {
    return "";
  }

  const zuluDateTime = `${date}z`;
  return moment(new Date(zuluDateTime)).format(format);
};

export const formatNumberUS = (style, number, fractionDigits) =>
  (style === "$" || style === "$ " ? style : "") +
  (fractionDigits
    ? number.toLocaleString("undefined", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    : parseInt(parseInt(number).toFixed(2)).toLocaleString("en-US")) +
  (!(style === "$" || style === "$ ") ? ` ${style}` : "");

export const encryptWithAES = (text) =>
  CryptoJS.AES.encrypt(`${text}`, secret).toString();

export const decryptWithAES = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(`${ciphertext}`, secret);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const checkPermission = (allowedRoles, roleId) =>
  Boolean(allowedRoles.find((element) => element === roleId));

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const displayTabsWithErrors = (formik) => {
  const errors = [];
  if (
    (formik.touched.name && Boolean(formik.errors.name)) ||
    (formik.touched.projectNumber && Boolean(formik.errors.projectNumber)) ||
    (formik.touched.divisionId && Boolean(formik.errors.divisionId)) ||
    (formik.touched.subsidiaryId && Boolean(formik.errors.subsidiaryId)) ||
    (formik.touched.description && Boolean(formik.errors.description)) ||
    (formik.touched.customer && Boolean(formik.errors.customer))
  ) {
    errors.push(1);
  }
  if (
    (formik.touched.expectedStartDate &&
      Boolean(formik.errors.expectedStartDate)) ||
    (formik.touched.expectedEndDate &&
      Boolean(formik.errors.expectedEndDate)) ||
    (formik.touched.marketSegmentId &&
      Boolean(formik.errors.marketSegmentId)) ||
    (formik.touched.marketCategoryId &&
      Boolean(formik.errors.marketCategoryId)) ||
    (formik.touched.marketSubcategoryId &&
      Boolean(formik.errors.marketSubcategoryId)) ||
    (formik.touched.constructionTypeId &&
      Boolean(formik.errors.constructionTypeId)) ||
    (formik.touched.typeOfWorkId && Boolean(formik.errors.typeOfWorkId)) ||
    (formik.touched.marketSectorId && Boolean(formik.errors.marketSectorId)) ||
    (formik.touched.isMinorityParticipationGoal &&
      Boolean(formik.errors.isMinorityParticipationGoal)) ||
    (formik.touched.country && Boolean(formik.errors.country)) ||
    (formik.touched.state && Boolean(formik.errors.state)) ||
    (formik.touched.country && Boolean(formik.errors.country)) ||
    (formik.touched.state && Boolean(formik.errors.state)) ||
    (formik.touched.county && Boolean(formik.errors.county)) ||
    (formik.touched.city && Boolean(formik.errors.city)) ||
    (formik.touched.city && Boolean(formik.errors.city)) ||
    (formik.touched.address && Boolean(formik.errors.address)) ||
    (formik.touched.address2 && Boolean(formik.errors.address2)) ||
    (formik.touched.zipCode && Boolean(formik.errors.zipCode))
  ) {
    errors.push(2);
  }
  if (
    (formik.touched.bidDueDate && Boolean(formik.errors.bidDueDate)) ||
    (formik.touched.notes && Boolean(formik.errors.notes)) ||
    (formik.touched.materials && Boolean(formik.errors.materials)) ||
    (formik.touched.requirements && Boolean(formik.errors.requirements)) ||
    (formik.touched.leadEstimator && Boolean(formik.errors.leadEstimator)) ||
    (formik.touched.estimators && Boolean(formik.errors.estimators)) ||
    (formik.touched.leadProjectManager &&
      Boolean(formik.errors.leadProjectManager)) ||
    (formik.touched.projectManagers &&
      Boolean(formik.errors.projectManagers)) ||
    (formik.touched.projectCoordinators &&
      Boolean(formik.errors.projectCoordinators)) ||
    (formik.touched.bidValue && Boolean(formik.errors.bidValue)) ||
    (formik.touched.estimatedCost && Boolean(formik.errors.estimatedCost)) ||
    (formik.touched.estimatedLaborCost &&
      Boolean(formik.errors.estimatedLaborCost)) ||
    (formik.touched.estimatedLaborHours &&
      Boolean(formik.errors.estimatedLaborHours))
  ) {
    errors.push(3);
  }
  if (
    (formik.touched.awardedDate && Boolean(formik.errors.awardedDate)) ||
    (formik.touched.taxExempt && Boolean(formik.errors.taxExempt)) ||
    (formik.touched.startDate && Boolean(formik.errors.startDate)) ||
    (formik.touched.endDate && Boolean(formik.errors.endDate)) ||
    (formik.touched.contractType && Boolean(formik.errors.contractType)) ||
    (formik.touched.originalContractAmount &&
      Boolean(formik.errors.originalContractAmount)) ||
    (formik.touched.originalEstimatedLaborCost &&
      Boolean(formik.errors.originalEstimatedLaborCost)) ||
    (formik.touched.originalEstimatedLaborHours &&
      Boolean(formik.errors.originalEstimatedLaborHours)) ||
    (formik.touched.originalEstimatedTotalCost &&
      Boolean(formik.errors.originalEstimatedTotalCost))
  ) {
    errors.push(4);
  }
  return errors;
};

export const getStatusLabel = (statusId, statuses) => {
  const found = statuses.find((element) => element.id === statusId);
  return found?.name || "-";
};

export const getLabel = (projectStatusTypeId) => {
  switch (projectStatusTypeId) {
    case 1:
      return "requested";
    case 2:
      return "new";
    case 3:
      return "noBid";
    case 4:
      return "estimating";
    case 6:
      return "bidPending";
    case 7:
      return "bidLost";
    case 8:
      return "bidWon";
    case 9:
      return "active";
    case 10:
      return "closed";
    case 11:
      return "deleted";
    default:
      return "requested";
  }
};

export const getLabelTitle = (projectStatusTypeId) => {
  switch (projectStatusTypeId) {
    case 1:
      return "Requested";
    case 2:
      return "New";
    case 3:
      return "No Bid";
    case 4:
      return "Estimating";
    case 6:
      return "Bid Submitted";
    case 7:
      return "Bid Lost";
    case 8:
      return "Awarded";
    case 9:
      return "Active";
    case 10:
      return "Closed";
    case 11:
      return "Deleted";
    default:
      return "Requested";
  }
};

export const getContactsIdArrayFromCards = (cards, projectId) => {
  const idArray = [];
  cards.forEach((card) => {
    idArray.push({
      projectId,
      customerId: card.customer.id,
      personId: card.person ? card.person.id : null,
      isCustomer: card.isCustomer,
    });
  });
  return idArray;
};

export const makeBase64 = async (file) => {
  if (typeof file === "string") {
    return file;
  }
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const isProjectActive = (statusId) => {
  return statusId > 8;
};

export const isProjectNew = (statusId) => {
  return statusId > 1;
};

export const isProjectAwarded = (statusId) => {
  return statusId > 7;
};

export const getAvailableStatusesForContract = (currentStatus) => {
  switch (currentStatus) {
    case 8:
      return [
        { id: 8, name: "Awarded" },
        { id: 9, name: "Active" },
      ];
    case 9:
      return [
        { id: 9, name: "Active" },
        { id: 10, name: "Closed" },
      ];
    case 10:
      return [{ id: 10, name: "Closed" }];
    case 11:
      return [{ id: 11, name: "Deleted" }];
    default:
      return [{ id: 8, name: "Awarded" }];
  }
};

export const getAvailableStatuses = (currentStatus) => {
  switch (currentStatus) {
    case 1:
      return [
        { id: 1, name: "Requested" },
        { id: 2, name: "New" },
      ];
    case 2:
      return [
        { id: 2, name: "New" },
        { id: 4, name: "Estimating" },
        { id: 3, name: "No Bid" },
      ];
    case 3:
      return [
        { id: 3, name: "No Bid" },
        { id: 2, name: "New" },
      ];
    case 4:
      return [
        { id: 4, name: "Estimating" },
        { id: 6, name: "Bid Submitted" },
      ];
    case 6:
      return [
        { id: 6, name: "Bid Submitted" },
        { id: 8, name: "Awarded" },
        { id: 7, name: "Bid Lost" },
      ];
    case 7:
      return [
        { id: 6, name: "Bid Submitted" },
        { id: 7, name: "Bid Lost" },
      ];
    case 8:
      return [
        { id: 8, name: "Awarded" },
        { id: 9, name: "Active" },
      ];
    case 9:
      return [
        { id: 9, name: "Active" },
        { id: 10, name: "Closed" },
      ];
    case 10:
      return [{ id: 10, name: "Closed" }];
    case 11:
      return [{ id: 11, name: "Deleted" }];
    default:
      return [
        { id: 1, name: "Requested" },
        { id: 2, name: "New" },
      ];
  }
};
