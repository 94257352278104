import React from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const ValidationInput = (props) => {
  const {
    id,
    name,
    value,
    pattern,
    onChange,
    onBlur,
    error,
    helperText,
    valueLabel,
    valueLabelPosition,
    disabled,
    label,
    className,
  } = props;

  const defaultValidationPattern = "^(?![0.]\\d)\\d{0,9}(\\.\\d{0,2})?$";

  const classes = useStyles();

  const onInputChange = (e) => {
    let newValue = e.target.value;

    if (newValue) {
      newValue = newValue.replaceAll(",", "");

      if (valueLabel) {
        if (
          valueLabelPosition === "back" &&
          e.nativeEvent &&
          e.nativeEvent.inputType === "deleteContentBackward" &&
          newValue.indexOf(valueLabel) === -1
        ) {
          const splitValue = newValue.split(" ");

          if (Array.isArray(splitValue)) {
            newValue = splitValue[0].substring(0, splitValue[0].length - 1);
          }
        } else {
          newValue = newValue.replaceAll(valueLabel, "");
        }
      }
      newValue = newValue.replaceAll(" ", "");

      if (newValue === ".") {
        return;
      }

      e.target.value = newValue;
    }

    if (
      validateValue(newValue) ||
      (newValue[newValue.length - 1] === "." &&
        Array.isArray(newValue.split(".")) &&
        newValue.split(".").length <= 2) ||
      newValue === ""
    ) {
      onChange(e);
    }
  };

  const validateValue = (value) => {
    const valuePattern = pattern || defaultValidationPattern;
    const regExp = new RegExp(valuePattern);

    return regExp.test(value);
  };

  const formatValue = (value) => {
    const formattedValue = validateValue(value)
      ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "";

    if (isNaN(value) || value === Infinity) {
      return "";
    }
    if (
      formattedValue !== null &&
      formattedValue !== "" &&
      valueLabel &&
      valueLabelPosition
    ) {
      switch (valueLabelPosition) {
        case "front":
          return `${valueLabel} ${formattedValue}`;
        case "back":
          return `${formattedValue} ${valueLabel}`;
        default:
          return formattedValue;
      }
    }

    return formattedValue;
  };

  return (
    <TextField
      disabled={disabled}
      fullWidth
      id={id}
      name={name}
      label={label ? label : null}
      value={formatValue(value)}
      size="small"
      variant="standard"
      onChange={onInputChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      className={className ? className : classes.customField}
    />
  );
};

const useStyles = makeStyles({
  customField: {
    minHeight: "4.25rem",
    margin: "0 0.5rem",
  },
});

export default ValidationInput;
