import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import ProjectHeader from "../ProjectHeader";
import ProjectTitle from "../ProjectTitle";
import { useFormik } from "formik";
import NotificationPopUp from "../../../utils/NotificationPopUp";
import {
  createAccountingHours,
  getAccountingHoursToStore,
  updateAccountingHours,
  getContractToStore,
} from "../../../../actions/projectActions";
import { formatDateUS } from "../../../../utils/helperFunctions";
import * as store from "../../../../state";
import AccountingHoursForm from "./AccountingHoursForm";

const AccountingHours = (props) => {
  const { projectId } = props;
  const history = useHistory();
  const dispatchRedux = useDispatch();

  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const roleId = loggedInUser.roleId;
  const accountingHours = useSelector(
    store.selectors.project.selectAccountingHoursInfo
  );
  const contract = useSelector(store.selectors.project.selectContractInfo);

  const classes = useStyles();

  const initialValuesForAccountingHours = {
    projectRef: parseInt(projectId),
    id: 0,
    createdAt: null,
    updatedAt: null,
    totalCostToDate: "",
    totalLaborCostIncurredToDate: "",
    totalLaborHoursToDate: "",
  };

  const [initialValues, setInitialValues] = useState(
    initialValuesForAccountingHours
  );
  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const onSucces = () => {
    setNotificationTitle("Project updated");
    setIsNotificationPopUpOpen(true);
    setNotificationText(<p>Project successfully updated.</p>);
    setNotificationType("success");
    setNotificationActions([
      {
        name: "OK",
        action: () => {
          handleCloseModal();
        },
      },
    ]);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      const accounting = {
        ...values,
        totalCostToDate: parseFloat(values.totalCostToDate),
        totalLaborCostIncurredToDate: parseFloat(
          values.totalLaborCostIncurredToDate
        ),
        totalLaborHoursToDate: parseFloat(values.totalLaborHoursToDate),
      };
      if (values.id === 0) {
        createAccountingHours(accounting, dispatchRedux, onSucces);
      } else {
        updateAccountingHours(accounting, dispatchRedux, onSucces);
      }
    },
  });

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleCancel = () => {
    history.push("/projects");
  };

  useEffect(() => {
    if (accountingHours && accountingHours.projectRef) {
      setInitialValues(accountingHours);
    } else {
      setInitialValues(initialValuesForAccountingHours);
    }
  }, [accountingHours]);

  useEffect(() => {
    async function fetchData() {
      await getAccountingHoursToStore(dispatchRedux, projectId);
      await getContractToStore(projectId, dispatchRedux);
    }
    fetchData();
  }, []);

  return (
    <>
      <ProjectHeader projectId={projectId} />
      <div className={classes.container}>
        <ProjectTitle
          pageTitle={"Accounting Hours"}
          actions={[
            {
              name: "Cancel",
              action: () => handleCancel(),
              type: "default",
            },
            {
              name: "Save",
              action: () => formik.handleSubmit(),
              type: "success",
            },
          ]}
        />
        <h4 className={classes.pageTitle}>
          {formik.values.updatedAt ? (
            <>(last update: {formatDateUS(formik.values.updatedAt)})</>
          ) : (
            <> </>
          )}
        </h4>
        <AccountingHoursForm
          formik={formik}
          roleId={roleId}
          contract={contract}
        />
      </div>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
  lastUpdateLabel: {
    marginBottom: ".5rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    minHeight: "1.5rem",
    borderRadius: 5,
  },
}));

export default AccountingHours;
