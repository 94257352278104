// !! import this module in the corresponding reducer !!
export const ADD_SUBSIDIARY = "ADD_SUBSIDIARY";
export const GET_SUBSIDIARY = "GET_SUBSIDIARY";
export const EDIT_SUBSIDIARY = "EDIT_SUBSIDIARY";
export const GET_SUBSIDIARIES = "GET_SUBSIDIARIES";
export const REMOVE_SUBSIDIARIES = "REMOVE_SUBSIDIARIES";
export const EDIT_SUBSIDIARY_STATUS = "EDIT_SUBSIDIARY_STATUS";

// export const subsidiaryInitStateAction = () => ({
//   type: SUBSIDIARY_INIT_STATE,
// });
// export const subsidiarySetAction = (payload) => ({
//   type: SUBSIDIARY_SET_,
//   payload,
// });
export const setSubsidiaries = (payload) => ({
  type: GET_SUBSIDIARIES,
  payload,
});

export const setStatus = (payload) => ({
  type: EDIT_SUBSIDIARY_STATUS,
  payload,
});
export const setSubsidiary = (payload) => ({
  type: GET_SUBSIDIARY,
  payload,
});
