import server from "../api/server";
import * as API from "./_apiCalls";
import { makeBase64 } from "../utils/helperFunctions";
import * as store from "../state";
// import {
//   // GET_COMPANIES,
//   // GET_COMPANY,
//   // REMOVE_COMPANIES,
//   //  EDIT_COMPANY,
//   GET_MATERIALS,
//   GET_REQUIREMENTS,
//   ADD_MATERIALS,
//   ADD_REQUIREMENTS,
//   GET_CUSTOMERS,
//   ADD_CUSTOMERS,
//   GET_PERSONS,
//   ADD_PERSONS,
//   GET_AVAILABLE_DIVISIONS,
//   GET_AVAILABLE_USER_SEATS,
//   GET_AVAILABLE_SUBSIDIARIES,
//   EDIT_COMPANY_STATUS,
// } from "./types";

export const addCompanyData = async (company, callback) => {
  let base64Logo = null;
  if (company.logo) base64Logo = await makeBase64(company.logo);
  company.logo = base64Logo;
  const newCompany = {
    Name: company.name,
    Address: company.address,
    Address2: company.address2,
    Logo: base64Logo,
    City: company.city,
    StateId: parseInt(company.stateId),
    ZipCode: company.zipCode,
    CountryId: parseInt(company.countryId),
    CountyId: company.county,
    Phone: company.phone,
    Website: company.website,
    IndustryClassificationId: company.industryClassificationId,
    MaxNumberOfUsers: parseInt(company.maxNumberOfUsers),
    MaxNumberOfSubsidiaries: parseInt(company.maxNumberOfSubsidiaries),
    MaxNumberOfDivisions: parseInt(company.maxNumberOfDivisions),
  };
  return API.postData("/company/addCompany", newCompany, callback);
  // const response = await server({
  //   url: "/company/addCompany",
  //   method: "POST",
  //   headers: server.headers,
  //   data: newCompany,
  // });
  // dispatch({
  //   type: ADD_COMPANY,
  //   company,
  // });
  // return response.data.id;
};

// export const addCompany = async (company, dispatch) => {
//   let base64Logo = null;
//   if (company.logo) base64Logo = await makeBase64(company.logo);
//   company.logo = base64Logo;
//   const newCompany = {
//     Name: company.name,
//     Address: company.address,
//     Address2: company.address2,
//     Logo: base64Logo,
//     City: company.city,
//     StateId: parseInt(company.stateId),
//     ZipCode: company.zipCode,
//     CountryId: parseInt(company.countryId),
//     CountyId: company.county,
//     Phone: company.phone,
//     Website: company.website,
//     IndustryClassificationId: company.industryClassificationId,
//     MaxNumberOfUsers: parseInt(company.maxNumberOfUsers),
//     MaxNumberOfSubsidiaries: parseInt(company.maxNumberOfSubsidiaries),
//     MaxNumberOfDivisions: parseInt(company.maxNumberOfDivisions),
//   };
//   const response = await server({
//     url: "/company/addCompany",
//     method: "POST",
//     headers: server.headers,
//     data: newCompany,
//   });
//   dispatch({
//     type: ADD_COMPANY,
//     company,
//   });
//   return response.data.id;
// };

export const removeCompaniesData = async (companies, callback) => {
  return API.postData("/company/removecompanies", companies, callback);

  // const response = await server({
  //   url: "/company/removecompanies",
  //   method: "PUT",
  //   headers: server.headers,
  //   data: companies,
  // });
  // dispatch({
  //   type: REMOVE_COMPANIES,
  //   companies,
  // });

  // return response.data;
};

// export const removeCompanies = async (companies, dispatch) => {
//   const response = await server({
//     url: "/company/removecompanies",
//     method: "PUT",
//     headers: server.headers,
//     data: companies,
//   });
//   dispatch({
//     type: REMOVE_COMPANIES,
//     companies,
//   });

//   return response.data;
// };

export const editCompanyData = async (company, callback) => {
  let base64Logo = null;
  if (company.logo) base64Logo = await makeBase64(company.logo);
  const newCompany = {
    Id: parseInt(company.id),
    Name: company.name,
    Logo: base64Logo,
    Address: company.address,
    Address2: company.address2,
    City: company.city,
    StateId: parseInt(company.stateId),
    ZipCode: company.zipCode,
    CountryId: parseInt(company.countryId),
    CountyId: company.county,
    Phone: company.phone,
    Website: company.website,
    StatusId: company.statusId,
    IndustryClassificationId: company.industryClassificationId,
    MaxNumberOfUsers: parseInt(company.maxNumberOfUsers),
    MaxNumberOfSubsidiaries: parseInt(company.maxNumberOfSubsidiaries),
    MaxNumberOfDivisions: parseInt(company.maxNumberOfDivisions),
  };
  API.postData("/company/updateCompany", newCompany, callback);
  // dispatch({
  //   type: EDIT_COMPANY,
  //   companyId: company.id,
  //   company,
  // });
  // callback && callback(response.data);
  // if (response.status === 200) {
  //   handleCallback && handleCallback();
  // }
};

// export const editCompany = async (company, dispatch, handleCallback) => {
//   let base64Logo = null;
//   if (company.logo) base64Logo = await makeBase64(company.logo);
//   const newCompany = {
//     Id: parseInt(company.id),
//     Name: company.name,
//     Logo: base64Logo,
//     Address: company.address,
//     Address2: company.address2,
//     City: company.city,
//     StateId: parseInt(company.stateId),
//     ZipCode: company.zipCode,
//     CountryId: parseInt(company.countryId),
//     CountyId: company.county,
//     Phone: company.phone,
//     Website: company.website,
//     StatusId: company.statusId,
//     IndustryClassificationId: company.industryClassificationId,
//     MaxNumberOfUsers: parseInt(company.maxNumberOfUsers),
//     MaxNumberOfSubsidiaries: parseInt(company.maxNumberOfSubsidiaries),
//     MaxNumberOfDivisions: parseInt(company.maxNumberOfDivisions),
//   };
//   const response = await server({
//     url: "/company/updateCompany",
//     method: "PUT",
//     headers: server.headers,
//     data: newCompany,
//   });
//   dispatch({
//     type: EDIT_COMPANY,
//     companyId: company.id,
//     company,
//   });
//   if (response.status === 200) {
//     handleCallback && handleCallback();
//   }
// };

export const getCompaniesData = async (callback) => {
  return API.getData("/company/getCompanies", callback);
};

// export const getCompanies = async (dispatch) => {
//   const response = await server({
//     url: "/company/getCompanies",
//     method: "GET",
//     headers: server.headers,
//   });
//   const content = response.data;

//   dispatch({
//     type: GET_COMPANIES,
//     companies: content,
//   });
// };

export const getCompanyData = async (id, callback) => {
  return await API.getDataById("/company/getCompany", id, callback);
};

export const getCompanyDataToStore = async (
  id,
  { callback, dispatchRedux }
) => {
  return await getCompanyData(id, callback).then(({ data }) => {
    dispatchRedux(store.actions.company.setCompany(data));
  });
};

// export const getCompany = async (id, dispatch, onlyReturn) => {
//   const response = await server({
//     url: `/company/getCompany/${id}`,
//     method: "GET",
//     headers: server.headers,
//   });
//   const content = response.data;
//   if (onlyReturn) return content;
//   dispatch({
//     type: GET_COMPANY,
//     company: content,
//   });
// };

export const getMaterialsData = async ({ companyId, divisionId }, callback) => {
  return API.getData(
    `/material/getMaterials?companyId=${companyId}&divisionId=${divisionId}`,
    callback
  );
  // const response = await server({
  //   url: `/material/getMaterials?companyId=${companyId}&divisionId=${divisionId}`,
  //   method: "GET",
  //   headers: server.headers,
  // });
  // const content = response.data;

  // dispatch({
  //   type: GET_MATERIALS,
  //   materials: content,
  // });
};

// export const getMaterials = async (dispatch, companyId, divisionId) => {
//   const response = await server({
//     url: `/material/getMaterials?companyId=${companyId}&divisionId=${divisionId}`,
//     method: "GET",
//     headers: server.headers,
//   });
//   const content = response.data;

//   dispatch({
//     type: GET_MATERIALS,
//     materials: content,
//   });
// };

export const getRequirementsData = async (
  { companyId, divisionId },
  callback
) => {
  return API.getData(
    `/requirement/getRequirements?companyId=${companyId}&divisionId=${divisionId}`,
    callback
  );

  // const response = await server({
  //   url: `/requirement/getRequirements?companyId=${companyId}&divisionId=${divisionId}`,
  //   method: "GET",
  //   headers: server.headers,
  // });
  // const content = response.data;

  // dispatch({
  //   type: GET_REQUIREMENTS,
  //   requirements: content,
  // });
};

// export const getRequirements = async (dispatch, companyId, divisionId) => {
//   const response = await server({
//     url: `/requirement/getRequirements?companyId=${companyId}&divisionId=${divisionId}`,
//     method: "GET",
//     headers: server.headers,
//   });
//   const content = response.data;

//   dispatch({
//     type: GET_REQUIREMENTS,
//     requirements: content,
//   });
// };

export const getCustomersData = async (companyId, callback) => {
  return await API.getData(
    `/customer/getCustomers?companyId=${companyId}`,
    callback
  );
  // const response = await server({
  //   url: `/customer/getCustomers?companyId=${companyId}`,
  //   method: "GET",
  //   headers: server.headers,
  // });
  // const content = response.data;

  // dispatch({
  //   type: GET_CUSTOMERS,
  //   customers: content,
  // });
};

export const getCustomersDataToStore = async (
  id,
  { callback, dispatchRedux }
) => {
  return await getCustomersData(id, callback).then(({ data }) =>
    dispatchRedux(store.actions.company.setCustomers(data))
  );
};

// export const getCustomers = async (dispatch, companyId) => {
//   const response = await server({
//     url: `/customer/getCustomers?companyId=${companyId}`,
//     method: "GET",
//     headers: server.headers,
//   });
//   const content = response.data;

//   dispatch({
//     type: GET_CUSTOMERS,
//     customers: content,
//   });
// };

export const getCustomerData = async (customerId, callback) => {
  return await API.getData(`/customer/getCustomer/${customerId}`, callback);
  // const response = await server({
  //   url: `/customer/getCustomer/${customerId}`,
  //   method: "GET",
  //   headers: server.headers,
  // });

  // const content = response.data;
  // return content;
};

// export const getCustomer = async (customerId) => {
//   const response = await server({
//     url: `/customer/getCustomer/${customerId}`,
//     method: "GET",
//     headers: server.headers,
//   });

//   const content = response.data;
//   return content;
// };

export const getPersonsData = async ({ customerId, projectId }, callback) => {
  return await API.getData(
    `/person/getPersons?customerId=${customerId}&projectId=${projectId}`,
    callback
  );
  // const response = await server({
  //   url: `/person/getPersons?customerId=${customerId}&projectId=${projectId}`,
  //   method: "GET",
  //   headers: server.headers,
  // });
  // const content = response.data;

  // dispatch({
  //   type: GET_PERSONS,
  //   persons: content,
  // });
};

export const getPersonsDataToStore = async (
  data,
  { callback, dispatchRedux }
) => {
  return await getPersonsData(data, callback).then(({ data }) =>
    dispatchRedux(store.actions.company.setPersons(data))
  );
};
// export const getPersons = async (dispatch, customerId, projectId) => {
//   const response = await server({
//     url: `/person/getPersons?customerId=${customerId}&projectId=${projectId}`,
//     method: "GET",
//     headers: server.headers,
//   });
//   const content = response.data;

//   dispatch({
//     type: GET_PERSONS,
//     persons: content,
//   });
// };

//not used!!!
export const getPerson = async (personId, callback) => {
  return await API.getData(`/person/getPerson?id=${personId}`, callback);

  // const response = await server({
  //   url: `/person/getPerson?id=${personId}`,
  //   method: "GET",
  //   headers: server.headers,
  // });
  // const content = response.data;

  // return content;
};

// export const getPerson = async (personId) => {
//   const response = await server({
//     url: `/person/getPerson?id=${personId}`,
//     method: "GET",
//     headers: server.headers,
//   });
//   const content = response.data;

//   return content;
// };

export const getAvailableUserSeatsData = async (companyId, handler) => {
  return await API.getData(
    `/company/getAvailableUserSeats?companyId=${companyId}`,
    handler
  );
  // const response = await server({
  //   url: `/company/getAvailableUserSeats?companyId=${companyId}`,
  //   method: "GET",
  // });
  // const content = await response.data;

  // dispatch({
  //   type: GET_AVAILABLE_USER_SEATS,
  //   availableUserSeats: content.availableUserSeats,
  // });
};

// export const getAvailableUserSeats = async (dispatch, companyId) => {
//   const response = await server({
//     url: `/company/getAvailableUserSeats?companyId=${companyId}`,
//     method: "GET",
//   });
//   const content = await response.data;

//   dispatch({
//     type: GET_AVAILABLE_USER_SEATS,
//     availableUserSeats: content.availableUserSeats,
//   });
// };
export const getAvailableDivisionsDataToStore = async (
  companyId,
  { callback, dispatchRedux }
) => {
  return await getAvailableDivisionsData(companyId, callback).then((data) => {
    dispatchRedux(store.actions.company.setAvailableDivisions(data));
  });
};
export const getAvailableDivisionsData = async (companyId, callback) => {
  return await API.getData(
    `/company/getAvailableDivisions?companyId=${companyId}`,
    callback
  );
  // const response = await server({
  //   url: `/company/getAvailableDivisions?companyId=${companyId}`,
  //   method: "GET",
  // });
  // const content = await response.data;

  // dispatch({
  //   type: GET_AVAILABLE_DIVISIONS,
  //   availableDivisions: content.availableDivisions,
  // });
};

// export const getAvailableDivisions = async (dispatch, companyId) => {
//   const response = await server({
//     url: `/company/getAvailableDivisions?companyId=${companyId}`,
//     method: "GET",
//   });
//   const content = await response.data;

//   dispatch({
//     type: GET_AVAILABLE_DIVISIONS,
//     availableDivisions: content.availableDivisions,
//   });
// };

export const getAvailableSubsidiariesData = async (companyId, callback) => {
  return await API.getData(
    `/company/getAvailableSubsidiaries?companyId=${companyId}`,
    callback
  );
  // const response = await server({
  //   url: `/company/getAvailableSubsidiaries?companyId=${companyId}`,
  //   method: "GET",
  // });
  // const content = await response.data;

  // dispatch({
  //   type: GET_AVAILABLE_SUBSIDIARIES,
  //   availableSubsidiaries: content.availableSubsidiaries,
  // });
};

// export const getAvailableSubsidiaries = async (dispatch, companyId) => {
//   const response = await server({
//     url: `/company/getAvailableSubsidiaries?companyId=${companyId}`,
//     method: "GET",
//   });
//   const content = await response.data;

//   dispatch({
//     type: GET_AVAILABLE_SUBSIDIARIES,
//     availableSubsidiaries: content.availableSubsidiaries,
//   });
// };

export const addMaterialData = async (material, callback) => {
  return await API.postData("/material/addMaterial", material, callback);
  // const response = await server({
  //   url: "/material/addMaterial",
  //   method: "POST",
  //   headers: server.headers,
  //   data: material,
  // });

  // const content = response.data;

  // dispatch({
  //   type: ADD_MATERIALS,
  //   material: { id: content.id, ...material },
  // });

  // setNewMaterial({ id: content.id, ...material });
};

// export const addMaterial = async (material, dispatch, setNewMaterial) => {
//   const response = await server({
//     url: "/material/addMaterial",
//     method: "POST",
//     headers: server.headers,
//     data: material,
//   });

//   const content = response.data;

//   dispatch({
//     type: ADD_MATERIALS,
//     material: { id: content.id, ...material },
//   });

//   setNewMaterial({ id: content.id, ...material });
// };

export const addRequirementData = async (requirement, callback) => {
  return await API.postData(
    "/requirement/addRequirement",
    requirement,
    callback
  );
  // const response = await server({
  //   url: "/requirement/addRequirement",
  //   method: "POST",
  //   headers: server.headers,
  //   data: requirement,
  // });

  // const content = response.data;
  // dispatch({
  //   type: ADD_REQUIREMENTS,
  //   requirement: { id: content.id, ...requirement },
  // });

  // setNewRequirement({ id: content.id, ...requirement });
};

// export const addRequirement = async (
//   requirement,
//   dispatch,
//   setNewRequirement
// ) => {
//   const response = await server({
//     url: "/requirement/addRequirement",
//     method: "POST",
//     headers: server.headers,
//     data: requirement,
//   });

//   const content = response.data;
//   dispatch({
//     type: ADD_REQUIREMENTS,
//     requirement: { id: content.id, ...requirement },
//   });

//   setNewRequirement({ id: content.id, ...requirement });
// };

export const addCustomerData = async (customer, callback) => {
  return await API.postData("/customer/addCustomer", customer, callback);
  // const response = await server({
  //   url: "/customer/addCustomer",
  //   method: "POST",
  //   headers: server.headers,
  //   data: customer,
  // });
  // const content = response.data;
  // dispatch({
  //   type: ADD_CUSTOMERS,
  //   customer: { id: content.id, ...customer },
  // });
  // setNewCustomer({ id: content.id, ...customer });
};

// export const addCustomer = async (customer, dispatch, setNewCustomer) => {
//   const response = await server({
//     url: "/customer/addCustomer",
//     method: "POST",
//     headers: server.headers,
//     data: customer,
//   });
//   const content = response.data;
//   dispatch({
//     type: ADD_CUSTOMERS,
//     customer: { id: content.id, ...customer },
//   });
//   setNewCustomer({ id: content.id, ...customer });
// };

export const addPersonData = async (person, callback) => {
  return await API.postData("/person/addPerson", person, callback);
  // const response = await server({
  //   url: "/person/addPerson",
  //   method: "POST",
  //   headers: server.headers,
  //   data: person,
  // });
  // const content = response.data;
  // dispatch({
  //   type: ADD_PERSONS,
  //   person: { id: content.id, ...person },
  // });
  // setNewPerson({ id: content.id, ...person });
};
// export const addPerson = async (person, dispatch, setNewPerson) => {
//   const response = await server({
//     url: "/person/addPerson",
//     method: "POST",
//     headers: server.headers,
//     data: person,
//   });
//   const content = response.data;
//   dispatch({
//     type: ADD_PERSONS,
//     person: { id: content.id, ...person },
//   });
//   setNewPerson({ id: content.id, ...person });
// };

export const updateCompanyStatusData = async (data, callback) => {
  const { companyIds, status } = data;
  const baseUrl = `/company/updateCompanyStatus?status=${status}`;

  const rawResponse = await server({
    url: baseUrl,
    method: "PUT",
    data: JSON.stringify(companyIds),
  });

  if (rawResponse.status === 200 && callback) {
    callback(rawResponse);
  }
};

// export const updateCompanyStatus = async (
//   companyIds,
//   status,
//   statusId,
//   dispatch
// ) => {
//   const baseUrl = `/company/updateCompanyStatus?status=${status}`;

//   const rawResponse = await server({
//     url: baseUrl,
//     method: "PUT",
//     data: JSON.stringify(companyIds),
//   });

//   if (rawResponse.status === 200 && dispatch) {
//     dispatch({
//       type: EDIT_COMPANY_STATUS,
//       statusId,
//       companyIds,
//     });
//   }
// };
