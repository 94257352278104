import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const ProjectsWithMissingInfo = (props) => {
  const { open, handleClose, title, rows } = props;
  const classes = useStyles();
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={classes.tableHead}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      if (column.id === "name") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Link
                              to={
                                props.row.projectStatusTypeId < 7
                                  ? "/projectDetails/generalInformation/" +
                                    props.row.id
                                  : "/projectDetails/contractInformation/" +
                                    props.row.id
                              }
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </Link>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const columns = [
  {
    id: "name",
    label: "Name",
    minWidth: 150,
    align: "center",
  },
  {
    id: "proposalNumber",
    label: "Proposal Number",
    minWidth: 150,
    align: "center",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableHead: {
    fontWeight: "bold",
    fontSize: theme.typography.h6.fontSize,
  },
  modalTitle: {
    fontWeight: "bold",
    fontSize: theme.typography.h6.fontSize,
  },
  container: {
    height: "20rem",
    maxWidth: "32rem",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));
export default ProjectsWithMissingInfo;
