export const selectTimeReport = (state) => {
  return state.report.timeReports;
};

export const selectTeamReport = (state) => {
  return state.report.teamReports;
};

export const selectLocationReport = (state) => {
  return state.report.locationReports;
};

export const selectMarketReport = (state) => {
  return state.report.marketReports;
};

export const selectCustomerReport = (state) => {
  return state.report.customerReports;
};
