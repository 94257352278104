import React, { useState } from "react";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { checkPermission } from "../../../utils/helperFunctions";
import { createProject } from "../../../actions/projectActions";
import AddProjectForm from "./AddProjectForm";
import NotificationPopUp from "../../utils/NotificationPopUp";
import * as store from "../../../state";

const AddProject = () => {
  const history = useHistory();
  const classes = useStyles();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId = loggedInUser.userCompany.id;
  const dispatchRedux = useDispatch();

  const { roleId } = loggedInUser;
  const [open, setOpen] = useState(true);

  const initialValuesForNewProject = {
    projectStatusTypeId: 1,
    // general info
    id: 0,
    name: "",
    division: null,
    subsidiary: null,
    companyId,
    description: "",
    customer: null,
  };

  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");

  const [initialValues, setInitialValues] = useState(
    initialValuesForNewProject
  );

  const handleClose = () => {
    history.goBack();
    setOpen(false);
  };

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const onSuccess = (proposalNumber, projectName, projectId) => {
    setIsNotificationPopUpOpen(true);
    setNotificationTitle("New Project Created");
    setNotificationText(
      <p>
        Project <b>{projectName}</b> has been successfully created and is
        assigned the proposal number
        <b> {proposalNumber} </b>. You will also receive an email confirmation.
      </p>
    );
    setNotificationActions([
      {
        name: "CANCEL",
        action: () => {
          handleClose();
          handleCloseModal();
        },
      },
      {
        name: "GO TO PROJECT",
        action: () => {
          handleCloseModal();
          setOpen(false);
          history.push(`/projectDetails/generalInformation/${projectId}`);
        },
      },
    ]);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      const project = {
        ...values,
        subsidiaryId: values.subsidiary.id,
        divisionId: values.division.id,
      };
      createProject(project, onSuccess, roleId, dispatchRedux);
    },
  });

  return (
    <Dialog
      open={open}
      fullWidth
      id={"addProjectModal"}
      maxWidth="xs"
      aria-labelledby="form-dialog-title"
      onClose={handleClose}
      scroll="paper"
    >
      <div className={classes.root}>
        <DialogTitle id="addProjectTitle">
          <Box className={classes.title}>New Project</Box>
        </DialogTitle>
        <DialogContent
          id={"addProjectContent"}
          className={classes.modalContent}
        >
          <form>
            <AddProjectForm
              setInitialValues={setInitialValues}
              initialValues={initialValues}
              formik={formik}
              companyId={companyId}
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            variant="outlined"
            size="medium"
            id={"cancelAddProjectButton"}
            className={classes.cancelButton}
            onClick={handleClose}
          >
            <Box>Cancel</Box>
          </Button>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            id={"createProjectButton"}
            onClick={formik.handleSubmit}
            disabled={checkPermission([5], roleId)}
          >
            <Box>Create</Box>
          </Button>
        </DialogActions>
      </div>
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type="success"
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </Dialog>
  );
};
export default AddProject;

const validationSchema = yup.object({
  projectStatusTypeId: yup.number(),
  name: yup
    .string("Enter the Name")
    .required("Name is required")
    .max(35, "Maximum of 35 characters allowed"),
  subsidiary: yup
    .mixed("Select a subsidiary")
    .required("Subsidiary is required"),
  division: yup.mixed("Select a division").required("Division is required"),
  description: yup
    .string("Enter a Description")
    .required("Description is required")
    .max(1000, "Maximum of 1000 characters allowed"),
  customer: yup.mixed("Select a customer").required("Customer is required"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    minHeight: "35rem",
    flexDirection: "column",
    "& .MuiDialogTitle-root": {
      margin: "auto",
      padding: ".75rem .75rem 0 .75rem",
    },
  },
  modalContent: {
    overflowY: "auto",
    paddingBottom: "2rem",
    // move this to a global variable of styles //
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  modalActions: {
    bottom: "0rem",
    position: "sticky",
    backgroundColor: "#fff",
    justifyContent: "space-between",
  },
  title: {
    fontSize: theme.typography.h3.fontSize,
  },
}));
