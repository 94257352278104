import { server } from "../api/server";
import * as API from "./_apiCalls";

export const getUsersData = async (companyId, callback) => {
  return API.getData(`/user/getUsers?companyId=${companyId}`, callback);
  // const response = await server({
  //   url: `/user/getUsers?companyId=${companyId}`,
  //   method: "GET",
  // });

  // callback && callback(response.data);
  // return response.data;
};

export const getUsersBaseInfo = async (companyId, callback) => {
  return API.getData(`/user/getUsersBaseInfo?companyId=${companyId}`, callback);
};

export const getSystemUsers = async () => {
  const response = await server({
    url: "/user/GetSysAdminUsers",
    method: "GET",
  });

  return response.data;
};

export const updateUserStatus = async (userIds, status) => {
  const baseUrl = `/user/updateUserStatus?status=${status}`;

  const rawResponse = await server({
    url: baseUrl,
    method: "POST",
    data: JSON.stringify(userIds),
  });

  if (rawResponse.status === 200) {
    return userIds;
  }

  return [];
};

export const getUser = async (userId) => {
  const response = await server({
    url: `/user/getUser/${userId}`,
    method: "GET",
  });

  return response.data;
};

export const getTitles = async () => {
  const response = await server({
    url: "title/GetUserTitles",
    method: "GET",
  });

  return response.data;
};

export const getRoles = async () => {
  const response = await server({
    url: "role/GetRoles",
    method: "GET",
  });

  return response.data;
};

export const addUser = async (user) => {
  const response = await server({
    url: "/user/createUser",
    method: "POST",
    data: JSON.stringify(user),
  });

  return response.data;
};

export const updateUser = async (user) => {
  const response = await server({
    url: "/user/UpdateUser",
    method: "POST",
    data: JSON.stringify(user),
  });

  return response.data;
};

export const getUserStatuses = async () => {
  const response = await server({
    url: "/user/getuserStatuses",
    method: "GET",
  });
  return response.data;
};
