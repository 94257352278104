import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { checkPermission } from "../../../../utils/helperFunctions";
import ValidationInput from "../../../elements/ValidationInput";
import * as store from "../../../../state";

const BiddingFinancials = (props) => {
  const { formik } = props;
  const classes = useStyles();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const roleId = loggedInUser.roleId;

  return (
    <div className={classes.container}>
      <h4 className={classes.pageTitle}>Bidding Financials (CURRENT)</h4>
      <Grid container className={classes.content}>
        <Grid item xs={2} className={classes.item}>
          <ValidationInput
            id="bidValue"
            name="bidValue"
            label={
              formik.values.statusTypeId >= 6 ? " Bid Value*" : " Bid Value"
            }
            disabled={checkPermission([5], roleId)}
            value={formik.values.bidValue}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.bidValue && Boolean(formik.errors.bidValue)}
            helperText={formik.touched.bidValue && formik.errors.bidValue}
            valueLabel="$"
            valueLabelPosition="front"
          />
        </Grid>
        <Grid item xs={2} className={classes.item}>
          <ValidationInput
            id="estimatedCost"
            name="estimatedCost"
            label={
              formik.values.statusTypeId >= 6
                ? " Estimated Cost*"
                : " Estimated Cost"
            }
            disabled={checkPermission([5], roleId)}
            value={formik.values.estimatedCost}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.estimatedCost &&
              Boolean(formik.errors.estimatedCost)
            }
            helperText={
              formik.touched.estimatedCost && formik.errors.estimatedCost
            }
            valueLabel="$"
            valueLabelPosition="front"
          />
        </Grid>
        <Grid item xs={2} className={classes.item}>
          <ValidationInput
            id="estimatedLaborCost"
            name="estimatedLaborCost"
            label={
              formik.values.statusTypeId >= 6
                ? "Estimated Labor Cost*"
                : "Estimated Labor Cost"
            }
            disabled={checkPermission([5], roleId)}
            value={formik.values.estimatedLaborCost}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.estimatedLaborCost &&
              Boolean(formik.errors.estimatedLaborCost)
            }
            helperText={
              formik.touched.estimatedLaborCost &&
              formik.errors.estimatedLaborCost
            }
            valueLabel="$"
            valueLabelPosition="front"
          />
        </Grid>
        <Grid item xs={2} className={classes.lastItem}>
          <ValidationInput
            id="estimatedLaborHours"
            label={
              formik.values.statusTypeId >= 6
                ? "Estimated Labor Hours*"
                : "Estimated Labor Hours"
            }
            name="estimatedLaborHours"
            disabled={checkPermission([5], roleId)}
            value={formik.values.estimatedLaborHours}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.estimatedLaborHours &&
              Boolean(formik.errors.estimatedLaborHours)
            }
            helperText={
              formik.touched.estimatedLaborHours &&
              formik.errors.estimatedLaborHours
            }
            valueLabel="hrs"
            valueLabelPosition="back"
          />
        </Grid>
        <Grid item xs={4} className={classes.item}>
          <p className={classes.compositeRate}>
            {" "}
            Estimated composite Rate:
            <b className={classes.compositeRateValue}>
              {formik.values.estimatedLaborCost === null ||
              formik.values.estimatedLaborCost === "" ||
              !formik.values.estimatedLaborHours ||
              isNaN(
                formik.values.estimatedLaborCost /
                  formik.values.estimatedLaborHours
              )
                ? " "
                : " $ " +
                  (
                    Math.round(
                      (formik.values.estimatedLaborCost /
                        formik.values.estimatedLaborHours) *
                        100
                    ) / 100
                  ).toFixed(2)}
            </b>
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "4rem",
  },
  content: {
    paddingLeft: 15,
  },
  item: {
    minWidth: "9rem",
    marginRight: "2rem",
  },
  lastItem: {
    minWidth: "10rem",
  },
  pageTitle: {
    textTransform: "uppercase",
    backgroundColor: "#3c4655",
    color: "white",
    padding: 9,
    borderRadius: 5,
  },
  compositeRate: {
    marginLeft: "2rem",
    fontSize: "1rem",
  },
  compositeRateValue: {
    marginLeft: "2rem",
  },
}));

export default BiddingFinancials;
