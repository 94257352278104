import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const New = () => {
  const classes = useStyles();
  return (
    <div className={classes.orientation}>
      {/* <Button variant="outlined" className={classes.noBid}>
        No Bid
      </Button> */}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  projectDottedButtonRoot: {
    minWidth: 0,
    maxWidth: 10,
  },
  noBid: {
    padding: "0",
    margin: "0rem",
    marginBottom: "0.3rem",
    borderRadius: "6px",
    maxWidth: "3.5rem",
  },
  orientation: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "10rem",
  },
}));

export default New;
