import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Divider, Box, Paper } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  editCompanyData,
  getCompanyData,
  //getCompanyDataToStore,
} from "../../actions/companyActions";
// import { CompanyContext } from "../../contexts/CompanyContext";
import CompanyForm from "./CompanyForm";
import NotificationPopUp from "../utils/NotificationPopUp";
import * as store from "../../state";

const CompanyDashboard = (props) => {
  const { id, view } = props;
  // const { dispatch } = useContext(CompanyContext);
  //const dispatchRedux = useDispatch();
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId =
    view === "sysAdmin" ? parseInt(id) : loggedInUser.userCompany?.id;
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({
    name: "",
    address: "",
    address2: "",
    city: "",
    stateId: "",
    countryId: "",
    zipCode: "",
    phone: "",
    fax: "",
    website: "",
    logo: null,
    industryClassificationId: "",
    statusId: null,
  });

  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationActions, setNotificationActions] = useState([]);

  useEffect(async () => {
    // const company = await getCompany(companyId, dispatch, true);
    //const company = await getCompanyDataToStore(companyId, { dispatchRedux });
    const { data: company } = await getCompanyData(companyId);
    setInitialValues(company);
  }, []);

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const onSubmit = async (values, handleCallback) => {
    if (values.statusId !== initialValues.statusId && values.statusId === 2) {
      setNotificationTitle("Deactivate Account");
      setNotificationText(
        "Deactivating this account will prevent all users in this account from logging in.  Do you want to continue?"
      );
      setNotificationActions([
        {
          name: "DEACTIVATE",
          action: () => {
            editCompanyData(
              values,
              (response) =>
                response.status === 200 &&
                handleCallback &&
                handleCallback(response)
            );
            handleCloseModal();
          },
        },
        { name: "CANCEL", action: () => handleCloseModal() },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      await editCompanyData(
        values,
        (response) =>
          response.status === 200 && handleCallback && handleCallback(response)
      );
    }
  };

  return (
    <>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        {view === "sysAdmin" ? "SYSTEM ADMINISTRATION" : "ADMINISTRATION"}
      </Box>
      <Divider className={classes.divider} />
      <CompanyForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        handleClose={handleCloseModal}
        title="Account Information"
        page="edit"
        id={companyId}
        view={view}
        setNotificationActions={setNotificationActions}
        setOpen={setIsNotificationPopUpOpen}
        setNotificationTitle={setNotificationTitle}
        setNotificationText={setNotificationText}
      />
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type="warning"
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    color: "#4A5568",
  },
  divider: {
    margin: "1rem 0",
  },
  additionalComponent: {
    marginLeft: "2rem",
    "& p": {
      margin: "0",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "1rem",
    },
  },
}));
export default CompanyDashboard;
