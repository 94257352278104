import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Select, Box } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useHistory, useLocation } from "react-router-dom";
import * as store from "../../state";

const MenuSelect = (props) => {
  const { name, options, key } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatchRedux = useDispatch();

  const currentPath = location.pathname;
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const companyId = loggedInUser.userCompany.id;

  const isMenuSelected = () => {
    if (options.filter((option) => option.link === currentPath).length === 1) {
      return true;
    }
    return false;
  };

  const handleOptionClick = (link) => {
    if (link.includes("systemAdministration")) {
      dispatchRedux(
        store.actions.login.setLoggedInUser({
          ...loggedInUser,
          userCompany: null,
          companyId,
        })
      );
    }
    history.push(link);
  };

  const renderValue = (name) => <p className={classes.text}>{name}</p>;

  return (
    <>
      {options.length === 1 ? (
        <>
          <Box
            className={!isMenuSelected() ? classes.link : classes.linkUnderline}
            onClick={() => handleOptionClick(options[0].link)}
          >
            {name}
          </Box>
        </>
      ) : (
        <Select
          variant="standard"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          IconComponent={KeyboardArrowDownIcon}
          id="userDetailsDropdown"
          defaultValue="Unknown"
          renderValue={renderValue}
          value={name || ""}
          className={!isMenuSelected() ? classes.root : classes.rootUnderline}
          disableUnderline={true}
          key={key}
        >
          <MenuItem value={name} className={classes.hiddenItem} />
          {options.map((option) => (
            <MenuItem
              id={option.name + "Option"}
              key={option.name}
              value="User Profile"
              onClick={() => handleOptionClick(option.link)}
            >
              {option.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: "center",
    fontWeight: "bold",
    color: theme.palette.info.main,
  },
  link: {
    backgroundColor: "white",
    marginLeft: "1rem",
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.info.main,
    cursor: "pointer",
    padding: "1.175rem 0",
  },
  linkUnderline: {
    backgroundColor: "white",
    marginLeft: "1rem",
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.info.main,
    padding: "1.175rem 0",
    cursor: "pointer",
    borderBottom: `0.3rem solid ${theme.palette.info.main}`,
  },
  root: {
    "&:after": {
      backgroundColor: "white",
    },
    marginLeft: "1rem",
    "& .MuiSelect-icon": {
      color: theme.palette.info.main,
    },
  },
  rootUnderline: {
    "&:after": {
      backgroundColor: "white",
    },
    marginLeft: "1rem",
    "& .MuiSelect-icon": {
      color: theme.palette.info.main,
    },
    borderBottom: `0.3rem solid ${theme.palette.info.main}`,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  hiddenItem: {
    visibility: "hidden",
    padding: "0",
  },
}));

export default MenuSelect;
