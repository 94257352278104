import React from "react";
import { useSelector } from "react-redux";

import * as store from "../state";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const Loader = () => {
  const classes = useStyles();
  const loaderVisible = useSelector(store.selectors.app.selectLoaderVisible);

  return (
    <Backdrop className={classes.backdrop} open={loaderVisible}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: `${theme.zIndex.drawer + 1000} !important`,
    color: "#fff",
  },
}));

export default Loader;
