import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Divider, Box, Paper } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
// import { ResourcesContext } from "../../contexts/ResourcesContext";
// import { CompanyContext } from "../../contexts/CompanyContext";
// import { SubsidiaryContext } from "../../contexts/SubsidiaryContext";
import {
  getIndustriesDataToStore,
  getStatusesDataToStore,
} from "../../actions/resourcesAction";
import NotificationPopUp from "../utils/NotificationPopUp";
import {
  updateDivisionStatusData,
  getDivisionData,
  getDivisionsData,
  editDivisionData,
} from "../../actions/divisionActions";
import DivisionForm from "./DivisionForm";
import { getCompanyDataToStore } from "../../actions/companyActions";
import { getSubsidiariesData } from "../../actions/subsidiaryActions";
import * as store from "../../state";
//import { editDivision, setDivisions } from "../../state/action/division";

const EditDivision = (props) => {
  const dispatchRedux = useDispatch();
  //const resourcesContext = useContext(ResourcesContext);
  const loggedInUser = useSelector(store.selectors.login.selectLoggedInUser);
  const divisions = useSelector(store.selectors.division.selectDivisions);
  // const companyContext = useContext(CompanyContext);
  // const subsidiaryContext = useContext(SubsidiaryContext);
  const subsidiaries = useSelector(
    store.selectors.subsidiary.selectSubsidiaries
  );
  const company = useSelector(store.selectors.company.selectCompany);
  const companyId =
    props.view === "sysAdmin"
      ? parseInt(props.companyId)
      : loggedInUser.userCompany.id;
  // companyContext.state.company.maxNumberOfDivisions;
  const id =
    props.view === "sysAdmin" ? parseInt(props.divisionId) : parseInt(props.id);
  const divisionDashboardUrl =
    props.view === "sysAdmin"
      ? `/systemAdministration/divisions/${props.companyId}`
      : "/divisions";
  const classes = useStyles();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState({
    companyId,
    name: "",
    divisionId: "",
    industryClassificationId: "",
    subsidiaryId: "",
  });
  const [divisionStatus, setDivisionStatus] = useState(1);
  const [initialSubsidiaryId, setInitialSubsidiaryId] = useState("");

  const [isNotificationPopUpOpen, setIsNotificationPopUpOpen] = useState(false);
  const [notificationActions, setNotificationActions] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const handleDelete = () => {
    if (
      divisions.filter(
        (row) => row.statusId === 1 && row.subsidiaryId === initialSubsidiaryId
      ).length !== 1
    ) {
      setNotificationTitle("Delete Division");
      setNotificationText(
        "The division will be removed. Are you sure you want to delete the division?"
      );
      setNotificationType("warning");
      setNotificationActions([
        {
          name: "DELETE",
          action: () => {
            deleteDivision();
            handleClose();
          },
        },
        { name: "CANCEL", action: () => handleCloseModal() },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      setNotificationTitle("Delete Division");
      setNotificationText(
        "Deletion cannot be performed. At least one division must remain active for each subsidiary."
      );
      setNotificationType("error");
      setNotificationActions([
        { name: "OK", action: () => handleCloseModal() },
      ]);
      setIsNotificationPopUpOpen(true);
    }
  };

  const deleteDivision = () => {
    updateDivisionStatusData([id], "DELETED").then((response) => {
      dispatchRedux(
        store.actions.division.editDivision({
          divisionIds: response.data,
          statusId: 3,
        })
      );
    });
  };

  const handleCloseModal = () => {
    setIsNotificationPopUpOpen(false);
  };

  const handleClose = () => {
    handleCloseModal();
    history.push(divisionDashboardUrl);
  };

  const handleSuccess = () => {
    handleClose();
  };

  const handleSuccessActivate = () => {
    setNotificationTitle("Division has been reactivated");
    setNotificationText(
      "The selected division has been successfully reactivated."
    );
    setNotificationType("success");
    setNotificationActions([{ name: "OK", action: () => handleClose() }]);
    setIsNotificationPopUpOpen(true);
  };

  const getSubsidiary = (id) =>
    subsidiaries.filter((subsidiary) => subsidiary.id === id);

  const onSubmit = async (values, subsidiary) => {
    if (values.statusId !== initialValues.statusId) {
      if (values.statusId === 1) {
        if (
          subsidiary[0].divisions.filter((division) => division.statusId === 1)
            .length >= company.maxNumberOfDivisions
        ) {
          setNotificationTitle("Activate Division Limit");
          setNotificationText(
            "You have reached the maximum number of active divisions per subsidiary. Please contact our support for help."
          );
          setNotificationType("warning");
          setNotificationActions([
            { name: "OK", action: () => handleCloseModal() },
          ]);
          setIsNotificationPopUpOpen(true);
        } else {
          await editDivisionData(values, handleSuccessActivate);
        }
      } else if (values.statusId === 2) {
        if (
          getSubsidiary(initialSubsidiaryId)[0].divisions.filter(
            (subsidiary) => subsidiary.statusId === 1
          ).length === 1
        ) {
          setNotificationTitle("Deactivate Division");
          setNotificationText(
            "Deactivation cannot be performed. At least one division per subsidiary must remain active."
          );
          setNotificationType("error");
          setNotificationActions([
            { name: "OK", action: () => handleCloseModal() },
          ]);
          setIsNotificationPopUpOpen(true);
        } else {
          setNotificationTitle("Deactivate Division");
          setNotificationText(
            "Deactivating a division will make it unavailable for projects. Do you want to continue?"
          );
          setNotificationType("warning");
          setNotificationActions([
            {
              name: "DEACTIVATE",
              action: async () => {
                await editDivisionData(values, handleSuccess).then(() => {
                  handleClose();
                });
              },
            },
            { name: "CANCEL", action: () => handleClose() },
          ]);
          setIsNotificationPopUpOpen(true);
        }
      }
    } else if (
      getSubsidiary(initialSubsidiaryId)[0].divisions.filter(
        (subsidiary) => subsidiary.statusId === 1
      ).length === 1 &&
      subsidiary[0].id !== initialSubsidiaryId &&
      values.statusId === 1
    ) {
      setNotificationTitle("Edit Division");
      setNotificationText(
        "Edit cannot be performed. At least one division must remain active for each subsidiary."
      );
      setNotificationType("error");
      setNotificationActions([
        { name: "OK", action: () => handleCloseModal() },
      ]);
      setIsNotificationPopUpOpen(true);
    } else if (
      subsidiary[0].divisions.filter((division) => division.statusId === 1)
        .length >= company.maxNumberOfDivisions &&
      subsidiary[0].id !== initialSubsidiaryId &&
      values.statusId === 1
    ) {
      setNotificationTitle("Division Limit");
      setNotificationText(
        "You have reached the maximum number of divisions per subsidiary. Please contact our support for help."
      );
      setNotificationType("warning");
      setNotificationActions([
        { name: "OK", action: () => setIsNotificationPopUpOpen(false) },
      ]);
      setIsNotificationPopUpOpen(true);
    } else {
      editDivisionData(values, handleSuccess).then(() => {
        history.push(divisionDashboardUrl);
      });
    }
  };

  const onCancel = () => {
    handleClose();
  };

  useEffect(() => {
    getDivisionData(id).then((newInitialValues) => {
      newInitialValues.divisionId
        ? setInitialValues(newInitialValues)
        : setInitialValues({ ...newInitialValues, divisionId: "" });
      setDivisionStatus(newInitialValues.statusId);
      setInitialSubsidiaryId(newInitialValues.subsidiaryId);
    });
  }, []);

  useEffect(async () => {
    // getIndustries(resourcesContext.dispatch);
    // getStatuses(resourcesContext.dispatch);

    getDivisionsData(companyId).then(({ data }) => {
      dispatchRedux(store.actions.division.setDivisions(data));
    });
    // getCompany(companyId, companyContext.dispatch);
    // getSubsidiaries(subsidiaryContext.dispatch, companyId);
    await getCompanyDataToStore(companyId, { dispatchRedux });
    await getSubsidiariesData(companyId);

    await getIndustriesDataToStore({ dispatchRedux });
    await getStatusesDataToStore({ dispatchRedux });

    //  }, [resourcesContext.dispatch]);
  }, []);

  return (
    <>
      <Box className={classes.pageTitle}>
        <Paper elevation={3}>
          <Settings />
        </Paper>
        {props.view === "sysAdmin" ? "SYSTEM ADMINISTRATION" : "ADMINISTRATION"}
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.pageSubTitle}>
        {`Edit Division: ${initialValues.name}`}
      </Box>
      <DivisionForm
        page="edit"
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
        handleDelete={handleDelete}
        companyId={companyId}
        divisionStatus={divisionStatus}
      />
      <NotificationPopUp
        isOpen={isNotificationPopUpOpen}
        handleClose={handleCloseModal}
        type={notificationType}
        title={notificationTitle}
        text={notificationText}
        actions={notificationActions}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    fontSize: "2.25rem",
    fontWeight: "bold",
    display: "flex",
    color: "#4A5568",
    "& div": {
      width: "3rem",
      height: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "1rem",
      "& svg": {
        fontSize: "2.25rem",
      },
    },
  },
  pageSubTitle: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#4A5568",
    marginBottom: ".5rem",
  },
  divider: {
    margin: "1rem 0",
  },
  additionalComponent: {
    marginLeft: "2rem",
    "& p": {
      margin: "0",
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "1rem",
    },
  },
  label: {
    fontSize: theme.typography.subtitle1.fontSize,
    margin: "0.5rem 0",
    fontWeight: "bold",
  },
}));

export default EditDivision;
