export const getChartObject = (chartSettings) => ({
  height: "90%",
  width: "100%",
  numberFormatter: {
    type: "NumberFormatter",
    numberFormat: "#.#a",
    smallNumberThreshold: 0,
  },
  xAxes: [
    {
      type: "CategoryAxis",
      dataFields: {
        category: chartSettings.chartCategory,
      },
      events: {
        sizechanged(ev) {
          const axis = ev.target;
          const cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
          if (cellWidth < axis.renderer.labels.template.maxWidth) {
            axis.renderer.labels.template.rotation = -45;
            axis.renderer.labels.template.horizontalCenter = "right";
            axis.renderer.labels.template.verticalCenter = "middle";
          } else {
            axis.renderer.labels.template.rotation = 0;
            axis.renderer.labels.template.horizontalCenter = "middle";
            axis.renderer.labels.template.verticalCenter = "top";
          }
        },
      },
    },
  ],
  yAxes: [
    {
      id: "valueAy",
      type: "ValueAxis",
      title: {
        text: chartSettings.reportFilterOutputLabel,
      },
      min: 0,
    },
    {
      id: "projectAy",
      type: "ValueAxis",
      title: {
        text: "No. of Projects",
      },
      renderer: {
        opposite: true,
      },
      syncWithAxis: "valueAy",
      min: 0,
    },
  ],
  series: [
    {
      type: "ColumnSeries",
      dataFields: {
        valueY: "backlog",
        categoryX: chartSettings.chartCategory,
      },
      stacked: true,
      stroke: "#AFDA95",
      legendSettings: {
        labelText: "Backlog",
      },
      columns: {
        config: {
          fill: "#AFDA95",
        },
        /* eslint no-template-curly-in-string: "off" */ // Next line is from amchart formating for tooltipText
        tooltipText: `${chartSettings.chartTooltipText}: [bold]{categoryX}[/] \n Backlog: [bold]\${backlog.formatNumber("#,###.")}[/]`,
        events: {
          hit(ev) {
            if (
              chartSettings.handleChartClickFilterUpdate &&
              ev.target.dataItem.dataContext
            ) {
              chartSettings.handleChartClickFilterUpdate(
                ev.target.dataItem.dataContext,
                chartSettings.chartLevelSettings
              );
            }
          },
        },
      },
    },
    {
      type: "ColumnSeries",
      dataFields: {
        valueY: "pipeline",
        categoryX: chartSettings.chartCategory,
      },
      stacked: true,
      stroke: "#7995E0",
      legendSettings: {
        labelText: "Pipeline",
      },
      columns: {
        config: {
          fill: "#7995E0",
        },
        tooltipText: `${chartSettings.chartTooltipText}: [bold]{categoryX}[/] \n Pipeline: [bold]\${pipeline.formatNumber("#,###.")}[/]`,
        events: {
          hit(ev) {
            if (
              chartSettings.handleChartClickFilterUpdate &&
              ev.target.dataItem.dataContext
            ) {
              chartSettings.handleChartClickFilterUpdate(
                ev.target.dataItem.dataContext,
                chartSettings.chartLevelSettings
              );
            }
          },
        },
      },
    },
    {
      type: "LineSeries",
      bullets: [
        {
          type: "CircleBullet",
          fill: "#ffeb3b",
          tooltipText: `${chartSettings.chartTooltipText}: [bold]{categoryX}[/] \n Projects: [bold]{valueY}[/]`,
          events: {
            hit(ev) {
              if (
                chartSettings.handleChartClickFilterUpdate &&
                ev.target.dataItem.dataContext
              ) {
                chartSettings.handleChartClickFilterUpdate(
                  ev.target.dataItem.dataContext,
                  chartSettings.chartLevelSettings
                );
              }
            },
          },
        },
      ],
      strokeWidth: chartSettings.chartLevelSettings === 0 ? 0 : 1,
      dataFields: {
        valueY: "projectsCount",
        categoryX: chartSettings.chartCategory,
      },
      yAxis: "projectAy",
      zIndex: 1,
      stroke: "#ffeb3b",
      legendSettings: {
        labelText: "Projects",
      },
    },
  ],
  legend: {
    type: "Legend",
  },
  scrollbarX: {
    startGrip: {
      icon: {
        disabled: true,
      },
    },
    endGrip: {
      icon: {
        disabled: true,
      },
    },
  },
  data: chartSettings.chartData,
});
