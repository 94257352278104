import React from "react";
import { Box } from "@material-ui/core";
import { DataGrid, useGridSlotComponentProps } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { getLabelTitle, getStatusLabel } from "../../utils/helperFunctions";
import TablePaginationActions from "../elements/TablePaginationActions";

const DivisionsTable = (props) => {
  const { rows, view, statuses } = props;
  const baseDivisionurl =
    view === "sysAdmin"
      ? `/systemAdministration/editDivision/${props.companyId}?divisionId=`
      : "/editDivision/";
  const classes = useStyles();
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      type: "number",
    },
    {
      field: "name",
      headerName: "Division Name",
      width: 275,
      renderCell: (params) => (
        <Link to={`${baseDivisionurl}${params.row.id}`}>{params.value}</Link>
      ),
    },
    {
      field: "statusId",
      headerName: "Status",
      headerAlign: "right",

      width: 180,
      renderCell: (params) => (
        <Box
          fontSize="subtitle2.fontSize"
          className={clsx(
            classes[getStatusLabel(params.row.statusId, statuses)],
            classes.chip
          )}
        >
          {getStatusLabel(params.row.statusId, statuses)}
        </Box>
      ),
      valueGetter: (params) => getLabelTitle(params.row.statusId, statuses),
      valueFormatter: (params) => getLabelTitle(params.row.statusId, statuses),
    },
  ];

  return (
    <div className={classes.root}>
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
          headerClassName: classes.headerRoot,
        }))}
        scrollbarSize={5}
        pagination
        pageSize={10}
        components={{
          Pagination: CustomPagination,
        }}
      />
    </div>
  );
};

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();
  const setPage = (page) => {
    apiRef.current.setPage(page - 1);
  };
  return (
    <TablePaginationActions
      page={state.pagination.page + 1}
      setPage={setPage}
      rowsPerPage={
        state.pagination.pageSize !== 100 ? state.pagination.pageSize : ""
      }
      setRowsPerPage={apiRef.current.setPageSize}
      count={state.pagination.pageCount}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "36rem",
    height: "17rem",
    "& .MuiDataGrid-root .MuiDataGrid-colCellWrapper": {
      backgroundColor: " #fafafa",
    },
  },
  dateInput: {
    height: "48px",
    "& div": {
      height: "100%",
    },
  },
  headerRoot: {
    backgroundColor: "#fafafa",
    fontSize: "1rem",
  },
  chip: {
    fontWeight: "bold",
    width: "8rem",
    height: "2rem",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
  },
  requested: {
    color: "rgba(203, 213, 224, 0.8)",
  },
  new: {
    color: `${theme.palette.secondary.dark}`,
  },
  estimating: {
    color: "rgba(118, 169, 250, 0.7)",
  },
  noBfield: {
    color: "#c15700",
  },
  ACTIVE: {
    color: "#7FB49B",
  },
  INACTIVE: {
    color: "#757575",
  },
  DELETED: {
    color: "#FC0000",
  },
}));

export default DivisionsTable;
