import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const BidWon = () => {
  const classes = useStyles();
  return <div className={classes.orientation} />;
};

const useStyles = makeStyles(() => ({
  projectDottedButtonRoot: {
    minWidth: 0,
    maxWidth: 10,
  },
  noBid: {
    padding: "0",
    borderRadius: "6px",
  },
  orientation: {
    display: "flex",
    flexDirection: "column",
    minWidth: "10rem",
  },
}));

export default BidWon;
